import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useQuery } from "react-query";
import { useIncidentMessageModel, } from "../../../../../../components-care/models/IncidentMessageModel";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Loader } from "components-care/dist";
import Message from "./Message";
import { useDialogContext, useFormContext, useModelDelete, } from "components-care";
import { showErrorDialog } from "components-care/dist/non-standalone/Dialog/Utils";
import { useAuthProviderContext } from "../../../../../components/AuthProvider";
import { useFormEnterpriseClientIdOpt } from "../../../../Enterprise/utils/useFormEnterpriseClientId";
import { ReactQueryRetryHandler } from "../../../../../../constants";
const useStyles = makeStyles()({
    wrapper: {},
    root: {
        overflowY: "auto",
    },
});
const Chat = () => {
    const { id, values } = useFormContext();
    const [pushDialog] = useDialogContext();
    if (!id)
        throw new Error("ID is null. Can't render chat.");
    const { classes } = useStyles();
    const enterpriseClientId = useFormEnterpriseClientIdOpt();
    const model = useIncidentMessageModel(useMemo(() => ({ enterpriseClientId, incidentId: id }), [enterpriseClientId, id]));
    const authCtx = useAuthProviderContext();
    const { isLoading, data, error, refetch } = useQuery(model.getReactQueryKey("index", false), () => model.fetchAll({ sort: [{ field: "created_at", direction: -1 }] }), { retry: ReactQueryRetryHandler });
    const { content, created_by, created_by_user, created_at, has_uploads } = values;
    const { mutateAsync: deleteMessage } = useModelDelete(model);
    const handleDelete = useCallback(async (id) => {
        try {
            await deleteMessage(id);
            await refetch();
        }
        catch (e) {
            await showErrorDialog(pushDialog, e);
        }
    }, [deleteMessage, refetch, pushDialog]);
    const inScrollRef = useRef(null);
    const lastMessageId = useRef(null);
    // scroll into view when we post something
    useEffect(() => {
        if (!data)
            return;
        const messages = data[0];
        if (messages.length === 0)
            return;
        const lastMsg = messages[0];
        if (lastMsg.id === lastMessageId.current)
            return;
        lastMessageId.current = lastMsg.id;
        if (lastMsg.created_by !== authCtx.current_user.id)
            return;
        const target = inScrollRef.current?.parentElement;
        if (!target)
            return;
        target.scroll(0, target.scrollHeight - target.clientHeight);
    }, [data, authCtx]);
    if (error)
        return _jsx(Typography, { color: "error", children: error.message });
    if (isLoading || !data)
        return _jsx(Loader, {});
    return (_jsx("div", { className: classes.wrapper, ref: inScrollRef, children: _jsxs(Grid, { container: true, spacing: 2, className: classes.root, children: [_jsx(Message, { incidentId: id, id: null, tenant_id: enterpriseClientId, content: content, system: false, created_by: created_by, created_by_user: created_by_user, created_at: created_at, has_uploads: has_uploads, onDelete: handleDelete }), [...data[0]].reverse().map((record) => (_jsx(Message, { incidentId: id, onDelete: handleDelete, ...record }, record.id)))] }) }));
};
export default React.memo(Chat);
