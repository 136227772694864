import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid, Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeviceModelViewerDeviceLink from "./DeviceModelViewerDeviceLink";
import { useDeviceModelVersions } from "../../../MasterDataManagement/CatalogModels/components/DeviceModelVersionList";
const DeviceModelViewerTabModelVersions = (props) => {
    const { device } = props;
    const { t } = useTranslation("device-models");
    const { isLoading, data, error } = useDeviceModelVersions(device);
    return (_jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { variant: "caption2", color: "textSecondary", component: "p", children: t("viewer.model.versions") }), isLoading && _jsx(Skeleton, { variant: "text" }), error && (_jsx(Typography, { variant: "body1", component: "p", color: "error", children: error.message })), data && (_jsx(Typography, { variant: "body1", component: "p", children: data[0].map((version) => (_jsx(DeviceModelViewerDeviceLink, { clickable: version.id !== device.id, ...(device.id === version.id
                        ? device
                        : version) }, version.id))) }))] }, "prev-version"));
};
export default React.memo(DeviceModelViewerTabModelVersions);
