import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { FormControlLabel, Grid, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
const ContactsFilterBar = (props) => {
    const { customData, setCustomData } = props;
    const { t } = useTranslation("contact");
    const handleSwitch = useCallback((_evt, checked) => {
        setCustomData((old) => ({
            ...old,
            "filter[scope]": checked ? undefined : "tenant",
        }));
    }, [setCustomData]);
    return (_jsx(Grid, { item: true, children: _jsx(FormControlLabel, { control: _jsx(Switch, { checked: !customData["filter[scope]"], onChange: handleSwitch }), label: t("grid.filter.manufacturer") }) }));
};
export default React.memo(ContactsFilterBar);
