import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Checkbox, combineClassNames } from "components-care";
import { customColors } from "../../../../../theme";
export const COMPACT_LAYOUT_HEIGHT = 45;
const useStyles = makeStyles()((theme) => ({
    wrapper: {
        height: "100%",
    },
    root: {
        height: "calc(100% - 1px)",
        paddingRight: 8,
    },
    rootSelected: {
        backgroundColor: customColors.lighterBlue,
    },
    rootSelectable: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: customColors.lightBlue,
        },
    },
    checkboxContainer: {
        backgroundColor: "#d7d7d7",
    },
    checkboxContainerSelected: {
        backgroundColor: "rgb(169, 207, 237)",
    },
    checkbox: {
        top: "50%",
        transform: "translateY(-50%)",
    },
    borderBottom: {
        height: 1,
        backgroundColor: `rgb(205, 224, 243)`,
    },
    contentContainer: {
        overflow: "auto",
        padding: theme.spacing(1),
        minHeight: "100%",
    },
    iconWrapper: {
        minWidth: 100,
        maxWidth: 100,
        position: "relative",
    },
    icon: {
        height: "100%",
        padding: theme.spacing(1),
        "& > img, svg": {
            height: "100%",
            width: "auto",
            objectFit: "contain",
            maxWidth: "100%",
            left: "50%",
            position: "relative",
            transform: "translateX(-50%)",
        },
    },
    tags: {
        minWidth: 60,
        height: `calc(100% + ${theme.spacing(2)})`,
        padding: theme.spacing(1),
    },
}));
const DenseLayout = (props) => {
    const { id, icon, title, info, extraInfo, tags, selectable, selected, multiSelect, onSelect, } = props;
    const { classes } = useStyles();
    const handleSelect = useCallback(() => {
        onSelect(id, !selected);
    }, [onSelect, selected, id]);
    return (_jsxs("div", { className: classes.wrapper, children: [_jsxs(Grid, { container: true, spacing: 0, wrap: "nowrap", onClick: handleSelect, className: combineClassNames([
                    classes.root,
                    selected && classes.rootSelected,
                    selectable && classes.rootSelectable,
                ]), children: [multiSelect && (_jsx(Grid, { item: true, className: combineClassNames([
                            classes.checkboxContainer,
                            selected && classes.checkboxContainerSelected,
                        ]), children: _jsx(Checkbox, { checked: selected, className: classes.checkbox }) })), icon && (_jsx(Grid, { item: true, className: classes.iconWrapper, children: _jsx("div", { className: classes.icon, children: icon }) })), _jsx(Grid, { item: true, xs: true, children: _jsxs(Grid, { container: true, spacing: 0, direction: "column", justifyContent: info ? "flex-start" : "center", className: classes.contentContainer, children: [_jsx(Grid, { item: true, children: _jsx(Typography, { variant: "subtitle1", noWrap: true, title: title, children: title }) }), info?.map((text, idx) => (_jsx(Grid, { item: true, children: _jsx(Typography, { color: "textSecondary", noWrap: true, title: text, children: text }) }, idx.toString(16))))] }) }), tags && (_jsxs(Grid, { item: true, className: classes.tags, container: true, direction: "column", justifyContent: "space-between", spacing: 2, wrap: "nowrap", children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Grid, { container: true, justifyContent: "flex-end", children: tags }) }), _jsx(Grid, { item: true, xs: 12, children: extraInfo })] }))] }), _jsx("div", { className: classes.borderBottom })] }));
};
export default React.memo(DenseLayout);
