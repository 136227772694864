import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, useFormContextLite } from "components-care";
import { Tooltip } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { KeyboardArrowRight as OpenIcon } from "@mui/icons-material";
const useOpenStyles = makeStyles()((theme) => ({
    root: {
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.primary.main,
        },
    },
}));
export const OpenDataGridRecord = (props) => {
    const { id } = props;
    const { t } = useTranslation("common");
    const { classes } = useOpenStyles();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const handleOpen = useCallback(() => {
        navigate(`${pathname}/${id}`);
    }, [navigate, pathname, id]);
    return (_jsx(Tooltip, { title: t("data-grid.open") ?? "", children: _jsx(OpenIcon, { classes: classes, onClick: handleOpen }) }));
};
export const EditDataGridRecord = (props) => {
    const { id } = props;
    const { t } = useTranslation("common");
    const { classes } = useOpenStyles();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const handleOpen = useCallback(() => {
        navigate(`${pathname}/${id}`);
    }, [navigate, pathname, id]);
    return (_jsx(Tooltip, { title: t("data-grid.edit") ?? "", children: _jsx(OpenIcon, { classes: classes, onClick: handleOpen }) }));
};
export const OpenDataGridRecordCrud = (props) => {
    const { id } = props;
    const { t } = useTranslation("common");
    const { classes } = useOpenStyles();
    const { open } = useFormContextLite().customProps;
    const handleOpen = useCallback(() => {
        open(id);
    }, [open, id]);
    return (_jsx(Tooltip, { title: t("data-grid.open") ?? "", children: _jsx(OpenIcon, { classes: classes, onClick: handleOpen }) }));
};
