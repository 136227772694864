import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { combineClassNames, ModelDataTypeImageRenderer, } from "components-care";
import { alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme) => ({
    wrapper: {
        width: "100%",
        height: "100%",
        position: "relative",
    },
    image: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
    },
    status: {
        width: 12,
        height: 12,
        position: "absolute",
        bottom: 0,
        right: 0,
        borderRadius: "50%",
        border: `1px solid`,
    },
    // inventory
    status_active: {
        backgroundColor: theme.palette.success.main,
        borderColor: alpha(theme.palette.success.contrastText, 0.2),
    },
    status_limited_use: {
        backgroundColor: theme.palette.warning.main,
        borderColor: alpha(theme.palette.warning.contrastText, 0.2),
    },
    status_out_of_order: {
        backgroundColor: theme.palette.error.main,
        borderColor: alpha(theme.palette.error.contrastText, 0.2),
    },
    status_retired: {
        backgroundColor: theme.palette.getContrastText(theme.palette.background.paper),
        borderColor: alpha(theme.palette.background.paper, 0.2),
    },
    // staff
    status_has_account: {
        backgroundColor: theme.palette.success.main,
        borderColor: alpha(theme.palette.success.contrastText, 0.2),
    },
    status_no_account: {
        backgroundColor: theme.palette.getContrastText(theme.palette.background.paper),
        borderColor: alpha(theme.palette.background.paper, 0.2),
    },
}));
const StatusWrapper = (props) => {
    const { status, children } = props;
    const { classes } = useStyles();
    return (_jsxs("div", { className: classes.wrapper, children: [_jsx("div", { className: classes.image, children: children }), _jsx("div", { className: combineClassNames([
                    classes.status,
                    classes[("status_" + status)],
                ]) })] }));
};
class RendererImageWithStatus extends ModelDataTypeImageRenderer {
    settings = {};
    statusFields;
    getFallbackImage;
    callback;
    constructor(statusFields, params, callback, getFallbackImage) {
        super(params);
        if (typeof statusFields == "string")
            statusFields = [statusFields];
        this.statusFields = statusFields;
        this.settings.updateHooks = statusFields;
        this.getFallbackImage = getFallbackImage;
        if (statusFields.length !== 1 && callback == null) {
            throw new Error("Callback needs to be specified when more than one value is used");
        }
        this.callback =
            callback ??
                ((values) => values[this.statusFields[0]]);
    }
    render(params) {
        const { visibility, values } = params;
        const originalContent = super.render({
            ...params,
            value: params.value ||
                (this.getFallbackImage && this.getFallbackImage(values)) ||
                "",
        });
        if (visibility.disabled ||
            visibility.hidden ||
            visibility.editable ||
            !visibility.grid) {
            return originalContent;
        }
        const status = this.callback(values);
        if (status == null)
            return originalContent;
        return _jsx(StatusWrapper, { status: status, children: originalContent });
    }
}
export default RendererImageWithStatus;
