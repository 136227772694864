import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CheckCircleOutline as InviteAcceptIcon, CancelOutlined as InviteRejectIcon, } from "@mui/icons-material";
import { useNavigate, useLocation, combineClassNames, combineColorsCss, useDialogContext, } from "components-care";
import { useAcceptInvite, useRejectInvite, } from "../../../api/samedis/Invites";
import { useTranslation } from "react-i18next";
import { showErrorDialog } from "components-care/dist/non-standalone/Dialog/Utils";
import { useAuthProviderReset } from "../../components/AuthProvider";
const useStyles = makeStyles()((theme) => ({
    root: {
        borderRadius: theme.shape.borderRadius,
        cursor: "pointer",
        backgroundColor: theme.palette.background.paper,
        "&:hover": {
            backgroundColor: combineColorsCss(theme.palette.background.paper, theme.palette.action.hover),
        },
    },
    rootBtn: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        "&:hover": {
            backgroundColor: combineColorsCss(theme.palette.background.paper, theme.palette.action.hover),
        },
    },
    rootBtnInvite: {
        backgroundColor: theme.palette.background.paper,
        cursor: "default",
        "&:hover": {
            backgroundColor: theme.palette.background.paper,
        },
    },
    rootBtnRecovery: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        "&:hover": {
            backgroundColor: combineColorsCss(theme.palette.error.main, theme.palette.action.hover),
        },
    },
    inviteAcceptBtn: {
        color: theme.palette.success.main,
    },
    inviteRejectBtn: {
        color: theme.palette.error.main,
    },
    avatar: {
        width: 48,
        height: 48,
        backgroundColor: "transparent",
        "& > svg": {
            width: "90%",
            height: "90%",
        },
        "& > img": {
            width: "100%",
            height: "100%",
        },
    },
}));
const FacilityListEntry = (props) => {
    const { invite, subtext, recovery } = props;
    const { t } = useTranslation("tenant-select");
    const [pushDialog] = useDialogContext();
    const resetAuthProvider = useAuthProviderReset();
    const navigate = useNavigate();
    const { classes } = useStyles();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const tenantRedirectURL = query.get("tenant_redirect");
    const { mutateAsync: acceptInvite } = useAcceptInvite();
    const { mutateAsync: rejectInvite } = useRejectInvite();
    const handleSelection = useCallback(() => {
        localStorage.setItem("tenant", props.id);
        if (tenantRedirectURL)
            navigate(`/tenant/${props.id}${tenantRedirectURL}`);
        else
            navigate(`/tenant/${props.id}/`);
    }, [navigate, props.id, tenantRedirectURL]);
    const handleAcceptInvite = useCallback(async () => {
        if (!invite)
            return;
        try {
            await acceptInvite(invite.token);
            resetAuthProvider();
            handleSelection();
        }
        catch (e) {
            await showErrorDialog(pushDialog, e);
        }
    }, [acceptInvite, resetAuthProvider, handleSelection, invite, pushDialog]);
    const handleRejectInvite = useCallback(async () => {
        if (!invite)
            return;
        try {
            await rejectInvite(invite.token);
        }
        catch (e) {
            await showErrorDialog(pushDialog, e);
        }
    }, [rejectInvite, invite, pushDialog]);
    return (_jsx(Box, { p: 1, className: combineClassNames([
            classes.root,
            props.onClick && classes.rootBtn,
            invite && classes.rootBtnInvite,
            recovery && classes.rootBtnRecovery,
        ]), onClick: invite ? undefined : (props.onClick ?? handleSelection), children: _jsxs(Grid, { container: true, spacing: 2, wrap: "nowrap", children: [_jsx(Grid, { item: true, children: _jsx(Avatar, { src: typeof props.image === "string" ? props.image : undefined, className: classes.avatar, children: typeof props.image !== "string" ? props.image : undefined }) }), _jsxs(Grid, { item: true, xs: true, children: [_jsx(Typography, { variant: "subtitle1", children: props.text }), invite ? (_jsx(Typography, { color: "textSecondary", variant: "caption2", children: t("invite", { NAME: invite.inviting_user.username }) })) : (subtext && (_jsx(Typography, { color: recovery ? undefined : "textSecondary", variant: "caption2", children: subtext })))] }), invite && (_jsx(Grid, { item: true, children: _jsx(Grid, { container: true, spacing: 2, justifyContent: "space-evenly", wrap: "nowrap", children: _jsxs(Grid, { item: true, children: [_jsx(IconButton, { onClick: handleAcceptInvite, size: "large", children: _jsx(InviteAcceptIcon, { className: classes.inviteAcceptBtn }) }), _jsx(IconButton, { onClick: handleRejectInvite, size: "large", children: _jsx(InviteRejectIcon, { className: classes.inviteRejectBtn }) })] }) }) }))] }) }));
};
export default React.memo(FacilityListEntry);
