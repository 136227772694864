import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ModelDataTypeString } from "components-care";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import getLocalizedString from "../../../utils/getLocalizedString";
const useStyles = makeStyles()((theme) => ({
    wrapper: {
        marginTop: -13,
        marginBottom: -13,
    },
    model: {
        display: "block",
        fontSize: "14px",
        fontWeight: 400,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    type: {
        display: "block",
        fontSize: "12px",
        fontWeight: 400,
        color: theme.palette.text.secondary,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    manufacturer: {
        display: "block",
        fontSize: "9px",
        fontWeight: 400,
        color: theme.palette.text.secondary,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
}));
export const DeviceModelGridRenderer = (props) => {
    const { model, type, manufacturer } = props;
    const { classes } = useStyles();
    const { i18n } = useTranslation();
    return (_jsxs("div", { className: classes.wrapper, children: [_jsx("span", { className: classes.model, children: model }), _jsx("span", { className: classes.type, children: typeof type === "string"
                    ? type
                    : getLocalizedString(i18n.language, type) }), _jsx("span", { className: classes.manufacturer, children: manufacturer })] }));
};
class RendererDeviceModelGrid extends ModelDataTypeString {
    modelField;
    versionField;
    typeField;
    manufacturerField;
    versionIdsField;
    settings = {};
    constructor(modelField, versionField, typeField, manufacturerField, versionIdsField) {
        super();
        this.modelField = modelField;
        this.versionField = versionField;
        this.typeField = typeField;
        this.manufacturerField = manufacturerField;
        this.versionIdsField = versionIdsField;
        this.settings.updateHooks = [
            modelField,
            versionField,
            typeField,
            manufacturerField,
        ];
        if (this.versionIdsField)
            this.settings.updateHooks.push(this.versionIdsField);
    }
    render(params) {
        const { visibility, values } = params;
        if (!visibility.grid)
            throw new Error("Unsupported");
        const versionStr = values[this.versionField];
        const version = this.versionIdsField
            ? values[this.versionIdsField].length > 0
                ? versionStr
                : ""
            : versionStr;
        return (_jsx(DeviceModelGridRenderer, { model: [values[this.modelField], version]
                .filter((x) => x)
                .join(" • "), type: values[this.typeField], manufacturer: values[this.manufacturerField] }));
    }
    getFilterType() {
        return "combined-string";
    }
}
export default RendererDeviceModelGrid;
