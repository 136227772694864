import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useId, useMemo, useRef, useState, } from "react";
import { makeStyles } from "tss-react/mui";
import { combineClassNames, Loader, processImageB64, useRefState, useWindowSize, } from "components-care";
import { Button, Fade, Grid, Slider, Stack, Typography } from "@mui/material";
import { KeyboardArrowRight as NextIcon, RotateLeft, RotateRight, RotateRight as RotateRightIcon, ZoomIn, ZoomOut, } from "@mui/icons-material";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import usePageContainerSpacing from "../../utils/usePageContainerSpacing";
const DRAG_ZONE_EDGE_SIZE = 32;
const DRAG_ZONE_CORNER_SIZE = 4;
const CROP_BOX_BORDER_SIZE = 2;
const CANVAS_SIZE = 4096;
const CROP_BOX_SIZE_FACTOR = 0.7;
const useStyles = makeStyles({ name: "CcImageEdit" })((theme) => ({
    contentRoot: {
        position: "relative",
        width: "100%",
        height: "100%",
        touchAction: "none",
    },
    loaderContainer: {
        position: "relative",
    },
    loaderWrapper: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    canvasImage: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    canvasShadow: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    cropBox: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        border: `${CROP_BOX_BORDER_SIZE}px solid ${theme.palette.divider}`,
    },
    cropBoxInner: {
        position: "relative",
        height: "100%",
        width: "100%",
    },
    cropMove: {
        position: "absolute",
        // backgroundColor: "rgba(0.5, 0.5, 0, 0.5)",
        top: DRAG_ZONE_EDGE_SIZE,
        left: DRAG_ZONE_EDGE_SIZE,
        width: `calc(100% - 2 * ${DRAG_ZONE_EDGE_SIZE}px)`,
        height: `calc(100% - 2 * ${DRAG_ZONE_EDGE_SIZE}px)`,
        cursor: "grab",
    },
    cropMoving: {
        cursor: "grabbing",
    },
    cropDrag: {
        position: "absolute",
        // backgroundColor: "blue",
    },
    cropDragL: {
        top: DRAG_ZONE_EDGE_SIZE,
        left: 0,
        width: DRAG_ZONE_EDGE_SIZE,
        height: `calc(100% - 2 * ${DRAG_ZONE_EDGE_SIZE}px)`,
        //cursor: "ew-resize",
    },
    cropDragR: {
        top: DRAG_ZONE_EDGE_SIZE,
        right: 0,
        width: DRAG_ZONE_EDGE_SIZE,
        height: `calc(100% - 2 * ${DRAG_ZONE_EDGE_SIZE}px)`,
        //cursor: "ew-resize",
    },
    cropDragT: {
        top: 0,
        left: DRAG_ZONE_EDGE_SIZE,
        height: DRAG_ZONE_EDGE_SIZE,
        width: `calc(100% - 2 * ${DRAG_ZONE_EDGE_SIZE}px)`,
        //cursor: "ns-resize",
    },
    cropDragB: {
        bottom: 0,
        left: DRAG_ZONE_EDGE_SIZE,
        height: DRAG_ZONE_EDGE_SIZE,
        width: `calc(100% - 2 * ${DRAG_ZONE_EDGE_SIZE}px)`,
        //cursor: "ns-resize",
    },
    cropCorner: {
        position: "absolute",
        borderStyle: "solid",
        borderWidth: 4,
        borderColor: theme.palette.background.paper,
        width: DRAG_ZONE_EDGE_SIZE + DRAG_ZONE_CORNER_SIZE,
        height: DRAG_ZONE_EDGE_SIZE + DRAG_ZONE_CORNER_SIZE,
        // backgroundColor: "green",
    },
    cropCornerTL: {
        top: -DRAG_ZONE_CORNER_SIZE,
        left: -DRAG_ZONE_CORNER_SIZE,
        borderBottom: "unset",
        borderRight: "unset",
        //cursor: "nwse-resize",
    },
    cropCornerTR: {
        top: -DRAG_ZONE_CORNER_SIZE,
        right: -DRAG_ZONE_CORNER_SIZE,
        borderBottom: "unset",
        borderLeft: "unset",
        //cursor: "nesw-resize",
    },
    cropCornerBL: {
        bottom: -DRAG_ZONE_CORNER_SIZE,
        left: -DRAG_ZONE_CORNER_SIZE,
        borderTop: "unset",
        borderRight: "unset",
        //cursor: "nesw-resize",
    },
    cropCornerBR: {
        bottom: -DRAG_ZONE_CORNER_SIZE,
        right: -DRAG_ZONE_CORNER_SIZE,
        borderTop: "unset",
        borderLeft: "unset",
        //cursor: "nwse-resize",
    },
    gridL: {
        position: "absolute",
        backgroundColor: "#D7D7D7",
    },
    gridL1: {
        top: "33.3333%",
        left: 0,
        width: "100%",
        height: 1,
    },
    gridL2: {
        top: "66.6666%",
        left: 0,
        width: "100%",
        height: 1,
    },
    gridL3: {
        top: 0,
        left: "33.3333%",
        width: 1,
        height: "100%",
    },
    gridL4: {
        top: 0,
        left: "66.6666%",
        width: 1,
        height: "100%",
    },
    actionButton: {
        width: 160,
        justifyContent: "flex-start",
    },
}));
const loadImage = (imageData) => {
    const image = new Image();
    return new Promise((resolve, reject) => {
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", (evt) => reject(evt.error));
        image.src = imageData;
    });
};
const ImageCropBoxBorder = (props) => {
    const { className } = props;
    return _jsx("div", { className: className });
};
const degToRad = (rotation) => (rotation / 180) * Math.PI;
export const ImageEdit = (props) => {
    const { onDone, outputOptions } = props;
    const { t } = useTranslation("common");
    const { classes } = useStyles();
    const pageSpacing = usePageContainerSpacing();
    const [image, setImage] = useState(null);
    const { get: getZoom, set: setZoom, state: zoom, } = useRefState(CROP_BOX_SIZE_FACTOR);
    const { set: setCrop, state: crop } = useRefState([0, 0]);
    const { get: getRotation, set: setRotation, state: rotation, } = useRefState(0);
    const rotationRad = useCallback(() => degToRad(rotation), [rotation]);
    const { get: getDrag, set: setDrag, state: drag } = useRefState(false);
    const getImageAspectFactors = useCallback(() => {
        if (image == null)
            return [1, 1];
        const imgAspect = image.width / image.height;
        let wFactor = 1;
        let hFactor = 1;
        if (imgAspect > 1) {
            // width > height
            wFactor = imgAspect;
        }
        else if (imgAspect < 1) {
            // height > width
            hFactor = 1 / imgAspect;
        }
        return [wFactor, hFactor];
    }, [image]);
    const undoAllChanges = useCallback(async () => {
        loadImage(props.image)
            .then((res) => {
            const canvas = document.createElement("canvas");
            // target size = fit image as square inside circle
            const rectSize = Math.max(res.width, res.height);
            const targetSize = Math.sqrt(Math.pow(rectSize / 2, 2) * 2) * 2;
            canvas.width = canvas.height = targetSize;
            const ctx = canvas.getContext("2d");
            if (!ctx)
                throw new Error("Failed getting Canvas 2D Context");
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(res, (targetSize - res.width) / 2, (targetSize - res.height) / 2, res.width, res.height);
            setImage(canvas);
            setZoom(CROP_BOX_SIZE_FACTOR * (targetSize / rectSize));
            setCrop([0, 0]);
        })
            .catch((e) => {
            Sentry.captureException(e);
            console.error(e);
        });
    }, [props.image, setCrop, setZoom]);
    // reset when prop is updated
    useEffect(() => {
        undoAllChanges();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.image]);
    const contentRootRef = useRef(null);
    const canvasImage = useRef(null);
    const canvasShadow = useRef(null);
    const [canvasSize, setCanvasSize] = useState(0);
    const [cropBoxSize, setCropBoxSize] = useState(0);
    const windowSize = useWindowSize();
    // re-size listener
    useEffect(() => {
        const div = contentRootRef.current;
        if (!div)
            return;
        const size = Math.min(div.clientWidth, div.clientHeight);
        setCropBoxSize(size * CROP_BOX_SIZE_FACTOR);
        setCanvasSize(size);
        // deps: image = div is only rendered if image is true
        //       windowSize = window resized => new size
    }, [image, windowSize]);
    // renderer
    const renderRequested = useRef(false);
    const render = useCallback(() => {
        renderRequested.current = false;
        if (!image)
            return; // no image, no canvas
        const imgCanvas = canvasImage.current;
        if (!imgCanvas)
            throw new Error("no img canvas ref");
        const imgCtx = imgCanvas.getContext("2d");
        if (!imgCtx)
            throw new Error("no img canvas ctx");
        const centerW = imgCanvas.width / 2;
        const centerH = imgCanvas.height / 2;
        imgCtx.translate(centerW, centerH);
        imgCtx.clearRect(-centerW, -centerH, imgCanvas.width, imgCanvas.height);
        imgCtx.fillStyle = "#BDBDBD";
        imgCtx.fillRect(-centerW, -centerH, imgCanvas.width, imgCanvas.height);
        imgCtx.rotate(rotationRad());
        const [wFactor, hFactor] = getImageAspectFactors();
        const imageW = imgCanvas.width * wFactor * zoom;
        const imageH = imgCanvas.height * hFactor * zoom;
        imgCtx.drawImage(image, -imageW / 2 - crop[0], -imageH / 2 - crop[1], imageW, imageH);
        // Reset current transformation matrix to the identity matrix
        imgCtx.setTransform(1, 0, 0, 1, 0, 0);
    }, [image, rotationRad, getImageAspectFactors, zoom, crop]);
    useEffect(() => {
        if (!image)
            return;
        if (renderRequested.current)
            return;
        window.requestAnimationFrame(render);
        renderRequested.current = true;
    }, [render, image]);
    const renderShadow = useCallback(() => {
        if (!image)
            return; // no image, no canvas
        const shadowCanvas = canvasShadow.current;
        if (!shadowCanvas)
            throw new Error("no shadow canvas ref");
        const shadowCtx = shadowCanvas.getContext("2d");
        if (!shadowCtx)
            throw new Error("no shadow canvas ctx");
        // draw shadows
        shadowCtx.clearRect(0, 0, shadowCanvas.width, shadowCanvas.height);
        shadowCtx.fillStyle = "rgba(0, 0, 0, 0.2)";
        shadowCtx.fillRect(0, 0, shadowCanvas.width, shadowCanvas.height);
        // clear crop box
        const cropRectSize = shadowCanvas.width * CROP_BOX_SIZE_FACTOR;
        shadowCtx.clearRect((shadowCanvas.width - cropRectSize) / 2, (shadowCanvas.height - cropRectSize) / 2, cropRectSize, cropRectSize);
        shadowCtx.fillRect(0, 0, shadowCanvas.width, shadowCanvas.height);
        shadowCtx.beginPath();
        shadowCtx.arc(shadowCanvas.width / 2, shadowCanvas.height / 2, cropRectSize / 2, 0, 2 * Math.PI);
        shadowCtx.clip();
        shadowCtx.clearRect(0, 0, shadowCanvas.width, shadowCanvas.height);
    }, [image]);
    useEffect(() => {
        window.requestAnimationFrame(renderShadow);
    }, [renderShadow]);
    const scale = canvasSize / CANVAS_SIZE;
    const ensureImageInCropBox = useCallback((pos) => {
        if (!image)
            return [0, 0];
        pos = [...pos];
        const [wFactor, hFactor] = getImageAspectFactors();
        const imageW = CANVAS_SIZE * wFactor * getZoom();
        const imageH = CANVAS_SIZE * hFactor * getZoom();
        const imageBB = [
            CANVAS_SIZE / 2 - imageW / 2 - pos[0],
            CANVAS_SIZE / 2 - imageH / 2 - pos[1],
            imageW,
            imageH,
        ];
        imageBB[2] += imageBB[0];
        imageBB[3] += imageBB[1];
        const cropBoxSize = CANVAS_SIZE * CROP_BOX_SIZE_FACTOR;
        const cropBB = [
            CANVAS_SIZE / 2 - cropBoxSize / 2,
            CANVAS_SIZE / 2 - cropBoxSize / 2,
            cropBoxSize,
            cropBoxSize,
        ];
        cropBB[2] += cropBB[0];
        cropBB[3] += cropBB[1];
        const delta = imageBB.map((n, i) => n - cropBB[i]);
        if (delta[0] > 0) {
            pos[0] += delta[0];
            delta[2] += delta[0];
            delta[0] = 0;
        }
        if (delta[1] > 0) {
            pos[1] += delta[1];
            delta[3] += delta[1];
            delta[1] = 0;
        }
        if (delta[2] < 0) {
            pos[0] += delta[2];
            delta[0] += delta[2];
            delta[2] = 0;
        }
        if (delta[3] < 0) {
            pos[1] += delta[3];
            delta[1] += delta[3];
            delta[3] = 0;
        }
        return pos;
    }, [image, getImageAspectFactors, getZoom]);
    useEffect(() => {
        setCrop(ensureImageInCropBox);
    }, [setCrop, ensureImageInCropBox, zoom]);
    const move = useCallback((dX, dY) => {
        const rotationRad = degToRad(getRotation());
        // handle rotation
        const finalDX = Math.cos(rotationRad) * dX + Math.sin(rotationRad) * dY;
        const finalDY = Math.cos(rotationRad) * dY - Math.sin(rotationRad) * dX;
        setCrop((prev) => ensureImageInCropBox([prev[0] + finalDX, prev[1] + finalDY]));
    }, [ensureImageInCropBox, getRotation, setCrop]);
    const handleWheel = useCallback((evt) => {
        const factor = evt.shiftKey ? -0.001 : -0.0001; // shift = fast zoom
        const movement = evt.deltaY * factor;
        const prevZoom = getZoom();
        const newZoom = Math.max(prevZoom + movement, CROP_BOX_SIZE_FACTOR);
        setZoom(newZoom);
        if (prevZoom === newZoom)
            return; // no zoom change, no moving
        const cropSensitivity = 16;
        const contentSize = evt.currentTarget.getBoundingClientRect();
        const contentCenterX = contentSize.x + contentSize.width / 2;
        const contentCenterY = contentSize.y + contentSize.height / 2;
        const offsetX = evt.pageX - contentCenterX;
        const offsetY = evt.pageY - contentCenterY;
        const dX = offsetX * movement * cropSensitivity;
        const dY = offsetY * movement * cropSensitivity;
        move(dX, dY);
    }, [getZoom, move, setZoom]);
    const handleZoomChange = useCallback((_evt, value) => {
        setZoom(value);
    }, [setZoom]);
    const startMove = useCallback((_evt) => {
        setDrag(true);
    }, [setDrag]);
    const rotate = useCallback(() => {
        setRotation((prev) => (prev + 90) % 360);
    }, [setRotation]);
    const handleRotateChange = useCallback((_evt, value) => {
        setRotation(value);
    }, [setRotation]);
    const evCache = useRef([]);
    const pinchZoom = useRef(null);
    const handlePointerDown = useCallback((evt) => {
        const ptrs = evCache.current;
        ptrs.push({
            pointerId: evt.pointerId,
            x: evt.clientX,
            y: evt.clientY,
        });
        if (ptrs.length !== 2) {
            pinchZoom.current = null;
            return;
        }
        const tlX = Math.min(ptrs[0].x, ptrs[1].x);
        const tlY = Math.min(ptrs[0].y, ptrs[1].y);
        const brX = Math.max(ptrs[0].x, ptrs[1].x);
        const brY = Math.max(ptrs[0].y, ptrs[1].y);
        const centerX = tlX + (brX - tlX) / 2;
        const centerY = tlY + (brY - tlY) / 2;
        pinchZoom.current = {
            dist: Math.sqrt(Math.pow(ptrs[0].x - ptrs[1].x, 2) +
                Math.pow(ptrs[0].y - ptrs[1].y, 2)),
            pos: [centerX, centerY],
        };
    }, []);
    const handlePointerMove = useCallback((evt) => {
        // update positions
        const ptrs = evCache.current;
        const ptr = ptrs.find((ev) => ev.pointerId === evt.pointerId);
        if (!ptr)
            return;
        const dX = (evt.clientX - ptr.x) / scale;
        const dY = (evt.clientY - ptr.y) / scale;
        ptr.x = evt.clientX;
        ptr.y = evt.clientY;
        if (ptrs.length === 1 && getDrag()) {
            // handle move
            move(-dX, -dY);
        }
        if (pinchZoom.current) {
            // handle pinch to zoom
            const newDist = Math.sqrt(Math.pow(ptrs[0].x - ptrs[1].x, 2) +
                Math.pow(ptrs[0].y - ptrs[1].y, 2));
            const deltaDist = newDist - pinchZoom.current.dist;
            pinchZoom.current.dist = newDist;
            const zoomFactor = 0.01;
            const zoom = deltaDist * zoomFactor;
            const prevZoom = getZoom();
            const newZoom = Math.max(prevZoom + zoom, CROP_BOX_SIZE_FACTOR);
            setZoom(newZoom);
            if (prevZoom === newZoom)
                return; // no zoom change, no moving
            const cropSensitivity = 160 * scale;
            const contentSize = evt.currentTarget.getBoundingClientRect();
            const contentCenterX = contentSize.x + contentSize.width / 2;
            const contentCenterY = contentSize.y + contentSize.height / 2;
            const offsetX = pinchZoom.current.pos[0] - contentCenterX;
            const offsetY = pinchZoom.current.pos[1] - contentCenterY;
            const dX = offsetX * zoom * cropSensitivity;
            const dY = offsetY * zoom * cropSensitivity;
            move(dX, dY);
        }
    }, [getDrag, getZoom, move, scale, setZoom]);
    const handlePointerUp = useCallback((evt) => {
        evCache.current = evCache.current.filter((ev) => ev.pointerId !== evt.pointerId);
        if (evCache.current.length !== 2)
            pinchZoom.current = null;
        if (evCache.current.length !== 1)
            setDrag(false);
    }, [setDrag]);
    useEffect(() => {
        window.addEventListener("pointerup", handlePointerUp);
        window.addEventListener("pointercancel", handlePointerUp);
        return () => {
            window.removeEventListener("pointerup", handlePointerUp);
            window.removeEventListener("pointercancel", handlePointerUp);
        };
    }, [handlePointerUp]);
    const handleDone = useCallback(async () => {
        if (!image)
            return; // no image, no canvas
        const imgCanvas = canvasImage.current;
        if (!imgCanvas)
            throw new Error("no img canvas ref");
        const imgCtx = imgCanvas.getContext("2d");
        if (!imgCtx)
            throw new Error("no img canvas ctx");
        // get image data
        const cropRectSize = imgCanvas.width * CROP_BOX_SIZE_FACTOR;
        const imageData = imgCtx.getImageData((imgCanvas.width - cropRectSize) / 2, (imgCanvas.height - cropRectSize) / 2, cropRectSize, cropRectSize);
        // convert image data to png
        const canvasSave = document.createElement("canvas");
        canvasSave.width = cropRectSize;
        canvasSave.height = cropRectSize;
        const saveCtx = canvasSave.getContext("2d");
        if (!saveCtx)
            throw new Error("could not get canvas 2d context for saving image");
        saveCtx.putImageData(imageData, 0, 0);
        const dataUri = canvasSave.toDataURL("image/png");
        onDone(outputOptions
            ? await processImageB64(dataUri, outputOptions.format, outputOptions.scaling)
            : dataUri);
    }, [image, onDone, outputOptions]);
    const zoomLabelId = useId();
    const rotateLabelId = useId();
    const cropBorders = useMemo(() => (_jsxs(_Fragment, { children: [_jsx(ImageCropBoxBorder, { className: combineClassNames([classes.cropDrag, classes.cropDragL]), direction: "L" }), _jsx(ImageCropBoxBorder, { className: combineClassNames([classes.cropDrag, classes.cropDragT]), direction: "T" }), _jsx(ImageCropBoxBorder, { className: combineClassNames([classes.cropDrag, classes.cropDragB]), direction: "B" }), _jsx(ImageCropBoxBorder, { className: combineClassNames([classes.cropDrag, classes.cropDragR]), direction: "R" }), _jsx(ImageCropBoxBorder, { className: combineClassNames([
                    classes.cropCorner,
                    classes.cropCornerTL,
                ]), direction: "TL" }), _jsx(ImageCropBoxBorder, { className: combineClassNames([
                    classes.cropCorner,
                    classes.cropCornerTR,
                ]), direction: "TR" }), _jsx(ImageCropBoxBorder, { className: combineClassNames([
                    classes.cropCorner,
                    classes.cropCornerBL,
                ]), direction: "BL" }), _jsx(ImageCropBoxBorder, { className: combineClassNames([
                    classes.cropCorner,
                    classes.cropCornerBR,
                ]), direction: "BR" })] })), [
        classes.cropCorner,
        classes.cropCornerBL,
        classes.cropCornerBR,
        classes.cropCornerTL,
        classes.cropCornerTR,
        classes.cropDrag,
        classes.cropDragB,
        classes.cropDragL,
        classes.cropDragR,
        classes.cropDragT,
    ]);
    const cropBoxInner = useMemo(() => (_jsxs("div", { className: classes.cropBoxInner, children: [cropBorders, _jsx("div", { className: combineClassNames([
                    classes.cropMove,
                    drag && classes.cropMoving,
                ]), onPointerDown: startMove }), _jsx(Fade, { in: drag, children: _jsxs("div", { children: [_jsx("div", { className: combineClassNames([classes.gridL, classes.gridL1]) }), _jsx("div", { className: combineClassNames([classes.gridL, classes.gridL2]) }), _jsx("div", { className: combineClassNames([classes.gridL, classes.gridL3]) }), _jsx("div", { className: combineClassNames([classes.gridL, classes.gridL4]) })] }) })] })), [
        classes.cropBoxInner,
        classes.cropMove,
        classes.cropMoving,
        classes.gridL,
        classes.gridL1,
        classes.gridL2,
        classes.gridL3,
        classes.gridL4,
        cropBorders,
        drag,
        startMove,
    ]);
    const previewArea = useMemo(() => (_jsxs("div", { ref: contentRootRef, className: classes.contentRoot, onWheel: handleWheel, onPointerDown: handlePointerDown, onPointerMove: handlePointerMove, children: [_jsx("canvas", { ref: canvasImage, width: CANVAS_SIZE, height: CANVAS_SIZE, className: classes.canvasImage, style: { width: canvasSize, height: canvasSize }, children: "Your browser does not support canvas" }), _jsx("canvas", { ref: canvasShadow, width: CANVAS_SIZE, height: CANVAS_SIZE, className: classes.canvasShadow, style: { width: canvasSize, height: canvasSize } }), _jsx("div", { className: classes.cropBox, style: {
                    width: cropBoxSize + 2 * CROP_BOX_BORDER_SIZE,
                    height: cropBoxSize + 2 * CROP_BOX_BORDER_SIZE,
                }, children: cropBoxInner })] })), [
        canvasSize,
        classes.canvasImage,
        classes.canvasShadow,
        classes.contentRoot,
        classes.cropBox,
        cropBoxInner,
        cropBoxSize,
        handlePointerDown,
        handlePointerMove,
        handleWheel,
    ]);
    const zoomControl = useMemo(() => (_jsxs(Grid, { item: true, xs: 12, lg: 6, children: [_jsx(Typography, { id: zoomLabelId, gutterBottom: true, children: t("image_edit.zoom") }), _jsxs(Stack, { spacing: 2, direction: "row", alignItems: "center", children: [_jsx(ZoomOut, {}), _jsx(Slider, { "aria-labelledby": zoomLabelId, value: zoom, min: CROP_BOX_SIZE_FACTOR, max: 4 / CROP_BOX_SIZE_FACTOR, step: 0.01, onChange: handleZoomChange }), _jsx(ZoomIn, {})] })] })), [handleZoomChange, t, zoom, zoomLabelId]);
    const rotateControl = useMemo(() => (_jsxs(Grid, { item: true, xs: 12, lg: 6, children: [_jsx(Typography, { id: rotateLabelId, gutterBottom: true, children: t("image_edit.rotate") }), _jsxs(Stack, { spacing: 2, direction: "row", alignItems: "center", children: [_jsx(RotateLeft, {}), _jsx(Slider, { "aria-labelledby": rotateLabelId, value: rotation, min: 0, max: 360, step: 0.01, onChange: handleRotateChange }), _jsx(RotateRight, {})] })] })), [handleRotateChange, rotateLabelId, rotation, t]);
    const actionButtons = useMemo(() => (_jsxs(Grid, { item: true, container: true, spacing: pageSpacing, justifyContent: "center", alignItems: "center", children: [_jsx(Grid, { item: true, children: _jsx(Button, { onClick: rotate, startIcon: _jsx(RotateRightIcon, {}), className: classes.actionButton, variant: "contained", children: t("image_edit.rotate-90") }) }), _jsx(Grid, { item: true, children: _jsx(Button, { onClick: handleDone, startIcon: _jsx(NextIcon, {}), className: classes.actionButton, variant: "contained", children: t("image_edit.next") }) })] })), [classes.actionButton, handleDone, pageSpacing, rotate, t]);
    return useMemo(() => (_jsxs(Grid, { item: true, xs: true, container: true, direction: "column", justifyContent: "space-between", spacing: 2, children: [_jsxs(Grid, { item: true, xs: true, className: classes.loaderContainer, children: [previewArea, !image && (_jsx("div", { className: classes.loaderWrapper, children: _jsx(Loader, {}) }))] }), _jsxs(Grid, { item: true, container: true, spacing: pageSpacing, justifyContent: "center", alignItems: "center", children: [zoomControl, rotateControl] }), actionButtons] })), [
        classes.loaderContainer,
        classes.loaderWrapper,
        previewArea,
        image,
        pageSpacing,
        zoomControl,
        rotateControl,
        actionButtons,
    ]);
};
export default React.memo(ImageEdit);
