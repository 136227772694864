import { ModelDataTypeEnumSelectRenderer, ModelDataTypeStringRendererCC, } from "components-care";
class RendererEnumSelectCustomReadonly extends ModelDataTypeEnumSelectRenderer {
    altRenderer = new ModelDataTypeStringRendererCC();
    render(params) {
        if (!params.visibility.grid &&
            params.visibility.editable &&
            params.visibility.readOnly) {
            return this.altRenderer.render({
                ...params,
                value: this.values.find((e) => e.value === params.value)?.getLabel() ?? "",
                handleChange: () => {
                    /* no op */
                },
            });
        }
        return super.render(params);
    }
}
export default RendererEnumSelectCustomReadonly;
