import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import SearchAndBrowse from "../index";
import { useTenantId } from "../../../../components/TenantContext";
import { COMPACT_LAYOUT_HEIGHT } from "../Renderers/DenseLayout";
import PositionModel, { PositionModelSelectorSort, usePositionModelTranslations, } from "../../../../../components-care/models/PositionModel";
import PositionRenderer from "../Renderers/PositionRenderer";
const PositionSearch = (props) => {
    const { ...other } = props;
    const tenantId = useTenantId();
    const { t: positionsModelT } = usePositionModelTranslations();
    const loadRecords = useCallback(async (search, _filters, offset) => {
        const positionsModel = PositionModel({
            t: positionsModelT,
            tenantId,
        });
        const [data, meta] = await positionsModel.index2({
            offset: offset,
            rows: 25,
            sort: PositionModelSelectorSort,
            quickFilter: search,
        });
        return {
            data: data,
            total: meta.filteredRows ?? meta.totalRows,
        };
    }, [positionsModelT, tenantId]);
    const filters = useMemo(() => ({}), []);
    return (_jsx(SearchAndBrowse, { ...other, loadRecords: loadRecords, filters: filters, renderer: PositionRenderer, rendererItemHeight: COMPACT_LAYOUT_HEIGHT, rendererContext: undefined, controlName: "PositionSearch" }));
};
export default React.memo(PositionSearch);
