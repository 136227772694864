import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import SearchAndBrowse from "../index";
import { useTenantIdWithContext } from "../../../../components/TenantContext";
import EmployeeModel, { EmployeeModelSelectorSort, useEmployeeModelTranslations, } from "../../../../../components-care/models/EmployeeModel";
import { multiSelectFilterEntryFromModel } from "../FilterEntryMultiSelect";
import TrainingGroupModel, { TrainingGroupModelSelectorSort, TrainingGroupModelToSelectorData, useTrainingGroupModelTranslations, } from "../../../../../components-care/models/TrainingGroupModel";
import EmployeeRenderer from "../Renderers/EmployeeRenderer";
const EmployeeSearch = (props) => {
    const { trainingRequirements, tenantIdMySamedis, trainingGroup, ...other } = props;
    const tenantId = useTenantIdWithContext(tenantIdMySamedis);
    const { t: employeeModelT } = useEmployeeModelTranslations();
    const { t: trainingGroupModelT } = useTrainingGroupModelTranslations();
    if (tenantIdMySamedis &&
        (!trainingRequirements || trainingRequirements.length !== 1))
        throw new Error("my-samedis training requirements invalid");
    const loadRecords = useCallback(async (search, filters, offset) => {
        const employeeModel = EmployeeModel({
            t: employeeModelT,
            tenantId,
            teamResponsible: trainingGroup,
            trainerDeviceModelId: tenantIdMySamedis
                ? trainingRequirements[0]
                : null,
            extraParams: {
                "filter[team_ids]": filters.teams.join(","),
                "filter[briefed_device_model_ids]": tenantIdMySamedis
                    ? undefined
                    : filters.with_training
                        ? trainingRequirements?.join(",")
                        : undefined,
                "filter[status]": filters.with_resigned
                    ? undefined
                    : "active",
            },
        });
        const [data, meta] = await employeeModel.index2({
            offset: offset,
            rows: 25,
            sort: EmployeeModelSelectorSort,
            quickFilter: search,
        });
        return {
            data: data,
            total: meta.filteredRows ?? meta.totalRows,
        };
    }, [
        employeeModelT,
        tenantId,
        trainingGroup,
        tenantIdMySamedis,
        trainingRequirements,
    ]);
    const filters = useMemo(() => ({
        teams: {
            variant: "multi-select",
            title: employeeModelT("staff:grid.filter.team_ids.label"),
            ...multiSelectFilterEntryFromModel(TrainingGroupModel({
                t: trainingGroupModelT,
                tenantId: tenantIdMySamedis ? null : tenantId,
                tenantFilter: tenantIdMySamedis,
                extraParams: { "filter[type]": "staff" },
            }), TrainingGroupModelSelectorSort, TrainingGroupModelToSelectorData),
        },
        with_training: {
            variant: "switch",
            title: employeeModelT("staff:grid.filter.with_training"),
            hidden: tenantIdMySamedis ||
                !trainingRequirements ||
                trainingRequirements.length === 0,
        },
        with_resigned: {
            variant: "switch",
            title: employeeModelT("staff:grid.filter.switch-label"),
        },
    }), [
        employeeModelT,
        trainingGroupModelT,
        tenantIdMySamedis,
        tenantId,
        trainingRequirements,
    ]);
    return (_jsx(SearchAndBrowse, { ...other, loadRecords: loadRecords, filters: filters, renderer: EmployeeRenderer, rendererContext: { trainingRequirements: trainingRequirements }, controlName: "StaffSearch" }));
};
export default React.memo(EmployeeSearch);
