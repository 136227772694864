import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import BasicPageLayout from "../components/BasicPageLayout";
import BoxGrid from "../components/BoxGrid";
import { useIsMobile } from "../utils";
import { SamedisBackendDataGridContext } from "./SamedisBackendDataGrid";
import { Grid } from "@mui/material";
import { IsInFormDialogContext } from "components-care/dist/backend-components/Form/FormDialog";
export const SamedisGridWrapperContext = React.createContext(null);
const SamedisGridWrapper = (props) => {
    const ctx = useContext(SamedisGridWrapperContext);
    const isMobile = useIsMobile();
    const mobileRenderer = useContext(SamedisBackendDataGridContext);
    const inDialog = useContext(IsInFormDialogContext);
    if (!ctx)
        return props.children;
    if ((isMobile && mobileRenderer) || inDialog)
        return (_jsx(BasicPageLayout, { title: ctx.title, actions: ctx.actions, column: true, noPadding: true, children: _jsx(Grid, { item: true, xs: true, container: true, children: props.children }) }));
    return (_jsx(BasicPageLayout, { title: ctx.title, actions: ctx.actions, column: true, children: _jsx(BoxGrid, { item: true, xs: true, children: props.children }) }));
};
export default React.memo(SamedisGridWrapper);
