import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import SearchAndBrowse from "../index";
import { useTenantId } from "../../../../components/TenantContext";
import DepartmentModel, { DepartmentModelSelectorSort, useDepartmentModelTranslations, } from "../../../../../components-care/models/DepartmentModel";
import DepartmentRenderer from "../Renderers/DepartmentRenderer";
const FILTERS = {};
const DepartmentSearch = (props) => {
    const { enterpriseClientId, ...other } = props;
    const tenantId = useTenantId();
    const { t: departmentModelT } = useDepartmentModelTranslations();
    const loadRecords = useCallback(async (search, _filters, offset) => {
        const departmentModel = DepartmentModel({
            t: departmentModelT,
            tenantId,
            enterpriseClientId,
        });
        const [data, meta] = await departmentModel.index2({
            offset: offset,
            rows: 25,
            sort: DepartmentModelSelectorSort,
            quickFilter: search,
        });
        return {
            data: data,
            total: meta.filteredRows ?? meta.totalRows,
        };
    }, [departmentModelT, tenantId, enterpriseClientId]);
    return (_jsx(SearchAndBrowse, { ...other, loadRecords: loadRecords, filters: FILTERS, renderer: DepartmentRenderer, rendererContext: undefined, controlName: "DepartmentSearch" }));
};
export default React.memo(DepartmentSearch);
