import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Popover } from "@mui/material";
import { ActionButton, useDialogContext, useFormContextLite, } from "components-care";
import ContactInviteDialog from "./ContactInviteDialog";
import { useActionButtonStyles } from "../../../../../components/ActionButton";
const ContactActionsButton = React.forwardRef((_, ref) => {
    const { id: recordId } = useFormContextLite();
    const { t } = useTranslation("contact");
    const { classes } = useActionButtonStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [pushDialog] = useDialogContext();
    const { readOnly } = useFormContextLite();
    const handleAction = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);
    useImperativeHandle(ref, () => ({
        handleAction,
    }), [handleAction]);
    const handleOpenInviteDialog = useCallback(() => {
        if (!recordId)
            throw new Error("id is null");
        pushDialog(_jsx(ContactInviteDialog, { contactIds: [recordId] }));
        handleClose();
    }, [recordId, handleClose, pushDialog]);
    return (_jsx(Popover, { open: !!anchorEl, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
        }, transformOrigin: {
            vertical: "top",
            horizontal: "right",
        }, className: classes.popover, children: _jsx("div", { className: classes.content, children: _jsx(Grid, { container: true, spacing: 2, children: _jsx(Grid, { item: true, xs: 12, children: _jsx(ActionButton, { onClick: handleOpenInviteDialog, disabled: !recordId || readOnly, children: t("buttons.invite") }) }) }) }) }));
});
export default React.memo(ContactActionsButton);
