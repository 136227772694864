import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import DenseLayout from "./DenseLayout";
import getLocalizedString from "../../../../../utils/getLocalizedString";
import { useTranslation } from "react-i18next";
import { getInventoryImage } from "../../../../../components-care/models/InventoryModel";
const InventoryRenderer = (props) => {
    const { data, ...otherProps } = props;
    const { i18n } = useTranslation();
    return (_jsx(DenseLayout, { id: data.id, icon: _jsx("img", { src: getInventoryImage(data), alt: getLocalizedString(i18n.language, data.device_type_title_labels) }), title: [
            data.device_model_title,
            data.device_model_version,
            data.device_number,
        ]
            .filter(Boolean)
            .join(" • "), info: [
            getLocalizedString(i18n.language, data.device_type_title_labels),
            data.device_model_manufacturer_according_to_type_plate,
        ].filter((e) => !!e), ...otherProps }));
};
export default React.memo(InventoryRenderer);
