import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { useTenantInfo } from "../TenantContext";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { combineClassNames, useLocation, useNavigate } from "components-care";
import { Avatar, Grid } from "@mui/material";
import { ArrowDropDown as ArrowDropDownIcon, Business as BusinessIcon, } from "@mui/icons-material";
const useStyles = makeStyles()((theme) => ({
    root: {
        ...theme.typography.subtitle1,
    },
    icon: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    arrowIcon: {
        width: 16,
        height: 16,
        verticalAlign: "middle",
    },
    link: {
        cursor: "pointer",
    },
}));
export const useGoToFacilitySelection = () => {
    const navigate = useNavigate();
    return useCallback(() => {
        localStorage.removeItem("tenant"); // remove cached tenant selection
        navigate("/");
    }, [navigate]);
};
const FacilitySelector = () => {
    const { t } = useTranslation("portal");
    const location = useLocation();
    const { classes } = useStyles();
    const goToSelection = useGoToFacilitySelection();
    const tenant = useTenantInfo();
    const is404 = !tenant && location.pathname !== "" && location.pathname !== "/";
    return (_jsxs(Grid, { container: true, alignContent: "center", alignItems: "center", className: classes.root, spacing: 1, flexWrap: "nowrap", children: [_jsx(Grid, { item: true, children: _jsx(Avatar, { src: tenant?.image?.small ?? undefined, className: classes.icon, children: _jsx(BusinessIcon, {}) }) }), _jsxs(Grid, { item: true, onClick: goToSelection, id: "tenant-name", className: combineClassNames([(is404 || tenant) && classes.link]), zeroMinWidth: true, children: [tenant?.short_name ??
                        tenant?.full_name ??
                        (is404
                            ? t("header.tenant.to-selection")
                            : t("header.tenant.not-selected")), _jsx(ArrowDropDownIcon, { className: classes.arrowIcon })] })] }));
};
export default React.memo(FacilitySelector);
