import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { ActionButton, Checkbox, FullFormDialog, GroupBox, showErrorDialog, TextFieldWithHelp, uniqueArray, useDialogContext, useFormContext, useModelMutation, } from "components-care";
import { FormControlLabel, Grid, Table, TableBody, TableCell, TableRow, Typography, } from "@mui/material";
import { AddCircleOutlined as AddIcon, RemoveCircleOutlined as RemoveIcon, } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { RightAlignedIconButton } from "./DeviceModelVideosEdit";
import { useDeviceModelsModel } from "../../../../../components-care/models/DeviceModelsModel";
import { MdmContext } from "../../components/MdmTypes";
const DeviceModelFirmwareAddDialog = (props) => {
    const { handleAdd } = props;
    const { t } = useTranslation("device-models");
    const [, popDialog] = useDialogContext();
    const [busy, setBusy] = useState(false);
    const [version, setVersion] = useState("");
    const [newTraining, setNewTraining] = useState(false);
    const handleVersionChange = useCallback((evt) => {
        setVersion(evt.target.value);
    }, []);
    const handleNewTrainingChange = useCallback((_evt, checked) => {
        setNewTraining(checked);
    }, []);
    const handleSubmit = useCallback(() => {
        setBusy(true);
        void (async () => {
            try {
                await handleAdd(version, newTraining);
            }
            finally {
                setBusy(false);
            }
        })();
        popDialog();
    }, [handleAdd, newTraining, version, popDialog]);
    return (_jsx(FullFormDialog, { maxWidth: "xs", dialogTitle: t("version_list.add-dialog.title"), children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { children: t("version_list.add-dialog.subtitle") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(TextFieldWithHelp, { variant: "standard", value: version, onChange: handleVersionChange, label: t("version_list.add-dialog.input-label"), placeholder: t("version_list.add-dialog.input-placeholder"), fullWidth: true }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { children: t("version_list.add-dialog.help") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormControlLabel, { control: _jsx(Checkbox, {}), value: newTraining, onChange: handleNewTrainingChange, label: t("version_list.add-dialog.new-training") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(ActionButton, { onClick: handleSubmit, disabled: !version || busy, children: t("version_list.add-dialog.okay") }) })] }) }));
};
const DeviceModelFirmwareVersionList = () => {
    const { t } = useTranslation("device-models");
    const target = useContext(MdmContext);
    const [pushDialog] = useDialogContext();
    const { id: recordId, values, setFieldValue, getFieldValues, submit, customProps, } = useFormContext();
    const { open: openView } = customProps;
    if (!recordId)
        throw new Error("Record ID not set");
    const versionModel = useDeviceModelsModel(useMemo(() => ({
        target,
        versionId: recordId,
    }), [recordId, target]));
    const { mutateAsync: createVersion } = useModelMutation(versionModel);
    const { version_list } = values;
    const setVersionString = useCallback((versionList) => {
        setFieldValue("version", versionList.length === 0
            ? ""
            : uniqueArray([
                versionList[0],
                versionList[versionList.length - 1],
            ]).join(" - "));
    }, [setFieldValue]);
    const handleVersionChange = useCallback((evt) => {
        const versionsList = getFieldValues().version_list;
        const index = parseInt(evt.currentTarget.name.slice("version_list.".length));
        const newVersionsList = [...versionsList];
        newVersionsList[index] = evt.target.value;
        setFieldValue("version_list", newVersionsList);
        setVersionString(newVersionsList);
    }, [getFieldValues, setFieldValue, setVersionString]);
    const handleVersionRemove = useCallback((evt) => {
        const versionsList = getFieldValues().version_list;
        const index = parseInt(evt.currentTarget.name.slice("version_list_remove.".length));
        const newVersionsList = [...versionsList].splice(index);
        setFieldValue("versions_list", newVersionsList);
        setVersionString(newVersionsList);
    }, [getFieldValues, setFieldValue, setVersionString]);
    const handleVersionAdd = useCallback(() => {
        pushDialog(_jsx(DeviceModelFirmwareAddDialog, { handleAdd: async (version, newTraining) => {
                if (!newTraining) {
                    const newVersionList = [
                        ...getFieldValues().version_list,
                        version,
                    ];
                    setFieldValue("version_list", newVersionList);
                    setVersionString(newVersionList);
                    return;
                }
                try {
                    await submit();
                    try {
                        const [newVersionRecord] = await createVersion({
                            version_list: [version],
                        });
                        openView(newVersionRecord.id, true);
                    }
                    catch (e) {
                        await showErrorDialog(pushDialog, e);
                    }
                }
                catch (_e) {
                    // error displayed by form
                }
            } }));
    }, [
        createVersion,
        getFieldValues,
        openView,
        pushDialog,
        setFieldValue,
        setVersionString,
        submit,
    ]);
    return (_jsx(GroupBox, { label: t("version_list.group-box"), children: _jsx(Table, { children: _jsxs(TableBody, { children: [version_list.map((version, idx) => (_jsxs(TableRow, { children: [_jsx(TableCell, { children: _jsx(TextFieldWithHelp, { variant: "standard", name: "version_list." + idx, value: version, onChange: handleVersionChange, fullWidth: true }) }), _jsx(TableCell, { children: _jsx(RightAlignedIconButton, { name: "version_list_remove." + idx, onClick: handleVersionRemove, children: _jsx(RemoveIcon, {}) }) })] }, idx))), _jsxs(TableRow, { children: [_jsx(TableCell, {}), _jsx(TableCell, { children: _jsx(RightAlignedIconButton, { onClick: handleVersionAdd, children: _jsx(AddIcon, {}) }) })] })] }) }) }));
};
export default React.memo(DeviceModelFirmwareVersionList);
