import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Add as AddIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useFormContext } from "components-care";
import SamedisApiClient from "../../../../../components-care/connectors/SamedisApiClient";
import { useTenantId } from "../../../../components/TenantContext";
import { useFormEnterpriseClientIdOpt } from "../../../Enterprise/utils/useFormEnterpriseClientId";
const InventoryNextDeviceNumberButton = () => {
    const tenantId = useTenantId();
    const { values, setFieldValue } = useFormContext();
    const enterpriseClientId = useFormEnterpriseClientIdOpt();
    const handleClick = useCallback(async () => {
        const response = await SamedisApiClient.get(enterpriseClientId
            ? `/api/v4/enterprise/tenants/${tenantId}/clients/${enterpriseClientId}/inventories/new`
            : `/api/v4/tenants/${tenantId}/inventories/new`, null);
        setFieldValue("device_number", response.data.attributes.device_number);
    }, [enterpriseClientId, tenantId, setFieldValue]);
    return (_jsx(_Fragment, { children: values.status === "draft" && (_jsx(IconButton, { onClick: handleClick, disabled: !!values.device_number, size: "large", children: _jsx(AddIcon, {}) })) }));
};
export default React.memo(InventoryNextDeviceNumberButton);
