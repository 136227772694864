import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DefaultErrorComponent, Form, FormField, GroupBox, Loader, useFormContext, } from "components-care";
import { useFacilityContentModel, } from "../../../../../components-care/models/FacilityContentModel";
import { useTranslation } from "react-i18next";
import { IconButton, styled, Table, TableBody, TableCell, TableRow, Typography, } from "@mui/material";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import * as Sentry from "@sentry/react";
import { getDeviceModelNameFromRecord } from "../../../../../components-care/models/DeviceModelsModel";
const VideoLinkForm = (_formProps) => {
    return _jsx(FormField, { name: "url" });
};
export const RightAlignedIconButton = styled(IconButton)({
    float: "right",
});
const HiddenTableRow = styled(TableRow)({
    display: "none",
});
const DeviceModelVideosEdit = (props) => {
    const { category } = props;
    const { t } = useTranslation("device-models");
    //const [language] = useActiveLanguageContext();
    const language = "en"; // static language till multi lang support is requested
    const { id: recordId, readOnly, getFieldValues, setCustomFieldDirty, } = useFormContext();
    const model = useFacilityContentModel();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [contentIds, setContentIds] = useState([]);
    const [deleteContentIds, setDeleteContentIds] = useState([]);
    useEffect(() => {
        let ignore = false;
        (async () => {
            try {
                if (!recordId) {
                    setContentIds([]);
                    return;
                }
                const [data] = await model.fetchAllCached({
                    fieldFilter: {
                        content_type: {
                            type: "equals",
                            value1: "link",
                            value2: "",
                        },
                        category: {
                            type: "equals",
                            value1: category,
                            value2: "",
                        },
                        /*language: {
                          type: "equals",
                          value1: language,
                          value2: "",
                        },*/
                        device_model_ids: {
                            type: "equals",
                            value1: recordId ?? "",
                            value2: "",
                        },
                    },
                });
                if (!ignore)
                    setContentIds(data.map((record) => record.id));
            }
            catch (e) {
                if (!ignore)
                    setError(e);
            }
            finally {
                if (!ignore)
                    setLoading(false);
            }
        })();
        return () => {
            ignore = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category, language]);
    const handleAdd = useCallback(() => {
        setContentIds((prev) => [...prev, "create-" + Date.now().toString(16)]);
    }, []);
    useEffect(() => {
        setCustomFieldDirty("video_" + category + "_" + language + "_toDelete", deleteContentIds.length > 0);
    }, [category, deleteContentIds.length, language, setCustomFieldDirty]);
    const content = useMemo(() => {
        if (error)
            return _jsx(Typography, { color: "error", children: error.message });
        if (!contentIds || loading)
            return _jsx(Loader, {});
        return (_jsx(Table, { children: _jsxs(TableBody, { children: [contentIds.map((id) => {
                        const RowComp = deleteContentIds.includes(id)
                            ? HiddenTableRow
                            : TableRow;
                        return (_jsxs(RowComp, { children: [_jsx(TableCell, { children: _jsx(Form, { model: model, nestedFormName: "video_" + category + "_" + language + "_" + id, id: id.startsWith("create-") ? null : id, initialRecord: {
                                            title: t("form.video-links.auto-title", {
                                                CATEGORY: t("videos." + category),
                                                DEVICE: getDeviceModelNameFromRecord(getFieldValues()),
                                            }),
                                            content_type: "link",
                                            category,
                                            language,
                                        }, nestedFormPreSubmitHandler: (id, _model, getFieldValue, setFieldValue) => {
                                            const ids = getFieldValue("device_model_ids");
                                            const thisId = getFieldValue("id");
                                            if (thisId && deleteContentIds.includes(thisId)) {
                                                if (!ids.includes(id))
                                                    return;
                                                setFieldValue("device_model_ids", ids.filter((idx) => idx !== id));
                                            }
                                            if (ids.includes(id))
                                                return;
                                            setFieldValue("device_model_ids", [...ids, id]);
                                        }, onSubmit: async (record) => {
                                            const recordId = record.id;
                                            // delete records without device model ids
                                            if (record.device_model_ids.length === 0) {
                                                if (deleteContentIds.includes(recordId)) {
                                                    await model.deleteRaw(recordId);
                                                    setContentIds((prev) => prev.filter((entry) => entry !== recordId));
                                                    setDeleteContentIds((prev) => prev.filter((entry) => entry !== recordId));
                                                    return;
                                                }
                                                else {
                                                    const error = new Error("deleteContentIds does not contain record without device_model_ids, aborting auto delete");
                                                    console.error(error);
                                                    Sentry.captureException(error);
                                                }
                                            }
                                            setContentIds((prev) => prev.map((entry) => (entry === id ? recordId : entry)));
                                        }, errorComponent: DefaultErrorComponent, customProps: undefined, onlySubmitNestedIfMounted: true, children: VideoLinkForm }, id) }), !readOnly && (_jsx(TableCell, { children: _jsx(RightAlignedIconButton, { onClick: () => {
                                            if (id.startsWith("create-")) {
                                                setContentIds((prev) => prev.filter((entry) => entry !== id));
                                            }
                                            else {
                                                setDeleteContentIds((prev) => [...prev, id]);
                                            }
                                        }, children: _jsx(RemoveIcon, {}) }) }))] }, id));
                    }), _jsxs(TableRow, { children: [_jsx(TableCell, {}), _jsx(TableCell, { children: _jsx(RightAlignedIconButton, { onClick: handleAdd, children: _jsx(AddIcon, {}) }) })] }, "add")] }) }));
    }, [
        category,
        contentIds,
        deleteContentIds,
        error,
        getFieldValues,
        handleAdd,
        language,
        loading,
        model,
        readOnly,
        t,
    ]);
    return useMemo(() => _jsx(GroupBox, { label: t("videos." + category), children: content }), [t, category, content]);
};
export default React.memo(DeviceModelVideosEdit);
