import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { CRUD, DataGridNoPersist, DataGridStorageManagerPersist, DefaultErrorComponent as ErrorComponent, showInfoDialog, useDialogContext, useLocation, } from "components-care";
import { Grid, useTheme } from "@mui/material";
import Forbidden from "../pages/Forbidden";
import { useTenantContext } from "../pages/components/TenantContext";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import SamedisGridWrapper, { SamedisGridWrapperContext, } from "./SamedisGridWrapper";
import SamedisBackendDataGrid, { SamedisBackendDataGridContext, } from "./SamedisBackendDataGrid";
const ImportUI = React.lazy(() => import("./ImportUI"));
export const useDefaultGridProps = () => {
    const theme = useTheme();
    const gridFilterBreakpoint = theme.breakpoints.down("lg");
    const location = useLocation();
    const { t } = useTranslation("common");
    const [pushDialog] = useDialogContext();
    return useMemo(() => {
        const urlQuery = new URLSearchParams(location.search);
        let columnFilter = undefined;
        if (urlQuery.has("column_filter")) {
            try {
                columnFilter = JSON.parse(urlQuery.get("column_filter") ?? "");
            }
            catch (e) {
                console.error("column filter parse error", e);
            }
        }
        return {
            enableFilterDialogMediaQuery: gridFilterBreakpoint,
            getAdditionalFilters: (data) => data,
            filterLimit: 1,
            sortLimit: 1,
            isFilterSupported: (dataType, filterType, field) => {
                if (field === "id") {
                    return filterType === "equals" || filterType === "notEqual";
                }
                if (dataType === "date") {
                    if (filterType === "lessThanOrEqual")
                        return false;
                    if (filterType === "greaterThanOrEqual")
                        return false;
                }
                else if (dataType === "combined-string") {
                    return filterType === "contains" || filterType === "notContains";
                }
                // disabled for UX reasons, user should use equals instead
                if (filterType === "matches" || filterType === "notMatches")
                    return false;
                return true;
            },
            overrideFilter: columnFilter,
            overrideCustomData: location.search
                ? Object.fromEntries(urlQuery.entries())
                : undefined,
            customDeleteErrorHandler: (error) => {
                if (error instanceof Error && error.name === "BackendError") {
                    const err = error;
                    const meta = err.meta;
                    if (meta?.meta?.msg?.error_details) {
                        return showInfoDialog(pushDialog, {
                            title: t("data-grid.delete.error.title"),
                            message: (_jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { item: true, xs: 12, children: t("data-grid.delete.error.message", {
                                            ERROR: error.message,
                                        }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx("ul", { style: { paddingLeft: 16 }, children: Object.entries(meta.meta.msg.error_details).map(([id, info]) => (_jsxs("li", { children: [info.title, ": ", info.errors.join(", ")] }, id))) }) })] })),
                        });
                    }
                }
                showInfoDialog(pushDialog, {
                    title: t("data-grid.delete.error.title"),
                    message: t("data-grid.delete.error.message", {
                        ERROR: error.message,
                    }),
                });
            },
            onError: Sentry.captureException,
        };
    }, [gridFilterBreakpoint, location.search, pushDialog, t]);
};
const SamedisCrud = (props) => {
    const { isSyncReadOnly, disableStore, gridTitle, gridActions, gridMobileRenderer, disableGridWrapper, ...crudProps } = props;
    const { t } = useTranslation("common");
    const [tenantId] = useTenantContext();
    const defaultGridProps = useDefaultGridProps();
    const formPropsCached = useMemo(() => ({
        errorComponent: ErrorComponent,
        ...props.formProps,
        readOnlyReasons: {
            ...props.formProps?.readOnlyReasons,
            ...(isSyncReadOnly && { sync: t("sync-disabled.edit") }),
        },
    }), [isSyncReadOnly, props.formProps, t]);
    const gridPropsCached = useMemo(() => ({
        ...defaultGridProps,
        ...props.gridProps,
    }), [defaultGridProps, props.gridProps]);
    const persistKeys = useMemo(() => {
        const keys = {
            model: props.model.modelId,
        };
        if (tenantId)
            keys.tenant = tenantId;
        return keys;
    }, [props.model.modelId, tenantId]);
    const wrapperCtx = useMemo(() => ({ title: gridTitle, actions: gridActions }), [gridTitle, gridActions]);
    const crud = (_jsx(SamedisGridWrapperContext.Provider, { value: disableGridWrapper ? null : wrapperCtx, children: _jsx(SamedisBackendDataGridContext.Provider, { value: gridMobileRenderer, children: _jsx(CRUD, { ...crudProps, newPermission: isSyncReadOnly ? false : props.newPermission, newPermissionHint: isSyncReadOnly ? t("sync-disabled.new") : undefined, editPermission: isSyncReadOnly ? false : props.editPermission, editPermissionHint: undefined, deletePermission: isSyncReadOnly ? false : props.deletePermission, deletePermissionHint: isSyncReadOnly ? t("sync-disabled.delete") : undefined, formProps: formPropsCached, gridProps: gridPropsCached, forbiddenPage: Forbidden, gridWrapper: SamedisGridWrapper, gridComponent: SamedisBackendDataGrid, importUI: ImportUI }) }) }));
    return disableStore ? (_jsx(DataGridNoPersist, { children: crud })) : (_jsx(DataGridStorageManagerPersist, { storageKeys: persistKeys, children: crud }));
};
export default React.memo(SamedisCrud);
