import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import DenseLayout from "./DenseLayout";
import { getEmployeeImage } from "../../../../../components-care/models/EmployeeModel";
const EmployeeRenderer = (props) => {
    const { data, context, ...otherProps } = props;
    const { t } = useTranslation("staff");
    const fullName = [data.first_name, data.last_name].filter(Boolean).join(" ");
    const hasInstruction = (dataSource) => context.trainingRequirements &&
        context.trainingRequirements.length > 0 &&
        !context.trainingRequirements.find((catalog) => !data[dataSource].includes(catalog));
    const hasVendorInstruction = hasInstruction("manufacturer_catalog_ids");
    const hasUserInstruction = hasInstruction("catalog_ids");
    let trainingLabel = context.trainingRequirements &&
        [
            hasVendorInstruction
                ? t("briefing-status.has_vendor_training")
                : hasUserInstruction
                    ? t("briefing-status.has_user_training")
                    : false,
            data.administered_briefings_count > 0 &&
                t("briefing-status.trainer_count", {
                    N: data.administered_briefings_count,
                }),
        ]
            .filter((e) => !!e)
            .join(". ");
    if (trainingLabel)
        trainingLabel = "⭐ " + trainingLabel;
    return (_jsx(DenseLayout, { id: data.id, icon: _jsx("img", { src: getEmployeeImage(data), alt: fullName }), title: fullName, info: [data.employee_no, trainingLabel, data.notes].filter((e) => !!e), ...otherProps }));
};
export default React.memo(EmployeeRenderer);
