import { jsx as _jsx } from "react/jsx-runtime";
import { Grid } from "@mui/material";
import { ActionButton, showErrorDialog, showInfoDialog, useDialogContext, useFormContext, useModelMutation, } from "components-care";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
const DeviceModelActionPublishToCommunity = (props) => {
    const { close } = props;
    const { model, values, id: recordId } = useFormContext();
    const { mutateAsync } = useModelMutation(model);
    const { t } = useTranslation("device-models");
    const [pushDialog] = useDialogContext();
    const { publish_to_community_requested } = values;
    const [busy, setBusy] = useState(false);
    const handlePublish = useCallback(async () => {
        close();
        try {
            setBusy(true);
            const resp = await mutateAsync({
                id: recordId,
                publish_to_community_requested: true,
            });
            if (!resp[0].publish_to_community_requested) {
                throw new Error("Failed to request to publish");
            }
            showInfoDialog(pushDialog, {
                title: t("actions.request-publish-to-community.success-dialog.title"),
                message: t("actions.request-publish-to-community.success-dialog.message"),
            });
        }
        catch (e) {
            showErrorDialog(pushDialog, e);
        }
        finally {
            setBusy(false);
        }
    }, [close, mutateAsync, pushDialog, recordId, t]);
    const handleUnpublish = useCallback(async () => {
        close();
        try {
            setBusy(true);
            const resp = await mutateAsync({
                id: recordId,
                publish_to_community_requested: false,
            });
            if (resp[0].publish_to_community_requested) {
                throw new Error("Failed to undo request to publish");
            }
            showInfoDialog(pushDialog, {
                title: t("actions.cancel-request-publish-to-community.success-dialog.title"),
                message: t("actions.cancel-request-publish-to-community.success-dialog.message"),
            });
        }
        catch (e) {
            showErrorDialog(pushDialog, e);
        }
        finally {
            setBusy(false);
        }
    }, [close, mutateAsync, pushDialog, recordId, t]);
    return useMemo(() => (_jsx(Grid, { item: true, xs: 12, children: _jsx(ActionButton, { onClick: publish_to_community_requested ? handleUnpublish : handlePublish, color: "secondary", disabled: busy, children: publish_to_community_requested
                ? t("actions.cancel-request-publish-to-community.button")
                : t("actions.request-publish-to-community.button") }) })), [publish_to_community_requested, handleUnpublish, handlePublish, busy, t]);
};
export default React.memo(DeviceModelActionPublishToCommunity);
