import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from "react";
import { useTenantModel } from "../../../../../components-care/models/TenantModel";
import { useTenantId } from "../../../../components/TenantContext";
import ActiveLanguageContext, { AvailableLanguageContext, } from "../../../../../components/ActiveLanguageContext";
import { uniqueArray, useFormContext, useModelGet } from "components-care";
const DeviceModelLanguageContextProvider = (props) => {
    const { children } = props;
    const { values } = useFormContext();
    const publishedLanguages = values.published_languages;
    const tenantModel = useTenantModel();
    const tenantId = useTenantId();
    const { data: tenantData } = useModelGet(tenantModel, tenantId);
    const languageCodes = useMemo(() => {
        const [tenant] = tenantData ?? [];
        const tenantLanguageCodes = tenant?.content_languages ??
            [];
        return uniqueArray([...tenantLanguageCodes, ...publishedLanguages]);
    }, [tenantData, publishedLanguages]);
    const activeLanguageStatePack = useState(() => {
        // first published language or first available language or en
        return publishedLanguages[0] ?? languageCodes[0] ?? "en";
    });
    const [activeLanguage, setActiveLanguage] = activeLanguageStatePack;
    // if active language is not available, update active language
    useEffect(() => {
        if (!languageCodes.includes(activeLanguage)) {
            setActiveLanguage(publishedLanguages[0] ?? languageCodes[0] ?? "en");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageCodes]);
    return (_jsx(AvailableLanguageContext.Provider, { value: languageCodes, children: _jsx(ActiveLanguageContext.Provider, { value: activeLanguageStatePack, children: children }) }));
};
export default React.memo(DeviceModelLanguageContextProvider);
