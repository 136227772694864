import { useTenantId } from "../../../pages/components/TenantContext";
import { useQuery } from "react-query";
import SamedisApiClient from "../../../components-care/connectors/SamedisApiClient";
import { ReactQueryRetryHandler } from "../../../constants";
export const usePlans = (withCustom) => {
    const tenantId = useTenantId();
    return useQuery(["plans", { tenantId, withCustom }], async () => {
        const data = (await SamedisApiClient.get(`/api/v4/tenants/${tenantId}/plans`, { "filter[with_custom]": withCustom })).data;
        return data.map((entry) => entry.attributes);
    }, {
        retry: ReactQueryRetryHandler,
    });
};
export const useBillingHistories = () => {
    const tenantId = useTenantId();
    return useQuery(["subscriptions", { tenantId }], async () => {
        const data = (await SamedisApiClient.get(`/api/v4/tenants/${tenantId}/invoices`, null)).data;
        return data.map((entry) => entry.attributes);
    }, {
        retry: ReactQueryRetryHandler,
    });
};
export const useSubscriptionPreview = (planId, additionalDevices) => {
    const tenantId = useTenantId();
    return useQuery(["subscription-preview", { tenantId, planId, additionalDevices }], async () => {
        const resp = await SamedisApiClient.post(`/api/v4/tenants/${tenantId}/subscription_previews`, null, {
            data: {
                plan_id: planId,
                additional_devices: additionalDevices,
            },
        });
        return resp.data.attributes;
    }, {
        retry: ReactQueryRetryHandler,
    });
};
