import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { useFormContext } from "components-care";
import { useTranslation } from "react-i18next";
import StatusBlock from "../../../../../components/StatusBlock";
const useStyles = makeStyles()((theme) => ({
    status_has_account: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
    },
    status_no_account: {
        backgroundColor: theme.palette.getContrastText(theme.palette.background.paper),
        color: theme.palette.background.paper,
    },
}));
const ContactHasAccountStatus = (props) => {
    const { status } = props;
    const { classes } = useStyles();
    const { t } = useTranslation("contact");
    return (_jsx(StatusBlock, { className: classes[("status_" + status)], children: t("login_status." + status) }));
};
export const ContactHasAccountStatusFromForm = (props) => {
    const { values } = useFormContext();
    // companies don't have accounts
    const isCompany = values.contact_type === "company";
    if (isCompany)
        return _jsx(React.Fragment, {});
    return (_jsx(ContactHasAccountStatus, { status: values.ident_user_id ? "has_account" : "no_account", ...props }));
};
export default React.memo(ContactHasAccountStatus);
