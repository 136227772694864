import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import TypeMaintenanceTypes from "../TypeMaintenanceTypes";
import MaintenanceTypesInput from "../../../components/MaintenanceTypesInput";
import Interval from "../../../components/Interval";
import MaintenanceTypeCategory from "../../../components/MaintenanceTypeCategory";
/**
 * Renders a maintenance types input
 */
class RendererMaintenanceTypes extends TypeMaintenanceTypes {
    render(params) {
        const { visibility, field, value, label, handleChange, handleBlur, errorMsg, } = params;
        if (visibility.disabled)
            return _jsx(_Fragment, {});
        if (visibility.hidden) {
            return (_jsx("input", { type: "hidden", name: field, value: this.stringify(value), readOnly: true, "aria-hidden": "true" }));
        }
        if (visibility.editable) {
            if (visibility.grid)
                throw new Error("Not supported");
            return (_jsx(_Fragment, { children: _jsx(MaintenanceTypesInput, { label: label, values: value, readOnly: visibility.readOnly, onChange: (value) => {
                        handleChange(field, value);
                    }, name: field, onBlur: handleBlur, errorMsg: errorMsg, ...this.params }) }));
        }
        if (this.params.languages != null)
            throw new Error("Grid view only supported without localized labels (languages == null)");
        const valueStr = (value ?? []).map((entry, idx) => (_jsxs("span", { children: [idx !== 0 && "; ", _jsx(MaintenanceTypeCategory, { category: entry.category, midAlign: true }), " ", entry.label, " ", _jsx(Interval, { value: entry.value, unit: entry.unit })] }, idx)));
        return (_jsxs(Typography, { noWrap: visibility.grid, children: [!visibility.grid && `${label}: `, valueStr] }));
    }
}
export default RendererMaintenanceTypes;
