import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Grid, Hidden, useMediaQuery, useTheme } from "@mui/material";
import SamedisLogoApp from "../../assets/img/Samedis_RGB.svg";
import SamedisLogoMy from "../../assets/img/my.samedis.care.svg";
import SamedisLogoSmall from "../../assets/img/Samedis_small.svg";
import { makeStyles } from "tss-react/mui";
import TenantSelector from "./header/FacilitySelector";
import AccountActions from "./header/AccountActions";
import MaintenanceIcon, { useIsMaintenanceIconVisible, } from "./header/MaintenanceIcon";
import SamedisNotifications from "./header/Notifications";
import { useIsGlobalMode } from "./SamedisPortal";
import getCurrentApp from "../../utils/getCurrentApp";
import SupportPopover from "../../components/Support/SupportPopover";
import { combineClassNames, useNavigate } from "components-care";
import { useTenantIdOpt } from "./TenantContext";
const useStyles = makeStyles()((theme) => ({
    header: {
        [theme.breakpoints.up("xs")]: {
            padding: theme.spacing(2, 0, 1, 0),
        },
    },
    logo: {
        width: "auto",
        height: 24,
    },
    logoMobile: {
        cursor: "pointer",
    },
}));
const logo = {
    app: SamedisLogoApp,
    my: SamedisLogoMy,
}[getCurrentApp()];
const PortalHeader = () => {
    const { classes } = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only("xs"));
    const globalMode = useIsGlobalMode();
    const maintenance = useIsMaintenanceIconVisible();
    const tenantId = useTenantIdOpt();
    const navigate = useNavigate();
    const goToHome = useCallback(() => {
        navigate(tenantId ? `/tenant/${tenantId}` : "/");
    }, [navigate, tenantId]);
    return (_jsxs(Grid, { container: true, justifyContent: "flex-start", alignItems: "center", spacing: isXs ? 1 : 2, wrap: "nowrap", className: classes.header, children: [_jsx(Hidden, { implementation: "js", mdDown: true, children: _jsx(Grid, { item: true, children: _jsx("img", { src: logo, className: classes.logo, alt: "Samedis.care" }) }) }), _jsx(Hidden, { implementation: "js", mdUp: true, children: _jsx(Grid, { item: true, children: _jsx("img", { src: SamedisLogoSmall, className: combineClassNames([
                            classes.logo,
                            isXs && classes.logoMobile,
                        ]), onClick: isXs ? goToHome : undefined, alt: "Samedis.care" }) }) }), _jsx(Hidden, { implementation: "js", smDown: true, children: _jsx(Grid, { item: true, children: !globalMode && _jsx(TenantSelector, {}) }) }), _jsx(Grid, { item: true, xs: true }), _jsx(Grid, { item: true, children: _jsx(SamedisNotifications, {}) }), _jsx(Grid, { item: true, children: _jsx(SupportPopover, {}) }), maintenance && (_jsx(Grid, { item: true, children: _jsx(MaintenanceIcon, {}) })), _jsx(Grid, { item: true, children: _jsx(AccountActions, {}) })] }));
};
export default React.memo(PortalHeader);
