import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo } from "react";
import { Grid } from "@mui/material";
import { ActionButton, FormField, FullFormDialog, ModelVisibilityDisabled, ModelVisibilityEditReadOnly, ModelVisibilityEditRequired, ModelVisibilityGridViewHidden, useDialogContext, useFormContext, } from "components-care";
import { usePlans } from "../../../../../api/samedis/models/SubscriptionPlan";
import { StripePublishableKey } from "../../../../../constants";
import { useTranslation } from "react-i18next";
import FacilitySubscriptionChangelogDialog from "./FacilitySubscriptionChangelogDialog";
const TenantBillingPage = React.lazy(() => import("../../Billing/TenantBilling/TenantBilling"));
const FacilityAdminSubscriptionManagement = () => {
    const { t } = useTranslation("tenant");
    const { id: recordId, values, setFieldValue } = useFormContext();
    const { no_of_devices_allowed, plan_id } = values;
    const { data: plans } = usePlans(true);
    const plan = plans?.find((plan) => plan.id === plan_id);
    const allowSetDevices = plan?.plan_type !== "stripe" || plan?.allow_additional_devices;
    const [pushDialog, popDialog] = useDialogContext();
    const openEditTenantBillingDialog = useCallback(() => {
        if (!recordId)
            return;
        pushDialog(_jsx(FullFormDialog, { onClose: popDialog, children: _jsx(TenantBillingPage, { customId: recordId, onSubmit: popDialog }) }));
    }, [recordId, pushDialog, popDialog]);
    const openSubscriptionHistory = useCallback(() => {
        if (!recordId)
            return;
        pushDialog(_jsx(FacilitySubscriptionChangelogDialog, { tenantId: recordId }));
    }, [recordId, pushDialog]);
    useEffect(() => {
        if (!plan)
            return;
        setFieldValue("no_of_devices_allowed", plan.included_device_count || no_of_devices_allowed || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plan_id]);
    return useMemo(() => (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, md: 6, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "plan_id" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "no_of_devices_allowed", overrides: {
                                    visibility: {
                                        overview: ModelVisibilityGridViewHidden,
                                        create: ModelVisibilityDisabled,
                                        edit: StripePublishableKey
                                            ? allowSetDevices
                                                ? ModelVisibilityEditRequired
                                                : ModelVisibilityEditReadOnly
                                            : ModelVisibilityDisabled,
                                    },
                                } }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "allow_overbooking" }) })] }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "subscribed_until" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(ActionButton, { onClick: openEditTenantBillingDialog, children: t("edit-billing-data") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(ActionButton, { onClick: openSubscriptionHistory, children: t("show-subscription-changelog") }) })] }) })] })), [allowSetDevices, openEditTenantBillingDialog, t, openSubscriptionHistory]);
};
export default React.memo(FacilityAdminSubscriptionManagement);
