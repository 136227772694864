import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Typography } from "@mui/material";
import { combineClassNames } from "components-care";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme) => ({
    root: {
        display: "inline-block",
        borderRadius: theme.shape.borderRadius,
        padding: `0 ${theme.spacing(2)}`,
        lineHeight: `calc(${theme.typography.h1.fontSize} * ${theme.typography.h1.lineHeight})`,
        verticalAlign: "bottom",
        [theme.breakpoints.between("sm", "lg")]: {
            lineHeight: `calc(1.5rem * ${theme.typography.h1.lineHeight})`,
        },
    },
    fullWidth: {
        width: "100%",
        textAlign: "center",
    },
    smallLabel: {
        lineHeight: "unset",
        ...theme.typography.h4,
        height: "unset",
        padding: 5,
    },
}));
const StatusBlock = React.forwardRef(function StatusBlock(props, ref) {
    const { fullWidth, small, className, children, ...other } = props;
    const { classes } = useStyles();
    return (_jsx(Typography, { ref: ref, component: "span", ...other, variant: "h2", className: combineClassNames([
            classes.root,
            fullWidth && classes.fullWidth,
            small && classes.smallLabel,
            className,
        ]), children: children }));
});
export default React.memo(StatusBlock);
