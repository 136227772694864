import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { styled, Typography } from "@mui/material";
import { FormDialogDispatchContext } from "components-care/dist/backend-components/Form/FormDialog";
import { useContext, useEffect } from "react";
export const useSetFormDialogTitle = (enabled, title) => {
    const formDialogDispatch = useContext(FormDialogDispatchContext);
    useEffect(() => {
        if (!enabled)
            return;
        formDialogDispatch?.setTitle(title);
    }, [formDialogDispatch, enabled, title]);
    return enabled && !!formDialogDispatch;
};
export const TitleTypography = React.forwardRef(function TitleTypography(props, ref) {
    const { disableAutoTitle, disableFormDialogLogic, ...muiProps } = props;
    const formDialogTitleSet = useSetFormDialogTitle(!disableAutoTitle && !disableFormDialogLogic, props.children);
    if (formDialogTitleSet) {
        return null;
    }
    return _jsx(Typography, { ref: ref, ...muiProps });
});
export const PageHeaderTypography = styled(TitleTypography)(({ theme }) => ({
    ...theme.typography.h1,
    marginBottom: -4,
    [theme.breakpoints.only("xs")]: {
        marginBottom: 0,
        ...theme.typography.subtitle1,
    },
    [theme.breakpoints.between("sm", "lg")]: {
        fontSize: "1.5rem",
    },
}));
