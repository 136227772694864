import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid, Link as MuiLink, Typography } from "@mui/material";
import { DeviceModelUploadsNonForm } from "../../../MasterDataManagement/CatalogModels/components/DeviceModelUploads";
import DeviceModelViewerImages from "./DeviceModelViewerImages";
import { GroupBox } from "components-care";
import getLocalizedString from "../../../../../utils/getLocalizedString";
import { useTranslation } from "react-i18next";
import RendererEmbeddedDeviceTags from "../../../../../components-care/types/renderers/RendererEmbeddedDeviceTags";
import { useActiveLanguageContext } from "../../../../../components/ActiveLanguageContext";
import DeviceModelViewerTabModelVersions from "./DeviceModelViewerTabModelVersions";
import DeviceModelViewerTabModelOperationStatus from "./DeviceModelViewerTabModelOperationStatus";
import { getDeviceModelManufacturerLabel } from "../../../../../components-care/models/DeviceModelsModel";
import DeviceModelViewerCommunityUploads from "./DeviceModelViewerCommunityUploads";
import VideoList from "./Videos/VideoList";
const DeviceModelViewerTabModel = (props) => {
    const { device, inventory, enterpriseClientId } = props;
    const [activeLanguage] = useActiveLanguageContext();
    const { i18n, t } = useTranslation("device-models");
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, md: 2, children: _jsx(DeviceModelViewerImages, { recordId: device.id, linkedImageId: device.linked_image_id, enterpriseClientId: enterpriseClientId }) }), _jsx(Grid, { item: true, xs: 12, md: 10, children: _jsxs(GroupBox, { label: null, children: [inventory && (_jsx(DeviceModelViewerTabModelOperationStatus, { inventory: inventory })), _jsxs(Grid, { container: true, direction: "column", height: "100%", children: [_jsx(Grid, { item: true, xs: true, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { variant: "h2", children: device.title }), _jsx(Typography, { variant: "caption2", component: "p", color: "textSecondary", children: getLocalizedString(i18n.language, // frontend locale, not activeLanguage
                                                        device.device_type_title_labels) }), _jsx(Typography, { variant: "caption2", component: "p", color: "textSecondary", children: getDeviceModelManufacturerLabel(device) })] }), _jsx(DeviceModelViewerTabModelVersions, { device: device }), device.manufacturer_product_url_labels[activeLanguage] && (_jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { variant: "caption2", color: "textSecondary", component: "p", children: t("viewer.model.product-page") }), _jsx(MuiLink, { target: "_blank", rel: "noreferrer", href: device.manufacturer_product_url_labels[activeLanguage], variant: "body1", children: device.manufacturer_product_url_labels[activeLanguage] })] }, "product-homepage")), _jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { variant: "caption2", color: "textSecondary", component: "p", children: t("viewer.model.tags") }), _jsx(Typography, { variant: "body1", component: "p", children: device.embedded_device_tags.length > 0
                                                            ? new RendererEmbeddedDeviceTags().stringify(device.embedded_device_tags)
                                                            : t("viewer.model.no-tags") })] })] }) }), _jsx(Grid, { item: true, children: _jsxs(Typography, { variant: "caption2", component: "p", align: "right", color: "textSecondary", children: [t("viewer.model.source", {
                                                SRC: t("trust_level.enum." + device.trust_level),
                                            }), " • ", t("viewer.model.last_update", {
                                                DATE: device.updated_at.toLocaleString(i18n.language),
                                            })] }) })] })] }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(DeviceModelUploadsNonForm, { category: "model", recordId: device.id, enterpriseClientId: enterpriseClientId, readOnly: true }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(DeviceModelViewerCommunityUploads, { category: "model", recordId: device.id, enterpriseClientId: enterpriseClientId, inventoryId: inventory?.id }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(GroupBox, { label: t("videos.model"), children: _jsx(VideoList, { recordId: device.id, category: "model", language: activeLanguage, scope: "public" }) }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(GroupBox, { label: t("facility-videos.model"), children: _jsx(VideoList, { recordId: device.id, category: "model", language: activeLanguage, scope: "tenant" }) }) })] }));
};
export default React.memo(DeviceModelViewerTabModel);
