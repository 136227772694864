import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import { selectorLocalLoadHandler, SingleSelect, useFormContext, } from "components-care";
import { useCCLanguagesTranslations } from "components-care/dist/utils/useCCTranslations";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useActiveLanguageContext, useAvailableLanguageContext, } from "../../../../../components/ActiveLanguageContext";
const DeviceModelActiveLanguageSelector = () => {
    const { values } = useFormContext();
    const { t: ccT } = useCCLanguagesTranslations();
    const { t } = useTranslation("device-models");
    const [activeLanguageCode, setActiveLanguage] = useActiveLanguageContext();
    const handleSelect = useCallback((data) => {
        if (!data)
            return;
        setActiveLanguage(data.value);
    }, [setActiveLanguage]);
    const publishedLanguages = values.published_languages;
    const languageCodes = useAvailableLanguageContext();
    const languages = useMemo(() => languageCodes.map((code) => ({
        value: code,
        label: [
            ccT(code),
            _jsx(Typography, { variant: "inherit", color: publishedLanguages.includes(code) ? "success.main" : undefined, children: ccT(code) }),
        ],
    })), [languageCodes, publishedLanguages, ccT]);
    const activeLanguage = languages.find((lng) => lng.value === activeLanguageCode) ?? null;
    return (_jsx(SingleSelect, { onLoad: selectorLocalLoadHandler(languages), label: t("active_language.field"), placeholder: t("active_language.placeholder"), selected: activeLanguage, onSelect: handleSelect, disableClearable: true }, languageCodes.join(",")));
};
export default React.memo(DeviceModelActiveLanguageSelector);
