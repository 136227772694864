import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { FullFormDialog } from "components-care";
import { useTranslation } from "react-i18next";
import { useDeviceModelsModel } from "../../../../../components-care/models/DeviceModelsModel";
import { useChangelogModel } from "../../../../../components-care/models/ChangelogModel";
import SamedisCrud from "../../../../../components-care/SamedisCrud";
const DeviceModelChangelogDialog = (props) => {
    const { target, title, id } = props;
    const { t } = useTranslation("device-models");
    const deviceModelsModel = useDeviceModelsModel(useMemo(() => ({
        target,
    }), [target]));
    const changelogModel = useChangelogModel({
        baseModel: deviceModelsModel,
        id,
    });
    const dialogTitle = t(`changelog.title`, {
        DEVICE_NAME: title,
    });
    return (_jsx(FullFormDialog, { dialogTitle: dialogTitle, useCustomClasses: true, children: _jsx(SamedisCrud, { model: changelogModel, gridTitle: dialogTitle, deletePermission: false, readPermission: false, editPermission: false, newPermission: false, exportPermission: false, disableRouting: true, gridProps: {
                disableSelection: true,
            }, children: undefined }) }));
};
export default React.memo(DeviceModelChangelogDialog);
