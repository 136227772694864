import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { shallowCompareArray, useFormContext } from "components-care/dist";
import React, { useContext, useEffect } from "react";
import { MdmContext } from "../../components/MdmTypes";
import { useAvailableLanguageContext } from "../../../../../components/ActiveLanguageContext";
const DeviceModelTenantAutoPublish = () => {
    const { setFieldValue, getFieldValue } = useFormContext();
    const langs = useAvailableLanguageContext();
    const context = useContext(MdmContext);
    useEffect(() => {
        if (context !== "tenant")
            return;
        if (shallowCompareArray(getFieldValue("published_languages"), langs))
            return;
        setFieldValue("published_languages", langs);
        setFieldValue("published", true);
    }, [context, getFieldValue, langs, setFieldValue]);
    return _jsx(_Fragment, {});
};
export default React.memo(DeviceModelTenantAutoPublish);
