import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { FormHelperText, Typography } from "@mui/material";
import TypeRegulatories from "../TypeRegulatories";
import DeviceModelRegulatoriesInput from "../../../components/DeviceModelRegulatoriesInput";
import { useTranslation } from "react-i18next";
const RegulatoriesGridRenderer = (params) => {
    const { visibility, value, label } = params;
    const { t } = useTranslation("device-models");
    const valueStr = (value ?? [])
        .map((x) => [t("regulatories.enum." + x.label), x.value].join(": "))
        .join("; ");
    return (_jsxs(Typography, { noWrap: visibility.grid, children: [!visibility.grid && `${label}: `, valueStr] }));
};
/**
 * Renders a regulatories type input
 */
class RendererRegulatories extends TypeRegulatories {
    render(params) {
        const { visibility, field, value, label, handleChange, handleBlur, errorMsg, } = params;
        if (visibility.disabled)
            return _jsx(_Fragment, {});
        if (visibility.hidden) {
            return (_jsx("input", { type: "hidden", name: field, value: this.stringify(value), readOnly: true, "aria-hidden": "true" }));
        }
        if (visibility.editable) {
            if (visibility.grid)
                throw new Error("Not supported");
            return (_jsxs(_Fragment, { children: [_jsx(DeviceModelRegulatoriesInput, { label: label, values: value, readOnly: visibility.readOnly, onChange: (value) => {
                            handleChange(field, value);
                        }, name: field, onBlur: handleBlur, isMdm: this.params?.isMdm }), _jsx(FormHelperText, { error: !!errorMsg, children: errorMsg })] }));
        }
        return _jsx(RegulatoriesGridRenderer, { ...params });
    }
}
export default RendererRegulatories;
