import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { DepartmentIcon } from "../../../../../components/icons";
import { useTranslation } from "react-i18next";
import DenseLayout from "./DenseLayout";
const DepartmentRenderer = (props) => {
    const { data, ...otherProps } = props;
    const { t } = useTranslation("department");
    return (_jsx(DenseLayout, { id: data.id, icon: _jsx(DepartmentIcon, {}), title: data.title, info: [
            data.notes,
            data.cost_center_number &&
                t("cost-center-number.field") + ": " + data.cost_center_number,
        ].filter((e) => !!e), ...otherProps }));
};
export default React.memo(DepartmentRenderer);
