import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo, useRef, useState, } from "react";
import { Divider, Grid, IconButton, Tooltip, useTheme, } from "@mui/material";
import { PageHeaderTypography, useSetFormDialogTitle, } from "./CustomTypography";
import { makeStyles } from "tss-react/mui";
import useIsMobile from "../utils/useIsMobile";
import { ArrowBackIos, MoreHoriz } from "@mui/icons-material";
import getCurrentApp from "../utils/getCurrentApp";
import { IsInFormDialogContext } from "components-care/dist/backend-components/Form/FormDialog";
import { ActionButton, DialogContextProvider, FormContextLite, useDialogContext, } from "components-care";
import usePageContainerSpacing from "../utils/usePageContainerSpacing";
import { SamedisFullFormDialogContext } from "../components-care/SamedisFullFormDialog";
import BasicPageLayoutMobileTitle from "./BasicPageLayoutMobileTitle";
import { createPortal } from "react-dom";
import LayoutPortal, { useLayoutPortalElement } from "./LayoutPortal";
const useStyles = makeStyles()((theme) => ({
    titleItem: {
        ".CcFormDialog &": {
            display: "none",
        },
    },
    titleItemAction: {
        ".CcFormDialog &": {
            opacity: "0",
        },
    },
    title: {
        top: "50%",
        position: "relative",
        transform: "translateY(-50%)",
    },
    mobileRoot: {
        height: "100%",
    },
    mobileBodyScroller: {
        overflow: "auto",
        height: "100%",
        display: "flex",
    },
    mobileHeader: {
        backgroundColor: "white",
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        position: "relative", // allow title ot be centered with position: absolute
        minHeight: 36,
    },
}));
export const BasicPageLayoutActionRenderer = (props) => {
    const { action: { icon: Icon, iconElem, name, handler: Handler, onClick, disabled, disabledHint, handlerProps, }, mobile, } = props;
    if (!Handler && !onClick && !disabled) {
        console.error("Invalid action", props);
        throw new Error("Invalid action, neither handler nor onClick set");
    }
    const handlerRef = useRef(null);
    const handleClick = useCallback((evt) => {
        const handler = handlerRef.current;
        if (!handler)
            return;
        handler.handleAction(evt);
    }, []);
    if (mobile) {
        return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: !disabled || !disabledHint ? name : `${name} - ${disabledHint}`, children: _jsx("span", { children: _jsx(IconButton, { size: "small", color: "primary", onClick: onClick ?? handleClick, disabled: disabled, children: iconElem ?? (Icon ? _jsx(Icon, {}) : _jsx(MoreHoriz, {})) }) }) }), Handler && _jsx(Handler, { ...handlerProps, ref: handlerRef })] }));
    }
    const ab = (_jsx(ActionButton, { icon: iconElem ?? (Icon && _jsx(Icon, {})), onClick: onClick ?? handleClick, disabled: disabled, children: name }));
    return (_jsxs(_Fragment, { children: [disabled && disabledHint ? (_jsx(Tooltip, { title: disabledHint, children: _jsx("span", { children: ab }) })) : (ab), Handler && _jsx(Handler, { ...handlerProps, ref: handlerRef })] }));
};
export const BasicPageLayoutContext = React.createContext(undefined);
export const useBasicPageLayoutContext = () => {
    const ctx = useContext(BasicPageLayoutContext);
    if (!ctx)
        throw new Error("BasicPageLayoutContext not set");
    return ctx;
};
const BasicPageLayout = (props) => {
    const { title, children, spacing: spacingProp, noPadding, actions: actionsProp, column, statusBlock, mobileBottomNavigation, } = props;
    const [, popDialog] = useDialogContext();
    const { classes } = useStyles();
    const theme = useTheme();
    const mobile = useIsMobile();
    const defaultSpacing = usePageContainerSpacing();
    const inDialog = useContext(IsInFormDialogContext);
    const dialogCtx = useContext(SamedisFullFormDialogContext);
    const isMobileActivityDialog = !!dialogCtx?.useFullHeight;
    const formCtx = useContext(FormContextLite);
    const spacing = spacingProp ?? defaultSpacing;
    const backActionMobile = useMemo(() => {
        if (!formCtx) {
            if (isMobileActivityDialog) {
                return () => popDialog();
            }
            return;
        }
        if (!formCtx.customProps)
            return;
        if (typeof formCtx.customProps !== "object")
            return;
        if (!("goBack" in formCtx.customProps))
            return;
        if (!("hasCustomSubmitHandler" in formCtx.customProps))
            return;
        const { goBack, hasCustomSubmitHandler } = formCtx.customProps;
        if (!goBack)
            return;
        if (inDialog && hasCustomSubmitHandler) {
            if (isMobileActivityDialog) {
                return () => popDialog();
            }
            return;
        }
        return () => goBack();
    }, [formCtx, inDialog, isMobileActivityDialog, popDialog]);
    const disableMobileBack = formCtx ? formCtx.submitting : false;
    const [additionalActions, setAdditionalActions] = useState({});
    const setAdditionalAction = useCallback((id, action) => {
        if (action == null) {
            setAdditionalActions((prev) => {
                const copy = { ...prev };
                delete copy[id];
                return copy;
            });
            return;
        }
        setAdditionalActions((prev) => ({ ...prev, [id]: action }));
    }, []);
    const actions = useMemo(() => {
        const actions = actionsProp
            ? [...actionsProp, ...Object.values(additionalActions)]
            : Object.values(additionalActions);
        if (actions.length === 0)
            return null;
        return actions;
    }, [actionsProp, additionalActions]);
    const ctx = useMemo(() => ({ setAdditionalAction }), [setAdditionalAction]);
    useSetFormDialogTitle(mobile && inDialog && !isMobileActivityDialog, title);
    const portalElem = useLayoutPortalElement();
    const portalContent = (_jsx(Grid, { container: true, spacing: spacing, mb: mobile && !column ? spacing : undefined, alignItems: "stretch", direction: column ? "column" : "row", wrap: column ? "nowrap" : undefined, sx: column
            ? {
                height: "calc(100% + " + theme.spacing(spacing) + ")",
            }
            : undefined, children: children }));
    const layout = (() => {
        if (mobile) {
            return (_jsxs(Grid, { container: true, direction: "column", wrap: "nowrap", className: classes.mobileRoot, children: [(!inDialog || isMobileActivityDialog) && (_jsx(_Fragment, { children: _jsxs(Grid, { item: true, container: true, justifyContent: "space-between", alignItems: "center", className: classes.mobileHeader, wrap: "nowrap", px: 1, children: [_jsx(Grid, { item: true, children: backActionMobile && (_jsx(IconButton, { size: "small", color: "primary", onClick: backActionMobile, disabled: disableMobileBack, children: _jsx(ArrowBackIos, {}) })) }), _jsx(BasicPageLayoutMobileTitle, { title: title || dialogCtx?.dialogTitle, isMobileActivityDialog: isMobileActivityDialog }), _jsx(Grid, { item: true, children: _jsx(DialogContextProvider, { children: actions &&
                                            actions.filter(Boolean).map((action) => (_jsx(BasicPageLayoutActionRenderer, { action: action, mobile: true }, action.id))) }) })] }) })), _jsx(Grid, { item: true, xs: true, className: !inDialog || isMobileActivityDialog
                            ? classes.mobileBodyScroller
                            : undefined, p: noPadding ? 0 : spacing, children: _jsx(LayoutPortal, { elem: portalElem }) }), mobileBottomNavigation && _jsx(Grid, { item: true, children: mobileBottomNavigation })] }));
        }
        return (_jsxs(Grid, { container: true, spacing: spacing, alignItems: "stretch", direction: column ? "column" : "row", wrap: column ? "nowrap" : undefined, sx: column
                ? {
                    height: "calc(100% + " + theme.spacing(spacing) + ")",
                }
                : undefined, p: inDialog ? 0 : spacing, children: [title && (_jsxs(Grid, { item: true, xs: column ? undefined : 12, container: true, spacing: 2, display: inDialog && !actions ? "none" : undefined, children: [_jsxs(Grid, { item: true, xs: 12, container: true, wrap: "nowrap", alignItems: "center", children: [_jsxs(Grid, { item: true, xs: true, className: actions ? classes.titleItemAction : classes.titleItem, container: true, spacing: 2, wrap: "nowrap", zeroMinWidth: true, alignContent: "center", children: [_jsx(Grid, { item: true, zeroMinWidth: true, children: _jsx(PageHeaderTypography, { component: "div", color: getCurrentApp() === "my" ? undefined : "background.paper", className: classes.title, noWrap: true, children: title }) }), statusBlock && _jsx(Grid, { item: true, children: statusBlock })] }), actions && (_jsx(Grid, { item: true, pl: 2, children: _jsx(DialogContextProvider, { children: actions.filter(Boolean).map((action) => (_jsx(BasicPageLayoutActionRenderer, { action: action }, action.id))) }) }))] }), getCurrentApp() === "my" && (_jsx(Grid, { item: true, xs: 12, children: _jsx(Divider, {}) }))] })), _jsx(Grid, { item: true, xs: column ? true : 12, container: true, children: _jsx(LayoutPortal, { elem: portalElem }) })] }));
    })();
    return (_jsxs(BasicPageLayoutContext.Provider, { value: ctx, children: [layout, createPortal(portalContent, portalElem)] }));
};
export default React.memo(BasicPageLayout);
