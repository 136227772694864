import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from "react";
import { Collapse, Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Checkbox } from "components-care";
import { marked } from "marked";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import useAsyncMemo from "components-care/dist/utils/useAsyncMemo";
const useStyles = makeStyles()({
    entryHeader: {
        marginTop: 11,
    },
    markdownArea: {
        "& ul": {
            paddingInlineStart: 18,
        },
    },
});
const PermissionBlock = (props) => {
    const { classes } = useStyles();
    const { checked, onChecked, name, title, description } = props;
    const [expanded, setExpanded] = useState(false);
    const markdown = useAsyncMemo(() => marked(description), [description]);
    const handleCheck = useCallback((_, checked) => {
        onChecked(name, checked);
    }, [name, onChecked]);
    const handleExpand = useCallback(() => {
        setExpanded((prev) => !prev);
    }, []);
    const ExpandIcon = expanded ? ExpandLess : ExpandMore;
    return (_jsxs(Grid, { container: true, spacing: 1, wrap: "nowrap", children: [_jsx(Grid, { item: true, children: _jsx(Checkbox, { checked: checked, onChange: handleCheck }) }), _jsxs(Grid, { item: true, xs: true, children: [_jsx(Typography, { variant: "body1", className: classes.entryHeader, children: _jsx("b", { children: title }) }), _jsx(Collapse, { in: expanded, children: _jsx("div", { className: classes.markdownArea, dangerouslySetInnerHTML: {
                                __html: markdown ?? "",
                            } }) })] }), _jsx(Grid, { item: true, children: _jsx(IconButton, { onClick: handleExpand, children: _jsx(ExpandIcon, {}) }) })] }));
};
export default React.memo(PermissionBlock);
