import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext } from "react";
import { Grid, IconButton } from "@mui/material";
import { FormField, FullFormDialog, useDialogContext, useFormContext, } from "components-care";
import DeviceModelUploads from "./DeviceModelUploads";
import DeviceModelLocalizedFormFieldProvider from "./DeviceModelLocalizedFormFieldProvider";
import DeviceModelVideosEdit from "./DeviceModelVideosEdit";
import DeviceModelInheritedDeviceTypeTags from "./DeviceModelInheritedDeviceTypeTags";
import { ShowDetailsIcon } from "../../../../../components/icons";
import { MdmContext } from "../../components/MdmTypes";
const ContactsCRUD = React.lazy(() => import("../../../Contacts/Contacts/components/Contacts"));
const ContactInput = (props) => {
    const [pushDialog, popDialog] = useDialogContext();
    const { values } = useFormContext();
    const contactId = values[props.field];
    const mode = useContext(MdmContext);
    const openContact = useCallback(() => {
        if (!contactId)
            return;
        pushDialog(_jsx(FullFormDialog, { useCustomClasses: true, children: _jsx(ContactsCRUD, { disableRouting: true, disableBackgroundGrid: true, disableStore: true, mode: mode, initialView: contactId, formProps: {
                    onSubmit: popDialog,
                } }) }));
    }, [contactId, mode, popDialog, pushDialog]);
    return (_jsxs(Grid, { item: true, xs: 12, md: 6, container: true, spacing: 2, alignItems: "flex-end", wrap: "nowrap", children: [_jsx(Grid, { item: true, xs: true, children: _jsx(FormField, { name: props.field }) }), _jsx(Grid, { item: true, children: _jsx(IconButton, { disabled: !contactId, onClick: openContact, size: "large", color: "primary", children: _jsx(ShowDetailsIcon, {}) }) })] }));
};
const TabModel = () => {
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "device_type_id" }) }), _jsx(Grid, { item: true, xs: 12, md: 6 }), _jsx(ContactInput, { field: "responsible_company_contact_id" }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(FormField, { name: "title" }) }), _jsx(ContactInput, { field: "manufacturer_company_contact_id" }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(FormField, { name: "version" }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(DeviceModelLocalizedFormFieldProvider, { children: _jsx(FormField, { name: "manufacturer_product_url_labels" }) }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(DeviceModelInheritedDeviceTypeTags, {}) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(FormField, { name: "device_tag_ids" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(DeviceModelUploads, { category: "model" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(DeviceModelVideosEdit, { category: "model" }) })] }));
};
export default React.memo(TabModel);
