import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import SearchAndBrowse from "../index";
import { useTenantId } from "../../../../components/TenantContext";
import { COMPACT_LAYOUT_HEIGHT } from "../Renderers/DenseLayout";
import AccessProfilesModel, { AccessProfilesModelSort, useAccessProfilesModelTranslations, } from "../../../../../components-care/models/AccessProfilesModel";
import AccessProfileRenderer from "../Renderers/AccessProfileRenderer";
const NO_FILTERS = {};
const AccessProfileSearch = (props) => {
    const { ...other } = props;
    const tenantId = useTenantId();
    const { t: accessProfilesT } = useAccessProfilesModelTranslations();
    const loadRecords = useCallback(async (search, _filters, offset) => {
        const accessProfilesModel = AccessProfilesModel({
            t: accessProfilesT,
            tenantId,
        });
        const [data, meta] = await accessProfilesModel.index2({
            offset: offset,
            rows: 25,
            sort: AccessProfilesModelSort,
            quickFilter: search,
        });
        return {
            data: data,
            total: meta.filteredRows ?? meta.totalRows,
        };
    }, [accessProfilesT, tenantId]);
    return (_jsx(SearchAndBrowse, { ...other, loadRecords: loadRecords, filters: NO_FILTERS, renderer: AccessProfileRenderer, rendererItemHeight: COMPACT_LAYOUT_HEIGHT, rendererContext: undefined, controlName: "AccessProfileSearch" }));
};
export default React.memo(AccessProfileSearch);
