import { Model, ModelDataTypeStringRendererCC, ModelVisibilityDisabledReadOnly, ModelVisibilityEdit, ModelVisibilityEditRequired, ModelVisibilityGridView, validatePresence, } from "components-care";
import BackendConnector from "../../connectors/BackendConnector";
import { AUDIT_FIELDS_DEF, ID_FIELD_DEF } from "../../../constants";
import { useTranslation } from "react-i18next";
import { useTenantId } from "../../../pages/components/TenantContext";
import { useMemo } from "react";
const DeviceLocationPropertyModel = (params) => new Model("device-locations-properties-model", {
    title: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("device-locations:title.placeholder"),
        }),
        getLabel: () => params.t("device-locations:title.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 420, 260],
        validate: validatePresence,
    },
    path: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("device-locations:path.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 420, 260],
    },
    notes: {
        type: new ModelDataTypeStringRendererCC({
            multiline: true,
            rows: 5,
            placeholder: params.t("device-locations:notes.placeholder"),
        }),
        getLabel: () => params.t("device-locations:notes.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 640, 320],
    },
    ...AUDIT_FIELDS_DEF(params.t, null),
    id: {
        ...ID_FIELD_DEF(params.t),
        customData: null,
    },
}, new BackendConnector(`v4/tenants/${params.tenantId}/properties`), [params.tenantId], {
    enableRequestBatching: true,
});
export const useDeviceLocationPropertyModelTranslations = () => useTranslation("device-locations");
export const useDeviceLocationPropertyModel = () => {
    const tenantId = useTenantId();
    const { t } = useDeviceLocationPropertyModelTranslations();
    return useMemo(() => DeviceLocationPropertyModel({ t, tenantId }), [tenantId, t]);
};
export default DeviceLocationPropertyModel;
