import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import SamedisCrudFileUpload from "../../../../../components-care/SamedisCrudFileUpload";
import { useTenantIdOpt } from "../../../../components/TenantContext";
import { useTranslation } from "react-i18next";
import { useActiveLanguageContext } from "../../../../../components/ActiveLanguageContext";
import { MdmContext } from "../../components/MdmTypes";
import { useFormContextLite } from "components-care";
export const DeviceModelUploadsNonForm = (props) => {
    const { recordId, readOnly, enterpriseClientId } = props;
    const { category } = props;
    const target = useContext(MdmContext);
    const [language] = useActiveLanguageContext();
    const tenantId = useTenantIdOpt();
    const { t } = useTranslation("device-models");
    return (_jsx(SamedisCrudFileUpload, { field: "uploads_" + category + "_" + language, label: t("uploads." + category), getEndpoint: (id) => target === "tenant"
            ? enterpriseClientId
                ? `v4/enterprise/tenants/${tenantId}/clients/${enterpriseClientId}/device_models/${id}/uploads`
                : tenantId
                    ? `v4/tenants/${tenantId}/device_models/${id}/uploads`
                    : `v4/my/device_models/${id}/uploads`
            : target === "user"
                ? `v4/tenants/${tenantId}/mdm/device_models/${id}/uploads`
                : `v4/device_models/${id}/uploads`, extraParams: target === "admin" ? { tenant_id: tenantId } : undefined, initialId: recordId, readOnly: readOnly, tags: {
            category: category === "model" ? [category, null] : category, // show uncategorized documents in model
            language: [language, null], // show un-localized in every language
        } }, "files_" + category + "_" + language));
};
export const DeviceModelUploads = (props) => {
    const { id: recordId } = useFormContextLite();
    return (_jsx(DeviceModelUploadsNonForm, { recordId: recordId, enterpriseClientId: null, ...props }));
};
export default React.memo(DeviceModelUploads);
