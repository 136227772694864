import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useImperativeHandle } from "react";
import { useActionButtonStyles } from "../../../../../components/ActionButton";
import { Popover } from "@mui/material";
import DeviceModelViewerLanguageSelector from "./DeviceModelViewerLanguageSelector";
const DeviceModelViewerTranslateButton = React.forwardRef((props, ref) => {
    const { device } = props;
    const { classes } = useActionButtonStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleAction = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);
    useImperativeHandle(ref, () => ({
        handleAction,
    }), [handleAction]);
    return (_jsx(Popover, { open: !!anchorEl, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
        }, transformOrigin: {
            vertical: "top",
            horizontal: "right",
        }, className: classes.popover, children: _jsx("div", { className: classes.content, children: _jsx(DeviceModelViewerLanguageSelector, { languages: device.published_languages }) }) }));
});
export default React.memo(DeviceModelViewerTranslateButton);
