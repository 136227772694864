import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo } from "react";
import { useContactModel, } from "../../../../../components-care/models/ContactModel";
import SamedisCrud from "../../../../../components-care/SamedisCrud";
import ContactsFilterBar from "./ContactsFilterBar";
import ContactForm from "./ContactForm";
import { hasPermission, useDialogContext, usePermissionContext, } from "components-care";
import ContactInviteDialog from "./ContactInviteDialog";
import { MailOutline as InviteIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { GRID_FILTER_DIALOG_MEDIA_QUERY_SINGLE_ENTRY } from "../../../../../constants";
import { SamedisMobileDataRendererContactsConfig } from "../../../../../components-care/SamedisMobileDataRenderers/SamedisMobileDataRendererContacts";
const ContactsCrudContext = React.createContext(undefined);
export const useContactsCrudContext = () => {
    const ctx = useContext(ContactsCrudContext);
    if (!ctx)
        throw new Error("missing ctx");
    return ctx;
};
const ContactsCRUD = (props) => {
    const model = useContactModel(useMemo(() => ({
        target: props.mode,
        forceFields: props.forceFields,
        enterpriseClientId: props.enterpriseClientId,
    }), [props.mode, props.forceFields, props.enterpriseClientId]));
    const { t } = useTranslation("contact");
    const [pushDialog] = useDialogContext();
    const [perms] = usePermissionContext();
    const checkDisabledInvite = useCallback((selectedRows) => selectedRows === 0 || !hasPermission(perms, "contacts.writer"), [perms]);
    const openInviteDialog = useCallback((_invert, ids) => {
        if (_invert)
            throw new Error("_invert not supported");
        pushDialog(_jsx(ContactInviteDialog, { contactIds: ids }));
    }, [pushDialog]);
    return (_jsx(ContactsCrudContext.Provider, { value: useMemo(() => ({
            servicePartnerView: props.servicePartnerView,
            mode: props.mode,
        }), [props.servicePartnerView, props.mode]), children: _jsx(SamedisCrud, { model: model, gridMobileRenderer: SamedisMobileDataRendererContactsConfig, gridTitle: t("grid-title"), gridProps: {
                filterBar: props.mode === "tenant" ? ContactsFilterBar : undefined,
                defaultCustomData: { "filter[scope]": "tenant" },
                enableFilterDialogMediaQuery: GRID_FILTER_DIALOG_MEDIA_QUERY_SINGLE_ENTRY,
                defaultSort: [
                    {
                        field: "name",
                        direction: 1,
                    },
                ],
                customDataActionButtons: [
                    {
                        icon: _jsx(InviteIcon, {}),
                        label: t("buttons.invite"),
                        isDisabled: checkDisabledInvite,
                        onClick: openInviteDialog,
                    },
                ],
            }, deletePermission: "contacts.deleter", readPermission: "contacts.reader", editPermission: "contacts.writer", newPermission: "contacts.writer", exportPermission: "contacts.xlsx", ...props, formProps: {
                ...props.formProps,
                readOnlyReasons: {
                    ...(props.servicePartnerView && { servicePartnerView: null }),
                },
            }, children: ContactForm }) }));
};
export default React.memo(ContactsCRUD);
