import { Model, ModelDataTypeBooleanCheckboxRendererCC, ModelDataTypeEnumSelectRenderer, ModelDataTypeIntegerRendererCC, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityDisabledReadOnly, ModelVisibilityEditReadOnly, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import { ID_FIELD_DEF } from "../../constants";
import i18n from "../../i18n";
import { useMemo } from "react";
import { useTenantId } from "../../pages/components/TenantContext";
const PlanModel = (params) => new Model("plan-model", {
    name: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityEditReadOnly,
            edit: ModelVisibilityEditReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    status: {
        type: new ModelDataTypeEnumSelectRenderer(["active", "inactive"].map((value) => ({
            value,
            getLabel: () => value,
        }))),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityEditReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    plan_type: {
        type: new ModelDataTypeEnumSelectRenderer(["custom", "trial", "stripe"].map((value) => ({
            value,
            getLabel: () => value,
        }))),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityEditReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    price: {
        type: new ModelDataTypeIntegerRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityEditReadOnly,
            edit: ModelVisibilityEditReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    included_device_count: {
        type: new ModelDataTypeIntegerRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityEditReadOnly,
            edit: ModelVisibilityEditReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    base_additional_devices: {
        type: new ModelDataTypeIntegerRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityEditReadOnly,
            edit: ModelVisibilityEditReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    allow_additional_devices: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityEditReadOnly,
            edit: ModelVisibilityEditReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    id: {
        ...ID_FIELD_DEF(i18n.t.bind(i18n)),
        customData: null,
    },
}, new BackendConnector(`v4/tenants/${params.tenantId}/plans`, "data", {
    additionalQueryParameters: { ...params.extraParams },
}), [params.tenantId, params.extraParams]);
export const usePlanModel = (params) => {
    const tenantId = useTenantId();
    return useMemo(() => PlanModel({ tenantId, ...params }), [params, tenantId]);
};
export default PlanModel;
