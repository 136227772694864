import { Model, ModelDataTypeBooleanCheckboxRendererCC, ModelDataTypeDateNullableRendererCC, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityDisabledReadOnly, ModelVisibilityEdit, ModelVisibilityGridView, ModelVisibilityHidden, validateEmail, validatePresence, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import AMPermissionRenderer from "./Types/Renderers/AMPermissionRenderer";
import { useTenantId } from "../../pages/components/TenantContext";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import RendererBackendObjectIdArray from "../types/renderers/RendererBackendObjectIdArray";
import { ID_FIELD_DEF } from "../../constants";
import RendererObjectId from "../types/renderers/RendererObjectId";
const BackendFieldVisibility = {
    overview: ModelVisibilityDisabledReadOnly,
    create: ModelVisibilityHidden,
    edit: ModelVisibilityHidden,
};
const OverviewFieldVisibility = {
    overview: ModelVisibilityGridView,
    create: ModelVisibilityEdit,
    edit: ModelVisibilityEdit,
};
const EditFieldVisibility = {
    overview: ModelVisibilityDisabled,
    create: ModelVisibilityEdit,
    edit: ModelVisibilityEdit,
};
const InvitesModel = (params) => new Model("invites-model", {
    invitable_id: {
        type: new RendererObjectId(),
        getLabel: () => "",
        visibility: BackendFieldVisibility,
        customData: null,
    },
    invitable_type: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => "",
        visibility: BackendFieldVisibility,
        customData: null,
    },
    "actions.contact_id": {
        type: new RendererObjectId(),
        getLabel: () => "",
        visibility: EditFieldVisibility,
        customData: null,
    },
    "actions.staff_id": {
        type: new RendererObjectId(),
        getLabel: () => "",
        visibility: EditFieldVisibility,
        customData: null,
    },
    "actions.add_access_group_ids": {
        type: new AMPermissionRenderer(true),
        getLabel: () => "",
        visibility: EditFieldVisibility,
        customData: null,
        validate: (value, values) => {
            if (values.my_samedis)
                return null;
            if (!value || value.length === 0) {
                return params.t("invites:access.required");
            }
            return null;
        },
    },
    "actions.add_access_groups": {
        type: new RendererBackendObjectIdArray(),
        getLabel: () => "",
        visibility: EditFieldVisibility,
        customData: null,
    },
    profile_id: {
        type: new RendererObjectId(),
        getLabel: () => "",
        visibility: BackendFieldVisibility,
        customData: null,
    },
    name: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("invites:name.field"),
        visibility: OverviewFieldVisibility,
        customData: null,
        validate: validatePresence,
    },
    email: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("invites:email.field"),
        visibility: OverviewFieldVisibility,
        customData: null,
        validate: (value, values, field) => {
            const presence = validatePresence(value, values, field);
            if (presence != null)
                return presence;
            const validEmail = validateEmail(value, values, field);
            if (validEmail != null)
                return params.t("invites:email.invalid");
            return null;
        },
    },
    created_at: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("invites:created_at.field"),
        visibility: OverviewFieldVisibility,
        customData: null,
    },
    valid_until: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("invites:valid_until.field"),
        visibility: OverviewFieldVisibility,
        customData: null,
    },
    my_samedis: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => "",
        visibility: BackendFieldVisibility,
        customData: null,
    },
    id: {
        ...ID_FIELD_DEF(params.t),
        customData: null,
    },
}, new BackendConnector(`v4/tenants/${params.tenantId}/access_control/invites`, "data", {
    additionalQueryParameters: { "filter[is_valid]": "true" },
}), [params.tenantId]);
export const useInvitesModel = () => {
    const tenantId = useTenantId();
    const { t } = useTranslation("invites");
    return useMemo(() => InvitesModel({ tenantId, t }), [tenantId, t]);
};
export default InvitesModel;
