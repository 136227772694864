import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from "react";
import { BottomNavigation, BottomNavigationAction, Box, Grid, Tab, Tabs, Typography, useMediaQuery, useTheme, } from "@mui/material";
import { Menu as MenuIcon, Translate as TranslateIcon, } from "@mui/icons-material";
import { TabContext, TabPanel } from "@mui/lab";
import ActiveLanguageContext from "../../../../../components/ActiveLanguageContext";
import getLocalizedString, { getAvailableLanguage, } from "../../../../../utils/getLocalizedString";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import DeviceModelViewerLanguageSelector from "./DeviceModelViewerLanguageSelector";
import DeviceModelViewerTabModel from "./DeviceModelViewerTabModel";
import DeviceModelViewerTabService from "./DeviceModelViewerTabService";
import DeviceModelViewerTabTechnical from "./DeviceModelViewerTabTechnical";
import DeviceModelViewerTabTraining from "./DeviceModelViewerTabTraining";
import DeviceModelViewerTabRegulatory from "./DeviceModelViewerTabRegulatory";
import DeviceModelViewerActionButton from "./DeviceModelViewerActionButton";
import useIsMobile from "../../../../../utils/useIsMobile";
import BasicPageLayout, { BasicPageLayoutActionRenderer, } from "../../../../../components/BasicPageLayout";
import MedicalDeviceIcon from "../../../../../components/icons/MedicalDeviceIcon";
import ServiceInspectionIcon from "../../../../../components/icons/ServiceInspectionIcon";
import ServiceMaintenanceIcon from "../../../../../components/icons/ServiceMaintenanceIcon";
import TrustLevelSupplierIcon from "../../../../../components/icons/TrustLevelSupplierIcon";
import BriefingIcon from "../../../../../components/icons/TrainingIcon";
import { useDeviceModelEditLink } from "./DeviceModelViewerActionEdit";
import { useTenantIdOpt } from "../../../../components/TenantContext";
import DeviceModelViewerTranslateButton from "./DeviceModelViewerTranslateButton";
import { FORM_DIALOG_PADDING } from "../../../../../constants";
const useStyles = makeStyles()((theme) => ({
    mainContentWrapper: {
        padding: 0,
    },
    tabs: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    tabPanel: { padding: 0 },
}));
const DeviceModelViewerContent = (props) => {
    const { device } = props;
    const { t, i18n } = useTranslation("device-models");
    const { classes } = useStyles();
    const isMobile = useIsMobile();
    const isSmallDevice = useMediaQuery(useTheme().breakpoints.down("sm"));
    const tenantId = useTenantIdOpt();
    const activeLanguageStatePack = useState(() => {
        const availLang = getAvailableLanguage(i18n.language, device.published_languages);
        if (availLang)
            return availLang;
        return device.published_languages[0] ?? i18n.language.split("-")[0];
    });
    const [selectedTab, setSelectedTab] = useState("model");
    const handleTabChange = useCallback((_event, newValue) => {
        setSelectedTab(newValue);
    }, []);
    const title = [
        device.title,
        device.associated_version_ids.length > 0 &&
            device.version,
        getLocalizedString(i18n.language, device.device_type_title_labels),
    ]
        .filter((entry) => !!entry)
        .join(" • ");
    const content = (_jsxs(TabContext, { value: selectedTab, children: [!isMobile && (_jsx(Grid, { item: true, maxWidth: "100% !important", children: _jsxs(Tabs, { value: selectedTab, onChange: handleTabChange, variant: "scrollable", className: classes.tabs, children: [_jsx(Tab, { label: t("form.tabs.model"), value: "model" }), _jsx(Tab, { label: t("form.tabs.service"), value: "service" }), _jsx(Tab, { label: t("form.tabs.technical"), value: "technical" }), _jsx(Tab, { label: t("form.tabs.training"), value: "training" }), _jsx(Tab, { label: t("form.tabs.regulatory"), value: "regulatory" })] }) })), _jsx(Grid, { item: true, xs: true, position: "relative", maxWidth: "100%  !important", children: _jsxs(Box, { p: isMobile ? 0 : 2, overflow: "auto", height: "100%", width: isMobile ? undefined : "calc(100% - 16px)", position: "absolute", children: [_jsx(TabPanel, { value: "model", className: classes.tabPanel, children: _jsx(DeviceModelViewerTabModel, { ...props }) }), _jsx(TabPanel, { value: "service", className: classes.tabPanel, children: _jsx(DeviceModelViewerTabService, { ...props }) }), _jsx(TabPanel, { value: "technical", className: classes.tabPanel, children: _jsx(DeviceModelViewerTabTechnical, { ...props }) }), _jsx(TabPanel, { value: "training", className: classes.tabPanel, children: _jsx(DeviceModelViewerTabTraining, { ...props }) }), _jsx(TabPanel, { value: "regulatory", className: classes.tabPanel, children: _jsx(DeviceModelViewerTabRegulatory, { ...props }) })] }) })] }));
    const deviceEditLink = useDeviceModelEditLink(device);
    const actionButton = {
        id: "device-actions",
        icon: MenuIcon,
        name: t("actions.button"),
        handler: DeviceModelViewerActionButton,
        handlerProps: props,
    };
    const hideActionButton = !tenantId && !deviceEditLink;
    const layout = isMobile ? (_jsx(BasicPageLayout, { title: title, column: true, actions: [
            device.published_languages.length > 1 &&
                {
                    id: "device-translation",
                    icon: TranslateIcon,
                    name: t("actions.translate"),
                    handler: DeviceModelViewerTranslateButton,
                    handlerProps: props,
                },
            !hideActionButton && actionButton,
        ], mobileBottomNavigation: isMobile && (_jsxs(BottomNavigation, { showLabels: true, value: selectedTab, onChange: handleTabChange, children: [_jsx(BottomNavigationAction, { label: t("form.tabs.model"), value: "model", icon: _jsx(MedicalDeviceIcon, {}) }), _jsx(BottomNavigationAction, { label: t("form.tabs.service"), value: "service", icon: _jsx(TrustLevelSupplierIcon, {}) }), _jsx(BottomNavigationAction, { label: t("form.tabs.technical"), value: "technical", icon: _jsx(ServiceMaintenanceIcon, {}) }), _jsx(BottomNavigationAction, { label: t("form.tabs.training"), value: "training", icon: _jsx(BriefingIcon, {}) }), _jsx(BottomNavigationAction, { label: t("form.tabs.regulatory"), value: "regulatory", icon: _jsx(ServiceInspectionIcon, {}) })] })), children: content })) : (_jsx(Box, { mx: -FORM_DIALOG_PADDING, height: "100%", children: _jsxs(Grid, { container: true, spacing: 1, direction: "column", alignItems: "stretch", height: "100%", children: [_jsx(Grid, { item: true, maxWidth: "100% !important", children: _jsx(Box, { p: 1, pr: 5, pb: 0, children: _jsxs(Grid, { container: true, alignItems: "center", justifyContent: "space-between", children: [_jsx(Grid, { item: true, children: _jsx(Box, { minWidth: isSmallDevice ? "120px" : "200px", children: _jsx(DeviceModelViewerLanguageSelector, { languages: device.published_languages }) }) }), _jsx(Grid, { item: true, xs: true, zeroMinWidth: true, children: _jsx(Typography, { component: "p", variant: "h2", noWrap: true, align: "center", children: title }) }), _jsx(Grid, { item: true, children: !hideActionButton && (_jsx(BasicPageLayoutActionRenderer, { action: actionButton, mobile: false })) })] }) }) }), content] }) }));
    return (_jsx(ActiveLanguageContext.Provider, { value: activeLanguageStatePack, children: layout }));
};
export default React.memo(DeviceModelViewerContent);
