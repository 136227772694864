import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext } from "react";
import { useButtonEndAdornmentStyles } from "./Dialogs/PickDeviceModelButton";
import { IconButton } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import SearchAndSingleSelect from "./SearchAndBrowse/Instances/SearchAndSingleSelect";
import reactNodeToElement from "../../../utils/reactNodeToElement";
import { BaseSelectorContext, useDialogContext } from "components-care";
import AccessProfileSearch from "./SearchAndBrowse/Instances/AccessProfileSearch";
const PickAccessProfileNoForm = (props) => {
    const { onSelect, disabled, title } = props;
    const { classes } = useButtonEndAdornmentStyles();
    const selectorContext = useContext(BaseSelectorContext);
    const [pushDialog, popDialog] = useDialogContext();
    const handleSelect = useCallback((selected) => {
        if (selected) {
            onSelect(selected);
            if (selectorContext)
                selectorContext.addToLru(selected);
            popDialog();
        }
    }, [onSelect, selectorContext, popDialog]);
    const openDialog = useCallback((evt) => {
        evt.stopPropagation();
        pushDialog(_jsx(SearchAndSingleSelect, { instance: AccessProfileSearch, onSelected: handleSelect, props: {}, title: title }));
    }, [pushDialog, handleSelect, title]);
    return reactNodeToElement(!disabled && (_jsx(IconButton, { onClick: openDialog, className: classes.button, disabled: disabled, size: "large", color: "primary", children: _jsx(SearchIcon, {}) })));
};
export default React.memo(PickAccessProfileNoForm);
