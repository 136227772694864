import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { LocationIcon } from "../../../../../components/icons";
import DenseLayout from "./DenseLayout";
const DeviceLocationRenderer = (props) => {
    const { data, ...otherProps } = props;
    const { title, path, notes } = data;
    const displayPath = title === path ? "" : path.split(" / ").slice(0, 3).join(" / ");
    return (_jsx(DenseLayout, { id: data.id, icon: _jsx(LocationIcon, {}), title: title, info: [displayPath, notes].filter((x) => x), ...otherProps }));
};
export default React.memo(DeviceLocationRenderer);
