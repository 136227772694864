import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useImperativeHandle } from "react";
import { Grid, Popover } from "@mui/material";
import { ActionButton, useDialogContext } from "components-care";
import { useTranslation } from "react-i18next";
import { IssueType } from "../../../../../components-care/models/DeviceTaskModel";
import { FullFormDialog as FormDialog } from "components-care/dist/backend-components/Form";
import { hasPermission, usePermissionContext } from "components-care/dist";
import { useActionButtonStyles } from "../../../../../components/ActionButton";
import { useFormEnterpriseClientIdOpt } from "../../../Enterprise/utils/useFormEnterpriseClientId";
import MyTenantContext from "../../../../MySamedis/components/MyTenantContext";
const DeviceTasksCRUD = React.lazy(() => import("../../Tasks/Issues"));
const DeviceRequestsActionsButton = React.forwardRef((props, ref) => {
    const { incidentId, inventoryId } = props;
    const { t } = useTranslation("incident");
    const [pushDialog] = useDialogContext();
    const [perms] = usePermissionContext();
    const { classes } = useActionButtonStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const enterpriseClientId = useFormEnterpriseClientIdOpt();
    const handleAction = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);
    useImperativeHandle(ref, () => ({
        handleAction,
    }), [handleAction]);
    const handleNewRepairIssue = useCallback(() => {
        handleClose();
        pushDialog(_jsx(FormDialog, { dialogTitle: t("actions.new-repair-issue"), useCustomClasses: true, children: _jsx(DeviceTasksCRUD, { disableRouting: true, initialView: "new", inventoryId: inventoryId, incidentId: incidentId, issueType: IssueType.Malfunction, enterpriseClientId: enterpriseClientId, disableBackgroundGrid: true }) }));
    }, [
        handleClose,
        pushDialog,
        t,
        inventoryId,
        incidentId,
        enterpriseClientId,
    ]);
    const handleRetireDevice = useCallback(() => {
        handleClose();
        pushDialog(_jsx(FormDialog, { dialogTitle: t("actions.retire-device"), useCustomClasses: true, children: _jsx(DeviceTasksCRUD, { disableRouting: true, initialView: "new", inventoryId: inventoryId, incidentId: incidentId, issueType: IssueType.DeviceRetired, enterpriseClientId: enterpriseClientId, deviceRetired: true, disableBackgroundGrid: true }) }));
    }, [
        handleClose,
        pushDialog,
        t,
        inventoryId,
        incidentId,
        enterpriseClientId,
    ]);
    return (_jsx(Popover, { open: !!anchorEl, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
        }, transformOrigin: {
            vertical: "top",
            horizontal: "right",
        }, className: classes.popover, children: _jsx("div", { className: classes.content, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(ActionButton, { onClick: handleNewRepairIssue, disabled: !hasPermission(perms, "issues.writer"), children: t("actions.new-repair-issue") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(ActionButton, { onClick: handleRetireDevice, disabled: !hasPermission(perms, "issues.writer"), children: t("actions.retire-device") }) })] }) }) }));
});
export const MyDeviceRequestsActionsButton = React.forwardRef((props, ref) => {
    const { tenantId, ...other } = props;
    return (_jsx(MyTenantContext, { tenantId: tenantId, children: _jsx(DeviceRequestsActionsButton, { ...other, ref: ref }) }));
});
export default React.memo(DeviceRequestsActionsButton);
