import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useMemo } from "react";
import { GroupBox, useFormContext, useModelFetchAll, useModelGet, } from "components-care";
import { useDeviceTypeModel } from "../../../../../components-care/models/DeviceTypeModel";
import { MdmContext } from "../../components/MdmTypes";
import { useTenantId } from "../../../../components/TenantContext";
import { useDeviceTagModel } from "../../../../../components-care/models/DeviceTagModel";
import { INVALID_ID } from "../../../../../constants";
import { useTranslation } from "react-i18next";
import getLocalizedString from "../../../../../utils/getLocalizedString";
import { styled } from "@mui/material";
import { customColors } from "../../../../../theme";
const DeviceTagDisplay = styled("div")(({ theme }) => ({
    ...theme.typography.body1,
    display: "inline-block",
    backgroundColor: customColors.lightGray,
    color: theme.palette.getContrastText(customColors.lightGray),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
}));
const DeviceModelInheritedDeviceTypeTagsRenderer = (props) => {
    const { deviceTypeId } = props;
    const { t, i18n } = useTranslation("device-models");
    const tenantId = useTenantId();
    const target = useContext(MdmContext);
    const deviceTypeModel = useDeviceTypeModel(useMemo(() => ({
        tenantId,
        target,
        extraParams: {
            "filter[scope]": "public_and_tenant",
        },
    }), [target, tenantId]));
    const deviceTagModel = useDeviceTagModel(useMemo(() => ({ target }), [target]));
    const { data: deviceTypeData } = useModelGet(deviceTypeModel, deviceTypeId);
    const { data: deviceTagData } = useModelFetchAll(deviceTagModel, {
        fieldFilter: {
            id: {
                type: "inSet",
                value1: deviceTypeData
                    ? deviceTypeData[0].device_tag_ids.join(",") ||
                        INVALID_ID
                    : INVALID_ID,
                value2: "",
            },
        },
    }, { enabled: !!deviceTypeData });
    return (_jsx(GroupBox, { label: t("inherited_device_tags.title"), children: deviceTagData &&
            deviceTagData[0]
                .map((entry) => entry)
                .map((entry) => (_jsx(DeviceTagDisplay, { children: getLocalizedString(i18n.language, entry.labels) }, entry.id))) }));
};
const DeviceModelInheritedDeviceTypeTags = () => {
    const { values } = useFormContext();
    const { device_type_id: deviceTypeId } = values;
    return (_jsx(_Fragment, { children: deviceTypeId && (_jsx(DeviceModelInheritedDeviceTypeTagsRenderer, { deviceTypeId: deviceTypeId })) }));
};
export default React.memo(DeviceModelInheritedDeviceTypeTags);
