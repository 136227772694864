import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import SamedisMobileDataRendererBase, { SAMEDIS_MOBILE_DATA_RENDERER_BASE_HEIGHT, } from "./SamedisMobileDataRendererBase";
import { Link as MuiLink, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getContactImage } from "../models/ContactModel";
import { preventPropagation } from "../../constants";
const SamedisMobileDataRendererContacts = (props) => {
    const record = props.record;
    const { t } = useTranslation("contact");
    return (_jsx(SamedisMobileDataRendererBase, { icon: getContactImage(record), line1: [record.last_name, record.first_name].filter(Boolean).join(", "), line2: [record.street, record.zip, record.town]
            .filter(Boolean)
            .join(", "), line3: _jsxs(Typography, { variant: "body2", component: "span", children: [record.phone && (_jsx(MuiLink, { href: "tel:" + record.phone, onClick: preventPropagation, children: record.phone })), record.phone && record.email && " •\u00A0", record.email && (_jsx(MuiLink, { href: "mailto:" + record.email, onClick: preventPropagation, children: record.email }))] }), rightInfo: _jsxs(_Fragment, { children: [_jsx(Typography, { component: "p", variant: "body2", color: "textPrimary", noWrap: true, children: t("contact_type.enum." + record.contact_type) }), _jsx(Typography, { component: "p", variant: "body2", color: "textSecondary", noWrap: true, children: t("status.enum." + record.status) })] }) }));
};
export const SamedisMobileDataRendererContactsConfig = {
    rendererHeight: SAMEDIS_MOBILE_DATA_RENDERER_BASE_HEIGHT,
    renderer: SamedisMobileDataRendererContacts,
};
