import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { addGetParams, ComponentsCareI18n, Framework, FrameworkHistory, Model, componentsCareBrowserTracingIntegration, } from "components-care";
import RootPage from "./pages";
import { getTheme } from "./theme";
import moment from "moment";
import { ReactQueryDevtools } from "react-query/devtools";
import TenantContextProvider from "./pages/components/TenantContext";
import SamedisApiClient from "./components-care/connectors/SamedisApiClient";
import { CURRENCY_STORAGE_KEY, IS_DEV, SYNC_APP_DOMAIN_PREFIX, VALID_SUBDOMAIN_PREFIXES, } from "./constants";
import * as Sentry from "@sentry/react";
import ErrorBoundary from "./pages/components/ErrorBoundary";
import "@fontsource/roboto";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto-condensed";
import "@fontsource/roboto-condensed/500.css";
import "@fontsource/roboto-condensed/700.css";
import { setDefaultConnectorAPI } from "components-care/dist/backend-integration/Connector/DefaultConnector";
import BackendConnector from "./components-care/connectors/BackendConnector";
import { LocalStorageProviderInstance, StorageManager, } from "components-care/dist/framework/Storage";
import { DATA_GRID_STORAGE_KEY_COLUMN_SIZING, DATA_GRID_STORAGE_KEY_FILTERS, } from "components-care/dist/standalone/DataGrid/StorageManagerPersist";
import BackendStorageProviderInstance from "./components-care/BackendStorageProvider";
import MaintenanceModeProvider from "./utils/MaintenanceMode";
import { marked } from "marked";
import MarkedRenderer from "./components/MarkedRenderer";
import DOMPurify from "dompurify";
import "./components-care/patches/CurrencySerializer";
import "./components-care/patches/ImageSerializer";
import "./components-care/patches/IdsFilterType";
import { STORAGE_KEY_SEARCH_AND_BROWSER_FILTER_DEVICE, STORAGE_KEY_SEARCH_AND_BROWSER_FILTER_USER, } from "./pages/TenantSpecific/components/SearchAndBrowse";
import { getSubdomain, isSubdomain } from "./utils";
import { getSession, getSessionExpire, getSessionRefreshToken, } from "./pages/components/AuthProvider";
import getCurrentApp from "./utils/getCurrentApp";
import * as ServiceWorkerRegistration from "./serviceWorkerRegistration";
import DevMode from "./components/DevMode";
import OfflineDatabase from "./api/offline/OfflineDatabase";
import * as History from "history";
// import i18n after offline mode, to support offline mode
// eslint-disable-next-line import/first
import i18n from "./i18n";
import { createRoot } from "react-dom/client";
import os from "platform-detect/os";
// enable offline mode
OfflineDatabase.init();
ServiceWorkerRegistration.unregister();
// Sentry
const sentryEnabled = process.env.REACT_APP_SENTRY_ENABLED === "true";
Sentry.init({
    dsn: sentryEnabled
        ? "https://b312f37e37694a6a9e05812b535bad08@sentry.samedis.care/2"
        : undefined,
    tunnel: "/api/error-reporting",
    integrations: [componentsCareBrowserTracingIntegration()],
    // performance trace sample rate
    tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE ?? "0.0"),
    enabled: sentryEnabled,
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: "samedis-care-frontend@" + process.env.REACT_APP_SENTRY_RELEASE,
    beforeSend: (data, hint) => {
        if (hint?.originalException instanceof Error) {
            switch (hint.originalException.name) {
                case "BackendError":
                    // if Backend developer drop backend errors, because annoying
                    if (process.env.REACT_APP_SENTRY_ENV === "develop" &&
                        window.location.host.endsWith("localhost.samedis.care")) {
                        return null;
                    }
                    break;
                case "NetworkError":
                    return null;
                case "AuthError":
                    return null;
            }
        }
        return data;
    },
});
Sentry.setTag("app.name", getCurrentApp());
Sentry.setTag("i18n.locale", i18n.language);
Sentry.setTag("navigator.language", navigator.language);
// #1214: sync.samedis.care redirect
if (isSubdomain(SYNC_APP_DOMAIN_PREFIX)) {
    window.location.href = "../public/index.html";
}
// Components-Care i18n locale sync
ComponentsCareI18n.on("languageChanged", (language) => {
    Sentry.setTag("i18n.locale", language);
    moment.locale(language);
    i18n.changeLanguage(language);
});
// Components-Care Backend Config
setDefaultConnectorAPI((endpoint, extraParams) => {
    if (!extraParams)
        return new BackendConnector(endpoint, "data");
    const { gridFilter, columns, ...additionalQueryParameters } = extraParams;
    return new BackendConnector(endpoint, "data", {
        additionalQueryParameters,
        forceFieldFilter: gridFilter,
        columns,
    });
});
// Marked
marked.use({ renderer: MarkedRenderer, gfm: true, breaks: true });
// expose our router history so we can navigate in markdown
// @ts-expect-error global export
window.ReactRouterHistory = FrameworkHistory;
// dict for markdown target url -> actual target to avoid escaping strings
// @ts-expect-error global export
window.MarkdownLinkDict = {};
// DOMpurify link safety
DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    // set all elements owning target to target=_blank
    if ("target" in node) {
        if (node.hasAttribute("href")) {
            const href = node.getAttribute("href");
            if (href) {
                const defaultRouting = () => node.setAttribute("onclick", 
                // handle open in new tab
                "if (event.ctrlKey || event.metaKey || event.shiftKey) return true;" +
                    // navigate
                    `window.ReactRouterHistory.push(event.currentTarget.href);` +
                    // close all popovers (e.g. notification dialog)
                    'Array.from(document.querySelectorAll(".popover-auto-closable")).forEach(x => x.children[0].click());' +
                    // cancel default action
                    "return false;");
                if (href.startsWith("/")) {
                    // if we stay on our own app invoke react router
                    defaultRouting();
                    return;
                }
                else {
                    const isCurrentApp = href && href.startsWith(window.location.origin);
                    const isApp = [...VALID_SUBDOMAIN_PREFIXES, null].find((subdomain) => href.startsWith(`${window.location.protocol}//${getSubdomain(subdomain)}`)) !== undefined;
                    if (isCurrentApp) {
                        // if we stay on our own app invoke react router
                        node.setAttribute("href", href.substring(window.location.origin.length));
                        defaultRouting();
                        return;
                    }
                    else if (isApp) {
                        const originalTarget = new URL(href);
                        // noinspection UnnecessaryLocalVariableJS
                        const targetUrl = addGetParams(`${originalTarget.origin}/authenticated`, {
                            token: getSession(),
                            token_expire: getSessionExpire()?.toISOString(),
                            refresh_token: getSessionRefreshToken(),
                            slim: sessionStorage.getItem("slim") ?? "false",
                            lang: i18n.language,
                            redirect_path: originalTarget.pathname,
                        }).replace("?", "#");
                        // @ts-expect-error global export
                        window.MarkdownLinkDict[href] = targetUrl;
                        node.setAttribute("onclick", 
                        // fetch actual target url from global dict
                        "const targetUrl = window.MarkdownLinkDict[event.currentTarget.href];" +
                            // check if user wants new tab
                            "if (event.ctrlKey || event.metaKey || event.shiftKey) {" +
                            // if so open new tab
                            "window.open(targetUrl);" +
                            "} else {" +
                            // otherwise just redirect
                            "window.location.href = targetUrl" +
                            "};" +
                            // cancel default action
                            " return false;");
                        node.setAttribute("onauxclick", 
                        // open new tab
                        "window.open(window.MarkdownLinkDict[event.currentTarget.href]);" +
                            // cancel default action
                            "return false");
                        return;
                    }
                }
            }
        }
        node.setAttribute("target", "_blank");
        node.setAttribute("rel", "noreferrer");
    }
});
// Dev Exports
if (IS_DEV) {
    // @ts-expect-error global export
    window.API = SamedisApiClient;
    // @ts-expect-error global export
    window.i18n = i18n;
    // @ts-expect-error global export
    window.BackendStorage = BackendStorageProviderInstance;
    // automatic model UX validation switch
    Model.autoValidateUX = false;
    Model.autoValidateUXThrow = true;
}
FrameworkHistory.listen((update) => {
    const { action, location } = update;
    // user wants to go back to tenant selection
    if (action === History.Action.Pop && location.pathname === "/") {
        localStorage.removeItem("tenant");
    }
});
// Storage manager configuration
StorageManager.configure((id, keys) => {
    let provider = null;
    if (id === DATA_GRID_STORAGE_KEY_FILTERS) {
        provider = BackendStorageProviderInstance;
    }
    else if (id === DATA_GRID_STORAGE_KEY_COLUMN_SIZING) {
        provider = LocalStorageProviderInstance;
        delete keys["tenant"]; // column sizes can be cross tenant
    }
    else if (id === CURRENCY_STORAGE_KEY) {
        provider = BackendStorageProviderInstance;
    }
    else if (id === STORAGE_KEY_SEARCH_AND_BROWSER_FILTER_DEVICE) {
        provider = LocalStorageProviderInstance;
    }
    else if (id === STORAGE_KEY_SEARCH_AND_BROWSER_FILTER_USER) {
        provider = BackendStorageProviderInstance;
    }
    // every storage ID must be handled
    if (provider == null) {
        provider = LocalStorageProviderInstance;
        const error = new Error("[StorageManager] Unhandled storage ID '" +
            id +
            "' with keys " +
            Object.keys(keys).join(", "));
        console.error(error);
        Sentry.captureException(error);
    }
    return [provider, keys];
});
const mobileScalingFixProps = {
    minWidth: os.macos ? 1000 : 320,
};
// render
const domRoot = document.getElementById("root");
const root = createRoot(domRoot);
root.render(_jsx(TenantContextProvider, { children: _jsxs(Framework, { defaultTheme: getTheme, mobileScalingFixProps: mobileScalingFixProps, children: [IS_DEV && false && _jsx(ReactQueryDevtools, { position: "top-left" }), _jsx(ErrorBoundary, { children: _jsx(DevMode, { children: _jsx(MaintenanceModeProvider, { children: _jsx(RootPage, {}) }) }) })] }) }));
