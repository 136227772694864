import { useMutation, useQuery } from "react-query";
import SamedisApiClient from "../../components-care/connectors/SamedisApiClient";
import { ModelDataStore } from "components-care";
import { ReactQueryRetryHandler } from "../../constants";
/**
 * Invites for the current user
 */
export const useOpenInvites = () => {
    return useQuery("v4/user/invitations", async () => {
        const data = (await SamedisApiClient.get("/api/v4/user/invitations", null)).data;
        return data.map((record) => record.attributes);
    }, {
        retry: ReactQueryRetryHandler,
    });
};
export const useAcceptInvite = () => {
    return useMutation("PUT_v4/user/invitations", (token) => {
        return SamedisApiClient.put(`/api/v4/user/invitations/${encodeURIComponent(token)}`, null, {});
    }, {
        onSuccess: () => {
            return ModelDataStore.invalidateQueries("v4/user/invitations", {
                exact: true,
            });
        },
    });
};
export const useRejectInvite = () => {
    return useMutation("DELETE_v4/user/invitations", (token) => {
        return SamedisApiClient.delete(`/api/v4/user/invitations/${encodeURIComponent(token)}`, null);
    }, {
        onSuccess: () => {
            return ModelDataStore.invalidateQueries("v4/user/invitations", {
                exact: true,
            });
        },
    });
};
