import AMPermissionRenderer from "./Types/Renderers/AMPermissionRenderer";
import { ID_FIELD_DEF } from "../../constants";
import BackendConnector from "../connectors/BackendConnector";
import { useTenantId } from "../../pages/components/TenantContext";
import { useTranslation } from "react-i18next";
import { Model, ModelDataTypeStringRendererCC, ModelVisibilityDisabledReadOnly, ModelVisibilityEdit, ModelVisibilityEditRequired, ModelVisibilityGridView, validatePresence, } from "components-care";
const AccessProfilesModel = (params) => new Model("profiles-model", {
    title: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("profiles:title.placeholder"),
        }),
        getLabel: () => params.t("profiles:title.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            edit: ModelVisibilityEditRequired,
            create: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        validate: validatePresence,
    },
    description: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("profiles:description.placeholder"),
            multiline: true,
        }),
        getLabel: () => params.t("profiles:description.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            edit: ModelVisibilityEdit,
            create: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
    },
    access_group_ids: {
        type: new AMPermissionRenderer(true),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityEdit,
            create: ModelVisibilityEdit,
        },
        customData: null,
        validate: (value) => {
            if (!value || value.length === 0) {
                return params.t("profiles:errors.no-groups");
            }
            return null;
        },
    },
    id: {
        ...ID_FIELD_DEF(params.t),
        customData: null,
    },
}, new BackendConnector(`v4/tenants/${params.tenantId}/profiles`, "data"), [params.tenantId]);
export const AccessProfilesModelSort = [
    {
        field: "title",
        direction: 1,
    },
];
export const AccessProfilesModelToSelectorData = (record) => {
    return {
        value: record.id,
        label: record.title,
    };
};
export const useAccessProfilesModelTranslations = () => useTranslation("profiles");
export const useAccessProfilesModel = () => {
    const tenantId = useTenantId();
    const { t } = useAccessProfilesModelTranslations();
    return AccessProfilesModel({ tenantId, t });
};
export default AccessProfilesModel;
