import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DefaultFormPage, FormField, FullFormDialog, GroupBox, hasPermission, Link, useDialogContext, useFormContext, usePermissionContext, } from "components-care";
import { Grid, Link as MuiLink } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { IsInFormDialogContext } from "components-care/dist/backend-components/Form/FormDialog";
import BasicPageLayout from "../../../../../components/BasicPageLayout";
import BoxGrid from "../../../../../components/BoxGrid";
import InventoryDetails from "../../Tasks/components/InventoryDetails";
import ChatBox, { ChatBoxForm } from "./Chat/ChatBox";
import Chat from "./Chat";
import { MyDeviceRequestsActionsButton, } from "./DeviceRequestsActionsButton";
import { DeviceRequestsContext, useIncidentsContext, } from "./DeviceRequestsCRUD";
import { useTenantIdWithContextOpt } from "../../../../components/TenantContext";
import MyTenantContext from "../../../../MySamedis/components/MyTenantContext";
import { useEnterpriseSyncConfigInForm } from "../../../Enterprise/utils/useEnterpriseSyncConfig";
import EnterpriseRecordCreateTenantSelect from "../../../Enterprise/components/EnterpriseRecordCreateTenantSelect";
import { useFormEnterpriseClientIdOpt } from "../../../Enterprise/utils/useFormEnterpriseClientId";
import SamedisMobileFormButtonsPortal, { SamedisMobileFormButtonsPortalBoxGridRenderer, } from "../../../../../components-care/SamedisMobileFormButtonsPortal";
const DeviceTasksCRUD = React.lazy(() => import("../../Tasks/Issues"));
const useStyles = makeStyles()({
    issueList: {
        paddingLeft: "1.5rem",
    },
});
const DeviceRequestsForm = (formProps) => {
    const { id } = formProps;
    const isInDialog = useContext(IsInFormDialogContext);
    const { t } = useTranslation("incident");
    const { t: issueT } = useTranslation("event-issue");
    const { values, relations } = useFormContext();
    const tenantId = useTenantIdWithContextOpt(values.tenant_id);
    const { classes } = useStyles();
    const incidentsContext = useIncidentsContext();
    const { type } = incidentsContext;
    const [pushDialog, popDialog] = useDialogContext();
    const [perms] = usePermissionContext();
    useEnterpriseSyncConfigInForm("incidents");
    const enterpriseClientId = useFormEnterpriseClientIdOpt();
    const handleOpenIssue = useCallback((evt, id, title) => {
        if (evt.ctrlKey || evt.metaKey || evt.shiftKey)
            return;
        evt.preventDefault();
        evt.stopPropagation();
        pushDialog(_jsx(FullFormDialog, { dialogTitle: t("incident:issue-dialog.title", { TITLE: title }), useCustomClasses: true, children: _jsx(DeviceRequestsContext.Provider, { value: incidentsContext, children: _jsx(DeviceTasksCRUD, { disableBackgroundGrid: true, disableRouting: true, initialView: id, formProps: { onSubmit: popDialog } }) }) }));
    }, [incidentsContext, popDialog, pushDialog, t]);
    const inventoryId = values.inventory_id;
    const subEntries = useMemo(() => [
        id &&
            ["dispatcher", "supporter"].includes(type) &&
            hasPermission(perms, "issues.reader") && (_jsx(Grid, { item: true, xs: 12, children: _jsx(GroupBox, { label: t("incident:ref-issues"), children: _jsx("ul", { className: classes.issueList, children: relations.issue_ids.map((issue) => (_jsx("li", { children: _jsx(MuiLink, { component: Link, to: `/tenant/${tenantId}/devices/tasks/${issue.id}`, onClick: (evt) => handleOpenIssue(evt, issue.id, issue.title), children: [
                                t("incident:issue-no", { NO: issue.issue_number }),
                                t("incident:issue-status", {
                                    STATUS: issueT("event-issue:status.enum." + issue.status),
                                }),
                            ].join(" • ") }) }, issue.id))) }) }) }, "ref_issues")),
        inventoryId && tenantId && (_jsx(Grid, { item: true, xs: 12, children: _jsx(MyTenantContext, { tenantId: tenantId, children: _jsx(InventoryDetails, { inventoryId: inventoryId, tenantId: tenantId, enterpriseClientId: enterpriseClientId }) }) }, "inventory_info")),
    ].filter((e) => e), [
        id,
        type,
        perms,
        t,
        classes.issueList,
        relations.issue_ids,
        inventoryId,
        tenantId,
        enterpriseClientId,
        issueT,
        handleOpenIssue,
    ]);
    const pageTitle = [t("pagetitle"), values.incident_number]
        .filter((e) => !!e)
        .join(" • ");
    return useMemo(() => (_jsx(DefaultFormPage, { ...formProps, children: _jsxs(BasicPageLayout, { title: !isInDialog && pageTitle, actions: id &&
                tenantId &&
                inventoryId &&
                [
                    {
                        id: "my-request-actions",
                        name: t("incident:actions.button"),
                        icon: MenuIcon,
                        handler: MyDeviceRequestsActionsButton,
                        handlerProps: { tenantId, inventoryId, incidentId: id },
                    },
                ], children: [_jsxs(BoxGrid, { item: true, xs: 12, md: 8, children: [id && _jsx(Chat, {}), id ? (_jsx(ChatBox, { incidentId: id })) : (_jsx(ChatBoxForm, { ...formProps, customProps: null }))] }), _jsx(Grid, { item: true, xs: 12, md: 4, children: _jsx("div", { children: _jsxs(BoxGrid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "inventory_id" }) }), inventoryId && (_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "responsible_id" }) })), inventoryId && (_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "inventory_operation_status" }) })), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "status" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "needs_transport" }) }), subEntries] }) }) }), _jsx(SamedisMobileFormButtonsPortal, { render: SamedisMobileFormButtonsPortalBoxGridRenderer })] }) })), [
        formProps,
        id,
        inventoryId,
        isInDialog,
        pageTitle,
        subEntries,
        t,
        tenantId,
    ]);
};
const DeviceRequestsFormWrapper = (formProps) => {
    const { t } = useTranslation("incident");
    return (_jsx(EnterpriseRecordCreateTenantSelect, { ...formProps, title: t("enterprise.create-title"), children: DeviceRequestsForm }));
};
export default React.memo(DeviceRequestsFormWrapper);
