import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext } from "react";
import { BaseSelectorContext, useDialogContext, useFormContextLite, useFormFieldContext, } from "components-care";
import { IconButton } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import SearchAndSingleSelect from "../SearchAndBrowse/Instances/SearchAndSingleSelect";
import EmployeeSearch from "../SearchAndBrowse/Instances/EmployeeSearch";
import { useButtonEndAdornmentStyles } from "./PickDeviceModelButton";
import reactNodeToElement from "../../../../utils/reactNodeToElement";
const PickEmployeeButton = (props) => {
    const { getBriefingRequirements, getTenantIdMySamedis, team, title } = props;
    const { getFieldValue } = useFormContextLite();
    const { visibility, field, handleChange } = useFormFieldContext();
    const { classes } = useButtonEndAdornmentStyles();
    const selectorContext = useContext(BaseSelectorContext);
    const [pushDialog, popDialog] = useDialogContext();
    const handleSelect = useCallback((selected) => {
        if (selected) {
            handleChange(field, selected);
            if (selectorContext)
                selectorContext.addToLru(selected);
            popDialog();
        }
    }, [handleChange, field, selectorContext, popDialog]);
    const openDialog = useCallback(async (evt) => {
        evt.stopPropagation();
        pushDialog(_jsx(SearchAndSingleSelect, { instance: EmployeeSearch, onSelected: handleSelect, props: {
                trainingRequirements: getBriefingRequirements &&
                    (await getBriefingRequirements(getFieldValue)),
                tenantIdMySamedis: getTenantIdMySamedis && getTenantIdMySamedis(getFieldValue),
                trainingGroup: team,
            }, title: title }));
    }, [
        pushDialog,
        handleSelect,
        getBriefingRequirements,
        getFieldValue,
        getTenantIdMySamedis,
        team,
        title,
    ]);
    return reactNodeToElement(!visibility.readOnly && (_jsx(IconButton, { onClick: openDialog, className: classes.button, disabled: visibility.readOnly, size: "large", color: "primary", children: _jsx(SearchIcon, {}) })));
};
export default React.memo(PickEmployeeButton);
