import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import { useInventoryModelLite } from "../../../../../components-care/models/InventoryModel";
import moment from "moment/moment";
import { showConfirmDialogBool, showInfoDialog, useDialogContext, useFormContext, } from "components-care";
import { IconButton, Tooltip } from "@mui/material";
import { Update as TimerIcon } from "@mui/icons-material";
import { useButtonEndAdornmentStyles } from "../../../components/Dialogs/PickDeviceModelButton";
import { showErrorDialog } from "components-care/dist/non-standalone/Dialog/Utils";
import { useTranslation } from "react-i18next";
import { normalizeDate } from "components-care/dist/backend-integration/Model/Types/Utils/DateUtils";
import { intervalToString } from "../../../../../components/Interval";
import { useFormEnterpriseClientIdOpt } from "../../../Enterprise/utils/useFormEnterpriseClientId";
const CalcNextMaintenanceDateBtn = () => {
    const { i18n, t } = useTranslation("event-issue");
    const { parentFormContext, readOnly, setFieldValue, getFieldValue, values: thisForm, } = useFormContext();
    const enterpriseClientId = useFormEnterpriseClientIdOpt();
    const inventoryModel = useInventoryModelLite(useMemo(() => ({ enterpriseClientId }), [enterpriseClientId]));
    let inventoryId;
    let maintenanceTypes;
    let getServiceIntervals;
    let baseDate;
    let enable;
    if (parentFormContext) {
        const { values } = parentFormContext;
        if (parentFormContext.model.modelId === "inventory-model") {
            // inventory create initial
            inventoryId = values.id;
            getServiceIntervals = () => values.with_service_intervals;
            maintenanceTypes = thisForm.services;
            baseDate = normalizeDate(new Date());
            enable = true;
        }
        else {
            // event issue (next event)
            inventoryId = values.inventory_id;
            getServiceIntervals = async () => {
                const inventoryData = await inventoryModel.getCached(inventoryId);
                return inventoryData[0]
                    .with_service_intervals;
            };
            maintenanceTypes = values.services;
            baseDate = values.done_at;
            enable = true;
        }
    }
    else {
        inventoryId = thisForm.inventory_id;
        getServiceIntervals = async () => {
            const inventoryData = await inventoryModel.getCached(inventoryId);
            return inventoryData[0]
                .with_service_intervals;
        };
        maintenanceTypes = thisForm.services;
        baseDate = normalizeDate(new Date());
        enable = thisForm.issue_type === "maintenance" && !!inventoryId;
    }
    const [pushDialog] = useDialogContext();
    const { classes } = useButtonEndAdornmentStyles();
    const calculateAndSet = useCallback(async () => {
        try {
            if (!baseDate)
                throw new Error(t("next-event.auto-calc.errors.not-done"));
            const result = (await getServiceIntervals()).filter((interval) => maintenanceTypes.includes(interval.label) && interval.value != null);
            if (result.length === 0)
                throw new Error(t("next-event.auto-calc.errors.no-intervals"));
            let interval = result[0];
            // if we have multiple intervals defined, find the shortest interval by calculating the next date of each and comparing that
            if (result.filter((r) => r.value !== interval.value || r.unit !== interval.unit).length > 0) {
                let intervalTarget = moment(baseDate)
                    .add(interval.value, interval.unit)
                    .toDate();
                for (const testInterval of result) {
                    const testTarget = moment(baseDate)
                        .add(testInterval.value, testInterval.unit)
                        .toDate();
                    if (testTarget < intervalTarget) {
                        interval = testInterval;
                        intervalTarget = testTarget;
                    }
                }
                await showInfoDialog(pushDialog, {
                    title: t("next-event.auto-calc.interval-mismatch.title"),
                    message: t("next-event.auto-calc.interval-mismatch.message", {
                        INTERVALS: result
                            .map((interval) => intervalToString(interval, t))
                            .join(", "),
                        INTERVAL: intervalToString(interval, t),
                    }),
                });
            }
            const targetDate = moment(baseDate)
                .add(interval.value, interval.unit)
                .toDate();
            const currentDate = getFieldValue("date");
            if (currentDate &&
                currentDate.toISOString().split("T")[0] !==
                    targetDate.toISOString().split("T")[0]) {
                const confirmResult = await showConfirmDialogBool(pushDialog, {
                    title: t("next-event.auto-calc.confirm.title"),
                    message: t("next-event.auto-calc.confirm.message", {
                        TARGET_DATE: targetDate.toLocaleDateString(i18n.language),
                        CURRENT_DATE: currentDate.toLocaleDateString(i18n.language),
                    }),
                    textButtonYes: t("next-event.auto-calc.confirm.yes"),
                    textButtonNo: t("next-event.auto-calc.confirm.no"),
                });
                if (!confirmResult) {
                    return;
                }
            }
            setFieldValue("date", targetDate);
        }
        catch (e) {
            await showErrorDialog(pushDialog, e);
        }
    }, [
        baseDate,
        t,
        getServiceIntervals,
        getFieldValue,
        setFieldValue,
        maintenanceTypes,
        pushDialog,
        i18n.language,
    ]);
    if (!enable)
        return _jsx(_Fragment, {});
    return (_jsx(Tooltip, { title: t("next-event.auto-calc.title") ?? "", children: _jsx(IconButton, { onClick: calculateAndSet, className: classes.button, disabled: readOnly, size: "large", children: _jsx(TimerIcon, {}) }) }));
};
export default React.memo(CalcNextMaintenanceDateBtn);
