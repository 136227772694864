import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid, styled, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
export const SAMEDIS_MOBILE_DATA_RENDERER_BASE_HEIGHT = 60;
const useStyles = makeStyles()((theme) => ({
    image: {
        height: "100%",
        width: `calc(${SAMEDIS_MOBILE_DATA_RENDERER_BASE_HEIGHT}px - 2 * ${theme.spacing(1)})`,
        aspectRatio: "1/1",
        objectFit: "contain",
    },
    rightInfo: {
        maxWidth: "50%",
        textAlign: "right",
    },
}));
const StyledTypography = styled(Typography)({
    display: "block",
    maxWidth: "100%",
});
const SamedisMobileDataRendererBase = (props) => {
    const { icon: Icon, line1, line2, line3, rightInfo } = props;
    const { classes } = useStyles();
    return (_jsxs(Grid, { container: true, wrap: "nowrap", spacing: 1, p: 1, children: [Icon && (_jsx(Grid, { item: true, children: typeof Icon === "string" ? (_jsx("img", { src: Icon, className: classes.image, alt: "" })) : (_jsx(Icon, { className: classes.image })) })), _jsxs(Grid, { item: true, xs: true, container: true, direction: "column", wrap: "nowrap", zeroMinWidth: true, children: [_jsx(Grid, { item: true, zeroMinWidth: true, children: _jsx(StyledTypography, { variant: "body1", color: "textPrimary", lineHeight: 1.2, noWrap: true, children: line1 }) }), line2 && (_jsx(Grid, { item: true, zeroMinWidth: true, children: _jsx(StyledTypography, { variant: "caption2", color: "textSecondary", lineHeight: 1.2, noWrap: true, children: line2 }) })), line3 && (_jsx(Grid, { item: true, zeroMinWidth: true, children: _jsx(StyledTypography, { variant: "body2", color: "textSecondary", lineHeight: 1.2, noWrap: true, children: line3 }) }))] }), rightInfo && (_jsx(Grid, { item: true, className: classes.rightInfo, children: rightInfo }))] }));
};
export default React.memo(SamedisMobileDataRendererBase);
