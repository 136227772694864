import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import SearchAndBrowse from "../index";
import { useTenantId } from "../../../../components/TenantContext";
import ContactModel, { CONTACT_CATEGORY_OPTIONS, ContactModelSelectorSort, useContactModelCCTranslations, useContactModelTranslations, } from "../../../../../components-care/models/ContactModel";
import ContactRenderer from "../Renderers/ContactRenderer";
import { multiSelectFilterEntryFromEnum } from "../FilterEntryMultiSelect";
const ContactSearch = (props) => {
    const { incident, issue, target, enterpriseClientId, extraParams, ...other } = props;
    const tenantId = useTenantId();
    const { t: contactModelT } = useContactModelTranslations();
    const { t: contactModelCCT } = useContactModelCCTranslations();
    const scope = extraParams?.["filter[scope]"] ?? "public_and_tenant";
    const enableScopeFilter = scope === "public_and_tenant";
    const loadRecords = useCallback(async (search, filters, offset) => {
        const categories = filters.categories;
        const contactModel = ContactModel({
            t: contactModelT,
            ccT: contactModelCCT,
            tenantId,
            target,
            incident,
            issue,
            enterpriseClientId,
            extraParams: {
                ...extraParams,
                "filter[scope]": enableScopeFilter
                    ? filters.scope
                        ? "public_and_tenant"
                        : "tenant"
                    : extraParams?.["filter[scope]"],
            },
        });
        const [data, meta] = await contactModel.index2({
            offset: offset,
            rows: 25,
            sort: ContactModelSelectorSort,
            quickFilter: search,
            fieldFilter: categories.length > 0
                ? {
                    categories: {
                        type: "inSet",
                        value1: categories.join(","),
                        value2: "",
                    },
                }
                : undefined,
        });
        return {
            data: data,
            total: meta.filteredRows ?? meta.totalRows,
        };
    }, [
        contactModelT,
        contactModelCCT,
        tenantId,
        target,
        incident,
        issue,
        enterpriseClientId,
        extraParams,
        enableScopeFilter,
    ]);
    const isResponsibleSearch = !!(incident || issue);
    const filters = useMemo(() => ({
        scope: {
            variant: "switch",
            title: contactModelT("contact:scope.filter"),
            hidden: !enableScopeFilter,
            defaultValue: true,
            persistType: "user",
            persistKeys: ["tenant"],
        },
        categories: {
            variant: "multi-select",
            title: contactModelT("contact:categories.filter"),
            ...multiSelectFilterEntryFromEnum(CONTACT_CATEGORY_OPTIONS(contactModelT)),
            hidden: isResponsibleSearch,
        },
    }), [contactModelT, enableScopeFilter, isResponsibleSearch]);
    return (_jsx(SearchAndBrowse, { ...other, loadRecords: loadRecords, filters: filters, renderer: ContactRenderer, rendererContext: props, controlName: "ContactSearch" }));
};
export default React.memo(ContactSearch);
