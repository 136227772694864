import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo } from "react";
import SamedisCrudMultiImage from "../../../../../components-care/SamedisCrudMultiImage";
import { useFormContext, useModelMutation } from "components-care";
import { useTenantId } from "../../../../components/TenantContext";
import { useTranslation } from "react-i18next";
import { MdmContext } from "../../components/MdmTypes";
const DeviceModelImages = () => {
    const target = useContext(MdmContext);
    const tenantId = useTenantId();
    const { t } = useTranslation("device-models");
    const { id: recordId, model, values, setFieldValue } = useFormContext();
    const { mutateAsync: updateDeviceModel } = useModelMutation(model);
    const getEndpoint = useCallback((id) => target === "tenant"
        ? `v4/tenants/${tenantId}/device_models/${id}/images`
        : target === "user"
            ? `v4/tenants/${tenantId}/mdm/device_models/${id}/images`
            : `v4/device_models/${id}/images`, [tenantId, target]);
    const handlePrimaryChange = useCallback((id, imageId) => {
        setFieldValue("linked_image_id", imageId);
        void updateDeviceModel({
            id,
            linked_image_id: imageId,
        });
    }, [setFieldValue, updateDeviceModel]);
    const extraParams = useMemo(() => (target === "admin" ? { tenant_id: tenantId } : undefined), [target, tenantId]);
    return (_jsx(SamedisCrudMultiImage, { field: "images", getEndpoint: getEndpoint, extraParams: extraParams, initialId: recordId, label: t("image"), primaryId: values.linked_image_id || "", onPrimaryChange: handlePrimaryChange }));
};
export default React.memo(DeviceModelImages);
