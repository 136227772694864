import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useActionButtonStyles } from "../../../../../components/ActionButton";
import { NoteAdd as DocumentIcon } from "@mui/icons-material";
import { Grid, Popover } from "@mui/material";
import { ActionButton, hasPermission, useDialogContext, usePermissionContext, useNavigate, FullFormDialog, } from "components-care";
import { useTenantIdOpt } from "../../../../components/TenantContext";
import getCurrentApp from "../../../../../utils/getCurrentApp";
import { getSubdomain } from "../../../../../utils";
import { DeviceIcon } from "../../../../../components/icons";
import DeviceModelViewerActionEdit, { useDeviceModelEditLink, } from "./DeviceModelViewerActionEdit";
const FacilityContentCRUD = React.lazy(() => import("../../../Facility/Content/FacilityContent"));
const DeviceModelViewerActionButton = React.forwardRef((props, ref) => {
    const { device, enterpriseClientId } = props;
    const deviceId = device.id;
    const { t } = useTranslation("device-models");
    const tenantId = useTenantIdOpt();
    const [perms] = usePermissionContext();
    const [pushDialog, popDialog] = useDialogContext();
    const { classes } = useActionButtonStyles();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleAction = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);
    useImperativeHandle(ref, () => ({
        handleAction,
    }), [handleAction]);
    const canCreateInventory = tenantId && hasPermission(perms, "inventories.writer");
    const handleCreateInventory = useCallback(() => {
        popDialog();
        const targetUrl = enterpriseClientId
            ? `/tenant/${tenantId}/enterprise/client-devices/new?device_model_id=${deviceId}&tenant_id=${enterpriseClientId}`
            : `/tenant/${tenantId}/devices/inventory/new?device_model_id=${deviceId}`;
        if (getCurrentApp() === "app") {
            navigate(targetUrl);
        }
        else {
            window.location.href = "//" + getSubdomain(null) + targetUrl;
        }
    }, [popDialog, enterpriseClientId, tenantId, deviceId, navigate]);
    const canAddDocument = tenantId &&
        !enterpriseClientId &&
        hasPermission(perms, "facility-contents.writer");
    const handleAddDocument = useCallback(() => {
        pushDialog(_jsx(FullFormDialog, { children: _jsx(FacilityContentCRUD, { initialView: "new", disableBackgroundGrid: true, disableRouting: true, formProps: {
                    initialRecord: {
                        device_model_ids: [deviceId],
                    },
                    onSubmit: () => {
                        popDialog();
                    },
                } }) }));
    }, [deviceId, popDialog, pushDialog]);
    const deviceEditLink = useDeviceModelEditLink(device);
    return (_jsx(Popover, { open: !!anchorEl, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
        }, transformOrigin: {
            vertical: "top",
            horizontal: "right",
        }, className: classes.popover, children: _jsx("div", { className: classes.content, children: _jsxs(Grid, { container: true, spacing: 2, children: [deviceEditLink && (_jsx(DeviceModelViewerActionEdit, { deviceEditLink: deviceEditLink, onAction: handleClose })), tenantId && (_jsx(Grid, { item: true, xs: 12, children: _jsx(ActionButton, { icon: _jsx(DeviceIcon, {}), disabled: !canCreateInventory, onClick: handleCreateInventory, children: t("actions.create-inventory") }) })), tenantId && !enterpriseClientId && (_jsx(Grid, { item: true, xs: 12, children: _jsx(ActionButton, { icon: _jsx(DocumentIcon, {}), disabled: !canAddDocument, onClick: handleAddDocument, children: t("actions.add-facility-content") }) }))] }) }) }));
});
export default React.memo(DeviceModelViewerActionButton);
