import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import SearchAndBrowse from "../index";
import { useTenantId } from "../../../../components/TenantContext";
import TrainingGroupModel, { TrainingGroupModelSelectorSort, useTrainingGroupModelTranslations, } from "../../../../../components-care/models/TrainingGroupModel";
import TrainingGroupRenderer from "../Renderers/TeamRenderer";
const TrainingGroupSearch = (props) => {
    const { type, ...other } = props;
    const tenantId = useTenantId();
    const { t: teamModelT } = useTrainingGroupModelTranslations();
    const loadRecords = useCallback(async (search, _filters, offset) => {
        const teamModel = TrainingGroupModel({
            t: teamModelT,
            tenantId,
            extraParams: {
                "filter[type]": type,
            },
        });
        const [data, meta] = await teamModel.index2({
            offset: offset,
            rows: 25,
            sort: TrainingGroupModelSelectorSort,
            quickFilter: search,
        });
        return {
            data: data,
            total: meta.filteredRows ?? meta.totalRows,
        };
    }, [teamModelT, tenantId, type]);
    const filters = useMemo(() => ({}), []);
    return (_jsx(SearchAndBrowse, { ...other, loadRecords: loadRecords, filters: filters, renderer: TrainingGroupRenderer, rendererContext: undefined, controlName: "TeamSearch" }));
};
export default React.memo(TrainingGroupSearch);
