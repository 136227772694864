import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { FileUploadGeneric } from "components-care";
import FacilityContentModel from "../../../../../components-care/models/FacilityContentModel";
import { useQuery } from "react-query";
import BackendConnector from "../../../../../components-care/connectors/BackendConnector";
import { useTranslation } from "react-i18next";
import { useTenantIdOpt } from "../../../../components/TenantContext";
import { ReactQueryRetryHandler } from "../../../../../constants";
export const DeviceModelCommunityUploadCategories = ["model", "service", "technical", "training"];
const DeviceModelViewerCommunityUploads = (props) => {
    const { recordId, category, inventoryId, enterpriseClientId } = props;
    const tenantId = useTenantIdOpt();
    const { t } = useTranslation("device-models");
    const { data: facilityContents } = useQuery(["device-viewer-facility-contents", tenantId, category, recordId], async () => {
        if (!tenantId)
            return [[], { totalRows: 0 }, null];
        const model = FacilityContentModel({
            tenantId,
            t,
            target: "tenant",
        });
        return model.fetchAll({
            additionalFilters: {
                "filter[video]": false,
            },
            fieldFilter: {
                content_type: {
                    type: "inSet",
                    value1: "file,link",
                    value2: "",
                },
                category: {
                    type: "equals",
                    value1: category,
                    value2: "",
                },
                device_model_ids: {
                    type: "equals",
                    value1: recordId,
                    value2: "",
                },
            },
        });
    }, { enabled: !!tenantId, retry: ReactQueryRetryHandler });
    const { data: inventoryDocuments } = useQuery([
        "device-viewer-inventory-uploads",
        tenantId,
        enterpriseClientId,
        inventoryId,
    ], () => {
        const endpoint = enterpriseClientId
            ? `v4/enterprise/tenants/${tenantId}/clients/${enterpriseClientId}/inventories/${inventoryId}/uploads`
            : `v4/tenants/${tenantId}/inventories/${inventoryId}/uploads`;
        return new BackendConnector(endpoint).index({ rows: 100 });
    }, { enabled: !!inventoryId, retry: ReactQueryRetryHandler });
    const files = [
        ...(facilityContents
            ? facilityContents[0].map((_entry) => {
                const entry = _entry;
                return {
                    file: {
                        name: entry.title || entry.name,
                        downloadLink: entry.document[0]?.file?.downloadLink || entry.url,
                    },
                };
            })
            : []),
        ...(inventoryDocuments
            ? inventoryDocuments[0].map((entry) => ({
                file: {
                    name: entry.name,
                    downloadLink: entry.document,
                },
            }))
            : []),
    ];
    return (_jsx(FileUploadGeneric, { label: t("community-uploads." + category), readOnly: true, files: files, previewSize: 24, handleError: console.error, variant: "modern" }));
};
export default React.memo(DeviceModelViewerCommunityUploads);
