import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import SamedisCrud from "../../../../../components-care/SamedisCrud";
import { useIncidentModel } from "../../../../../components-care/models/IncidentModel";
import IncidentsForm from "./DeviceRequestsFormPage";
import { useSyncConfigOpt } from "../../../../../utils/useSyncConfig";
import EnterpriseContext from "../../../Enterprise/components/EnterpriseContext";
import DeviceRequestsFilterBar from "./DeviceRequestsFilterBar";
import { useTranslation } from "react-i18next";
import { GRID_FILTER_DIALOG_MEDIA_QUERY_SINGLE_ENTRY } from "../../../../../constants";
import { SamedisMobileDataRendererDeviceRequestsConfig } from "../../../../../components-care/SamedisMobileDataRenderers/SamedisMobileDataRendererDeviceRequests";
export const DeviceRequestsContext = React.createContext(null);
export const useIncidentsContext = () => {
    const ctx = useContext(DeviceRequestsContext);
    if (!ctx)
        throw new Error("IncidentsContext not set");
    return ctx;
};
const DeviceRequestsCRUD = (props) => {
    const ctx = useIncidentsContext();
    const { t } = useTranslation("incident");
    const enterprise = useContext(EnterpriseContext);
    const model = useIncidentModel({
        role: ctx.type,
        enterpriseClients: enterprise?.clients,
    });
    const isSyncReadOnly = useSyncConfigOpt()?.incidents === "to_samedis";
    return (_jsx(SamedisCrud, { model: model, gridMobileRenderer: SamedisMobileDataRendererDeviceRequestsConfig, gridTitle: t("grid-title"), isSyncReadOnly: isSyncReadOnly, deletePermission: "incidents.dispatcher", readPermission: null, editPermission: null, 
        // only dispatcher controller supports create, otherwise use landing page
        newPermission: ctx.type === "dispatcher" ? "incidents.reporter" : false, exportPermission: "incidents.xlsx", gridProps: {
            filterBar: DeviceRequestsFilterBar,
            enableFilterDialogMediaQuery: GRID_FILTER_DIALOG_MEDIA_QUERY_SINGLE_ENTRY,
        }, ...props, children: IncidentsForm }));
};
export default React.memo(DeviceRequestsCRUD);
