import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useState } from "react";
import { Loader, showInfoDialog, TextFieldWithHelp, useDialogContext, useModelGet, } from "components-care";
import { useTenantAdminModel, useTenantModel, } from "../../../../../components-care/models/TenantModel";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { showErrorDialog } from "components-care/dist/non-standalone/Dialog/Utils";
import { useAuthProviderReset } from "../../../../components/AuthProvider";
import TenantOverviewContext from "../../../MasterDataManagement/TenantManagement/components/TenantOverviewContext";
import { useGoToFacilitySelection } from "../../../../components/header/FacilitySelector";
const DEFAULT_STATE = {
    name: "",
    email: "",
};
const useStyles = makeStyles()((theme) => ({
    danger: {
        color: theme.palette.error.main,
    },
}));
const FacilityDeleteConfirmDialog = (props) => {
    const { tenantId, goBack } = props;
    const { t } = useTranslation("tenant");
    const [pushDialog, popDialog] = useDialogContext();
    const { classes } = useStyles();
    const resetAuth = useAuthProviderReset();
    const isTenantManagement = useContext(TenantOverviewContext);
    const tenantModel = useTenantModel();
    const tenantAdminModel = useTenantAdminModel();
    const goToTenantSelection = useGoToFacilitySelection();
    const { isLoading, data, error } = useModelGet(isTenantManagement ? tenantAdminModel : tenantModel, tenantId);
    const [state, setState] = useState(DEFAULT_STATE);
    const handleChange = useCallback((evt) => {
        setState((prev) => ({ ...prev, [evt.target.name]: evt.target.value }));
    }, []);
    const [deleting, setDeleting] = useState(false);
    const handleDelete = useCallback(async () => {
        let success = false;
        try {
            setDeleting(true);
            if (isTenantManagement) {
                await tenantAdminModel.deleteRaw(tenantId);
            }
            else {
                await tenantModel.deleteRaw(tenantId);
            }
            await showInfoDialog(pushDialog, {
                title: t("actions.delete-facility.executed.title"),
                message: t("actions.delete-facility.executed.message"),
            });
            success = true;
        }
        catch (e) {
            await showErrorDialog(pushDialog, e);
        }
        finally {
            setDeleting(false);
        }
        popDialog();
        if (isTenantManagement)
            goBack(true);
        else if (success) {
            // and not tenant management
            goToTenantSelection();
            resetAuth();
        }
    }, [
        popDialog,
        isTenantManagement,
        goBack,
        pushDialog,
        t,
        tenantAdminModel,
        tenantId,
        tenantModel,
        goToTenantSelection,
        resetAuth,
    ]);
    return (_jsxs(Dialog, { open: true, children: [_jsx(DialogTitle, { children: t("actions.delete-facility.confirm.title") }), _jsxs(DialogContent, { children: [isLoading && _jsx(Loader, {}), error && _jsx(Typography, { color: "error", children: error.message }), data && (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { children: t("actions.delete-facility.confirm.message") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(TextFieldWithHelp, { label: t("actions.delete-facility.confirm.facility_name.label"), placeholder: t("actions.delete-facility.confirm.facility_name.placeholder"), name: "name", value: state.name, onChange: handleChange, fullWidth: true }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(TextFieldWithHelp, { label: t("actions.delete-facility.confirm.facility_email.label"), placeholder: t("actions.delete-facility.confirm.facility_email.placeholder"), name: "email", value: state.email, onChange: handleChange, fullWidth: true }) })] }))] }), _jsxs(DialogActions, { children: [_jsx(Button, { disabled: !data ||
                            data[0].name !== state.name ||
                            data[0].email !== state.email ||
                            deleting, onClick: handleDelete, className: classes.danger, children: t("actions.delete-facility.confirm.yes") }), _jsx(Button, { onClick: popDialog, children: t("actions.delete-facility.confirm.no") })] })] }));
};
export default React.memo(FacilityDeleteConfirmDialog);
