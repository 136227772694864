import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { useFormFieldContext } from "components-care";
import TrainingPickDeviceModelsButton from "../../Training/Tasks/components/TrainingPickDeviceModelsButton";
const PickDeviceModelsButton = (props) => {
    const { visibility, value, field, handleChange } = useFormFieldContext();
    const handleSelect = useCallback((selected) => {
        handleChange(field, selected);
    }, [handleChange, field]);
    return (_jsx(TrainingPickDeviceModelsButton, { ...props, disabled: visibility.readOnly, getInitialSelection: () => value, onSelect: handleSelect }));
};
export default React.memo(PickDeviceModelsButton);
