import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { ModelDataTypeStringArray } from "components-care";
import PermissionView from "../../../../pages/TenantSpecific/Facility/AccessManagement/components/PermissionView";
class AMPermissionRenderer extends ModelDataTypeStringArray {
    tenant;
    constructor(tenant) {
        super();
        this.tenant = tenant;
    }
    render(params) {
        const { visibility, field, handleChange } = params;
        let { value } = params;
        if (!value) {
            value = [];
        }
        if (visibility.disabled)
            return _jsx(_Fragment, {});
        if (visibility.hidden) {
            return (_jsx("input", { type: "hidden", name: field, value: value, readOnly: true, "aria-hidden": "true" }));
        }
        if (visibility.editable) {
            if (visibility.grid) {
                throw new Error("Not supported");
            }
            return (_jsx(PermissionView, { selected: value, tenant: this.tenant, field: field, setSelected: handleChange }));
        }
        throw new Error("Not supported");
    }
}
export default AMPermissionRenderer;
