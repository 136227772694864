import { jsx as _jsx } from "react/jsx-runtime";
import { ComponentsCareI18n, Model, ModelDataStore, ModelDataTypeBackendSingleSelectRenderer, ModelDataTypeBooleanCheckboxRendererCC, ModelDataTypeDateNullableRendererCC, ModelDataTypeDecimalCurrencyRendererCC, ModelDataTypeEnumSelectRenderer, ModelDataTypeIntegerRendererCC, ModelDataTypeLocalizedStringRenderer, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityDisabledReadOnly, ModelVisibilityEdit, ModelVisibilityEditReadOnly, ModelVisibilityEditRequired, ModelVisibilityGridView, ModelVisibilityGridViewHidden, ModelVisibilityHidden, throwError, usePermissionContext, validatePresence, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import InventoryModel, { INVENTORY_OPERATION_STATUS_ENUM, InventoryModelSelectorSort, InventoryModelToSelectorData, } from "./InventoryModel";
import { getLruConfig } from "../../utils";
import handleBackendMultiSelectLoadError from "../../utils/handleBackendMultiSelectLoadError";
import RendererMaintenanceTypeMultiSelector from "../types/renderers/RendererMaintenanceTypeMultiSelector";
import PickInventoryButton from "../../pages/TenantSpecific/components/Dialogs/PickInventoryButton";
import CalcNextMaintenanceDateBtn from "../../pages/TenantSpecific/Devices/Tasks/components/TaskCalcNextMaintenanceDateBtn";
import RendererImageWithStatus from "../types/renderers/RendererImageWithStatus";
import IncidentModel, { IncidentModelToSelectorData } from "./IncidentModel";
import { normalizeDate } from "components-care/dist/backend-integration/Model/Types/Utils/DateUtils";
import { hasPermission } from "components-care/dist";
import RendererDeviceModelGrid from "../types/renderers/RendererDeviceModelGrid";
import ContactModel, { ContactModelSelectorSort, ContactModelToSelectorData, } from "./ContactModel";
import PickContactButton from "../../pages/TenantSpecific/components/Dialogs/PickContactButton";
import { useTranslation } from "react-i18next";
import { useTenantId } from "../../pages/components/TenantContext";
import { useMemo } from "react";
import { AUDIT_FIELDS_DEF, ID_FIELD_DEF, supportedLanguages, } from "../../constants";
import RendererObjectId from "../types/renderers/RendererObjectId";
export var IssueType;
(function (IssueType) {
    IssueType["Malfunction"] = "malfunction";
    IssueType["Maintenance"] = "maintenance";
    IssueType["SecurityMessage"] = "security_message";
    IssueType["Occurrence"] = "occurrence";
    IssueType["DeviceRetired"] = "device_retired";
    IssueType["RecommissionDevice"] = "recommission_device";
})(IssueType || (IssueType = {}));
export const ISSUE_TYPE_OPTIONS = (t) => [
    {
        value: "malfunction",
        getLabel: () => t("event-issue:type.enum.malfunction"),
    },
    {
        value: "maintenance",
        getLabel: () => t("event-issue:type.enum.maintenance"),
    },
    {
        value: "security_message",
        getLabel: () => t("event-issue:type.enum.security_message"),
    },
    {
        value: "occurrence",
        getLabel: () => t("event-issue:type.enum.occurrence"),
    },
    {
        value: "device_retired",
        getLabel: () => t("event-issue:type.enum.device_retired"),
        isDisabled: true,
        invisible: true,
    },
    {
        value: "recommission_device",
        getLabel: () => t("event-issue:type.enum.recommission_device"),
        isDisabled: true,
        invisible: true,
    },
];
export const STATUS_OPTIONS = (t) => [
    {
        value: "_new",
        getLabel: () => t("event-issue:status.enum.new"),
    },
    {
        value: "pending",
        getLabel: () => t("event-issue:status.enum.pending"),
    },
    {
        value: "in_progress",
        getLabel: () => t("event-issue:status.enum.in_progress"),
    },
    {
        value: "done",
        getLabel: () => t("event-issue:status.enum.done"),
    },
];
export const getEventIssueIncidentsRole = (perms) => hasPermission(perms, "incidents.dispatcher") ? "dispatcher" : "supporter";
const isIssueDeviceStateChange = (_values, initialValues) => ["device_retired", "recommission_device"].includes(initialValues.issue_type) &&
    initialValues.status === "done" &&
    initialValues.done_at;
const ModelVisibilityIssueTypeReadOnly = (_values, initialValues) => ["device_retired", "recommission_device"].includes(initialValues.issue_type)
    ? ModelVisibilityEditReadOnly
    : ModelVisibilityEditRequired;
const ModelVisibilityIssueTitleReadOnly = (values, initialValues) => isIssueDeviceStateChange(values, initialValues)
    ? ModelVisibilityEditReadOnly
    : ModelVisibilityEditRequired;
const ModelVisibilityIssueDeviceStateChangeVisibility = (values, initialValues) => isIssueDeviceStateChange(values, initialValues)
    ? ModelVisibilityEditReadOnly
    : ModelVisibilityEditRequired;
const ModelVisibilityIssueDoneAt = (values, initialValues) => isIssueDeviceStateChange(values, initialValues)
    ? ModelVisibilityIssueDeviceStateChangeVisibility(values, initialValues)
    : values.status === "done"
        ? ModelVisibilityEditRequired
        : ModelVisibilityEdit;
const DeviceTaskModel = (params) => new Model(params.enterpriseClients
    ? "enterprise-event-issue-model"
    : "event-issue-model", {
    inventory_device_picture: {
        type: new RendererImageWithStatus("inventory_operation_status", undefined, undefined, getDeviceTaskImage),
        getLabel: () => params.t("inventory:inventory-image"),
        getColumnLabel: () => "",
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        filterable: false,
        customData: null,
        columnWidth: [60, 60, 60],
    },
    inventory_operation_status: {
        type: new ModelDataTypeEnumSelectRenderer(INVENTORY_OPERATION_STATUS_ENUM(params.t), {
            disableSearch: true,
            disableClearable: true,
        }),
        getLabel: () => params.t("inventory:operation_status.field"),
        getDefaultValue: () => "active",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEdit,
            edit: (values) => values.inventory_operation_status === "retired"
                ? ModelVisibilityEditReadOnly
                : ModelVisibilityEdit,
        },
        customData: null,
    },
    device_model_combo_search: {
        type: new RendererDeviceModelGrid("device_model_title", "device_model_version", "device_type_title_labels", "inventory_manufacturer_according_to_type_plate"),
        getLabel: () => params.t("inventory:device_model_combo_search.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        filterable: true,
        sortable: true,
        customData: null,
        columnWidth: [80, 400, 260],
    },
    device_type_title_labels: {
        // device type
        type: new ModelDataTypeLocalizedStringRenderer({
            enabledLanguages: supportedLanguages,
        }),
        getLabel: () => params.t("device-models:device-type.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        filterable: true,
        sortable: true,
        customData: null,
        columnWidth: [80, 320, 160],
    },
    device_type_title: {
        // device type
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("device-models:device-type.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        filterable: true,
        sortable: true,
        customData: null,
        columnWidth: [80, 320, 160],
    },
    device_model_title: {
        // device model
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("device-models:device-form-title.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        filterable: true,
        sortable: true,
        customData: null,
        columnWidth: [80, 320, 160],
    },
    device_model_version: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("device-models:version.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        sortable: true,
        filterable: true,
        columnWidth: [80, 320, 160],
    },
    tenant_id: params.enterpriseClients
        ? {
            type: new ModelDataTypeEnumSelectRenderer(params.enterpriseClients.map((client) => ({
                getLabel: () => client.name ?? client.name2,
                value: client.id,
                isDisabled: client.sync_config.issues === "to_samedis",
            })), {
                lru: {
                    ...getLruConfig(params.tenantId, "enterprise-tenant"),
                    forceQuery: false,
                    loadData: (id) => {
                        if (!params.enterpriseClients)
                            throw new Error("no clients");
                        const client = params.enterpriseClients.find((client) => client.id === id);
                        if (!client)
                            throw new Error("not found");
                        return {
                            label: client.name ?? client.name2,
                            value: client.id,
                            isDisabled: client.sync_config.issues === "to_samedis",
                        };
                    },
                },
            }),
            getLabel: () => params.t("event-issue:tenant_id.field"),
            getDefaultValue: () => params.enterpriseClientId,
            visibility: {
                overview: ModelVisibilityGridView,
                edit: ModelVisibilityEditRequired,
                create: ModelVisibilityEditRequired,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 640, 240],
        }
        : {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("event-issue:tenant_id.field"),
            getDefaultValue: () => params.enterpriseClientId ?? params.tenantId,
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityEditRequired,
                create: ModelVisibilityEditRequired,
            },
            customData: null,
        },
    issue_number: {
        type: new ModelDataTypeIntegerRendererCC(),
        getLabel: () => params.t("event-issue:issue_number.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [120, 120, 240],
    },
    inventory_device_number: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("inventory:device-number.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        filterable: true,
        sortable: true,
        customData: null,
        columnWidth: [160, 160, 240],
    },
    issue_type: {
        type: new ModelDataTypeEnumSelectRenderer(ISSUE_TYPE_OPTIONS(params.t), {
            disableSearch: true,
            disableClearable: true,
        }),
        getLabel: () => params.t("event-issue:type.field"),
        getColumnLabel: () => params.t("event-issue:type.column"),
        getDefaultValue: () => params.issueType || ISSUE_TYPE_OPTIONS(params.t)[0].value,
        visibility: {
            overview: ModelVisibilityGridView,
            create: params.issueType
                ? ModelVisibilityEditReadOnly
                : ModelVisibilityIssueTypeReadOnly,
            edit: ModelVisibilityIssueTypeReadOnly,
        },
        filterable: true,
        customData: null,
        columnWidth: [80, 320, 220],
        validate: validatePresence,
    },
    status: {
        type: new ModelDataTypeEnumSelectRenderer(STATUS_OPTIONS(params.t), {
            disableSearch: true,
            disableClearable: true,
        }),
        getLabel: () => params.t("event-issue:status.field"),
        getDefaultValue: () => params.deviceRetired || params.deviceRecommission
            ? "done"
            : STATUS_OPTIONS(params.t)[0].value,
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityIssueDeviceStateChangeVisibility,
            edit: ModelVisibilityIssueDeviceStateChangeVisibility,
        },
        filterable: true,
        sortable: true,
        customData: null,
        validate: validatePresence,
        columnWidth: [80, 160, 150],
    },
    responsible_name: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("event-issue:responsible_id.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
        customData: null,
    },
    responsible_id: {
        type: new ModelDataTypeBackendSingleSelectRenderer({
            modelToSelectorData: ContactModelToSelectorData,
            placeholder: params.t("event-issue:responsible_id.placeholder"),
            lru: getLruConfig(params.tenantId, "event-issue-responsible-contact"),
            sort: ContactModelSelectorSort,
            onLoadError: handleBackendMultiSelectLoadError,
            enableIcons: true,
            iconSize: 24,
            endAdornment: (_jsx(PickContactButton, { target: "tenant", issue: true, title: params.t("event-issue:responsible_id.dialog-title") })),
        }),
        getRelationModel: (_id, values) => ContactModel({
            t: params.t,
            ccT: ComponentsCareI18n.t.bind(ComponentsCareI18n),
            tenantId: params.tenantId ??
                values.tenant_id ??
                throwError("tenant id not set"),
            enterpriseClientId: params.enterpriseClientId ??
                (params.enterpriseClients
                    ? (values.tenant_id ??
                        throwError("tenant id not set"))
                    : null),
            issue: {
                inventoryId: values.inventory_id,
            },
            target: "tenant",
        }),
        getRelationModelValues: ["tenant_id", "inventory_id"],
        getLabel: () => params.t("event-issue:responsible_id.field"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: params.enterpriseClientId || params.enterpriseClients
                ? ModelVisibilityEditRequired
                : ModelVisibilityEdit,
            create: params.enterpriseClientId || params.enterpriseClients
                ? ModelVisibilityEditRequired
                : ModelVisibilityEdit,
        },
        validate: params.enterpriseClientId || params.enterpriseClients
            ? validatePresence
            : undefined,
        customData: null,
        onChange: (responsibleId, _model, setFieldValue) => {
            setFieldValue("status", responsibleId ? "pending" : "_new");
            return responsibleId;
        },
    },
    date: {
        type: new ModelDataTypeDateNullableRendererCC({
            slotProps: {
                textField: {
                    InputProps: { startAdornment: _jsx(CalcNextMaintenanceDateBtn, {}) },
                },
            },
        }),
        getLabel: () => params.t("event-issue:date.field"),
        getDefaultValue: () => params.deviceRetired || params.deviceRecommission
            ? normalizeDate(new Date())
            : null,
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityIssueDeviceStateChangeVisibility,
            edit: ModelVisibilityIssueDeviceStateChangeVisibility,
        },
        filterable: true,
        sortable: true,
        customData: null,
        columnWidth: [80, 160, 150],
        validate: validatePresence,
        validateHint: (value) => {
            if (!value)
                return null;
            if (value < normalizeDate(new Date()))
                return params.t("event-issue:date.validations.before-today");
            return null;
        },
    },
    title: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("event-issue:title.placeholder"),
        }),
        getLabel: () => params.t("event-issue:title.field"),
        getDefaultValue: async () => params.inventoryId
            ? params.deviceRetired
                ? `${params.t("event-issue:title.retired-text")}: ${(await InventoryModel({
                    t: params.t,
                    tenantId: params.tenantId,
                    enterpriseClientId: params.enterpriseClientId,
                }).getCached(params.inventoryId))[0].device_number}`
                : params.deviceRecommission
                    ? `${params.t("event-issue:title.recommission-text")}: ${(await InventoryModel({
                        t: params.t,
                        tenantId: params.tenantId,
                        enterpriseClientId: params.enterpriseClientId,
                    }).getCached(params.inventoryId))[0].device_number}`
                    : undefined
            : undefined,
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityIssueTitleReadOnly,
            edit: ModelVisibilityIssueTitleReadOnly,
        },
        filterable: true,
        sortable: true,
        columnWidth: [80, 640, 320],
        customData: null,
        validate: (value, records, def) => {
            if (records.issue_type === "maintenance")
                return null;
            return validatePresence(value, records, def);
        },
    },
    done_at: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("event-issue:done-at.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityIssueDoneAt,
            edit: ModelVisibilityIssueDoneAt,
        },
        filterable: true,
        sortable: true,
        columnWidth: [80, 160, 160],
        customData: null,
        validate: (value, record, def) => {
            if (record.status !== "done")
                return null;
            return validatePresence(value, record, def);
        },
        validateHint: (value, values) => {
            if (!value)
                return null;
            const dueOn = values.date;
            if (dueOn && value < dueOn) {
                return params.t("event-issue:done-at.validations.before-due");
            }
            if (value < normalizeDate(new Date()))
                return params.t("event-issue:done-at.validations.before-today");
            return null;
        },
    },
    department_title: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("event-issue:department_title.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        filterable: true,
        sortable: true,
        customData: null,
        columnWidth: [80, 320, 200],
    },
    inventory_device_location: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("event-issue:device-location.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        filterable: true,
        sortable: true,
        customData: null,
        columnWidth: [80, 320, 200],
    },
    inventory_current_responsible_manufacturer: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("device-models:current-responsible-manufacturer.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        filterable: true,
        sortable: true,
        customData: null,
        columnWidth: [80, 640, 280],
    },
    inventory_manufacturer_according_to_type_plate: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("device-models:manufacturer-according-to-type-plate.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        filterable: true,
        sortable: true,
        customData: null,
        columnWidth: [80, 640, 280],
    },
    inventory_no_medical_device: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => params.t("event-issue:inventory_no_medical_device.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        sortable: true,
        filterable: true,
        customData: null,
        columnWidth: [80, 320, 160],
    },
    inventory_id: {
        type: new ModelDataTypeBackendSingleSelectRenderer({
            modelToSelectorData: InventoryModelToSelectorData,
            placeholder: params.t("event-issue:select-inventory.field"),
            lru: getLruConfig(params.tenantId, "event-issue-inventory"),
            sort: InventoryModelSelectorSort(null),
            onLoadError: handleBackendMultiSelectLoadError,
            modelFetch: (values) => InventoryModel({
                t: params.t,
                tenantId: params.tenantId,
                enterpriseClientId: params.enterpriseClientId ??
                    (params.enterpriseClients
                        ? (values.tenant_id ??
                            throwError("tenant id not set"))
                        : null),
            }),
            endAdornment: (_jsx(PickInventoryButton, { title: params.t("event-issue:select-inventory.field"), noRetired: true })),
            enableIcons: true,
            iconSize: 24,
        }),
        getRelationModel: (_, values) => InventoryModel({
            t: params.t,
            tenantId: params.tenantId,
            enterpriseClientId: params.enterpriseClientId ??
                (params.enterpriseClients
                    ? (values.tenant_id ??
                        throwError("tenant id not set"))
                    : null),
            extraParams: {
                "filter[status]": "active",
            },
        }),
        getRelationModelValues: ["tenant_id"],
        getLabel: () => params.t("event-issue:inventory_selector"),
        getDefaultValue: () => params.inventoryId,
        visibility: {
            overview: ModelVisibilityDisabled,
            create: params.inventoryId
                ? ModelVisibilityEditReadOnly
                : ModelVisibilityIssueDeviceStateChangeVisibility,
            edit: ModelVisibilityIssueDeviceStateChangeVisibility,
        },
        customData: null,
        validate: validatePresence,
    },
    retirement_date: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("event-issue:retirement-date.field"),
        getDefaultValue: () => params.deviceRetired ? normalizeDate(new Date()) : null,
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
    },
    maintenance_performer: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("event-issue:maintenance-performer.placeholder"),
        }),
        getLabel: () => params.t("event-issue:maintenance-performer.field"),
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    maintenance_passed: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => params.t("event-issue:maintenance-passed.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        sortable: true,
        filterable: true,
        customData: null,
        columnWidth: [80, 190, 190],
    },
    security_message_id: {
        type: new RendererObjectId(),
        getLabel: () => "",
        getDefaultValue: () => params.securityMessageId,
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    next_event_date: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("event-issue:next-event.date.field"),
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    services: {
        type: new RendererMaintenanceTypeMultiSelector({
            inventoryId: params.inventoryId ?? null,
        }),
        getLabel: () => params.t("event-issue:services.field"),
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        validate: (value, records, def) => {
            if (records.issue_type !== "maintenance")
                return null;
            return validatePresence(value, records, def);
        },
    },
    cost_in_cents_currency: {
        // internal field, frontend only
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => "",
        getDefaultValue: () => "EUR",
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityHidden,
            edit: ModelVisibilityHidden,
        },
        customData: null,
    },
    cost_in_cents: {
        type: new ModelDataTypeDecimalCurrencyRendererCC({
            currency: (values) => values.cost_in_cents_currency || "EUR",
            currencyUpdateFields: ["cost_in_cents_currency"],
            placeholder: params.t("event-issue:cost_in_cents.placeholder"),
        }),
        getLabel: () => params.t("event-issue:cost_in_cents.field"),
        getColumnLabel: () => params.t("event-issue:cost_in_cents.column"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        sortable: true,
        filterable: true,
        columnWidth: [100, 160, 120],
    },
    incident_id: {
        type: new ModelDataTypeBackendSingleSelectRenderer({
            modelToSelectorData: IncidentModelToSelectorData,
        }),
        getLabel: () => params.t("event-issue:incident_id.field"),
        getDefaultValue: () => params.incidentId,
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityEdit,
            create: params.incidentId
                ? ModelVisibilityEditReadOnly
                : ModelVisibilityEdit,
        },
        customData: null,
        getRelationModel: (id, values) => IncidentModel({
            t: params.t,
            tenantId: params.tenantId,
            role: params.incidentsRole,
            filter: id
                ? { inventory_id: { type: "equals", value1: id, value2: "" } }
                : undefined,
            enterpriseClientId: params.enterpriseClientId ??
                (params.enterpriseClients
                    ? (values.tenant_id ??
                        throwError("tenant id not set"))
                    : null),
        }),
        getRelationModelValues: ["tenant_id"],
    },
    delete_currently_open_tasks: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => params.t("event-issue:delete_currently_open_tasks.field"),
        getDefaultValue: () => false,
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    patient_data_securely_removed: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => params.t("event-issue:patient_data_securely_removed.field"),
        getDefaultValue: () => false,
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    ...AUDIT_FIELDS_DEF(params.t, null),
    id: {
        ...ID_FIELD_DEF(params.t),
        customData: null,
    },
}, new BackendConnector(params.enterpriseClientId
    ? `v4/enterprise/tenants/${params.tenantId}/clients/${params.enterpriseClientId}/issues`
    : params.enterpriseClients
        ? `v4/enterprise/tenants/${params.tenantId}/issues`
        : `v4/tenants/${params.tenantId}/issues`, "data", {
    forceFieldFilter: {
        ...(params.inventoryId && {
            inventory_id: {
                type: "equals",
                value1: params.inventoryId,
                value2: "",
            },
        }),
        ...(params.issueType && {
            issue_type: {
                type: "equals",
                value1: params.issueType,
                value2: "",
            },
        }),
    },
    additionalQueryParameters: {
        "filter[archive]": true,
    },
    getEndpointOverrideCreate: params.enterpriseClients
        ? (record) => `/api/v4/enterprise/tenants/${params.tenantId}/clients/${record.tenant_id}/issues`
        : undefined,
    getEndpointOverrideUpdate: params.enterpriseClients
        ? (record) => `/api/v4/enterprise/tenants/${params.tenantId}/clients/${record.tenant_id}/issues/${record.id}`
        : undefined,
}), [
    params.tenantId,
    params.enterpriseClientId,
    params.enterpriseClients,
    params.issueType,
    params.deviceRetired,
    params.deviceRecommission,
    params.inventoryId,
    params.securityMessageId,
    params.incidentId,
], {
    cacheKeysIndex: [params.inventoryId, params.issueType],
    hooks: {
        onCreateOrUpdate: async ([data]) => {
            if (data.inventory_id) {
                InventoryModel({
                    t: params.t,
                    tenantId: params.tenantId,
                    enterpriseClientId: params.enterpriseClientId,
                }).invalidateCacheForId(data.inventory_id);
                void ModelDataStore.invalidateQueries({
                    queryKey: ["event-issues-open-tasks", data.inventory_id],
                });
            }
            if (data.incident_id) {
                IncidentModel({
                    t: params.t,
                    tenantId: params.tenantId,
                    role: "dispatcher",
                }).invalidateCacheForId(data.incident_id);
            }
        },
    },
});
export const getDeviceTaskImage = (data) => data.inventory_device_picture || "/img/placeholders/device.png";
export const useDeviceTaskModelTranslations = () => useTranslation(["inventory", "device-models", "event-issue", "common"]);
export const useDeviceTaskModel = (params) => {
    const { t } = useDeviceTaskModelTranslations();
    const tenantId = useTenantId();
    const [perms] = usePermissionContext();
    const incidentsRole = getEventIssueIncidentsRole(perms);
    return useMemo(() => DeviceTaskModel({ tenantId, incidentsRole, ...params, t }), [tenantId, incidentsRole, t, params]);
};
export default DeviceTaskModel;
