import { useFormContext } from "components-care";
import { useContext } from "react";
import EnterpriseContext from "../components/EnterpriseContext";
export const useFormEnterpriseClientIdOpt = () => {
    const enterprise = useContext(EnterpriseContext);
    let { values, parentFormContext } = useFormContext();
    if (!enterprise) {
        return null;
    }
    // look up tenant id in parent forms if not present
    while (!("tenant_id" in values) && parentFormContext != null) {
        values = parentFormContext.values;
        parentFormContext = parentFormContext.parentFormContext;
    }
    if (!("tenant_id" in values)) {
        throw new Error("useFormEnterpriseClientId: No tenant_id in form");
    }
    const { tenant_id } = values;
    return tenant_id ?? null;
};
export const useFormEnterpriseClientId = () => {
    const tenantId = useFormEnterpriseClientIdOpt();
    if (!tenantId)
        throw new Error("Form values does not include tenant_id");
    return tenantId;
};
