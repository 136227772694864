import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import SearchAndBrowse from "../index";
import { useTenantId } from "../../../../components/TenantContext";
import { multiSelectFilterEntryFromEnum } from "../FilterEntryMultiSelect";
import DeviceTagModel, { DEVICE_TAG_TYPES, getDeviceTagModelSort, useDeviceTagModelTranslations, } from "../../../../../components-care/models/DeviceTagModel";
import DeviceTagRenderer from "../Renderers/DeviceTagRenderer";
import { COMPACT_LAYOUT_HEIGHT } from "../Renderers/DenseLayout";
const DeviceTagSearch = (props) => {
    const { target, ...other } = props;
    const tenantId = useTenantId();
    const { t: deviceTagModelT } = useDeviceTagModelTranslations();
    const loadRecords = useCallback(async (search, filters, offset) => {
        const deviceTagModel = DeviceTagModel({
            t: deviceTagModelT,
            tenantId,
            target,
        });
        const [data, meta] = await deviceTagModel.index2({
            offset: offset,
            rows: 25,
            sort: getDeviceTagModelSort(),
            quickFilter: search,
            fieldFilter: {
                type: {
                    type: "inSet",
                    value1: filters.type.join(","),
                    value2: "",
                },
            },
        });
        return {
            data: data,
            total: meta.filteredRows ?? meta.totalRows,
        };
    }, [deviceTagModelT, tenantId, target]);
    const filters = useMemo(() => ({
        type: {
            variant: "multi-select",
            title: deviceTagModelT("device-tag:type.field"),
            ...multiSelectFilterEntryFromEnum(DEVICE_TAG_TYPES(deviceTagModelT)),
        },
    }), [deviceTagModelT]);
    return (_jsx(SearchAndBrowse, { ...other, loadRecords: loadRecords, filters: filters, renderer: DeviceTagRenderer, rendererItemHeight: COMPACT_LAYOUT_HEIGHT, rendererContext: undefined, controlName: "DeviceTagSearch" }));
};
export default React.memo(DeviceTagSearch);
