import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Model, ModelDataTypeEnumSelectRenderer, ModelDataTypeLocalizedStringRenderer, ModelDataTypeStringRendererCC, ModelVisibilityEditRequired, ModelVisibilityGridView, validatePresence, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import { useTenantId } from "../../pages/components/TenantContext";
import getLocalizedString from "../../utils/getLocalizedString";
import i18n from "../../i18n";
import { AUDIT_FIELDS_DEF, ID_FIELD_DEF, supportedLanguages, } from "../../constants";
export const DEVICE_TAG_TYPES = (t) => ["area_of_application", "purpose", "device_type", "technical_property"].map((value) => ({
    value,
    getLabel: () => t("device-tag:type.enum." + value),
}));
const DeviceTagModel = (params) => new Model("device-tag-model", {
    name: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("device-tag:name.placeholder"),
        }),
        getLabel: () => params.t("device-tag:name.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 420, 260],
        validate: async (value, values, field) => {
            const present = await validatePresence(value, values, field);
            if (present)
                return present;
            if (!/^[a-z_]+$/.test(value))
                return params.t("device-tag:name.validation.regex");
            return null;
        },
    },
    labels: {
        type: new ModelDataTypeLocalizedStringRenderer({
            enabledLanguages: supportedLanguages,
            placeholder: params.t("device-tag:labels.placeholder"),
        }),
        getLabel: () => params.t("device-tag:labels.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 420, 260],
    },
    type: {
        type: new ModelDataTypeEnumSelectRenderer(DEVICE_TAG_TYPES(params.t), {
            disableSearch: true,
            disableClearable: true,
            placeholder: params.t("device-tag:type.placeholder"),
        }),
        getLabel: () => params.t("device-tag:type.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 420, 260],
        validate: validatePresence,
    },
    ...AUDIT_FIELDS_DEF(params.t, null),
    id: {
        ...ID_FIELD_DEF(params.t),
        customData: null,
    },
}, new BackendConnector(params.target === "tenant"
    ? params.tenantId
        ? `v4/tenants/${params.tenantId}/device_tags`
        : "v4/my/device_tags"
    : `v4/tenants/${params.tenantId}/mdm/device_tags`, "data", {
    additionalQueryParameters: params.filterTagType
        ? {
            "filter[types]": params.filterTagType,
        }
        : undefined,
}), [params.tenantId, params.target], {
    enableRequestBatching: true,
});
export const DeviceTagModelToSelectorData = (record) => ({
    value: record.id,
    label: getLocalizedString(i18n.language, record.labels),
});
export const getDeviceTagModelSort = () => [
    {
        field: "type",
        direction: 1,
    },
    {
        field: "labels." + i18n.language.split("-")[0],
        direction: 1,
    },
];
export const useDeviceTagModelTranslations = () => useTranslation(["device-tag", "common"]);
export const useDeviceTagModel = (params) => {
    const tenantId = useTenantId();
    const { t } = useDeviceTagModelTranslations();
    return useMemo(() => DeviceTagModel({ tenantId, t, ...params }), [tenantId, t, params]);
};
export default DeviceTagModel;
