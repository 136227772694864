import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { Model, ModelDataTypeEnumSelectRenderer, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityEditRequired, ModelVisibilityGridView, ModelVisibilityHidden, ModelVisibilityView, useDialogContext, validateEmail, validatePresence, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import { useTranslation } from "react-i18next";
import { useTenantId } from "../../pages/components/TenantContext";
import { useCallback } from "react";
import { FullFormDialog as FormDialog } from "components-care/dist/backend-components/Form";
import { ID_FIELD_DEF } from "../../constants";
const PaymentMethodsCRUD = React.lazy(() => import("../../pages/TenantSpecific/Facility/Billing/PaymentMethods/PaymentMethods"));
const PaymentMethodModel = (params) => new Model("payment-method-model", {
    payment_provider: {
        type: new ModelDataTypeEnumSelectRenderer(["stripe"].map((v) => ({
            getLabel: () => params.t("subscription:payment_method.payment_provider.enum." + v),
            value: v,
        }))),
        getLabel: () => params.t("subscription:payment_method.payment_provider.name"),
        getDefaultValue: () => "stripe",
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityHidden,
            create: ModelVisibilityHidden,
        },
        customData: null,
    },
    payment_type: {
        type: new ModelDataTypeEnumSelectRenderer(["card", "sepa_debit"].map((v) => ({
            getLabel: () => params.t("subscription:payment_method.payment_type.enum." + v),
            value: v,
        }))),
        getLabel: () => params.t("subscription:payment_method.payment_type.name"),
        getDefaultValue: () => "card",
        visibility: {
            overview: ModelVisibilityGridView,
            edit: ModelVisibilityView,
            create: ModelVisibilityHidden,
        },
        customData: null,
    },
    remote_payment_method_id: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
            create: ModelVisibilityHidden,
        },
        customData: null,
    },
    human_name: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("subscription:payment_method.human_name.name"),
        visibility: {
            overview: ModelVisibilityGridView,
            edit: ModelVisibilityView,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    // frontend only fields
    iban_name: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("subscription:payment_method.iban_name.name"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityEditRequired,
            create: ModelVisibilityEditRequired,
        },
        customData: null,
        validate: (value, values, field) => {
            if (values.payment_type !== "sepa_debit")
                return null;
            return validatePresence(value, values, field);
        },
    },
    iban_email: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("subscription:payment_method.iban_email.name"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityEditRequired,
            create: ModelVisibilityEditRequired,
        },
        customData: null,
        validate: (value, values, field) => {
            if (values.payment_type !== "sepa_debit")
                return null;
            return validateEmail(value, values, field);
        },
    },
    id: {
        ...ID_FIELD_DEF(params.t),
        customData: null,
    },
}, new BackendConnector(`v4/tenants/${params.tenantId}/payment_methods`, "data"), [params.tenantId]);
export const usePaymentMethodModel = () => {
    const { t } = useTranslation("subscription");
    const tenantId = useTenantId();
    return useMemo(() => PaymentMethodModel({ t, tenantId }), [t, tenantId]);
};
export const convertPaymentMethodToBaseSelectorData = (t, data) => ({
    value: data.id,
    label: `${t(`subscription:payment_method.payment_type.enum.${data.payment_type}`)}: ${data.human_name}`,
});
export const useAddPaymentMethodDialog = () => {
    const [pushDialog, popDialog] = useDialogContext();
    const { t } = useTranslation("subscription");
    return useCallback(async () => {
        try {
            const data = await new Promise((resolve, reject) => pushDialog(_jsx(FormDialog, { useCustomClasses: true, onClose: reject, children: _jsx(PaymentMethodsCRUD, { disableRouting: true, initialView: "new", formProps: {
                        onSubmit: (data) => {
                            popDialog();
                            resolve(data);
                        },
                    } }) })));
            return convertPaymentMethodToBaseSelectorData(t, data);
        }
        catch (_e) {
            return null;
        }
    }, [popDialog, pushDialog, t]);
};
export default PaymentMethodModel;
