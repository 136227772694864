import { jsx as _jsx } from "react/jsx-runtime";
import { debouncePromise, FullFormDialog, hasPermission, Model, ModelDataStore, ModelDataTypeBackendMultiSelectRenderer, ModelDataTypeBackendSingleSelectRenderer, ModelDataTypeBooleanCheckboxRendererCC, ModelDataTypeDateNullableRendererCC, ModelDataTypeDecimalCurrencyRendererCC, ModelDataTypeEnumSelectRenderer, ModelDataTypeEnumSelectRendererMUI, ModelDataTypeImageRenderer, ModelDataTypeLocalizedStringRenderer, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityDisabledReadOnly, ModelVisibilityEdit, ModelVisibilityEditReadOnly, ModelVisibilityEditRequired, ModelVisibilityGridView, ModelVisibilityGridViewHidden, ModelVisibilityHidden, throwError, useDialogContext, useModelGet, usePermissionContext, validatePresence, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import DeviceLocationModel, { DeviceLocationModelToSelectorData, useAddDeviceLocationDialog, } from "./DeviceLocationModel";
import DepartmentModel, { useAddDepartmentDialog } from "./DepartmentModel";
import DeviceModelsModel, { DeviceModelsModelSelectorSort, DeviceModelsModelToSelectorData, getDeviceModelImage, OPERATOR_ORDINANCE_OPTIONS, RISK_LEVEL_OPTIONS, useAddDeviceModelDialog, } from "./DeviceModelsModel";
import { InputAdornment } from "@mui/material";
import { getLruConfig, handleBackendMultiSelectLoadError } from "../../utils";
import RendererMaintenanceTypes from "../types/renderers/RendererMaintenanceTypes";
import NextDeviceNumberButton from "../../pages/TenantSpecific/Devices/Data/components/InventoryNextDeviceNumberButton";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTenantId, useTenantIdWithContext, } from "../../pages/components/TenantContext";
import PickDeviceModelButton from "../../pages/TenantSpecific/components/Dialogs/PickDeviceModelButton";
import PickDeviceLocationButton from "../../pages/TenantSpecific/components/Dialogs/PickDeviceLocationButton";
import PickDepartmentButton from "../../pages/TenantSpecific/components/Dialogs/PickDepartmentButton";
import RendererCurrencySelect, { getCurrencySelectFieldDefinition, } from "../types/renderers/RendererCurrencySelect";
import { useTenantModel } from "./TenantModel";
import RendererImageWithStatus from "../types/renderers/RendererImageWithStatus";
import RendererDeviceModelGrid from "../types/renderers/RendererDeviceModelGrid";
import { getVisibility } from "components-care/dist/backend-integration/Model/Visibility";
import PickInventoryButton from "../../pages/TenantSpecific/components/Dialogs/PickInventoryButton";
import RendererDeviceModelInventoryGrid from "../types/renderers/RendererDeviceModelInventoryGrid";
import ccI18n from "components-care/dist/i18n";
import getLocalizedString from "../../utils/getLocalizedString";
import EnterpriseContext from "../../pages/TenantSpecific/Enterprise/components/EnterpriseContext";
import ContactModel, { ContactModelSelectorSort, ContactModelToSelectorData, useAddContactDialog, } from "./ContactModel";
import PickContactsButton from "../../pages/TenantSpecific/components/Dialogs/PickContactsButton";
import ContactCRUD from "../../pages/TenantSpecific/Contacts/Contacts/components/Contacts";
import { AUDIT_FIELDS_DEF, ID_FIELD_DEF, PlaceholderDeviceModelId, supportedLanguages, } from "../../constants";
import { useRegulatoryDomain } from "../../utils/useRegulatoryDomain";
import { normalizeDate } from "components-care/dist/backend-integration/Model/Types/Utils/DateUtils";
import RendererObjectId from "../types/renderers/RendererObjectId";
import i18n from "../../i18n";
import TypePlatePlaceholder from "../../assets/img/type_plate_placeholder.svg";
const OWNERSHIP_OPTIONS = (t) => ["tenant", "patient", "no-owner"].map((value) => ({
    value,
    getLabel: () => t("inventory:ownership.enum." + value),
}));
const NETWORK_CONNECTIVITY_OPTIONS = (t) => ["", "w-lan", "lan", "bluetooth"].map((value) => ({
    value,
    getLabel: () => t("inventory:network_connectivity.enum." + value),
}));
export const INVENTORY_OPERATION_STATUS_ENUM = (t) => ["active", "limited_use", "out_of_order", "retired"].map((value) => ({
    value,
    getLabel: () => t("inventory:operation_status.enum." + value),
    invisible: value === "retired",
    invisibleInGridFilter: false,
}));
const InventoryModel = (params) => {
    const qrToken = sessionStorage.getItem("qr-token");
    const isEnterprise = !!(params.enterpriseClients || params.enterpriseClientId);
    const isDeviceSystemView = !!params.extraParams?.["filter[device_system_inventory_id]"];
    const model = new Model(params.deviceModelReview
        ? "inventory-device-model-review"
        : params.enterpriseClients
            ? qrToken
                ? "enterprise-inventory-link-model"
                : isDeviceSystemView
                    ? "enterprise-inventory-device-system-model"
                    : "enterprise-inventory-model"
            : qrToken
                ? "inventory-link-model"
                : isDeviceSystemView
                    ? "inventory-device-system-model"
                    : "inventory-model", {
        status: {
            type: new ModelDataTypeEnumSelectRenderer([
                { value: "draft", getLabel: () => "" },
                { value: "finalize_creation", getLabel: () => "" }, // gets set to created in backend
                { value: "created", getLabel: () => "" },
            ]),
            getLabel: () => "",
            getDefaultValue: () => "draft",
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityHidden,
                edit: ModelVisibilityHidden,
            },
            customData: {
                complete: "disabled",
            },
        },
        device_model_image: {
            type: new RendererImageWithStatus("operation_status", {
                uploadLabel: params.t("common:uploads.choose-picture"),
            }, undefined, getInventoryImage),
            getLabel: () => params.t("inventory:inventory-image"),
            getColumnLabel: () => "",
            visibility: {
                overview: ModelVisibilityGridView,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: false,
            customData: {
                complete: "disabled",
            },
            columnWidth: [60, 60, 60],
        },
        type_plate: {
            type: new ModelDataTypeImageRenderer({
                placeholder: TypePlatePlaceholder,
            }),
            getLabel: () => params.t("inventory:type_plate.field"),
            getColumnLabel: () => "",
            visibility: {
                overview: params.deviceModelReview
                    ? ModelVisibilityGridView
                    : ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: false,
            customData: {
                complete: "disabled",
            },
            columnWidth: [60, 60, 60],
        },
        device_model_combo_search: {
            type: new RendererDeviceModelGrid("device_model_title", "device_model_version", "device_type_title_labels", "device_model_manufacturer_according_to_type_plate"),
            getLabel: () => params.t("inventory:device_model_combo_search.field"),
            visibility: {
                overview: params.deviceModelReview
                    ? ModelVisibilityDisabledReadOnly
                    : ModelVisibilityGridView,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 400, 260],
        },
        tenant_id: params.enterpriseClients
            ? {
                type: new ModelDataTypeEnumSelectRenderer(params.enterpriseClients.map((client) => ({
                    getLabel: () => client.name ?? client.name2,
                    value: client.id,
                    isDisabled: client.sync_config.inventories === "to_samedis",
                })), {
                    lru: {
                        ...getLruConfig(params.tenantId, "enterprise-tenant"),
                        forceQuery: false,
                        loadData: (id) => {
                            if (!params.enterpriseClients)
                                throw new Error("no clients");
                            const client = params.enterpriseClients.find((client) => client.id === id);
                            if (!client)
                                throw new Error("not found");
                            return {
                                label: client.name ?? client.name2,
                                value: client.id,
                                isDisabled: client.sync_config.inventories === "to_samedis",
                            };
                        },
                    },
                }),
                getLabel: () => params.t("inventory:tenant_id.field"),
                getDefaultValue: () => params.enterpriseClientId,
                visibility: {
                    overview: ModelVisibilityGridViewHidden,
                    edit: ModelVisibilityEditRequired,
                    create: ModelVisibilityEditRequired,
                },
                customData: {
                    complete: "disabled",
                },
                filterable: true,
                sortable: true,
                columnWidth: [80, 640, 240],
            }
            : {
                type: new ModelDataTypeStringRendererCC(),
                getLabel: () => params.t("inventory:tenant_id.field"),
                getDefaultValue: () => params.enterpriseClientId ?? params.tenantId,
                visibility: {
                    overview: ModelVisibilityDisabledReadOnly,
                    edit: ModelVisibilityEditRequired,
                    create: ModelVisibilityEditRequired,
                },
                customData: {
                    complete: "disabled",
                },
            },
        tenant_name: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("inventory:tenant_id.field"),
            getDefaultValue: () => "",
            visibility: {
                overview: params.deviceModelReview
                    ? ModelVisibilityGridView
                    : ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
                create: ModelVisibilityDisabled,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
        },
        operation_status: {
            type: new ModelDataTypeEnumSelectRenderer(INVENTORY_OPERATION_STATUS_ENUM(params.t), {
                disableSearch: true,
                disableClearable: true,
            }),
            getLabel: () => params.t("inventory:operation_status.field"),
            getDefaultValue: () => "active",
            visibility: {
                overview: ModelVisibilityGridViewHidden, // for excel export
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
        },
        device_type_title: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("device-models:device-type.field"),
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabledReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 320, 160],
        },
        device_type_title_labels: {
            type: new ModelDataTypeLocalizedStringRenderer({
                enabledLanguages: supportedLanguages,
            }),
            getLabel: () => params.t("device-models:device-type.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabledReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 320, 160],
        },
        device_model_title: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("device-models:device-form-title.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabledReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 320, 160],
        },
        device_model_version: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("inventory:device_model_version.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabledReadOnly,
            },
            sortable: true,
            filterable: true,
            columnWidth: [80, 320, 160],
            customData: {
                complete: "disabled",
            },
        },
        device_model_manufacturer_according_to_type_plate: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("device-models:manufacturer-according-to-type-plate.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabledReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 640, 280],
        },
        device_number: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("inventory:device-number.placeholder"),
                InputProps: {
                    endAdornment: (_jsx(InputAdornment, { position: "start", children: _jsx(NextDeviceNumberButton, {}) })),
                },
            }),
            getLabel: () => params.t("inventory:device-number.field"),
            visibility: {
                overview: ModelVisibilityGridView,
                create: ModelVisibilityEditRequired,
                edit: params.deviceModelReview
                    ? ModelVisibilityEditReadOnly
                    : ModelVisibilityEditRequired,
            },
            validate: (value, record, field) => {
                const presence = validatePresence(value, record, field);
                if (presence != null)
                    return presence;
                if (value && !params.deviceModelReview) {
                    return validateDeviceNumber(params.t, params.tenantId, params.enterpriseClientId ??
                        (params.enterpriseClients
                            ? (record.tenant_id ??
                                throwError("tenant id not set"))
                            : null), value, record.id);
                }
                return null;
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "required",
                completeDefault: true,
            },
            columnWidth: [80, 320, 160],
        },
        serial_number: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("inventory:serial-number.placeholder"),
            }),
            getLabel: () => params.t("inventory:serial-number.field"),
            visibility: {
                overview: params.deviceModelReview
                    ? ModelVisibilityGridView
                    : ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: params.deviceModelReview
                    ? ModelVisibilityEditReadOnly
                    : ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "optional",
                completeDefault: true,
            },
            columnWidth: [80, 320, 160],
        },
        manufacturer_system_number: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("inventory:manufacturer-system-number.placeholder"),
            }),
            getLabel: () => params.t("inventory:manufacturer-system-number.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "optional",
            },
            columnWidth: [80, 320, 160],
        },
        parent_device_model_combo_search: {
            type: new RendererDeviceModelInventoryGrid("parent_device_model_title", "parent_device_model_version", "parent_device_type_title", "parent_manufacturer_according_to_type_plate", "main_inventory_number"),
            getLabel: () => params.t("inventory:parent_device_model_combo_search.field"),
            visibility: {
                overview: isDeviceSystemView
                    ? ModelVisibilityDisabledReadOnly
                    : params.deviceModelReview
                        ? ModelVisibilityGridViewHidden
                        : ModelVisibilityGridView,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 400, 260],
        },
        parent_device_model_title: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("inventory:parent_device_model_title.field"),
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabledReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 320, 160],
        },
        parent_device_model_version: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("inventory:parent_device_model_version.field"),
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: {
                complete: "disabled",
            },
            sortable: true,
            filterable: true,
            columnWidth: [80, 320, 160],
        },
        parent_device_type_title: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("inventory:parent_device_type_title.field"),
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabledReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 320, 160],
        },
        parent_manufacturer_according_to_type_plate: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("inventory:parent_manufacturer_according_to_type_plate.field"),
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabledReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 640, 280],
        },
        main_inventory_number: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("inventory:main-inventory-number.placeholder"),
            }),
            getLabel: () => params.t("inventory:main-inventory-number.field"),
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 320, 160],
        },
        main_inventory_id: {
            type: new ModelDataTypeBackendSingleSelectRenderer({
                placeholder: params.t("inventory:main-inventory-number.placeholder"),
                modelToSelectorData: InventoryModelToSelectorData,
                lru: getLruConfig(params.tenantId, "inventory-main_inventory_id"),
                sort: InventoryModelSelectorSort(null),
                onLoadError: handleBackendMultiSelectLoadError,
                enableIcons: true,
                iconSize: 24,
                endAdornment: (_jsx(PickInventoryButton, { searchForMainInventory: true, title: params.t("inventory:main-inventory-number.dialog-title") })),
            }),
            getLabel: () => params.t("inventory:main-inventory-number.field"),
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityEdit,
                edit: (values) => values.is_device_system
                    ? ModelVisibilityEditReadOnly
                    : ModelVisibilityEdit,
            },
            getRelationModel: (id, values) => InventoryModel({
                t: params.t,
                tenantId: params.tenantId,
                regDomain: params.regDomain,
                filterMainInventoryId: "empty",
                filterMainInventoryIdRecordId: id,
                enterpriseClientId: params.enterpriseClientId ??
                    (params.enterpriseClients
                        ? (values.tenant_id ??
                            throwError("tenant id not set"))
                        : null),
            }),
            getRelationModelValues: ["tenant_id"],
            customData: {
                complete: "disabled",
            },
        },
        is_device_system: {
            // aka is parent/main inventory (other inventories reference it)
            type: new ModelDataTypeBooleanCheckboxRendererCC(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabledReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
        },
        device_location_path: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("inventory:device-location"),
            visibility: {
                overview: ModelVisibilityGridView,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabledReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
        },
        department_title: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("inventory:department"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 320, 160],
        },
        device_model_risk_level: {
            type: new ModelDataTypeEnumSelectRenderer(RISK_LEVEL_OPTIONS(params.t)),
            getLabel: () => params.t("device-models:risk-level.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityHidden,
                edit: ModelVisibilityHidden,
            },
            customData: {
                complete: "disabled",
            },
        },
        commissioning_at: {
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => params.t("inventory:commissioning-at.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "optional",
                completeDefault: true,
            },
            columnWidth: [80, 320, 180],
            validate: (value, values) => {
                if (!value)
                    return null;
                const constructionYear = parseInt(values.construction_year);
                if (!isNaN(constructionYear)) {
                    if (constructionYear > value.getFullYear())
                        return params.t("inventory:commissioning-at.validations.before-construction");
                }
                return null;
            },
            validateHint: (value) => {
                if (!value)
                    return null;
                const year = value.getFullYear();
                // need to handle invalid values here too
                if (year < 1990)
                    return params.t("inventory:commissioning-at.validations.too-old");
                if (value > normalizeDate(new Date()))
                    return params.t("inventory:commissioning-at.validations.future");
                return null;
            },
        },
        commissioning_through: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("inventory:commissioning-through.placeholder"),
            }),
            getLabel: () => params.t("inventory:commissioning-through.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "optional",
            },
            columnWidth: [80, 320, 180],
        },
        construction_year: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("inventory:construction-year.placeholder"),
            }),
            getLabel: () => params.t("inventory:construction-year.field"),
            visibility: {
                overview: params.deviceModelReview
                    ? ModelVisibilityGridView
                    : ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: params.deviceModelReview
                    ? ModelVisibilityEditReadOnly
                    : ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "optional",
                completeDefault: true,
            },
            columnWidth: [80, 320, 160],
            validate: (value) => {
                if (!value)
                    return null;
                const year = parseInt(value);
                // if year is string or > 9999
                if (isNaN(year) || year > 9999)
                    return params.t("inventory:construction-year.validations.invalid");
                return null;
            },
            validateHint: (value) => {
                if (!value)
                    return null;
                const year = parseInt(value);
                // need to handle invalid values here too
                if (isNaN(year))
                    return null;
                if (year < 1990)
                    return params.t("inventory:construction-year.validations.too-old");
                if (year > new Date().getFullYear())
                    return params.t("inventory:construction-year.validations.future");
                return null;
            },
        },
        service_partner: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("inventory:service-partner.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 320, 320],
        },
        date_of_acquisition: {
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => params.t("inventory:date-of-acquisition.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 320, 160],
        },
        asset_accounting_number: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("inventory:asset-accounting-number.placeholder"),
            }),
            getLabel: () => params.t("inventory:asset-accounting-number.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "optional",
            },
            columnWidth: [80, 320, 160],
        },
        purchase_price_in_cents: {
            type: new ModelDataTypeDecimalCurrencyRendererCC({
                placeholder: params.t("inventory:purchase-price.placeholder"),
                currency: (values) => values.currency_code || "EUR",
                currencyUpdateFields: ["currency_code"],
            }),
            getLabel: () => params.t("inventory:purchase-price.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: (values) => values.currency_code
                    ? ModelVisibilityEdit
                    : ModelVisibilityEditReadOnly,
            },
            customData: {
                complete: "optional",
                completeDepends: ["currency_code"],
            },
            filterable: true,
            sortable: true,
            columnWidth: [80, 320, 160],
        },
        tenant_currency_code: {
            // frontend only helper field to set currency
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => "",
            getDefaultValue: () => params.tenantCurrencyCode ?? "EUR",
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityHidden,
                edit: ModelVisibilityHidden,
            },
            customData: {
                complete: "disabled",
            },
        },
        "issue_statistics.total_cost_in_cents": {
            type: new ModelDataTypeDecimalCurrencyRendererCC({
                currency: (values) => values.tenant_currency_code || "EUR",
                currencyUpdateFields: ["tenant_currency_code"],
            }),
            getLabel: () => params.t("inventory:issue_statistics.total_cost_in_cents.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEditReadOnly,
            },
            customData: {
                complete: "disabled",
            },
            filterable: true,
            sortable: true,
            columnWidth: [80, 320, 160],
        },
        currency_code: {
            type: new RendererCurrencySelect({
                placeholder: params.t("inventory:currency-code.placeholder"),
            }),
            getLabel: () => params.t("inventory:currency-code.field"),
            ...getCurrencySelectFieldDefinition(params.tenantId),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
        },
        depreciation_in_years: {
            type: new ModelDataTypeEnumSelectRenderer([...Array(25)].map((_, year) => ({
                value: String(year + 1),
                getLabel: () => String(year + 1),
            })), {
                placeholder: params.t("inventory:depreciation-in-years.placeholder"),
            }),
            getLabel: () => params.t("inventory:depreciation-in-years.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "optional",
            },
        },
        depreciation_date: {
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => params.t("inventory:depreciation-date.field"),
            getColumnLabel: () => params.t("inventory:depreciation-date.column-field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEditReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 320, 160],
        },
        comments_field: {
            type: new ModelDataTypeStringRendererCC({
                multiline: true,
                minRows: 5,
                inputProps: { style: { resize: "vertical" } },
            }),
            getLabel: () => params.t("inventory:notes"),
            visibility: {
                overview: qrToken
                    ? ModelVisibilityGridViewHidden
                    : ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 640, 320],
        },
        retirement_date: {
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => params.t("inventory:retirement-date"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEditReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 160, 160],
        },
        last_maintenance: {
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => params.t("inventory:last-maintenance"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEditReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 160, 160],
        },
        next_maintenance: {
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => params.t("inventory:next-maintenance"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEditReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 160, 160],
        },
        no_medical_device: {
            type: new ModelDataTypeBooleanCheckboxRendererCC(),
            getLabel: () => params.t("inventory:no-medical-device"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            getDefaultValue: () => false,
            sortable: true,
            filterable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 320, 160],
        },
        ownership: {
            type: new ModelDataTypeEnumSelectRenderer(OWNERSHIP_OPTIONS(params.t), {
                disableSearch: true,
                disableClearable: true,
                placeholder: params.t("inventory:ownership.placeholder"),
            }),
            getLabel: () => params.t("inventory:ownership.field"),
            getDefaultValue: () => OWNERSHIP_OPTIONS(params.t)[0].value,
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityHidden,
                edit: ModelVisibilityEdit,
            },
            sortable: true,
            filterable: true,
            customData: {
                complete: "optional",
            },
            columnWidth: [80, 320, 160],
        },
        device_nick_name: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("inventory:device-nickname.placeholder"),
            }),
            getLabel: () => params.t("inventory:device-nickname.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "optional",
            },
            columnWidth: [80, 320, 160],
        },
        device_model_operator_ordinance: {
            type: new ModelDataTypeEnumSelectRendererMUI(OPERATOR_ORDINANCE_OPTIONS(params.t)),
            getLabel: () => params.t("device-models:operator-ordinance.field"),
            visibility: {
                overview: params.regDomain === "DE"
                    ? ModelVisibilityGridViewHidden
                    : ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabledReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 320, 160],
        },
        linked_image_id: {
            type: new RendererObjectId(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityHidden,
                edit: ModelVisibilityHidden,
            },
            customData: {
                complete: "disabled",
            },
        },
        catalog_id: {
            type: new ModelDataTypeBackendSingleSelectRenderer({
                modelToSelectorData: DeviceModelsModelToSelectorData,
                enableIcons: true,
                endAdornment: (_jsx(PickDeviceModelButton, { target: "tenant", scope: "public_and_tenant", moduleName: "inventory-device-selector", title: params.t("inventory:catalog-id.dialog-title") })),
                iconSize: 24,
                placeholder: params.t("inventory:catalog-id.select"),
                //onAddNew: params.dialogParams?.addDeviceDialog || undefined,
                //addNewLabel: params.t("inventory:catalog-id.add"),
                onLoadError: handleBackendMultiSelectLoadError,
                lru: getLruConfig(params.tenantId, "inventory-catalog"),
                sort: DeviceModelsModelSelectorSort(null),
            }),
            onChange: (catalogId, _model, setFieldValue, getFieldValue) => {
                setFieldValue("device_model_is_placeholder", !catalogId || catalogId === PlaceholderDeviceModelId);
                if (!catalogId)
                    return catalogId;
                (async () => {
                    const [data] = await DeviceModelsModel({
                        tenantId: params.tenantId,
                        t: params.t,
                        ccT: ccI18n.t,
                        target: "tenant",
                        enterpriseClientId: params.enterpriseClientId ??
                            (params.enterpriseClients
                                ? (getFieldValue("tenant_id") ??
                                    throwError("tenant id not set"))
                                : null),
                    }).getCached(catalogId);
                    if (data.risk_level)
                        setFieldValue("device_model_risk_level", data.risk_level);
                    setFieldValue("device_model_title", data.title);
                    setFieldValue("device_model_image", getDeviceModelImage(data));
                    setFieldValue("with_service_intervals", (() => {
                        return data.with_service_intervals.map((interval) => ({
                            ...interval,
                            label: interval.labels
                                ? getLocalizedString(params.tenantLanguage ?? null, interval.labels)
                                : (interval.label ?? ""),
                            labels: undefined,
                        }));
                    })());
                    setFieldValue("operating_system", data.software_operating_system ?? "");
                })();
                return catalogId;
            },
            getRelationModel: (_, values) => DeviceModelsModel({
                tenantId: params.tenantId,
                t: params.t,
                ccT: ccI18n.t,
                target: "tenant",
                extraParams: {
                    "filter[scope]": "public_and_tenant",
                    "filter[published]": true,
                },
                enterpriseClientId: params.enterpriseClientId ??
                    (params.enterpriseClients
                        ? (values.tenant_id ??
                            throwError("tenant id not set"))
                        : null),
            }),
            getRelationModelValues: ["tenant_id"],
            getLabel: () => params.t("inventory:catalog-id.field"),
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: {
                complete: "optional",
                completeDefault: true,
            },
        },
        device_model_current_responsible_manufacturer: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("device-models:current-responsible-manufacturer.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabledReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
        },
        warranty_period: {
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => params.t("inventory:warranty-period"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "optional",
            },
        },
        device_location_id: {
            type: new ModelDataTypeBackendSingleSelectRenderer({
                modelToSelectorData: DeviceLocationModelToSelectorData,
                enableIcons: true,
                placeholder: params.t("inventory:device-location-id.select"),
                onAddNew: params.dialogParams?.addDeviceLocationDialog || undefined,
                onLoadError: handleBackendMultiSelectLoadError,
                sort: [{ field: "title", direction: 1 }],
                lru: getLruConfig(params.tenantId, "inventory-device-location"),
                endAdornment: (_jsx(PickDeviceLocationButton, { title: params.t("inventory:device-location-id.dialog-title") })),
                className: "SamedisInputRTL",
            }),
            getRelationModel: (_, values) => DeviceLocationModel({
                tenantId: params.tenantId,
                t: params.t,
                enterpriseClientId: params.enterpriseClientId ??
                    (params.enterpriseClients
                        ? (values.tenant_id ??
                            throwError("tenant id not set"))
                        : null),
            }),
            getRelationModelValues: ["tenant_id"],
            getLabel: () => params.t("inventory:device-location-id.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: {
                complete: "optional",
            },
        },
        department_id: {
            type: new ModelDataTypeBackendSingleSelectRenderer({
                modelToSelectorData: (data) => ({
                    value: data.id,
                    label: data.title,
                }),
                enableIcons: true,
                placeholder: params.t("inventory:department-id.select"),
                onAddNew: params.dialogParams?.addDepartmentDialog || undefined,
                onLoadError: handleBackendMultiSelectLoadError,
                sort: [{ field: "title", direction: 1 }],
                lru: getLruConfig(params.tenantId, "inventory-department"),
                endAdornment: (_jsx(PickDepartmentButton, { title: params.t("inventory:department-id.dialog-title") })),
            }),
            getRelationModel: (_, values) => DepartmentModel({
                tenantId: params.tenantId,
                t: params.t,
                enterpriseClientId: params.enterpriseClientId ??
                    (params.enterpriseClients
                        ? (values.tenant_id ??
                            throwError("tenant id not set"))
                        : null),
            }),
            getRelationModelValues: ["tenant_id"],
            getLabel: () => params.t("inventory:department-id.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: {
                complete: "optional",
            },
        },
        device_retired: {
            type: new ModelDataTypeBooleanCheckboxRendererCC(),
            getLabel: () => params.t("inventory:device_retired.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityHidden,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
        },
        qr_code_resource_token: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEdit,
            },
            customData: {
                complete: "disabled",
            },
        },
        inventory_found: {
            type: new ModelDataTypeBooleanCheckboxRendererCC(),
            getLabel: () => params.t("inventory:inventory-found.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: {
                complete: "disabled",
            },
        },
        inventory_found_at: {
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => params.t("inventory:inventory-found-at.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEditReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 320, 180],
        },
        inventory_not_found_at: {
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => params.t("inventory:inventory-not-found-at.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEditReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
            columnWidth: [80, 320, 180],
        },
        // next_inspection_at: {
        //   type: new ModelDataTypeDateNullableRendererCC(),
        //   getLabel: () => params.t("inventory:next_inspection_at.field"),
        //   visibility: {
        //     overview: ModelVisibilityGridViewHidden,
        //     create: ModelVisibilityDisabled,
        //     edit: ModelVisibilityEditReadOnly,
        //   },
        //   filterable: true,
        //   sortable: true,
        //   customData: {
        //     complete: "disabled",
        //   } as InventoryModelCustomData,
        //   columnWidth: [80, 320, 180],
        // },
        with_service_intervals: {
            type: new RendererMaintenanceTypes({ languages: null }),
            getLabel: () => params.t("inventory:service_intervals.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityHidden,
                edit: ModelVisibilityEdit,
            },
            customData: {
                complete: "disabled",
            },
        },
        service_company_ids: {
            type: new ModelDataTypeBackendMultiSelectRenderer({
                modelToSelectorData: (data) => ({
                    ...ContactModelToSelectorData(data),
                    onClick: params.dialogParams?.openServicePartnerView &&
                        (() => params.dialogParams.openServicePartnerView(data.id)),
                }),
                placeholder: params.t("inventory:service-partner.placeholder"),
                addNewLabel: params.t("inventory:service-partner.add-new"),
                onAddNew: params.dialogParams?.addServicePartnerDialog || undefined,
                lru: getLruConfig(params.tenantId, "inventory.service-partner"),
                onLoadError: handleBackendMultiSelectLoadError,
                sort: ContactModelSelectorSort,
                enableIcons: true,
                endAdornment: (_jsx(PickContactsButton, { target: isEnterprise ? "enterprise" : "tenant", filter: {
                        "filter[scope]": isEnterprise ? "tenant" : "public_and_tenant",
                        "filter[contact_types]": ["company"],
                    }, title: params.t("inventory:service-partner.dialog-title") })),
            }),
            getLabel: () => params.t("inventory:service-partner.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                edit: isEnterprise
                    ? ModelVisibilityEditReadOnly
                    : ModelVisibilityEdit,
                create: ModelVisibilityEdit,
            },
            getRelationModel: (_, values) => ContactModel({
                t: params.t,
                ccT: ccI18n.t,
                target: isEnterprise ? "enterprise" : "tenant",
                tenantId: params.tenantId,
                enterpriseClientId: params.enterpriseClientId ??
                    (params.enterpriseClients
                        ? (values.tenant_id ??
                            throwError("tenant id not set"))
                        : null),
                extraParams: {
                    "filter[scope]": isEnterprise ? "tenant" : "public_and_tenant",
                    "filter[contact_types]": ["company"],
                },
            }),
            getRelationModelValues: ["tenant_id"],
            customData: {
                complete: "disabled",
            },
        },
        device_model_is_placeholder: {
            type: new ModelDataTypeBooleanCheckboxRendererCC(),
            getLabel: () => "",
            getDefaultValue: () => true,
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityHidden,
                edit: ModelVisibilityHidden,
            },
            customData: {
                complete: "disabled",
            },
        },
        placeholder_device_type_title: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("inventory:placeholder_device_type_title.placeholder"),
            }),
            getLabel: () => params.t("inventory:placeholder_device_type_title.field"),
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: {
                complete: "disabled",
            },
            validate: (value, values, field) => {
                if (!values.device_model_is_placeholder)
                    return null;
                return validatePresence(value, values, field);
            },
        },
        placeholder_device_model_title: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("inventory:placeholder_device_model_title.placeholder"),
            }),
            getLabel: () => params.t("inventory:placeholder_device_model_title.field"),
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: {
                complete: "disabled",
            },
            validate: (value, values, field) => {
                if (!values.device_model_is_placeholder)
                    return null;
                return validatePresence(value, values, field);
            },
        },
        placeholder_device_model_manufacturer: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("inventory:placeholder_device_model_manufacturer.placeholder"),
            }),
            getLabel: () => params.t("inventory:placeholder_device_model_manufacturer.field"),
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: {
                complete: "disabled",
            },
            validate: (value, values, field) => {
                if (!values.device_model_is_placeholder)
                    return null;
                return validatePresence(value, values, field);
            },
        },
        create_device_model_from_placeholder: {
            type: new ModelDataTypeBooleanCheckboxRendererCC(),
            getLabel: () => "",
            getDefaultValue: () => false,
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabled,
                edit: params.deviceModelReview
                    ? ModelVisibilityEdit
                    : ModelVisibilityDisabled,
            },
            customData: {
                complete: "disabled",
            },
        },
        create_device_type_from_placeholder: {
            type: new ModelDataTypeBooleanCheckboxRendererCC(),
            getLabel: () => "",
            getDefaultValue: () => false,
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabled,
                edit: params.deviceModelReview
                    ? ModelVisibilityEdit
                    : ModelVisibilityDisabled,
            },
            customData: {
                complete: "disabled",
            },
        },
        ...AUDIT_FIELDS_DEF(params.t, {
            complete: "disabled",
        }),
        software_version: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("inventory:software_version.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEdit,
            },
            customData: {
                complete: "optional",
            },
            filterable: true,
            sortable: true,
        },
        operating_system: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("inventory:operating_system.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: {
                complete: "optional",
            },
            filterable: true,
            sortable: true,
        },
        network_connectivity: {
            type: new ModelDataTypeEnumSelectRenderer(NETWORK_CONNECTIVITY_OPTIONS(params.t), {
                disableSearch: true,
                disableClearable: true,
                placeholder: params.t("inventory:network_connectivity.placeholder"),
            }),
            getLabel: () => params.t("inventory:network_connectivity.field"),
            getDefaultValue: () => NETWORK_CONNECTIVITY_OPTIONS(params.t)[0].value,
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "optional",
            },
        },
        ip_address: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("inventory:ip_address.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEdit,
            },
            validate: (value) => {
                if (value) {
                    const validIPV4V6 = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;
                    return validIPV4V6.test(value)
                        ? null
                        : params.t("inventory:ip_address.not_valid");
                }
                return null;
            },
            customData: {
                complete: "optional",
            },
            filterable: true,
            sortable: true,
        },
        mac_address: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("inventory:mac_address.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEdit,
            },
            validate: (value) => {
                if (value) {
                    const validMAC = new RegExp("^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$");
                    return validMAC.test(value)
                        ? null
                        : params.t("inventory:mac_address.not_valid");
                }
                return null;
            },
            customData: {
                complete: "optional",
            },
            filterable: true,
            sortable: true,
        },
        accessible_usb_ports: {
            type: new ModelDataTypeBooleanCheckboxRendererCC(),
            getLabel: () => params.t("inventory:accessible_usb_ports.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
        },
        contains_patient_data: {
            type: new ModelDataTypeBooleanCheckboxRendererCC(),
            getLabel: () => params.t("inventory:contains_patient_data.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: {
                complete: "disabled",
            },
        },
        has_warranty: {
            type: new ModelDataTypeBooleanCheckboxRendererCC(),
            getLabel: () => params.t("inventory:has_warranty.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: {
                complete: "disabled",
            },
            filterable: true,
            sortable: true,
            columnWidth: [160, 160, 160],
        },
        do_maintenance: {
            type: new ModelDataTypeBooleanCheckboxRendererCC(),
            getLabel: () => params.t("inventory:do_maintenance.field"),
            getColumnLabel: () => params.t("inventory:do_maintenance.column"),
            getDefaultValue: () => true,
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: {
                complete: "disabled",
            },
            filterable: true,
            sortable: true,
            columnWidth: [120, 120, 120],
        },
        id: {
            ...ID_FIELD_DEF(params.t),
            customData: {
                complete: "disabled",
            },
        },
    }, new BackendConnector(params.enterpriseClientId
        ? `v4/enterprise/tenants/${params.tenantId}/clients/${params.enterpriseClientId}/inventories`
        : params.enterpriseClients
            ? `v4/enterprise/tenants/${params.tenantId}/inventories`
            : params.deviceModelReview
                ? `v4/inventories`
                : `v4/tenants/${params.tenantId}/inventories`, "data", {
        additionalQueryParameters: {
            ...params.extraParams,
            ...(params.deviceModelReview && { tenant_id: params.tenantId }),
        },
        forceFieldFilter: (() => {
            const filter = {};
            if (params.filterMainInventoryId) {
                filter["main_inventory_id"] = {
                    type: params.filterMainInventoryId,
                    value1: params.filterMainInventoryId,
                    value2: "",
                };
                if (params.filterMainInventoryId === "empty" &&
                    params.filterMainInventoryIdRecordId) {
                    filter["id"] = {
                        type: "notEqual",
                        value1: params.filterMainInventoryIdRecordId,
                        value2: "",
                    };
                }
            }
            if (params.filterDeviceSystem) {
                filter["is_device_system"] = {
                    type: "equals",
                    value1: "true",
                    value2: "",
                };
            }
            return filter;
        })(),
        getEndpointOverrideCreate: params.enterpriseClients
            ? (record) => `/api/v4/enterprise/tenants/${params.tenantId}/clients/${record.tenant_id}/inventories`
            : undefined,
        getEndpointOverrideUpdate: params.enterpriseClients
            ? (record) => `/api/v4/enterprise/tenants/${params.tenantId}/clients/${record.tenant_id}/inventories/${record.id}`
            : undefined,
    }), [params.tenantId], {
        cacheKeysIndex: [
            params.tenantId,
            params.enterpriseClientId,
            !!params.enterpriseClients,
            params.extraParams,
            params.filterDeviceSystem,
            params.filterMainInventoryId,
            params.filterMainInventoryIdRecordId,
        ],
    });
    if (params.requiredFieldOverride) {
        params.requiredFieldOverride
            .filter((field) => field in model.fields &&
            model.fields[field].customData
                .complete === "optional")
            .forEach((field) => {
            const fieldDef = model.fields[field];
            const vis = fieldDef.visibility;
            if (typeof vis.create === "object") {
                vis.create = { ...vis.create, required: true };
            }
            else if (typeof vis.create === "function") {
                vis.create = (values, initialValues) => ({
                    ...getVisibility(vis.create, values, initialValues),
                    required: true,
                });
            }
            else
                throw new Error("visibility callback invalid: " + field);
            if (typeof vis.edit === "object") {
                vis.edit = { ...vis.edit, required: true };
            }
            else if (typeof vis.edit === "function") {
                vis.edit = (values, initialValues) => ({
                    ...getVisibility(vis.edit, values, initialValues),
                    required: true,
                });
            }
            else
                throw new Error("visibility callback invalid: " + field);
        });
    }
    return model;
};
export const validateDeviceNumber = debouncePromise(async (t, tenantId, enterpriseClientId, value, id) => {
    const total = await ModelDataStore.fetchQuery(["inventory-number", tenantId, enterpriseClientId, value, id], async () => {
        const [records] = await InventoryModel({
            t,
            tenantId,
            enterpriseClientId,
        }).index({
            page: 1,
            rows: 2,
            fieldFilter: {
                device_number: {
                    type: "matches",
                    value1: value.trim(),
                    value2: "",
                },
            },
        });
        // Added check if the records does not contain the current record
        return records.filter((r) => r.id !== id).length;
    }, {
        staleTime: 30000,
    });
    return total > 0 ? (t("inventory:device-number.error") ?? "") : null;
}, 300);
export const getInventoryImage = (data) => data.device_model_image || "/img/placeholders/device.png";
export const InventoryModelToSelectorData = (data) => ({
    value: data.id,
    label: [
        getLocalizedString(null, data.device_type_title_labels),
        data.device_model_title,
        data.device_model_current_responsible_manufacturer,
    ]
        .filter((entry) => entry)
        .join(", "),
    icon: getInventoryImage(data),
});
export const InventoryModelSelectorSort = (language) => [
    {
        field: "device_type_title_labels." + (language ?? i18n.language).split("-")[0],
        direction: 1,
    },
    {
        field: "device_model_title",
        direction: 1,
    },
    {
        field: "device_model_current_responsible_manufacturer",
        direction: 1,
    },
];
export const useInventoryModelTranslations = () => useTranslation(["inventory", "device-models"]);
export const useInventoryModel = (params) => {
    const { t } = useInventoryModelTranslations();
    const tenantId = useTenantId();
    const regDomain = useRegulatoryDomain();
    const tenantModel = useTenantModel();
    const { data, error } = useModelGet(tenantModel, tenantId);
    const [perms] = usePermissionContext();
    const enterprise = useContext(EnterpriseContext);
    const addDevicePerms = hasPermission(perms, "catalogs.tenant-writer") &&
        hasPermission(perms, "type-catalogs.tenant-reader");
    const addDeviceDialog = useAddDeviceModelDialog();
    const addDeviceLocationPerms = hasPermission(perms, "device-locations.writer");
    const addDeviceLocationDialog = useAddDeviceLocationDialog();
    const addDepartmentPerms = hasPermission(perms, "departments.writer");
    const addDepartmentDialog = useAddDepartmentDialog();
    const addContactPerms = hasPermission(perms, "contacts.writer");
    const [pushDialog, popDialog] = useDialogContext();
    const openServicePartnerDetails = useCallback((contactId) => {
        pushDialog(_jsx(FullFormDialog, { dialogTitle: t("inventory:service-partner.view"), useCustomClasses: true, children: _jsx(ContactCRUD, { disableRouting: true, mode: "tenant", initialView: contactId, servicePartnerView: true, formProps: {
                    onSubmit: () => {
                        popDialog();
                    },
                } }) }));
    }, [pushDialog, t, popDialog]);
    const addServicePartnerDialog = useAddContactDialog(useMemo(() => ({
        title: t("inventory:service-partner.add-new"),
        customDataConverter: (data) => {
            return {
                ...ContactModelToSelectorData(data),
                onClick: () => openServicePartnerDetails(data.id),
            };
        },
        mode: "tenant",
        forceFields: {
            contact_type: "company",
        },
    }), [t, openServicePartnerDetails]));
    return useMemo(() => {
        if (error)
            return error;
        if (!data)
            return null;
        const requiredFields = data[0].required_inventory_fields;
        const tenantCurrencyCode = data[0].default_currency ?? "EUR";
        const tenantLanguage = data[0].language;
        return InventoryModel({
            t,
            tenantId,
            regDomain,
            requiredFieldOverride: requiredFields,
            tenantCurrencyCode,
            tenantLanguage,
            ...params,
            dialogParams: {
                addDeviceDialog: !enterprise && addDevicePerms && addDeviceDialog,
                addDeviceLocationDialog: !enterprise && addDeviceLocationPerms && addDeviceLocationDialog,
                addDepartmentDialog: !enterprise && addDepartmentPerms && addDepartmentDialog,
                addServicePartnerDialog: !enterprise && addContactPerms && addServicePartnerDialog,
                openServicePartnerView: openServicePartnerDetails,
                ...params?.dialogParams,
            },
        });
    }, [
        error,
        data,
        t,
        tenantId,
        regDomain,
        params,
        enterprise,
        addDevicePerms,
        addDeviceDialog,
        addDeviceLocationPerms,
        addDeviceLocationDialog,
        addDepartmentPerms,
        addDepartmentDialog,
        addContactPerms,
        addServicePartnerDialog,
        openServicePartnerDetails,
    ]);
};
export const useInventoryModelLite = (params) => {
    const { t } = useInventoryModelTranslations();
    const tenantId = useTenantIdWithContext(params?.tenantId);
    const regDomain = useRegulatoryDomain();
    return useMemo(() => {
        return InventoryModel({
            t,
            regDomain,
            ...params,
            tenantId,
        });
    }, [t, tenantId, regDomain, params]);
};
export const getInventoryModelFieldMeta = (t) => {
    const model = InventoryModel({
        t,
        regDomain: "META",
        tenantId: "meta-only",
    });
    const requiredFields = new Set();
    const optionalFields = new Set();
    const defaultFields = new Set();
    for (const f in model.fields) {
        const field = f;
        const fieldDef = model.fields[field];
        if (fieldDef.customData.complete === "required") {
            requiredFields.add(field);
            fieldDef.customData.completeDepends?.forEach((dependency) => requiredFields.add(dependency));
        }
        else if (fieldDef.customData.complete === "optional") {
            if (!requiredFields.has(field))
                optionalFields.add(field);
            fieldDef.customData.completeDepends
                ?.filter((dependency) => !requiredFields.has(dependency))
                .forEach((dependency) => optionalFields.add(dependency));
        }
        if (fieldDef.customData.completeDefault) {
            defaultFields.add(field);
            fieldDef.customData.completeDepends?.forEach((dependency) => defaultFields.add(dependency));
        }
    }
    if (process.env.NODE_ENV !== "production") {
        // make sure logic is sound
        requiredFields.forEach((field) => {
            const fieldDef = model.fields[field];
            if (!fieldDef.validate)
                throw new Error(`required field ${field} has no validation`);
        });
    }
    return {
        model,
        requiredFields,
        optionalFields,
        defaultFields,
    };
};
export default InventoryModel;
