import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { CrudFileUpload, fileToData, useFormContextLite, DefaultErrorComponent as ErrorComponent, } from "components-care";
import useLazyCrudConnector, { extractLazyCrudConnectorParams, } from "components-care/dist/backend-components/Form/useLazyCrudConnector";
import DefaultConnector from "components-care/dist/backend-integration/Connector/DefaultConnector";
export const serializeFileDataWithTags = (tags) => async (data, id) => ({
    document: data.preview ?? (await fileToData(data.file)),
    name: data.file.name,
    preview: !!data.preview,
    id: id ?? undefined,
    ...tags,
});
export const serializeFileData = serializeFileDataWithTags(undefined);
export const deserializeFileData = (data) => ({
    file: {
        id: data.id,
        name: data.name ?? "",
        downloadLink: data.document,
    },
    preview: data.preview ? data.document : undefined,
});
export const FILE_UPLOAD_IMAGE_RESIZE = {
    width: 1920,
    height: 1080,
    keepRatio: true,
};
const SamedisCrudFileUpload = (props, ref) => {
    // eslint-disable-next-line prefer-const
    let [connectorProps, otherProps] = extractLazyCrudConnectorParams(props);
    const { tags } = otherProps;
    if (tags) {
        const tagsFilter = {};
        const tagsColumns = [];
        Object.entries(tags).forEach(([key, value]) => {
            if (value === undefined)
                return;
            tagsFilter[key] = Array.isArray(value)
                ? {
                    type: "equals",
                    value1: value[0],
                    value2: "",
                    nextFilterType: "or",
                    nextFilter: {
                        type: "empty",
                        value1: "empty",
                        value2: "",
                    },
                }
                : value === null
                    ? { type: "empty", value1: "empty", value2: "" }
                    : { type: "equals", value1: value, value2: "" };
            tagsColumns.push({
                field: key,
                headerName: key,
                type: "string",
            });
        });
        connectorProps = {
            ...connectorProps,
            extraParams: {
                ...connectorProps.extraParams,
                gridFilter: tagsFilter,
                columns: tagsColumns,
            },
        };
    }
    const serialize = useMemo(() => tags
        ? serializeFileDataWithTags(Object.fromEntries(Object.entries(tags).map(([key, value]) => [
            key,
            Array.isArray(value) ? value[0] : value,
        ])))
        : serializeFileData, [tags]);
    let connector;
    let readOnly = props.readOnly;
    let errorComponent = ErrorComponent;
    try {
        // this is technically violating the rules of react hooks, but it doesn't matter
        // this would only cause issues if the form context would appear/disappear without this component remounting
        // which will never happen, it will always remount
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const formCtx = useFormContextLite();
        readOnly = formCtx.readOnly || readOnly;
        errorComponent = formCtx.errorComponent;
        // eslint-disable-next-line react-hooks/rules-of-hooks
        connector = useLazyCrudConnector(connectorProps).connector;
    }
    catch (_e) {
        // using without form context, connect directly with backend
        if (!DefaultConnector.getNormal)
            throw new Error("Please configure setDefaultConnectorAPI");
        connector = connectorProps.initialId
            ? DefaultConnector.getNormal(connectorProps.getEndpoint(connectorProps.initialId), connectorProps.extraParams)
            : null;
    }
    return (_jsx(CrudFileUpload
    // @ts-expect-error typescript complains due to ref, no idea how to work around, so just ignore
    , { 
        // @ts-expect-error typescript complains due to ref, no idea how to work around, so just ignore
        ref: ref, connector: connector, errorComponent: errorComponent, serialize: serialize, deserialize: deserializeFileData, previewSize: 24, readOnly: readOnly, maxFiles: otherProps.maxFiles ?? 100, ...otherProps }));
};
export default React.memo(React.forwardRef(SamedisCrudFileUpload));
