import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import StatusBlock from "../../../../../components/StatusBlock";
const useStyles = makeStyles()((theme) => ({
    status_active: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
    },
    status_limited_use: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
    status_out_of_order: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
    status_retired: {
        backgroundColor: theme.palette.getContrastText(theme.palette.background.paper),
        color: theme.palette.background.paper,
    },
}));
const InventoryOperationStatus = (props) => {
    const { status, small, fullWidth } = props;
    const { classes } = useStyles();
    const { t } = useTranslation("inventory");
    return (_jsx(StatusBlock, { small: small, fullWidth: fullWidth, className: classes[("status_" + status)], children: t("operation_status.enum." + status) }));
};
export default React.memo(InventoryOperationStatus);
