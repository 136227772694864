import { jsx as _jsx } from "react/jsx-runtime";
import SamedisMobileDataRendererBase, { SAMEDIS_MOBILE_DATA_RENDERER_BASE_HEIGHT, } from "./SamedisMobileDataRendererBase";
import getLocalizedString from "../../utils/getLocalizedString";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { getDeviceModelImage } from "../models/DeviceModelsModel";
const SamedisMobileDataRendererDeviceModels = (props) => {
    const { t, i18n } = useTranslation("device-models");
    const record = props.record;
    return (_jsx(SamedisMobileDataRendererBase, { icon: getDeviceModelImage(record), line1: [record.title, record.version].filter(Boolean).join(" • "), line2: getLocalizedString(i18n.language, record.device_type_title_labels), line3: record.manufacturer_according_to_type_plate, rightInfo: _jsx(Typography, { component: "p", variant: "body2", color: "textPrimary", noWrap: true, children: t("device-models:trust_level.enum." + record.trust_level) }) }));
};
export const SamedisMobileDataRendererDeviceModelsConfig = {
    rendererHeight: SAMEDIS_MOBILE_DATA_RENDERER_BASE_HEIGHT,
    renderer: SamedisMobileDataRendererDeviceModels,
};
