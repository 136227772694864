import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext } from "react";
import { BaseSelectorContext, useDialogContext, useFormContextLite, } from "components-care";
import { IconButton } from "@mui/material";
import { useButtonEndAdornmentStyles } from "../../../components/Dialogs/PickDeviceModelButton";
import SearchAndMultiSelect from "../../../components/SearchAndBrowse/Instances/SearchAndMultiSelect";
import EmployeeSearch from "../../../components/SearchAndBrowse/Instances/EmployeeSearch";
import { Search as SearchIcon } from "@mui/icons-material";
import reactNodeToElement from "../../../../../utils/reactNodeToElement";
const TrainingPickEmployeesButton = (props) => {
    const { onSelect, disabled, getInitialSelection, getBriefingRequirements, title, } = props;
    const { getFieldValue } = useFormContextLite();
    const { classes } = useButtonEndAdornmentStyles();
    const selectorContext = useContext(BaseSelectorContext);
    const [pushDialog] = useDialogContext();
    const handleSelect = useCallback((selected) => {
        onSelect(selected);
        if (selectorContext)
            selectorContext.addToLru(...selected);
    }, [onSelect, selectorContext]);
    const openDialog = useCallback(async (evt) => {
        evt.stopPropagation();
        pushDialog(_jsx(SearchAndMultiSelect, { instance: EmployeeSearch, initialSelection: getInitialSelection(), onSelected: handleSelect, props: {
                trainingRequirements: getBriefingRequirements &&
                    (await getBriefingRequirements(getFieldValue)),
            }, title: title }));
    }, [
        pushDialog,
        getInitialSelection,
        getFieldValue,
        handleSelect,
        getBriefingRequirements,
        title,
    ]);
    return reactNodeToElement(!disabled && (_jsx(IconButton, { onClick: openDialog, className: classes.button, disabled: disabled, size: "large", color: "primary", children: _jsx(SearchIcon, {}) })));
};
export default React.memo(TrainingPickEmployeesButton);
