import { useTenantId } from "../../pages/components/TenantContext";
import { useQuery } from "react-query";
import SamedisApiClient from "../../components-care/connectors/SamedisApiClient";
import { useTranslation } from "react-i18next";
import { ReactQueryRetryHandler } from "../../constants";
export const useAccessGroups = (tenant) => {
    const tenantId = useTenantId();
    const { i18n } = useTranslation();
    return useQuery(["access_control/groups", { tenantId, lang: i18n.language, tenant }], async () => {
        const data = (await SamedisApiClient.get(tenant
            ? `/api/v4/tenants/${tenantId}/access_control/groups`
            : `/api/v4/access_control/groups`, tenant ? null : { tenant_id: tenantId })).data;
        const rawGroups = data.map((entry) => entry.attributes);
        const groups = {};
        rawGroups.forEach((entry) => {
            if (!(entry.group in groups)) {
                groups[entry.group] = [entry];
            }
            else {
                groups[entry.group].push(entry);
            }
        });
        return groups;
    }, {
        staleTime: 86400000 /* 1 day */,
        retry: ReactQueryRetryHandler,
    });
};
