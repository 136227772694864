import { Model, ModelDataTypeEnumSelectRenderer, ModelDataTypeStringRendererCC, ModelVisibilityDisabledReadOnly, ModelVisibilityGridView, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { ID_FIELD_DEF } from "../../constants";
import RendererObjectId from "../types/renderers/RendererObjectId";
export const ChangelogModel = (params) => new Model(`changelogs`, {
    user_id: {
        type: new RendererObjectId(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    created_at: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.customSettings?.created_at?.label ??
            params.t(`changelog:fields.created_at`),
        visibility: {
            overview: (params.customSettings?.created_at?.visible ?? true)
                ? ModelVisibilityGridView
                : ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
        sortable: true,
        filterable: true,
        columnWidth: params.customSettings?.created_at?.columnWidth ?? [
            160, 160, 160,
        ],
    },
    user_name: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.customSettings?.user_name?.label ??
            params.t(`changelog:fields.user_name`),
        visibility: {
            overview: (params.customSettings?.user_name?.visible ?? true)
                ? ModelVisibilityGridView
                : ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
        sortable: true,
        filterable: true,
        columnWidth: params.customSettings?.user_name?.columnWidth ?? [
            60, 300, 140,
        ],
    },
    attr_name: {
        type: params.fieldList
            ? new ModelDataTypeEnumSelectRenderer(params.fieldList)
            : new ModelDataTypeStringRendererCC(),
        getLabel: () => params.customSettings?.attr_name?.label ??
            params.t(`changelog:fields.attr_name_human`),
        visibility: {
            overview: !params.fieldList
                ? ModelVisibilityDisabledReadOnly
                : (params.customSettings?.attr_name?.visible ?? true)
                    ? ModelVisibilityGridView
                    : ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
        sortable: true,
        filterable: true,
        columnWidth: params.customSettings?.attr_name?.columnWidth ?? [
            80, 400, 180,
        ],
    },
    attr_name_human: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.customSettings?.attr_name?.label ??
            params.t(`changelog:fields.attr_name_human`),
        visibility: {
            overview: params.fieldList
                ? ModelVisibilityDisabledReadOnly
                : (params.customSettings?.attr_name?.visible ?? true)
                    ? ModelVisibilityGridView
                    : ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
        sortable: true,
        filterable: true,
        columnWidth: params.customSettings?.attr_name?.columnWidth ?? [
            80, 400, 180,
        ],
    },
    value_before: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.customSettings?.value_before?.label ??
            params.t(`changelog:fields.value_before`),
        visibility: {
            overview: (params.customSettings?.value_before?.visible ?? true)
                ? ModelVisibilityGridView
                : ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
        sortable: true,
        filterable: true,
        columnWidth: params.customSettings?.value_before?.columnWidth ?? [
            80, 400, 180,
        ],
    },
    value_after: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.customSettings?.value_after?.label ??
            params.t(`changelog:fields.value_after`),
        visibility: {
            overview: (params.customSettings?.value_after?.visible ?? true)
                ? ModelVisibilityGridView
                : ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
        sortable: true,
        filterable: true,
        columnWidth: params.customSettings?.value_after?.columnWidth ?? [
            80, 400, 180,
        ],
    },
    changelog_id: {
        type: new RendererObjectId(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    record_id: {
        type: new RendererObjectId(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    record_type: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    tenant_locale: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    id: {
        ...ID_FIELD_DEF(params.t),
        customData: null,
    },
}, new BackendConnector(params.customEndpoint ??
    `${params.baseModel.connector
        .getApiBase()
        .substring(5 /* strip /api/ */)}/${params.id}/changelogs`, "data", {
    additionalQueryParameters: params.customQueryParams,
    forceFieldFilter: params.forceFilter,
}), {
    endpoint: params.customEndpoint,
    base: JSON.stringify(params.baseModel.getReactQueryKey(params.id, false)),
});
export const useChangelogTranslations = () => useTranslation("changelog");
export const useChangelogModel = (params) => {
    const { t } = useChangelogTranslations();
    return useMemo(() => ChangelogModel({ ...params, t }), [params, t]);
};
