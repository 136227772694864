import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useId, useMemo, useRef, useState, } from "react";
import { combineClassNames, combineColorsCss, FormContextLite, TextFieldWithHelp, useBackendDataGridAddNewButtons, useBackendDataGridDeleteHandler, } from "components-care";
import { Dialog, Grid, IconButton, InputAdornment, Paper, Skeleton, styled, Typography, } from "@mui/material";
import { Close as CloseIcon, Delete as DeleteIcon, Search as SearchIcon, } from "@mui/icons-material";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList } from "react-window";
import { useInfiniteLoaderBackendFetch, } from "../pages/TenantSpecific/components/SearchAndBrowse/BrowseContainer";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useBasicPageLayoutContext } from "../components/BasicPageLayout";
import AddIcon from "../components/icons/AddIcon";
import { DataGridPropsContext, } from "components-care/dist/standalone/DataGrid/DataGrid";
import { CustomFilterActiveContext } from "components-care/dist/standalone/DataGrid/Header/FilterBar";
import { PageHeaderTypography } from "../components/CustomTypography";
import { EditDataGridRecord, OpenDataGridRecord, OpenDataGridRecordCrud, } from "../pages/TenantSpecific/components/OpenDataGridRecord";
import useCCTranslations from "components-care/dist/utils/useCCTranslations";
import ComponentWithLabel from "components-care/dist/standalone/UIKit/ComponentWithLabel";
const EMPTY_OBJECT = {};
const ItemRenderer = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    overflow: "clip",
    "&.Mui-active": {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: combineColorsCss(theme.palette.background.paper, theme.palette.action.hover),
        },
    },
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "column",
}));
const ItemRendererInner = styled("div")({
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "100%",
    overscrollBehaviorX: "contain",
    scrollSnapType: "x mandatory",
    scrollBehavior: "smooth",
    overflowX: "auto",
    overflowY: "clip",
    whiteSpace: "nowrap",
    scrollbarColor: "transparent transparent",
    "&::-webkit-scrollbar": {
        display: "none",
    },
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "stretch",
});
const ItemRendererInnerContent = styled("div")({
    width: "100%",
    height: "100%",
    display: "flex",
    flexBasis: "100%",
    flexShrink: 0,
    flexGrow: 0,
    scrollSnapAlign: "start",
});
const ItemRendererInnerActions = styled("div")(({ theme }) => ({
    display: "flex",
    flexBasis: "0%",
    flexShrink: 0,
    flexGrow: 1,
    scrollSnapAlign: "start",
    verticalAlign: "top",
    backgroundColor: theme.palette.secondary.light,
}));
const ItemRendererInnerAction = (props) => {
    const { icon, label, onClick, recordId } = props;
    const handleClick = useCallback((evt) => {
        evt.stopPropagation();
        onClick(recordId);
    }, [onClick, recordId]);
    return (_jsx(ComponentWithLabel, { control: _jsx(IconButton, { color: "primary", onClick: handleClick, children: icon }), labelText: label, labelPlacement: "bottom", onClick: handleClick }));
};
const SamedisMobileBackendDataGridItemRenderer = (props) => {
    const { index, style, data: { records, renderer: Renderer, onEdit, onRowDoubleClick, customSelectionControl, dataActions, }, } = props;
    const formCtxLite = useContext(FormContextLite);
    const crudFormProps = formCtxLite?.customProps;
    const open = crudFormProps && "open" in crudFormProps ? crudFormProps.open : undefined;
    const record = records[index];
    const canEdit = Boolean(customSelectionControl || onEdit || onRowDoubleClick);
    const handleEdit = useCallback(() => {
        if (!record)
            return;
        if (customSelectionControl &&
            customSelectionControl !== OpenDataGridRecord &&
            customSelectionControl !== EditDataGridRecord) {
            if (customSelectionControl === OpenDataGridRecordCrud) {
                if (!open)
                    throw new Error("OpenDataGridRecordCrud emulated, but no form context");
                open(record.id);
            }
            else {
                throw new Error("Unexpected customSelectionControl");
            }
            return;
        }
        if (onRowDoubleClick) {
            onRowDoubleClick(Object.fromEntries(Object.entries(record)
                .map(([key, value]) => [
                [key, value],
                [key + "_raw", value],
            ])
                .flat(1)));
            return;
        }
        if (onEdit) {
            onEdit(record.id);
            return;
        }
    }, [record, customSelectionControl, onRowDoubleClick, onEdit, open]);
    const content = useMemo(() => (_jsxs(ItemRendererInner, { children: [_jsx(ItemRendererInnerContent, { children: record ? (_jsx(Renderer, { record: record })) : (_jsx(Skeleton, { variant: "rectangular" })) }), dataActions.length > 0 && record && (_jsx(ItemRendererInnerActions, { children: dataActions.map((action, idx) => (_jsx(ItemRendererInnerAction, { ...action, recordId: record.id }, idx))) }))] })), [Renderer, dataActions, record]);
    return (_jsx(ItemRenderer, { style: style, onClick: canEdit ? handleEdit : undefined, className: combineClassNames([canEdit && "Mui-active"]), children: content }));
};
const useStyles = makeStyles()((theme) => ({
    searchRoot: {
        padding: theme.spacing(1),
    },
    searchInput: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 17.5,
    },
    searchIconClickable: {
        cursor: "pointer",
    },
}));
const SamedisMobileBackendDataGrid = (props) => {
    const { rendererHeight, renderer, model, defaultSort: sort, overrideCustomData, defaultCustomData, onEdit, onRowDoubleClick, filterBar: FilterBar, customSelectionControl, forceRefreshToken, customDataActionButtons, } = props;
    const { t } = useTranslation("common");
    const { t: ccT } = useCCTranslations();
    const { classes } = useStyles();
    const ctx = useBasicPageLayoutContext();
    const [refreshToken, setRefreshToken] = useState(() => Date.now().toString(16));
    const refreshGrid = useCallback(() => {
        setRefreshToken(Date.now().toString(16));
    }, []);
    const initialMount = useRef(false);
    useEffect(() => {
        if (!initialMount.current) {
            initialMount.current = true;
            return;
        }
        refreshGrid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceRefreshToken]);
    const addNewButtons = useBackendDataGridAddNewButtons(props);
    const handleGridDelete = useBackendDataGridDeleteHandler(props, refreshGrid);
    const dataActions = useMemo(() => [
        handleGridDelete && {
            icon: _jsx(DeleteIcon, {}),
            label: ccT("standalone.data-grid.footer.delete"),
            onClick: (recordId) => handleGridDelete(false, [recordId], undefined),
        },
        ...(customDataActionButtons ?? [])
            .filter((btn) => !btn.isDisabled(1))
            .map((btn) => ({
            ...btn,
            onClick: (recordId) => btn.onClick(false, [recordId]),
        })),
    ].filter(Boolean), [ccT, customDataActionButtons, handleGridDelete]);
    const addIdSuffix = useId();
    useEffect(() => {
        const addId = "mobile-data-grid-add-" + addIdSuffix;
        if (Array.isArray(addNewButtons)) {
            addNewButtons.forEach((addNew, idx) => {
                ctx.setAdditionalAction(addId + idx, {
                    id: addId + idx,
                    icon: AddIcon,
                    iconElem: addNew.icon,
                    name: addNew.label,
                    handler: null,
                    onClick: addNew.onClick,
                    disabled: !addNew.onClick,
                    disabledHint: addNew.disableHint,
                });
            });
        }
        else if (addNewButtons) {
            ctx.setAdditionalAction(addId, {
                id: addId,
                icon: AddIcon,
                name: t("mobile-grid.new"),
                handler: null,
                onClick: typeof addNewButtons === "string" ? console.log : addNewButtons,
                disabled: typeof addNewButtons === "string",
                disabledHint: typeof addNewButtons === "string" ? addNewButtons : undefined,
            });
        }
        return () => {
            if (Array.isArray(addNewButtons)) {
                addNewButtons.forEach((_, idx) => ctx.setAdditionalAction(addId + idx, null));
            }
            else
                ctx.setAdditionalAction(addId, null);
        };
    }, [addIdSuffix, addNewButtons, ctx, t]);
    const [customFiltersOpen, setCustomFiltersOpen] = useState(false);
    const openCustomFilters = useCallback(() => {
        setCustomFiltersOpen(true);
    }, []);
    const closeCustomFilters = useCallback(() => {
        setCustomFiltersOpen(false);
    }, []);
    const customFiltersActivePack = useState(0);
    const [customFiltersActive] = customFiltersActivePack;
    const [customData, setCustomData] = useState(overrideCustomData ?? defaultCustomData ?? EMPTY_OBJECT);
    const loadRecords = useCallback(async (search, filters, offset) => {
        const [records, meta] = await model.index2({
            quickFilter: search,
            sort,
            offset,
            rows: 25,
            additionalFilters: filters,
        });
        return {
            data: records,
            total: meta.filteredRows ?? meta.totalRows,
        };
    }, [model, sort]);
    const { infiniteLoader, records, search, handleSearchChange, total, loadMore, lastError, } = useInfiniteLoaderBackendFetch({
        loadRecords,
        filters: customData,
        forceRefreshToken: refreshToken,
    });
    const itemData = useMemo(() => ({
        records,
        renderer,
        onEdit,
        onRowDoubleClick,
        customSelectionControl,
        dataActions,
    }), [
        customSelectionControl,
        dataActions,
        onEdit,
        onRowDoubleClick,
        records,
        renderer,
    ]);
    return (_jsxs(Grid, { container: true, direction: "column", children: [_jsxs(Grid, { item: true, children: [_jsx(TextFieldWithHelp, { variant: "outlined", value: search, onChange: handleSearchChange, placeholder: t("mobile-grid.search"), InputProps: {
                            startAdornment: (_jsx(InputAdornment, { position: "start", children: _jsx(SearchIcon, { color: customFiltersActive > 0
                                        ? "warning"
                                        : FilterBar
                                            ? "primary"
                                            : undefined, className: FilterBar ? classes.searchIconClickable : undefined, onClick: openCustomFilters }) })),
                            className: classes.searchInput,
                        }, fullWidth: true, className: classes.searchRoot }), FilterBar && (
                    // @ts-expect-error this is a hack, filter dialog doesn't really need the props
                    _jsx(DataGridPropsContext.Provider, { value: props, children: _jsx(CustomFilterActiveContext.Provider, { value: customFiltersActivePack, children: _jsx(Dialog, { open: customFiltersOpen, fullScreen: true, onClose: closeCustomFilters, keepMounted // required for active filter logic
                                : true, children: _jsxs(Grid, { justifyContent: "space-between", spacing: 2, container: true, p: 2, children: [_jsxs(Grid, { item: true, xs: 12, container: true, alignItems: "center", children: [_jsx(Grid, { item: true, xs: true, zeroMinWidth: true, children: _jsx(PageHeaderTypography, { noWrap: true, children: t("mobile-grid.configure-filters") }) }), _jsx(Grid, { item: true, children: _jsx(IconButton, { onClick: closeCustomFilters, children: _jsx(CloseIcon, {}) }) })] }), _jsx(FilterBar, { customData: customData, setCustomData: setCustomData, inDialog: true })] }) }) }) }))] }), _jsxs(Grid, { item: true, xs: true, children: [lastError && (_jsx(Paper, { children: _jsx(Typography, { color: "error", p: 2, children: lastError.message }) })), total === 0 ? (_jsx(Paper, { children: _jsx(Typography, { p: 2, children: t(search ? "mobile-grid.no-data-filter" : "mobile-grid.no-data") }) })) : (_jsx(AutoSizer, { children: ({ width, height }) => (_jsx(InfiniteLoader, { ref: infiniteLoader, isItemLoaded: (index) => index < records.length && !!records[index], loadMoreItems: loadMore, itemCount: total ?? 25, minimumBatchSize: 25, children: ({ ref, onItemsRendered }) => (_jsx(FixedSizeList, { ref: ref, itemSize: rendererHeight, height: height, itemCount: total ?? 25, width: width, onItemsRendered: onItemsRendered, itemData: itemData, children: SamedisMobileBackendDataGridItemRenderer })) })) }))] })] }));
};
export default React.memo(SamedisMobileBackendDataGrid);
