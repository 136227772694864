import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useImperativeHandle, useMemo, } from "react";
import { Grid, Popover } from "@mui/material";
import { Business as LinkEnterpriseIcon, CloudDownload as DataDumpIcon, DoNotDisturb as DeleteIcon, } from "@mui/icons-material";
import { ActionButton, ConfirmDialog, showInfoDialog, SubActionButton, useDialogContext, useFormContext, } from "components-care";
import { useTranslation } from "react-i18next";
import { LegalIcon } from "../../../../../components/icons";
import TenantDeleteConfirmDialog from "./FacilityDeleteConfirmDialog";
import SamedisApiClient from "../../../../../components-care/connectors/SamedisApiClient";
import { showErrorDialog } from "components-care/dist/non-standalone/Dialog/Utils";
import { DangerSubActionButton, useActionButtonStyles, } from "../../../../../components/ActionButton";
import { useTenantBillingModel } from "../../../../../components-care/models/TenantBillingModel";
import { useSubscriptionModel } from "../../../../../components-care/models/SubscriptionModel";
import useAsyncMemo from "components-care/dist/utils/useAsyncMemo";
import { StripePublishableKey } from "../../../../../constants";
import TenantLinkEnterpriseDialog from "./FacilityLinkEnterpriseDialog";
const FacilityActionsButton = React.forwardRef((props, ref) => {
    const { tenantId, goBack, isAdmin } = props;
    const { t } = useTranslation("tenant");
    const [pushDialog] = useDialogContext();
    const { classes } = useActionButtonStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleAction = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);
    useImperativeHandle(ref, () => ({
        handleAction,
    }), [handleAction]);
    const handleLinkEnterprise = useCallback(() => {
        pushDialog(_jsx(TenantLinkEnterpriseDialog, { tenantId: tenantId }));
        handleClose();
    }, [pushDialog, tenantId, handleClose]);
    const handleCreateDpa = useCallback(() => {
        pushDialog(_jsx(ConfirmDialog, { title: t("actions.dpa.confirm.title"), message: t("actions.dpa.confirm.message"), textButtonYes: t("actions.dpa.confirm.yes"), textButtonNo: t("actions.dpa.confirm.no"), handlerButtonYes: async () => {
                try {
                    const url = `/api/v4/tenants/${tenantId}/service_contracts.pdf`;
                    const pdfTab = window.open("about:blank", "_blank");
                    if (!pdfTab)
                        throw new Error("Failed to open popup");
                    const response = await SamedisApiClient.get(url, null);
                    pdfTab.location.href = response.meta.msg.url;
                }
                catch (error) {
                    await showErrorDialog(pushDialog, error);
                }
            }, handlerButtonNo: () => { } }));
    }, [pushDialog, t, tenantId]);
    const handleCreateDump = useCallback(() => {
        pushDialog(_jsx(ConfirmDialog, { title: t("actions.data-dump.confirm.title"), message: t("actions.data-dump.confirm.message"), textButtonYes: t("actions.data-dump.confirm.yes"), textButtonNo: t("actions.data-dump.confirm.no"), handlerButtonYes: async () => {
                try {
                    await SamedisApiClient.post(`/api/v4/tenants/${tenantId}/export`, null, {});
                    await showInfoDialog(pushDialog, {
                        title: t("actions.data-dump.started.title"),
                        message: t("actions.data-dump.started.message"),
                    });
                }
                catch (e) {
                    await showErrorDialog(pushDialog, e);
                }
            }, handlerButtonNo: () => { } }));
    }, [pushDialog, t, tenantId]);
    const handleDeleteFacility = useCallback(() => {
        pushDialog(_jsx(TenantDeleteConfirmDialog, { tenantId: tenantId, goBack: goBack }));
    }, [pushDialog, tenantId, goBack]);
    const { values: tenant } = useFormContext();
    const tenantBillingModel = useTenantBillingModel();
    const subscriptionPlanModel = useSubscriptionModel();
    const trial = useAsyncMemo(async () => {
        if (!StripePublishableKey)
            return false;
        if (isAdmin)
            return true;
        const [tenantBilling] = await tenantBillingModel.getCached(tenantId);
        const subId = tenantBilling.current_subscription_id;
        if (!subId)
            return true;
        const [subscription] = await subscriptionPlanModel.getCached(subId);
        return subscription.status === "trialing";
    }, [tenantId, isAdmin]);
    const downloadContractDisabled = !tenant?.subscribed_until ||
        tenant.subscribed_until < new Date() ||
        (trial ?? false);
    const downloadContractHidden = isAdmin ||
        !StripePublishableKey ||
        !tenant ||
        tenant.subscription_plan_type === "custom";
    const handleDownloadContract = useCallback(async () => {
        try {
            const url = `/api/v4/tenants/${tenantId}/subscription_reports.pdf`;
            const pdfTab = window.open("about:blank", "_blank");
            if (!pdfTab)
                throw new Error("Failed to open popup");
            const response = await SamedisApiClient.get(url, null);
            pdfTab.location.href = response.meta.msg.url;
        }
        catch (error) {
            await showErrorDialog(pushDialog, error);
        }
    }, [pushDialog, tenantId]);
    return useMemo(() => (_jsx(Popover, { open: !!anchorEl, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
        }, transformOrigin: {
            vertical: "top",
            horizontal: "right",
        }, className: classes.popover, children: _jsx("div", { className: classes.content, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(ActionButton, { icon: _jsx(LegalIcon, {}), onClick: handleCreateDpa, children: t("actions.dpa.action") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(ActionButton, { icon: _jsx(DataDumpIcon, {}), onClick: handleCreateDump, children: t("actions.data-dump.action") }) }), _jsxs(Grid, { item: true, xs: 12, children: [!downloadContractHidden && (_jsx(SubActionButton, { onClick: handleDownloadContract, disabled: downloadContractDisabled, icon: _jsx(LegalIcon, {}), disableDivider: true, children: t("actions.download-contract.action") })), !isAdmin && ( // no API for this in MDM
                            _jsx(SubActionButton, { onClick: handleLinkEnterprise, icon: _jsx(LinkEnterpriseIcon, {}), disableDivider: downloadContractHidden, children: t("actions.link-enterprise.action") })), _jsx(DangerSubActionButton, { icon: _jsx(DeleteIcon, {}), onClick: handleDeleteFacility, disableDivider: downloadContractHidden && isAdmin, children: t("actions.delete-facility.action") })] })] }) }) })), [
        anchorEl,
        classes.content,
        classes.popover,
        downloadContractDisabled,
        downloadContractHidden,
        handleClose,
        handleCreateDpa,
        handleCreateDump,
        handleDeleteFacility,
        handleDownloadContract,
        handleLinkEnterprise,
        isAdmin,
        t,
    ]);
});
export default React.memo(FacilityActionsButton);
