import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ComponentsCareI18n, FullFormDialog as FormDialog, hasPermission, Model, ModelDataTypeBackendMultiSelectRenderer, ModelDataTypeBackendSingleSelectRenderer, ModelDataTypeBooleanCheckboxRendererCC, ModelDataTypeDateNullableRendererCC, ModelDataTypeEnumMultiSelectRenderer, ModelDataTypeEnumSelectRenderer, ModelDataTypeIntegerRendererCC, ModelDataTypeLocalizedStringRenderer, ModelDataTypeStringArrayBackendRenderer, ModelDataTypeStringLocalizedSingleRenderer, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityDisabledReadOnly, ModelVisibilityEdit, ModelVisibilityEditReadOnly, ModelVisibilityEditRequired, ModelVisibilityGridView, ModelVisibilityGridViewHidden, ModelVisibilityHidden, throwError, useDialogContext, usePermissionContext, validateArrayNotEmpty, validateLocalized, validateOptional, validatePresence, validateURL, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import DeviceTypeModel, { useAddDeviceTypeDialog } from "./DeviceTypeModel";
import DeviceModelsCRUD from "../../pages/TenantSpecific/MasterDataManagement/CatalogModels/components/DeviceModels";
import { getLruConfig, handleBackendMultiSelectLoadError } from "../../utils";
import RendererMaintenanceTypes from "../types/renderers/RendererMaintenanceTypes";
import { useTenantId, useTenantIdOpt, } from "../../pages/components/TenantContext";
import RendererDeviceModelGrid from "../types/renderers/RendererDeviceModelGrid";
import ContactModel, { ContactModelSelectorSort, ContactModelToSelectorData, useAddContactDialog, } from "./ContactModel";
import PickContactButton from "../../pages/TenantSpecific/components/Dialogs/PickContactButton";
import RendererRegulatories from "../types/renderers/RendererRegulatories";
import RendererRegionSelect from "../types/renderers/RendererRegionSelect";
import DeviceTagModel, { DeviceTagModelToSelectorData, getDeviceTagModelSort, } from "./DeviceTagModel";
import PickDeviceTagsButton from "../../pages/TenantSpecific/components/Dialogs/PickDeviceTagsButton";
import RendererEnumSelectCustomReadonly from "../types/renderers/RendererEnumSelectCustomReadonly";
import DeviceModelPickDeviceTypeButton from "../../pages/TenantSpecific/MasterDataManagement/CatalogModels/components/DeviceModelPickDeviceTypeButton";
import { useCCLanguagesTranslations } from "components-care/dist/utils/useCCTranslations";
import languagesRaw from "components-care/dist/assets/data/languages.json";
import RendererEmbeddedDeviceTags from "../types/renderers/RendererEmbeddedDeviceTags";
import queryCache from "components-care/dist/backend-integration/Store";
import RendererBackendObjectIdArray from "../types/renderers/RendererBackendObjectIdArray";
import { AUDIT_FIELDS_DEF, ID_FIELD_DEF, INVALID_ID, supportedLanguages, } from "../../constants";
import RendererObjectId from "../types/renderers/RendererObjectId";
import getLocalizedString from "../../utils/getLocalizedString";
import PickDeviceModelButton from "../../pages/TenantSpecific/components/Dialogs/PickDeviceModelButton";
import i18n from "../../i18n";
import RendererImageWithStatus from "../types/renderers/RendererImageWithStatus";
const languages = languagesRaw;
export const RISK_LEVEL_OPTIONS = (t) => ["0", "1", "2"].map((v) => ({
    value: v,
    getLabel: () => t("device-models:risk-level.enum." + v),
}));
export const OPERATOR_ORDINANCE_OPTIONS = (t) => ["none", "annex_1", "annex_2", "annex_1_2"].map((v) => ({
    value: v,
    getLabel: () => t("device-models:operator-ordinance.enum." + v),
}));
export const NETWORK_CONNECTION_OPTIONS = (t) => ["w-lan", "lan", "bluetooth"].map((v) => ({
    value: v,
    getLabel: () => t("device-models:network_connections.enum." + v),
}));
const DeviceModelsModel = (params) => new Model(params.tenantId
    ? params.publishRequested
        ? "device-models-publish-model"
        : "device-models-model"
    : "my-device-models-model", {
    device_picture: {
        type: new RendererImageWithStatus([], {
            uploadLabel: params.t("common:uploads.choose-picture"),
        }, () => null, getDeviceModelImage),
        getLabel: () => params.t("device-models:image"),
        getColumnLabel: () => "",
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: false,
        columnWidth: [60, 60, 60],
    },
    linked_image_id: {
        type: new RendererObjectId(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityHidden,
            edit: ModelVisibilityHidden,
        },
        customData: null,
    },
    tenant_name: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("device-models:tenant-name"),
        visibility: {
            overview: params.publishRequested
                ? ModelVisibilityGridView
                : ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityEditReadOnly,
        },
        customData: null,
        filterable: true,
        sortable: true,
    },
    device_model_combo_search: {
        type: new RendererDeviceModelGrid("title", "version", "device_type_title_labels", "manufacturer_according_to_type_plate", "associated_version_ids"),
        getLabel: () => params.t("device-models:device_model_combo_search.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        filterable: true,
        sortable: true,
        customData: null,
        columnWidth: [80, 400, 260],
    },
    title: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("device-models:device-form-title.placeholder"),
        }),
        getLabel: () => params.t("device-models:device-form-title.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
        validate: validatePresence,
    },
    version: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("device-models:version.placeholder"),
        }),
        getLabel: () => params.t("device-models:version.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityEditReadOnly,
            edit: ModelVisibilityEditReadOnly,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    version_number: {
        type: new ModelDataTypeIntegerRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    version_list: {
        type: new ModelDataTypeStringArrayBackendRenderer(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    published_languages: {
        type: new ModelDataTypeEnumMultiSelectRenderer(languages.map((lang) => ({
            value: lang,
            getLabel: () => params.ccT("languages:" + lang),
        }))),
        getLabel: () => params.t("device-models:published_languages.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        filterable: true,
        columnWidth: [80, Number.MAX_SAFE_INTEGER, 200],
        customData: null,
    },
    associated_version_ids: {
        type: new RendererBackendObjectIdArray(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    device_type_title: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("device-models:device-type.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    device_type_title_labels: {
        type: new ModelDataTypeLocalizedStringRenderer({
            enabledLanguages: supportedLanguages,
        }),
        getLabel: () => params.t("device-models:device-type.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    manufacturer_company_contact_id: {
        type: new ModelDataTypeBackendSingleSelectRenderer({
            modelToSelectorData: ContactModelToSelectorData,
            placeholder: params.t("device-models:manufacturer-according-to-type-plate.placeholder"),
            lru: getLruConfig(params.tenantId, "device-model-manufacturer-company-contact"),
            sort: ContactModelSelectorSort,
            onLoadError: handleBackendMultiSelectLoadError,
            enableIcons: true,
            iconSize: 24,
            onAddNew: params.addContactDialog,
            addNewLabel: params.t("device-models:manufacturer-according-to-type-plate.add-new"),
            endAdornment: (_jsx(PickContactButton, { target: params.target, extraParams: { "filter[contact_types]": ["company"] }, title: params.t("device-models:manufacturer-according-to-type-plate.dialog-title") })),
            // special case MDM admin opens tenant record with tenant contacts, backend does not return those with index
            disableRequestBatching: params.target === "admin",
        }),
        getRelationModel: (_id, values) => ContactModel({
            t: params.t,
            ccT: ComponentsCareI18n.t.bind(ComponentsCareI18n),
            tenantId: params.tenantId ??
                values.tenant_id ??
                throwError("tenant id not set"),
            target: params.target,
            extraParams: {
                "filter[contact_types]": ["company"],
            },
        }),
        getRelationModelValues: ["tenant_id"],
        getLabel: () => params.t("device-models:manufacturer-according-to-type-plate.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        validate: validatePresence,
    },
    manufacturer_according_to_type_plate: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("device-models:manufacturer-according-to-type-plate.placeholder"),
        }),
        getLabel: () => params.t("device-models:manufacturer-according-to-type-plate.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 640, 280],
    },
    responsible_company_contact_id: {
        type: new ModelDataTypeBackendSingleSelectRenderer({
            modelToSelectorData: ContactModelToSelectorData,
            placeholder: params.t("device-models:current-responsible-manufacturer.placeholder"),
            lru: getLruConfig(params.tenantId, "device-model-responsibele-company-contact"),
            sort: ContactModelSelectorSort,
            onLoadError: handleBackendMultiSelectLoadError,
            enableIcons: true,
            iconSize: 24,
            onAddNew: params.addContactDialog,
            addNewLabel: params.t("device-models:current-responsible-manufacturer.add-new"),
            endAdornment: (_jsx(PickContactButton, { target: params.target, extraParams: { "filter[contact_types]": ["company"] }, title: params.t("device-models:current-responsible-manufacturer.dialog-title") })),
            // special case MDM admin opens tenant record with tenant contacts, backend does not return those with index
            disableRequestBatching: params.target === "admin",
        }),
        getRelationModel: (_id, values) => ContactModel({
            t: params.t,
            ccT: ComponentsCareI18n.t.bind(ComponentsCareI18n),
            tenantId: params.tenantId ??
                values.tenant_id ??
                throwError("tenant id not set"),
            target: params.target,
            extraParams: {
                "filter[contact_types]": ["company"],
            },
        }),
        getRelationModelValues: ["tenant_id"],
        getLabel: () => params.t("device-models:current-responsible-manufacturer.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    current_responsible_manufacturer: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("device-models:current-responsible-manufacturer.placeholder"),
        }),
        getLabel: () => params.t("device-models:current-responsible-manufacturer.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 640, 280],
    },
    risk_level: {
        type: new ModelDataTypeEnumSelectRenderer(RISK_LEVEL_OPTIONS(params.t), {
            placeholder: params.t("device-models:risk-level.placeholder"),
        }),
        getLabel: () => params.t("device-models:risk-level.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 640, 270],
        validate: validatePresence,
    },
    device_tag_ids: {
        type: new ModelDataTypeBackendMultiSelectRenderer({
            sort: getDeviceTagModelSort(),
            modelToSelectorData: DeviceTagModelToSelectorData,
            placeholder: params.t("device-models:device_tag_ids.placeholder"),
            searchResultLimit: 50,
            onLoadError: handleBackendMultiSelectLoadError,
            endAdornment: (_jsx(PickDeviceTagsButton, { target: params.target, title: params.t("device-models:device_tag_ids.dialog-title") })),
            lru: getLruConfig(params.tenantId, "device-model.tags"),
        }),
        getLabel: () => params.t("device-models:device_tag_ids.label"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        getRelationModel: () => DeviceTagModel({
            tenantId: params.tenantId,
            t: params.t,
            target: params.target,
        }),
        customData: null,
    },
    operator_ordinance: {
        type: new ModelDataTypeEnumSelectRenderer(OPERATOR_ORDINANCE_OPTIONS(params.t), {
            placeholder: params.t("device-models:operator-ordinance.placeholder"),
        }),
        getLabel: () => params.t("device-models:operator-ordinance.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [160, 160, 160],
    },
    device_type_id: {
        type: new ModelDataTypeBackendSingleSelectRenderer({
            modelToSelectorData: (data) => ({
                value: data.id,
                label: data.title_with_path,
            }),
            enableIcons: true,
            placeholder: params.t("device-models:device-type.placeholder"),
            lru: getLruConfig(params.tenantId, "device-models-device-types"),
            sort: [{ field: "title_with_path", direction: 1 }],
            onLoadError: handleBackendMultiSelectLoadError,
            onAddNew: params.addDeviceTypeDialog,
            addNewLabel: params.t("device-models:device-type.add"),
            endAdornment: _jsx(DeviceModelPickDeviceTypeButton, {}),
        }),
        getRelationModel: () => DeviceTypeModel({
            tenantId: params.tenantId,
            target: params.target,
            extraParams: {
                "filter[scope]": "public_and_tenant",
            },
            t: params.t,
        }),
        getLabel: () => params.t("device-models:device-type.field"),
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        validate: validateArrayNotEmpty,
    },
    manufacturer_product_url_labels: {
        type: new ModelDataTypeStringLocalizedSingleRenderer(),
        getLabel: () => params.t("device-models:manufacturer_product_url.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        validate: validateLocalized(validateOptional(validateURL)),
    },
    manufacturer_service_url_labels: {
        type: new ModelDataTypeStringLocalizedSingleRenderer(),
        getLabel: () => params.t("device-models:manufacturer_service_url.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        validate: validateLocalized(validateOptional(validateURL)),
    },
    is_public: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityHidden,
        },
        customData: null,
    },
    published: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => "",
        getDefaultValue: () => false,
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityHidden,
            edit: ModelVisibilityHidden,
        },
        customData: null,
    },
    affected_tenants: {
        type: new ModelDataTypeIntegerRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    affected_inventory_items: {
        type: new ModelDataTypeIntegerRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    inventory_count: {
        type: new ModelDataTypeIntegerRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    briefing_count: {
        type: new ModelDataTypeIntegerRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    affected_inventories: {
        // on device model merge
        type: new ModelDataTypeIntegerRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    affected_trainings: {
        // on device model merge
        type: new ModelDataTypeIntegerRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    with_service_intervals: {
        type: new RendererMaintenanceTypes({ languages: "context" }),
        getLabel: () => params.t("device-models:service_intervals.field"),
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    manufacturer_warranty_months: {
        type: new ModelDataTypeIntegerRendererCC({
            placeholder: params.t("device-models:manufacturer_warranty_months.placeholder"),
        }),
        getLabel: () => params.t("device-models:manufacturer_warranty_months.field"),
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    stores_patient_data: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => params.t("device-models:stores_patient_data.field"),
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    network_connections: {
        type: new ModelDataTypeEnumMultiSelectRenderer(NETWORK_CONNECTION_OPTIONS(params.t), {
            placeholder: params.t("device-models:network_connections.placeholder"),
            disableSearch: true,
        }),
        getLabel: () => params.t("device-models:network_connections.field"),
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    software_operating_system: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("device-models:software_operating_system.placeholder"),
        }),
        getLabel: () => params.t("device-models:software_operating_system.field"),
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    available_in_countries: {
        type: new RendererRegionSelect(),
        getLabel: () => params.t("device-models:available_in_countries.field"),
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    regulatories: {
        type: new RendererRegulatories({ isMdm: params.target !== "tenant" }),
        getLabel: () => params.t("device-models:regulatories.field"),
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    trust_level: {
        type: new RendererEnumSelectCustomReadonly(["tenant", "supplier", "community", "manufacturer"].map((value) => ({
            value,
            getLabel: () => params.t("device-models:trust_level.enum." + value),
        }))),
        getLabel: () => params.t("device-models:trust_level.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityEditReadOnly,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    embedded_device_tags: {
        type: new RendererEmbeddedDeviceTags(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    end_of_life_at: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("device-models:end_of_life_at.field"),
        getColumnLabel: () => params.t("device-models:end_of_life_at.column"),
        getHeaderName: () => params.t("device-models:end_of_life_at.headername"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [160, 160, 160],
    },
    end_of_life: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => params.t("device-models:end_of_life.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled, // use field_at
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [160, 160, 160],
    },
    end_of_service_at: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("device-models:end_of_service_at.field"),
        getColumnLabel: () => params.t("device-models:end_of_service_at.column"),
        getHeaderName: () => params.t("device-models:end_of_service_at.headername"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [160, 160, 160],
    },
    end_of_service: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => params.t("device-models:end_of_service.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled, // use field_at
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [160, 160, 160],
    },
    tenant_id: {
        type: new RendererObjectId(),
        getLabel: () => params.t("device-models:tenant-id.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    publish_to_community_requested: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: params.publishRequested
                ? ModelVisibilityDisabledReadOnly
                : ModelVisibilityHidden,
            edit: params.publishRequested
                ? ModelVisibilityDisabledReadOnly
                : ModelVisibilityHidden,
        },
        customData: null,
    },
    publish_to_community_decision: {
        type: new ModelDataTypeEnumSelectRenderer(["", "accept", "merge", "deny"].map((value) => ({
            value,
            getLabel: () => value,
        }))),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: params.publishRequested
                ? ModelVisibilityHidden
                : ModelVisibilityDisabled,
            edit: params.publishRequested
                ? ModelVisibilityHidden
                : ModelVisibilityDisabled,
        },
        customData: null,
    },
    publish_to_community_merge_device_model_id: {
        type: new ModelDataTypeBackendSingleSelectRenderer({
            modelToSelectorData: DeviceModelsModelToSelectorData,
            onLoadError: handleBackendMultiSelectLoadError,
            enableIcons: true,
            iconSize: 24,
            sort: DeviceModelsModelSelectorSort(null),
            placeholder: params.t("device-models:publish_to_community_merge_device_model_id.placeholder"),
            endAdornment: (_jsx(PickDeviceModelButton, { title: params.t("device-models:publish_to_community_merge_device_model_id.pick-dialog-title"), target: params.target, scope: "public", moduleName: "device-models-merge", disableInventory: true })),
        }),
        getLabel: () => params.t("device-models:publish_to_community_merge_device_model_id.field"),
        getRelationModel: () => DeviceModelsModel({
            t: params.t,
            ccT: params.ccT,
            tenantId: params.tenantId,
            target: params.target,
            extraParams: {
                "filter[scope]": "public",
            },
        }),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: params.publishRequested
                ? ModelVisibilityEditRequired
                : ModelVisibilityDisabled,
            edit: params.publishRequested
                ? ModelVisibilityEditRequired
                : ModelVisibilityDisabled,
        },
        customData: null,
        validate: (value, values, fieldDef) => {
            if (values.publish_to_community_decision !== "merge")
                return null;
            return validatePresence(value, values, fieldDef);
        },
    },
    publish_to_community_reply: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("device-models:publish_to_community_reply.placeholder"),
            multiline: true,
            rows: 5,
        }),
        getLabel: () => params.t("device-models:publish_to_community_reply.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: params.publishRequested
                ? ModelVisibilityEdit
                : ModelVisibilityDisabled,
            edit: params.publishRequested
                ? ModelVisibilityEdit
                : ModelVisibilityDisabled,
        },
        customData: null,
    },
    ...AUDIT_FIELDS_DEF(params.t, null),
    id: {
        ...ID_FIELD_DEF(params.t),
        customData: null,
    },
}, new BackendConnector(!params.tenantId
    ? params.forceHasInventory
        ? `v4/my/used_device_models`
        : `v4/my/device_models`
    : params.enterpriseClientId
        ? `v4/enterprise/tenants/${params.tenantId}/clients/${params.enterpriseClientId}/device_models`
        : params.target === "tenant"
            ? params.versionId
                ? `v4/tenants/${params.tenantId}/device_model_versions/${params.versionId}/device_models`
                : `v4/tenants/${params.tenantId}/device_models`
            : params.target === "admin"
                ? params.versionId
                    ? `v4/device_model_versions/${params.versionId}/device_models`
                    : params.publishRequested
                        ? "v4/admin/publish_device_models"
                        : "v4/device_models"
                : params.versionId
                    ? `v4/tenants/${params.tenantId}/mdm/device_model_versions/${params.versionId}/device_models`
                    : `v4/tenants/${params.tenantId}/mdm/device_models`, "data", {
    forceFieldFilter: {
        ...(() => {
            // special handling for supporting scope filter in MDM admin context
            // if scope = public, filter is_public true
            // if scope = public_and_tenant do nothing (gives all data)
            // if scope = tenant throw error, unsupported as scope is undefined
            if (params.target !== "admin")
                return null;
            if (!params.extraParams || !params.extraParams["filter[scope]"])
                return null;
            if (params.extraParams["filter[scope]"] === "public_and_tenant")
                return null;
            if (params.extraParams["filter[scope]"] === "public")
                return {
                    is_public: { type: "equals", value1: "true", value2: "" },
                };
            throw new Error("Unsupported scope for admin endpoint");
        })(),
    },
    additionalQueryParameters: {
        ...params.extraParams,
        tenant_id: params.target === "admin" ? params.tenantId : undefined,
        "filter[has_inventory]": params.forceHasInventory ? true : undefined,
    },
}), [params.tenantId, params.target], {
    enableRequestBatching: !!params.versionId, // endpoint does not support show, so just in case
    cacheKeysIndex: [
        params.versionId,
        params.forceHasInventory,
        params.extraParams,
    ],
    hooks: {
        onCreateOrUpdate: ([data]) => {
            // associated versions may be affected
            data.associated_version_ids.forEach((id) => DeviceModelsModel(params).invalidateCacheForId(id));
            queryCache.invalidateQueries(DeviceModelsModel({
                t: params.t,
                ccT: params.ccT,
                tenantId: params.tenantId,
                target: params.target,
                extraParams: {
                    "filter[scope]": "public_and_tenant",
                },
            }).getReactQueryKeyFetchAll({
                fieldFilter: {
                    id: {
                        type: "inSet",
                        value1: [
                            ...data.associated_version_ids,
                            data.id,
                        ]
                            .sort()
                            .join(",") || INVALID_ID,
                        value2: "",
                    },
                },
                sort: [
                    {
                        field: "version_number",
                        direction: 1,
                    },
                ],
            }));
        },
    },
});
export const getDeviceModelNameFromRecord = (data) => {
    return [
        getLocalizedString(null, data.device_type_title_labels),
        data.title,
        data.associated_version_ids.length > 0 && data.version,
        data.current_responsible_manufacturer,
    ]
        .filter(Boolean)
        .join(", ");
};
export const DeviceModelsModelToSelectorData = (data) => ({
    value: data.id,
    label: getDeviceModelNameFromRecord(data),
    icon: getDeviceModelImage(data),
});
export const getDeviceModelImage = (data) => data.device_picture || "/img/placeholders/device.png";
export const getDeviceModelManufacturerLabel = (data) => {
    const mfTP = data.manufacturer_according_to_type_plate ?? "";
    const mfRSP = data.current_responsible_manufacturer ?? "";
    // show in order:
    // - TypePlate (Responsible) - if mismatch
    // - TypePlate - if match or no responsible
    // - Responsible
    return !mfTP
        ? mfRSP
        : !mfRSP
            ? mfTP
            : mfTP !== mfRSP
                ? `${mfTP} (${mfRSP})`
                : mfTP;
};
export const useAddDeviceModelDialog = () => {
    const [pushDialog, popDialog] = useDialogContext();
    const { t } = useTranslation("inventory");
    return useCallback(async () => {
        try {
            const data = await new Promise((resolve, reject) => pushDialog(_jsx(FormDialog, { dialogTitle: t("catalog-id.add-new"), useCustomClasses: true, onClose: reject, children: _jsx(DeviceModelsCRUD, { disableRouting: true, initialView: "new", mode: "tenant", formProps: {
                        onSubmit: (data) => {
                            popDialog();
                            resolve(data);
                        },
                    } }) })));
            return DeviceModelsModelToSelectorData(data);
        }
        catch (_e) {
            return null;
        }
    }, [popDialog, pushDialog, t]);
};
export const DeviceModelsModelSelectorSort = (language) => [
    {
        field: "device_type_title_labels." + (language ?? i18n.language).split("-")[0],
        direction: 1,
    },
    {
        field: "title",
        direction: 1,
    },
    {
        field: "current_responsible_manufacturer",
        direction: 1,
    },
];
export const useDeviceModelsModelTranslations = () => useTranslation(["device-models", "common"]);
export const useDeviceModelsModelCCTranslations = () => useCCLanguagesTranslations();
export const useDeviceModelsModel = (params) => {
    const tenantId = useTenantId();
    const { t } = useDeviceModelsModelTranslations();
    const { t: ccT } = useDeviceModelsModelCCTranslations();
    const [perms] = usePermissionContext();
    const addDeviceTypePerms = hasPermission(perms, params.target === "admin"
        ? "type-catalogs.mdm-admin"
        : params.target === "user"
            ? "type-catalogs.mdm-user"
            : "type-catalogs.tenant-writer");
    const addDeviceTypeDialog = useAddDeviceTypeDialog(params.target);
    const addContactPerms = hasPermission(perms, params.target === "admin"
        ? "contacts.mdm-admin"
        : params.target === "user"
            ? "contacts.mdm-user"
            : "contacts.writer");
    const addContactDialog = useAddContactDialog({
        mode: params.target,
        title: t("device-models:add-manufacturer-contact"),
        forceFields: {
            contact_type: "company",
        },
    });
    return useMemo(() => DeviceModelsModel({
        ...params,
        tenantId,
        t,
        ccT,
        addDeviceTypeDialog: addDeviceTypePerms
            ? addDeviceTypeDialog
            : undefined,
        addContactDialog: addContactPerms ? addContactDialog : undefined,
    }), [
        params,
        tenantId,
        t,
        ccT,
        addDeviceTypePerms,
        addDeviceTypeDialog,
        addContactPerms,
        addContactDialog,
    ]);
};
export const useMyGlobalDeviceModelsModel = (params) => {
    const { t } = useDeviceModelsModelTranslations();
    const { t: ccT } = useDeviceModelsModelCCTranslations();
    return useMemo(() => DeviceModelsModel({
        tenantId: null,
        target: "tenant",
        t,
        ccT,
        ...params,
    }), [t, ccT, params]);
};
export const useDeviceModelsModelOptionalTenant = (params) => {
    const tenantId = useTenantIdOpt();
    const { t } = useDeviceModelsModelTranslations();
    const { t: ccT } = useDeviceModelsModelCCTranslations();
    return useMemo(() => DeviceModelsModel({ tenantId, t, ccT, ...params }), [t, ccT, tenantId, params]);
};
export default DeviceModelsModel;
