import { CountryFlags, Model, ModelDataTypeAny, ModelDataTypeBackendMultiSelectRenderer, ModelDataTypeBackendSingleSelectRenderer, ModelDataTypeBooleanCheckboxRendererCC, ModelDataTypeBooleanSwitchRendererMUI, ModelDataTypeDateNullableRendererCC, ModelDataTypeEnumMultiSelectRenderer, ModelDataTypeEnumSelectRenderer, ModelDataTypeIntegerRendererCC, ModelDataTypeStringArrayBackendRenderer, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityDisabledReadOnly, ModelVisibilityEdit, ModelVisibilityEditReadOnly, ModelVisibilityEditRequired, ModelVisibilityGridView, ModelVisibilityGridViewHidden, ModelVisibilityView, throwError, useDialogContext, validateEmail, validateOptional, validatePresence, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import countryList from "components-care/dist/assets/data/countries.json";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import TenantBillingModel from "./TenantBillingModel";
import RendererCurrencySelect from "../types/renderers/RendererCurrencySelect";
import { getInventoryModelFieldMeta } from "./InventoryModel";
import PlanModel from "./PlanModel";
import { handleBackendMultiSelectLoadError } from "../../utils";
import { ID_FIELD_DEF, StripePublishableKey } from "../../constants";
import { useTenantId } from "../../pages/components/TenantContext";
import { invalidateDashboardData } from "../../api/samedis/Dashboard";
import { useCCTranslationsNS } from "components-care/dist/utils/useCCTranslations";
import ccI18n from "components-care/dist/i18n";
import languagesRaw from "components-care/dist/assets/data/languages.json";
import RendererEnterpriseAccess from "../types/renderers/RendererEnterpriseAccess";
import { showImageEditDialog } from "../../components/ImageEdit/ImageEditDialog";
import RendererObjectId from "../types/renderers/RendererObjectId";
import RendererImageWithStatus from "../types/renderers/RendererImageWithStatus";
const languages = languagesRaw;
const SyncConfigOptions = (t) => ["bidirectional", "to_samedis", "from_samedis"].map((value) => ({
    value,
    getLabel: () => t("tenant:sync_config.direction_enum." + value),
}));
const SyncConfigProps = {
    disableClearable: true,
    disableSearch: true,
};
const getLanguageOptions = (ccT) => {
    const options = ccI18n.getResourceBundle(ccI18n.language, "languages");
    // options might return null when languages are not loaded or fail to load
    // can't do much about it though, except maybe bundle the app differently
    // so in case this is null we do some fallback handling by loading the
    // language list and manually calling ccT which will probably yield
    // fallback labels, but still better than crashing the app
    return (options
        ? Object.keys(options)
            .filter((lang) => lang.length === 2)
            .map((lang) => {
            const langTranslated = options[lang];
            const langNative = options[lang + "-native"];
            const label = langNative
                ? `${langTranslated} (${langNative})`
                : langTranslated;
            return {
                value: lang,
                getLabel: () => label,
            };
        })
        : languages.map((lang) => ({
            value: lang,
            getLabel: () => ccT("languages:" + lang),
        }))).sort((a, b) => a.getLabel().localeCompare(b.getLabel()));
};
const TenantModel = (params) => {
    const languageOptions = getLanguageOptions(params.ccT);
    const isUserView = params.my || params.enterprise;
    return new Model(params.admin
        ? "tenant-overview-model"
        : params.my
            ? "my-tenant-model"
            : params.enterprise
                ? "enterprise-tenant-model"
                : "tenant-model", {
        image: {
            type: new RendererImageWithStatus([], {
                postEditCallback: params.postImageEdit,
            }, () => null, getTenantImage),
            getLabel: () => params.t("tenant:image.field"),
            getColumnLabel: () => "",
            visibility: {
                overview: ModelVisibilityGridView,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
            filterable: false,
            columnWidth: [60, 60],
        },
        name: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:name.placeholder"),
            }),
            getLabel: () => params.t("tenant:name.field"),
            getColumnLabel: () => params.t("tenant:name.column-field"),
            visibility: {
                overview: ModelVisibilityGridView,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            validate: validatePresence,
            filterable: true,
            sortable: true,
            columnWidth: [80, 320, 220],
        },
        name2: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:name2.placeholder"),
            }),
            getLabel: () => params.t("tenant:name2.field"),
            getColumnLabel: () => params.t("tenant:name2.column-field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            validate: validatePresence,
            filterable: true,
            sortable: true,
            columnWidth: [80, 640, 320],
        },
        email: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:email.placeholder"),
            }),
            getLabel: () => params.t("tenant:email.field"),
            visibility: {
                overview: isUserView
                    ? ModelVisibilityGridView
                    : ModelVisibilityGridViewHidden,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            filterable: true,
            sortable: true,
            validate: validateEmail,
            columnWidth: [80, 500, 250],
        },
        phone: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:phone.placeholder"),
            }),
            getLabel: () => params.t("tenant:phone.field"),
            visibility: {
                overview: isUserView
                    ? ModelVisibilityGridView
                    : ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 320, 200],
        },
        tenant_id: {
            type: new RendererObjectId(),
            getLabel: () => params.t("tenant:tenant_id.field"),
            visibility: {
                overview: isUserView
                    ? ModelVisibilityDisabled
                    : ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityView,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [250, 250, 250],
        },
        created_at: {
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => params.t("tenant:created_at.field"),
            visibility: {
                overview: isUserView
                    ? ModelVisibilityDisabled
                    : ModelVisibilityGridView,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 160, 160],
        },
        created_by_user: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("tenant:creator.field"),
            visibility: {
                overview: isUserView
                    ? ModelVisibilityDisabled
                    : ModelVisibilityGridView,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEditReadOnly,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 320, 160],
        },
        street: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:street.placeholder"),
            }),
            getLabel: () => params.t("tenant:street.field"),
            visibility: {
                overview: isUserView
                    ? ModelVisibilityGridView
                    : ModelVisibilityGridViewHidden,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            validate: validatePresence,
            filterable: true,
            sortable: true,
            columnWidth: [80, 640, 320],
        },
        zip: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:zip.placeholder"),
            }),
            getLabel: () => params.t("tenant:zip.field"),
            visibility: {
                overview: isUserView
                    ? ModelVisibilityGridView
                    : ModelVisibilityGridViewHidden,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            validate: validatePresence,
            filterable: true,
            sortable: true,
            columnWidth: [120, 120, 120],
        },
        town: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:town.placeholder"),
            }),
            getLabel: () => params.t("tenant:town.field"),
            visibility: {
                overview: isUserView
                    ? ModelVisibilityGridView
                    : ModelVisibilityGridViewHidden,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            validate: validatePresence,
            filterable: true,
            sortable: true,
            columnWidth: [80, 640, 200],
        },
        country: {
            type: new ModelDataTypeEnumSelectRenderer(countryList.map((countryCode) => ({
                value: countryCode,
                getLabel: () => params.ccT("countries:" + countryCode),
                icon: CountryFlags[countryCode],
            })), {
                enableIcons: true,
                placeholder: params.t("tenant:country.placeholder"),
            }),
            getLabel: () => params.t("tenant:country.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            validate: validatePresence,
            filterable: true,
            sortable: true,
            columnWidth: [80, 640, 200],
        },
        "billing_contact.first_name": {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:billing_contact.first_name.placeholder"),
            }),
            getLabel: () => params.t("tenant:billing_contact.first_name.field"),
            getColumnLabel: () => params.t("tenant:billing_contact.first_name.column-field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 640, 320],
        },
        "billing_contact.last_name": {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:billing_contact.last_name.placeholder"),
            }),
            getLabel: () => params.t("tenant:billing_contact.last_name.field"),
            getColumnLabel: () => params.t("tenant:billing_contact.last_name.column-field"),
            getHeaderName: () => params.t("tenant:billing_contact.last_name.column-field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 640, 320],
        },
        "billing_contact.email": {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:billing_contact.email.placeholder"),
            }),
            getLabel: () => params.t("tenant:billing_contact.email.field"),
            getColumnLabel: () => params.t("tenant:billing_contact.email.column-field"),
            getHeaderName: () => params.t("tenant:billing_contact.email.column-field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            validate: validateOptional(validateEmail),
            filterable: true,
            sortable: true,
            columnWidth: [80, 640, 320],
        },
        "billing_contact.phone": {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:billing_contact.phone.placeholder"),
            }),
            getLabel: () => params.t("tenant:billing_contact.phone.field"),
            getColumnLabel: () => params.t("tenant:billing_contact.phone.column-field"),
            getHeaderName: () => params.t("tenant:billing_contact.phone.column-field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 640, 320],
        },
        "facility_contact.first_name": {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:facility_contact.first_name.placeholder"),
            }),
            getLabel: () => params.t("tenant:facility_contact.first_name.field"),
            getColumnLabel: () => params.t("tenant:facility_contact.first_name.column-field"),
            getHeaderName: () => params.t("tenant:facility_contact.first_name.column-field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            validate: validatePresence,
            filterable: true,
            sortable: true,
            columnWidth: [80, 640, 320],
        },
        "facility_contact.last_name": {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:facility_contact.last_name.placeholder"),
            }),
            getLabel: () => params.t("tenant:facility_contact.last_name.field"),
            getColumnLabel: () => params.t("tenant:facility_contact.last_name.column-field"),
            getHeaderName: () => params.t("tenant:facility_contact.last_name.column-field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            validate: validatePresence,
            filterable: true,
            sortable: true,
            columnWidth: [80, 640, 320],
        },
        "facility_contact.email": {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:facility_contact.email.placeholder"),
            }),
            getLabel: () => params.t("tenant:facility_contact.email.field"),
            getColumnLabel: () => params.t("tenant:facility_contact.email.column-field"),
            getHeaderName: () => params.t("tenant:facility_contact.email.column-field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            validate: validateEmail,
            filterable: true,
            sortable: true,
            columnWidth: [80, 640, 320],
        },
        "facility_contact.phone": {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:facility_contact.phone.placeholder"),
            }),
            getLabel: () => params.t("tenant:facility_contact.phone.field"),
            getColumnLabel: () => params.t("tenant:facility_contact.phone.column-field"),
            getHeaderName: () => params.t("tenant:facility_contact.phone.column-field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 640, 320],
        },
        facility_number: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:facility_number.placeholder"),
            }),
            getLabel: () => params.t("tenant:facility_number.field"),
            visibility: {
                overview: params.admin
                    ? ModelVisibilityGridViewHidden
                    : ModelVisibilityDisabledReadOnly,
                create: params.admin
                    ? ModelVisibilityEdit
                    : ModelVisibilityEditReadOnly,
                edit: params.admin
                    ? ModelVisibilityEdit
                    : ModelVisibilityEditReadOnly,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 640, 200],
        },
        facility_customer_number: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:facility_customer_number.placeholder"),
            }),
            getLabel: () => params.t("tenant:facility_customer_number.field"),
            visibility: {
                overview: params.admin
                    ? ModelVisibilityGridViewHidden
                    : ModelVisibilityDisabledReadOnly,
                create: params.admin
                    ? ModelVisibilityEdit
                    : ModelVisibilityEditReadOnly,
                edit: params.admin
                    ? ModelVisibilityEdit
                    : ModelVisibilityEditReadOnly,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 640, 200],
        },
        supplied_tenant_ids: {
            // form only
            type: new ModelDataTypeBackendMultiSelectRenderer({
                placeholder: params.t("tenant:supplied_tenant_ids.placeholder"),
                enableIcons: true,
                modelToSelectorData: (record) => ({
                    value: record.id,
                    icon: record.image,
                    label: record.name2,
                }),
            }),
            getLabel: () => params.t("tenant:supplied_tenant_ids.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEdit,
            },
            customData: null,
            getRelationModel: (id) => (params.admin
                ? TenantModel(params)
                : TenantModel({ ...params, tenantId: id })),
        },
        language: {
            // form only
            type: new ModelDataTypeEnumSelectRenderer(languageOptions, {
                placeholder: params.t("tenant:language.placeholder"),
                disableClearable: true,
            }),
            getLabel: () => params.t("tenant:language.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
        },
        content_languages: {
            // form only
            type: new ModelDataTypeEnumMultiSelectRenderer(languageOptions, {
                placeholder: params.t("tenant:content_languages.placeholder"),
                disableClearable: true,
            }),
            getLabel: () => params.t("tenant:content_languages.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
        },
        coupling_code: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEdit,
            },
            customData: null,
        },
        enterprise_ids: {
            type: params.enterprise
                ? new RendererEnterpriseAccess(params.tenantId ?? throwError("tenant_id not set"))
                : new ModelDataTypeStringArrayBackendRenderer(),
            getLabel: () => params.t("tenant:connected_column"),
            visibility: {
                overview: params.enterprise
                    ? ModelVisibilityGridView
                    : ModelVisibilityDisabled,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
            columnWidth: [180, 320, 180],
            filterable: false, // "virtual" field
            sortable: false,
        },
        decouple_enterprise_ids: {
            type: new ModelDataTypeStringArrayBackendRenderer(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEdit,
            },
            customData: null,
        },
        enterprise_names: {
            type: new ModelDataTypeAny(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
        },
        subscribed_until: {
            // form only
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => params.t("tenant:subscribed_until.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabled,
                edit: params.admin && StripePublishableKey
                    ? ModelVisibilityEditRequired
                    : ModelVisibilityDisabledReadOnly,
            },
            customData: null,
            filterable: true,
            sortable: true,
            validate: params.admin && StripePublishableKey ? validatePresence : undefined,
            columnWidth: [80, 160, 160],
        },
        subscription_valid_until: {
            // form only
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => params.t("tenant:subscribed_until.field"),
            visibility: {
                overview: isUserView
                    ? ModelVisibilityDisabled
                    : ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 160, 160],
        },
        subscription_plan_name: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:subscription_plan_name.placeholder"),
            }),
            getLabel: () => params.t("tenant:subscription_plan_name.field"),
            getColumnLabel: () => params.t("tenant:subscription_plan_name.column-field"),
            visibility: {
                overview: isUserView
                    ? ModelVisibilityDisabled
                    : ModelVisibilityGridView,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabledReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: null,
            columnWidth: [120, 120, 120],
        },
        subscription_plan_type: {
            type: new ModelDataTypeEnumSelectRenderer(["custom", "trial", "stripe"].map((value) => ({
                value,
                getLabel: () => value,
            }))),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityEditReadOnly,
                create: ModelVisibilityDisabled,
            },
            customData: null,
        },
        plan_id: {
            type: new ModelDataTypeBackendSingleSelectRenderer({
                modelToSelectorData: (data) => ({
                    value: data.id,
                    label: data.name,
                    hidden: data.plan_type === "trial",
                }),
                onLoadError: handleBackendMultiSelectLoadError,
                disableClearable: true,
                disableSearch: true,
                disableRequestBatching: true,
            }),
            getRelationModel: () => PlanModel({
                tenantId: params.admin
                    ? (params.tenantId ?? throwError("tenant id not provided"))
                    : "user-mode",
                extraParams: { "filter[with_custom]": true },
            }),
            getLabel: () => params.t("tenant:subscription_plan_name.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabled,
                edit: StripePublishableKey
                    ? ModelVisibilityEditRequired
                    : ModelVisibilityDisabled,
            },
            customData: null,
            validate: params.admin ? validatePresence : undefined,
        },
        url: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:url.placeholder"),
            }),
            getLabel: () => params.t("tenant:url.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
        },
        fax: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("tenant:fax.placeholder"),
            }),
            getLabel: () => params.t("tenant:fax.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
        },
        trust_level: {
            type: new ModelDataTypeEnumSelectRenderer(["tenant", "supplier", "community", "manufacturer"].map((value) => ({
                value,
                getLabel: () => params.t("tenant:trust_level.values." + value),
            })), { disableClearable: true }),
            getDefaultValue: () => "tenant",
            getLabel: () => params.t("tenant:trust_level.field"),
            visibility: {
                overview: isUserView
                    ? ModelVisibilityDisabled
                    : ModelVisibilityGridView,
                create: params.admin ? ModelVisibilityEdit : ModelVisibilityDisabled,
                edit: params.admin ? ModelVisibilityEdit : ModelVisibilityDisabled,
            },
            customData: null,
            sortable: true,
            filterable: true,
            columnWidth: [160, 160, 160],
        },
        default_currency: {
            type: new RendererCurrencySelect({
                placeholder: params.t("tenant:default_currency.placeholder"),
            }),
            getLabel: () => params.t("tenant:default_currency.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            validate: validatePresence,
        },
        type_catalog_id: {
            type: new RendererObjectId(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
        },
        required_inventory_fields: {
            type: new ModelDataTypeEnumMultiSelectRenderer((() => {
                const { model, requiredFields, optionalFields } = getInventoryModelFieldMeta(params.t);
                return Array.from(requiredFields)
                    .map((field) => ({
                    value: field,
                    getLabel: () => model.fields[field].getLabel(),
                    noDelete: true,
                }))
                    .concat(Array.from(optionalFields).map((field) => ({
                    value: field,
                    getLabel: () => model.fields[field].getLabel(),
                })));
            })(), {
                placeholder: params.t("tenant:required_inventory_fields.placeholder"),
            }),
            getDefaultValue: () => Array.from(getInventoryModelFieldMeta(params.t).defaultFields),
            getLabel: () => params.t("tenant:required_inventory_fields.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            validate: (value) => {
                const { model, requiredFields } = getInventoryModelFieldMeta(params.t);
                for (const requiredField of Array.from(requiredFields)) {
                    if (!value.includes(requiredField)) {
                        return params.t("tenant:required_inventory_fields.validation", {
                            FIELD: model.fields[requiredField].getLabel(),
                        });
                    }
                }
                return null;
            },
        },
        use_extended_device_locations: {
            type: new ModelDataTypeBooleanCheckboxRendererCC(),
            getLabel: () => params.t("tenant:use_extended_device_locations.field"),
            getColumnLabel: () => params.t("tenant:use_extended_device_locations.column"),
            getDefaultValue: () => false,
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: null,
            columnWidth: [120, 120, 120],
        },
        allow_overbooking: {
            type: new ModelDataTypeBooleanCheckboxRendererCC(),
            getLabel: () => params.t("tenant:allow_overbooking.field"),
            getColumnLabel: () => params.t("tenant:allow_overbooking.column"),
            getDefaultValue: () => false,
            visibility: {
                overview: params.admin
                    ? ModelVisibilityGridViewHidden
                    : ModelVisibilityDisabled,
                create: ModelVisibilityDisabled,
                edit: params.admin
                    ? ModelVisibilityEdit
                    : ModelVisibilityDisabledReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: null,
            columnWidth: [120, 120, 120],
        },
        no_of_devices_allowed: {
            // form only
            type: new ModelDataTypeIntegerRendererCC({
                placeholder: params.t("tenant:no_of_devices.placeholder"),
            }),
            getLabel: () => params.t("tenant:no_of_devices.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabled,
                edit: StripePublishableKey
                    ? ModelVisibilityEditReadOnly
                    : ModelVisibilityDisabled,
            },
            customData: null,
            filterable: true,
            sortable: true,
            validate: params.admin
                ? (value, records, def) => {
                    if (records.subscription_plan_name !== "Custom")
                        return null;
                    return validatePresence(value, records, def);
                }
                : undefined,
        },
        devices_allowed_count: {
            // data grid only
            type: new ModelDataTypeIntegerRendererCC(),
            getLabel: () => params.t("tenant:no_of_devices.field"),
            visibility: {
                overview: isUserView
                    ? ModelVisibilityDisabled
                    : ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
            filterable: true,
            sortable: true,
        },
        stripe_customer_id: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("tenant:stripe_customer_id.field"),
            visibility: {
                overview: isUserView
                    ? ModelVisibilityDisabled
                    : ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: StripePublishableKey
                    ? ModelVisibilityView
                    : ModelVisibilityDisabled,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [200, 200, 600],
        },
        internal_label: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("tenant:internal_label.field"),
            visibility: params.admin
                ? {
                    overview: ModelVisibilityGridViewHidden,
                    create: ModelVisibilityEdit,
                    edit: ModelVisibilityEdit,
                }
                : {
                    overview: ModelVisibilityDisabled,
                    create: ModelVisibilityEditReadOnly,
                    edit: ModelVisibilityEditReadOnly,
                },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [200, 200, 200],
        },
        debtor_number: {
            type: new ModelDataTypeIntegerRendererCC(),
            getLabel: () => params.t("tenant:debtor_number.field"),
            visibility: params.admin
                ? {
                    overview: ModelVisibilityGridViewHidden,
                    create: ModelVisibilityEditReadOnly,
                    edit: ModelVisibilityEditReadOnly,
                }
                : {
                    overview: ModelVisibilityDisabled,
                    create: ModelVisibilityDisabled,
                    edit: ModelVisibilityDisabled,
                },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [160, 200, 160],
        },
        // sync API
        "sync_config.locations": {
            type: new ModelDataTypeEnumSelectRenderer(SyncConfigOptions(params.t), SyncConfigProps),
            getLabel: () => params.t("tenant:sync_config.locations.field"),
            visibility: {
                overview: params.enterprise
                    ? ModelVisibilityDisabledReadOnly
                    : ModelVisibilityDisabled,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
        },
        "sync_config.departments": {
            type: new ModelDataTypeEnumSelectRenderer(SyncConfigOptions(params.t), SyncConfigProps),
            getLabel: () => params.t("tenant:sync_config.departments.field"),
            visibility: {
                overview: params.enterprise
                    ? ModelVisibilityDisabledReadOnly
                    : ModelVisibilityDisabled,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
        },
        "sync_config.inventories": {
            type: new ModelDataTypeEnumSelectRenderer(SyncConfigOptions(params.t), SyncConfigProps),
            getLabel: () => params.t("tenant:sync_config.inventories.field"),
            visibility: {
                overview: params.enterprise
                    ? ModelVisibilityDisabledReadOnly
                    : ModelVisibilityDisabled,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
        },
        "sync_config.issues": {
            type: new ModelDataTypeEnumSelectRenderer(SyncConfigOptions(params.t), SyncConfigProps),
            getLabel: () => params.t("tenant:sync_config.issues.field"),
            visibility: {
                overview: params.enterprise
                    ? ModelVisibilityDisabledReadOnly
                    : ModelVisibilityDisabled,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
        },
        "sync_config.staffs": {
            type: new ModelDataTypeEnumSelectRenderer(SyncConfigOptions(params.t), SyncConfigProps),
            getLabel: () => params.t("tenant:sync_config.staffs.field"),
            visibility: {
                overview: params.enterprise
                    ? ModelVisibilityDisabledReadOnly
                    : ModelVisibilityDisabled,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
        },
        "sync_config.trainings": {
            type: new ModelDataTypeEnumSelectRenderer(SyncConfigOptions(params.t), SyncConfigProps),
            getLabel: () => params.t("tenant:sync_config.trainings.field"),
            visibility: {
                overview: params.enterprise
                    ? ModelVisibilityDisabledReadOnly
                    : ModelVisibilityDisabled,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
        },
        "sync_config.incidents": {
            type: new ModelDataTypeEnumSelectRenderer(SyncConfigOptions(params.t), SyncConfigProps),
            getLabel: () => params.t("tenant:sync_config.incidents.field"),
            visibility: {
                overview: params.enterprise
                    ? ModelVisibilityDisabledReadOnly
                    : ModelVisibilityDisabled,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
        },
        "sync_config.security_messages_enabled": {
            type: new ModelDataTypeBooleanSwitchRendererMUI(),
            getLabel: () => params.t("tenant:sync_config.security_messages_enabled.field"),
            visibility: {
                overview: params.enterprise
                    ? ModelVisibilityDisabledReadOnly
                    : ModelVisibilityDisabled,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
        },
        // statistics
        exclude_from_bi: {
            type: new ModelDataTypeBooleanSwitchRendererMUI(false, {
                switchProps: {
                    color: "primary",
                },
            }),
            getLabel: () => params.t("tenant:exclude-from-BI.field"),
            visibility: {
                overview: isUserView
                    ? ModelVisibilityDisabled
                    : ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: null,
        },
        inventory_count: {
            type: new ModelDataTypeIntegerRendererCC(),
            getLabel: () => params.t("tenant:inventory_count.field"),
            visibility: {
                overview: isUserView
                    ? ModelVisibilityDisabled
                    : ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [160, 160, 160],
        },
        "statistic.inventories": {
            type: new ModelDataTypeIntegerRendererCC(),
            getLabel: () => params.t("tenant:inventory_count.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityView,
                edit: ModelVisibilityView,
            },
            customData: null,
        },
        issue_count: {
            type: new ModelDataTypeIntegerRendererCC(),
            getLabel: () => params.t("tenant:issue_count.field"),
            visibility: {
                overview: isUserView
                    ? ModelVisibilityDisabled
                    : ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [160, 160, 160],
        },
        "statistic.issues": {
            type: new ModelDataTypeIntegerRendererCC(),
            getLabel: () => params.t("tenant:issue_count.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityView,
                edit: ModelVisibilityView,
            },
            customData: null,
        },
        staff_count: {
            type: new ModelDataTypeIntegerRendererCC(),
            getLabel: () => params.t("tenant:staff_count.field"),
            visibility: {
                overview: isUserView
                    ? ModelVisibilityDisabled
                    : ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [160, 160, 160],
        },
        "statistic.staffs": {
            type: new ModelDataTypeIntegerRendererCC(),
            getLabel: () => params.t("tenant:staff_count.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityView,
                edit: ModelVisibilityView,
            },
            customData: null,
        },
        briefing_count: {
            type: new ModelDataTypeIntegerRendererCC(),
            getLabel: () => params.t("tenant:briefing_count.field"),
            visibility: {
                overview: isUserView
                    ? ModelVisibilityDisabled
                    : ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [160, 160, 160],
        },
        "statistic.briefings": {
            type: new ModelDataTypeIntegerRendererCC(),
            getLabel: () => params.t("tenant:briefing_count.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityView,
                edit: ModelVisibilityView,
            },
            customData: null,
        },
        id: {
            ...ID_FIELD_DEF(params.t),
            customData: null,
        },
    }, new BackendConnector(params.admin
        ? "v4/admin/tenants"
        : params.enterprise
            ? `v4/enterprise/tenants/${params.tenantId}/clients`
            : params.my
                ? "v4/my/tenants"
                : params.tenantId
                    ? `v4/tenants/${params.tenantId}/supplied_tenants`
                    : "v4/user/tenants", "data", {
        additionalQueryParameters: params.admin
            ? { tenant_id: params.tenantId }
            : undefined,
    }), {
        my: params.my,
        enterprise: params.enterprise,
        admin: params.admin,
        tenantId: params.tenantId,
    }, {
        hooks: {
            onCreateOrUpdate: ([tenant]) => {
                // backend updates tenant billing data based on this, so invalidate that data here
                TenantBillingModel({
                    t: params.t,
                    ccT: params.ccT,
                    tenantId: tenant.id,
                    admin: params.admin || false,
                }).invalidateCacheForId(tenant.id);
                // incomplete inventory definition
                invalidateDashboardData(tenant.id);
            },
        },
    });
};
export const getTenantImage = (data) => data.image || "/img/placeholders/company.png";
export const useTenantModelTranslations = () => useTranslation(["tenant", "inventory"]);
export const useTenantModelCCTranslations = () => useCCTranslationsNS(["countries", "languages"]);
export const useTenantDialogs = () => {
    const { t } = useTenantModelTranslations();
    const [pushDialog] = useDialogContext();
    return useMemo(() => ({
        postImageEdit: (image) => showImageEditDialog(pushDialog, image, t("tenant:image.edit-title")),
    }), [pushDialog, t]);
};
export const useTenantModel = (params) => {
    const { t } = useTenantModelTranslations();
    const { t: ccT } = useTenantModelCCTranslations();
    const dialogs = useTenantDialogs();
    return useMemo(() => TenantModel({
        ...dialogs,
        ...params,
        t,
        ccT,
    }), [dialogs, params, t, ccT]);
};
export const useMyTenantModel = () => {
    const { t } = useTenantModelTranslations();
    const { t: ccT } = useTenantModelCCTranslations();
    const dialogs = useTenantDialogs();
    return useMemo(() => TenantModel({
        ...dialogs,
        t,
        ccT,
        my: true,
    }), [dialogs, t, ccT]);
};
export const useEnterpriseTenantModel = () => {
    const { t } = useTenantModelTranslations();
    const { t: ccT } = useTenantModelCCTranslations();
    const dialogs = useTenantDialogs();
    const tenantId = useTenantId();
    return useMemo(() => TenantModel({
        ...dialogs,
        t,
        ccT,
        enterprise: true,
        tenantId,
    }), [dialogs, t, ccT, tenantId]);
};
export const useTenantAdminModel = () => {
    const { t } = useTenantModelTranslations();
    const { t: ccT } = useTenantModelCCTranslations();
    const dialogs = useTenantDialogs();
    const tenantId = useTenantId();
    return useMemo(() => TenantModel({
        ...dialogs,
        t,
        ccT,
        admin: true,
        tenantId,
    }), [dialogs, t, ccT, tenantId]);
};
export default TenantModel;
