import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Business as BusinessIcon } from "@mui/icons-material";
import SamedisInfoBox from "../../../../../components/SamedisInfoBox";
import { FormField } from "components-care";
import MedicalDeviceIcon from "../../../../../components/icons/MedicalDeviceIcon";
const FacilitySettingsTab = () => {
    const { t } = useTranslation("tenant");
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, lg: 6, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(SamedisInfoBox, { title: t("required_inventory_fields.info.title"), icon: MedicalDeviceIcon, contentType: "paragraphs", content: [
                                    t("required_inventory_fields.info.block1"),
                                    t("required_inventory_fields.info.block2"),
                                ] }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "required_inventory_fields" }) })] }) }), _jsx(Grid, { item: true, xs: 12, lg: 6, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(SamedisInfoBox, { title: t("supplied_tenant_ids.info.title"), icon: MedicalDeviceIcon, contentType: "paragraphs", content: [
                                    t("supplied_tenant_ids.info.block1"),
                                    t("supplied_tenant_ids.info.block2"),
                                ] }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "supplied_tenant_ids" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "content_languages" }) })] }) }), _jsx(Grid, { item: true, xs: 12, lg: 6, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(SamedisInfoBox, { title: null, icon: BusinessIcon, contentType: "paragraphs", content: [
                                    t("use_extended_device_locations.info.block1"),
                                    t("use_extended_device_locations.info.block2"),
                                ] }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "use_extended_device_locations" }) })] }) })] }));
};
export default React.memo(FacilitySettingsTab);
