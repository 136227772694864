import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FullFormDialog as FormDialog, Model, ModelDataTypeBackendSingleSelectRenderer, ModelDataTypeBooleanCheckboxRendererCC, ModelDataTypeIntegerRendererCC, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityDisabledReadOnly, ModelVisibilityEdit, ModelVisibilityEditReadOnly, ModelVisibilityEditRequired, ModelVisibilityGridView, ModelVisibilityGridViewHidden, useDialogContext, useModelGet, validatePresence, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import { useTenantId } from "../../pages/components/TenantContext";
import { AUDIT_FIELDS_DEF, ID_FIELD_DEF } from "../../constants";
import DeviceLocationPropertyModel from "./DeviceLocation/DeviceLocationPropertyModel";
import RendererObjectId from "../types/renderers/RendererObjectId";
import DeviceLocationBuildingModel from "./DeviceLocation/DeviceLocationBuildingModel";
import DeviceLocationFloorModel from "./DeviceLocation/DeviceLocationFloorModel";
import { useTenantModel } from "./TenantModel";
const DeviceLocationsCRUD = React.lazy(() => import("../../pages/TenantSpecific/Facility/DeviceLocations/DeviceLocations"));
const DeviceLocationModel = (params) => new Model("device-location-model", {
    title: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("device-locations:title.placeholder"),
        }),
        getLabel: () => params.t("device-locations:title.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 420, 260],
        validate: validatePresence,
    },
    path: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("device-locations:path.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 1000, 620],
    },
    notes: {
        type: new ModelDataTypeStringRendererCC({
            multiline: true,
            rows: 5,
            placeholder: params.t("device-locations:notes.placeholder"),
        }),
        getLabel: () => params.t("device-locations:notes.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 640, 320],
    },
    inventory_count: {
        type: new ModelDataTypeIntegerRendererCC(),
        getLabel: () => params.t("device-locations:inventories.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 120, 120],
    },
    is_active: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => params.t("device-locations:is_active.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        getDefaultValue: () => true,
        filterable: true,
        sortable: true,
        columnWidth: [100, 100, 100],
    },
    // frontend form only
    property_id: params.tenantId
        ? {
            type: new ModelDataTypeBackendSingleSelectRenderer({
                modelToSelectorData: (data) => ({
                    value: data.id,
                    label: data.title,
                }),
            }),
            getLabel: () => params.t("device-locations:property_id.field"),
            getRelationModel: () => DeviceLocationPropertyModel({
                tenantId: params.tenantId,
                t: params.t,
            }),
            onChange: (value, _, setFieldValue) => {
                setFieldValue("building_id", null);
                setFieldValue("floor_id", null);
                return value;
            },
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            validate: params.extendedLocationMode
                ? validatePresence
                : undefined,
        }
        : {
            type: new RendererObjectId(),
            getLabel: () => params.t("device-locations:property_id.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
        },
    // frontend form only
    building_id: params.tenantId
        ? {
            type: new ModelDataTypeBackendSingleSelectRenderer({
                modelToSelectorData: (data) => ({
                    value: data.id,
                    label: data.title,
                }),
            }),
            getLabel: () => params.t("device-locations:building_id.field"),
            getRelationModel: (_, values) => DeviceLocationBuildingModel({
                tenantId: params.tenantId,
                t: params.t,
                filters: {
                    "filter[property_ids]": values.property_id,
                },
            }),
            getRelationModelValues: ["property_id"],
            onChange: (value, _, setFieldValue) => {
                setFieldValue("floor_id", null);
                return value;
            },
            visibility: {
                overview: ModelVisibilityDisabled,
                create: (values) => values.property_id
                    ? ModelVisibilityEditRequired
                    : ModelVisibilityEditReadOnly,
                edit: (values) => values.property_id
                    ? ModelVisibilityEditRequired
                    : ModelVisibilityEditReadOnly,
            },
            customData: null,
            validate: params.extendedLocationMode
                ? validatePresence
                : undefined,
        }
        : {
            type: new RendererObjectId(),
            getLabel: () => params.t("device-locations:floor_id.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
        },
    floor_id: params.tenantId
        ? {
            type: new ModelDataTypeBackendSingleSelectRenderer({
                modelToSelectorData: (data) => ({
                    value: data.id,
                    label: data.title,
                }),
            }),
            getLabel: () => params.t("device-locations:floor_id.field"),
            getRelationModel: (_, values) => DeviceLocationFloorModel({
                tenantId: params.tenantId,
                t: params.t,
                filters: {
                    "filter[building_ids]": values.building_id,
                },
            }),
            getRelationModelValues: ["building_id"],
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: (values) => values.building_id
                    ? ModelVisibilityEditRequired
                    : ModelVisibilityEditReadOnly,
                edit: (values) => values.building_id
                    ? ModelVisibilityEditRequired
                    : ModelVisibilityEditReadOnly,
            },
            customData: null,
            validate: params.extendedLocationMode
                ? validatePresence
                : undefined,
        }
        : {
            type: new RendererObjectId(),
            getLabel: () => params.t("device-locations:floor_id.field"),
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
        },
    ...AUDIT_FIELDS_DEF(params.t, null),
    id: {
        ...ID_FIELD_DEF(params.t),
        customData: null,
    },
}, new BackendConnector(params.tenantId
    ? params.enterpriseClientId
        ? `v4/enterprise/tenants/${params.tenantId}/clients/${params.enterpriseClientId}/device_locations`
        : `v4/tenants/${params.tenantId}/device_locations`
    : "v4/my/device_locations", "data"), [params.tenantId, params.enterpriseClientId]);
export const DeviceLocationModelToSelectorData = (data) => ({
    value: data.id,
    label: data.path,
});
export const DeviceLocationModelSelectorSort = [
    {
        field: "title",
        direction: 1,
    },
];
export const useAddDeviceLocationDialog = () => {
    const [pushDialog, popDialog] = useDialogContext();
    const { t } = useTranslation("inventory");
    return useCallback(async () => {
        try {
            const data = await new Promise((resolve, reject) => pushDialog(_jsx(FormDialog, { dialogTitle: t("device-location-id.add-new"), maxWidth: "sm", useCustomClasses: true, onClose: reject, children: _jsx(DeviceLocationsCRUD, { disableRouting: true, initialView: "new", formProps: {
                        onSubmit: (data) => {
                            popDialog();
                            resolve(data);
                        },
                    } }) })));
            return {
                value: data.id,
                label: data.title,
            };
        }
        catch (_e) {
            return null;
        }
    }, [popDialog, pushDialog, t]);
};
export const useExtendedLocationMode = () => {
    const tenantId = useTenantId();
    const model = useTenantModel();
    const { data } = useModelGet(model, tenantId);
    return data ? data[0].use_extended_device_locations : null;
};
export const useDeviceLocationModelTranslations = () => useTranslation(["device-locations", "common"]);
export const useDeviceLocationModel = (params) => {
    const tenantId = useTenantId();
    const { t } = useDeviceLocationModelTranslations();
    return useMemo(() => DeviceLocationModel({
        tenantId,
        t,
        ...params,
    }), [tenantId, t, params]);
};
export default DeviceLocationModel;
