import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useFacilityContentModel } from "../../../../../../components-care/models/FacilityContentModel";
import { Loader, useModelFetchAll } from "components-care";
import { Grid, Typography } from "@mui/material";
import Video from "./Video";
const VideoList = (props) => {
    const { recordId, category, scope } = props;
    const model = useFacilityContentModel();
    const { isLoading, data, error } = useModelFetchAll(model, {
        fieldFilter: {
            content_type: {
                type: "equals",
                value1: "link",
                value2: "",
            },
            category: {
                type: "equals",
                value1: category ?? "",
                value2: "",
            },
            /*language: { // should probably just fetch all and do client side filtering
              type: "equals",
              value1: language,
              value2: "",
            },*/
            is_public: {
                type: "equals",
                value1: scope === "public" ? "true" : scope === "tenant" ? "false" : "",
                value2: "",
            },
            device_model_ids: {
                type: "equals",
                value1: recordId,
                value2: "",
            },
        },
        additionalFilters: {
            "filter[video]": true,
            "filter[scope]": scope,
        },
    });
    if (error)
        return _jsx(Typography, { color: "error", children: error.message });
    if (isLoading || !data)
        return _jsx(Loader, {});
    return (_jsx(Grid, { container: true, spacing: 2, direction: "column", children: data[0].map((record) => {
            const video = record;
            return (_jsx(Grid, { item: true, children: _jsx(Video, { thumbnail: video.thumbnail_cached, title: video.resource.title, url: video.url }) }, video.id));
        }) }));
};
export default React.memo(VideoList);
