import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import { BackendDataGrid, } from "components-care";
import { useIsMobile } from "../utils";
import SamedisMobileBackendDataGrid from "./SamedisMobileBackendDataGrid";
export const SamedisBackendDataGridContext = React.createContext(undefined);
const SamedisBackendDataGrid = (props) => {
    const isMobile = useIsMobile();
    const mobileRenderer = useContext(SamedisBackendDataGridContext);
    if (isMobile && mobileRenderer) {
        return (_jsx(SamedisMobileBackendDataGrid, { ...props, ...mobileRenderer }));
    }
    return _jsx(BackendDataGrid, { ...props });
};
export default React.memo(SamedisBackendDataGrid);
