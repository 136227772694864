import { jsx as _jsx } from "react/jsx-runtime";
import { FullFormDialog as FormDialog, Model, ModelDataTypeBackendMultiSelectRenderer, ModelDataTypeBackendSingleSelectRenderer, ModelDataTypeEnumSelectRenderer, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityDisabledReadOnly, ModelVisibilityEdit, ModelVisibilityEditRequired, ModelVisibilityGridView, useDialogContext, validatePresence, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import EmployeeModel, { getEmployeeLabel, EmployeeModelSelectorSort, EmployeeModelToSelectorData, useAddEmployeeDialog, } from "./EmployeeModel";
import DeviceModelsModel, { DeviceModelsModelSelectorSort, DeviceModelsModelToSelectorData, } from "./DeviceModelsModel";
import { getLruConfig, handleBackendMultiSelectLoadError } from "../../utils";
import { useTranslation } from "react-i18next";
import React, { useCallback, useMemo } from "react";
import PickDeviceModelsButton from "../../pages/TenantSpecific/components/Dialogs/PickDeviceModelsButton";
import PickEmployeesButton from "../../pages/TenantSpecific/components/Dialogs/PickEmployeesButton";
import { useTenantId } from "../../pages/components/TenantContext";
import ccI18n from "components-care/dist/i18n";
import RendererBackendObjectIdArray from "../types/renderers/RendererBackendObjectIdArray";
import { AUDIT_FIELDS_DEF, ID_FIELD_DEF } from "../../constants";
import { ContactModelSelectorSort } from "./ContactModel";
import PickEmployeeButton from "../../pages/TenantSpecific/components/Dialogs/PickEmployeeButton";
import RendererObjectId from "../types/renderers/RendererObjectId";
import { hasPermission, usePermissionContext } from "components-care/dist";
const TrainingGroupCRUD = React.lazy(() => import("../../pages/TenantSpecific/Training/Groups/TrainingGroups"));
export const TrainingGroupTypeOptions = (t) => ["staff", "device", "both"].map((value) => ({
    value,
    getLabel: () => t("team:type.enum." + value),
}));
const TrainingGroupModel = (params) => new Model("team-model", {
    type: {
        type: new ModelDataTypeEnumSelectRenderer(TrainingGroupTypeOptions(params.t), {
            disableSearch: true,
            disableClearable: true,
            placeholder: params.t("team:type.placeholder"),
        }),
        getDefaultValue: () => TrainingGroupTypeOptions(params.t)[2].value,
        getLabel: () => params.t("team:type.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        filterable: true,
        sortable: true,
        customData: null,
        validate: validatePresence,
        columnWidth: [80, 320, 180],
    },
    name: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("team:name.placeholder"),
        }),
        getLabel: () => params.t("team:name.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 180],
        validate: validatePresence,
    },
    manager: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("team:manager.placeholder"),
        }),
        getLabel: () => params.t("team:manager.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 180],
    },
    description: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("team:description.placeholder"),
        }),
        getLabel: () => params.t("team:description.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 640, 320],
    },
    responsible_id: {
        type: new ModelDataTypeBackendSingleSelectRenderer({
            modelToSelectorData: EmployeeModelToSelectorData,
            placeholder: params.t("team:responsible_id.placeholder"),
            lru: getLruConfig(params.tenantId, "team-responsible"),
            sort: ContactModelSelectorSort,
            onLoadError: handleBackendMultiSelectLoadError,
            enableIcons: true,
            iconSize: 24,
            onAddNew: params.dialogParams?.addStaffDialog || undefined,
            endAdornment: (_jsx(PickEmployeeButton, { team: true, title: params.t("team:responsible_id.dialog-title") })),
        }),
        getRelationModel: () => EmployeeModel({
            t: params.t,
            tenantId: params.tenantId,
            teamResponsible: true,
        }),
        getLabel: () => params.t("team:responsible_id.field"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityEdit,
            create: ModelVisibilityEdit,
        },
        customData: null,
        onChange: (value, _, setFieldValue, getFieldValue) => {
            if (getFieldValue("manager"))
                return value;
            if (value) {
                (async () => {
                    const model = EmployeeModel({
                        t: params.t,
                        tenantId: params.tenantId,
                        teamResponsible: true,
                    });
                    const [data] = await model.getCached(value);
                    setFieldValue("manager", getEmployeeLabel(data, false, false));
                })();
            }
            else {
                setFieldValue("manager", "");
            }
            return value;
        },
    },
    staff_ids: {
        type: params.tenantId
            ? new ModelDataTypeBackendMultiSelectRenderer({
                modelToSelectorData: EmployeeModelToSelectorData,
                placeholder: params.t("team:staff-selector.placeholder"),
                lru: getLruConfig(params.tenantId, "staff-groups"),
                sort: EmployeeModelSelectorSort,
                onLoadError: handleBackendMultiSelectLoadError,
                enableIcons: true,
                iconSize: 24,
                onAddNew: params.dialogParams?.addStaffDialog || undefined,
                endAdornment: (_jsx(PickEmployeesButton, { title: params.t("team:staff-selector.dialog-title") })),
            })
            : new RendererBackendObjectIdArray(),
        getRelationModel: params.tenantId
            ? () => EmployeeModel({
                t: params.t,
                tenantId: params.tenantId,
            })
            : undefined,
        getLabel: () => params.t("team:staff-selector.label"),
        visibility: {
            overview: ModelVisibilityDisabled,
            create: params.tenantId
                ? ModelVisibilityEdit
                : ModelVisibilityDisabled,
            edit: params.tenantId ? ModelVisibilityEdit : ModelVisibilityDisabled,
        },
        customData: null,
    },
    catalog_ids: {
        type: new ModelDataTypeBackendMultiSelectRenderer({
            modelToSelectorData: (data) => ({
                ...DeviceModelsModelToSelectorData(data),
                onClick: params.openDeviceDialog
                    ? () => params.openDeviceDialog &&
                        params.openDeviceDialog(data.id)
                    : undefined,
            }),
            placeholder: params.t("team:device-selector.placeholder"),
            lru: getLruConfig(params.tenantId, "device-groups"),
            sort: DeviceModelsModelSelectorSort(null),
            onLoadError: handleBackendMultiSelectLoadError,
            endAdornment: (_jsx(PickDeviceModelsButton, { target: "tenant", scope: "public_and_tenant", enableTenantFilters: true, moduleName: "team-device-selector", title: params.t("team:device-selector.dialog") })),
            enableIcons: true,
            iconSize: 24,
        }),
        getRelationModel: () => DeviceModelsModel({
            tenantId: params.tenantId,
            t: params.t,
            ccT: ccI18n.t,
            target: "tenant",
            extraParams: {
                "filter[scope]": "public_and_tenant",
                "filter[published]": true,
            },
        }),
        getLabel: () => params.t("team:device-selector.label"),
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    tenant_id: {
        type: new RendererObjectId(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
    },
    ...AUDIT_FIELDS_DEF(params.t, null),
    id: {
        ...ID_FIELD_DEF(params.t),
        customData: null,
    },
}, new BackendConnector(params.tenantId
    ? params.enterpriseClientId
        ? `v4/enterprise/tenants/${params.tenantId}/clients/${params.enterpriseClientId}/teams`
        : params.enterpriseClients
            ? `v4/enterprise/tenants/${params.tenantId}/teams`
            : `v4/tenants/${params.tenantId}/teams`
    : "v4/my/teams", "data", {
    includedRelations: params.include
        ? Object.fromEntries(params.include.map((item) => [
            item + "_ids",
            [item, item + "s"],
        ]))
        : undefined,
    additionalQueryParameters: { ...params.extraParams },
    forceFieldFilter: params.tenantFilter
        ? {
            tenant_id: {
                type: "equals",
                value1: params.tenantFilter,
                value2: "",
            },
        }
        : undefined,
}), [
    params.tenantId,
    params.enterpriseClientId,
    params.enterpriseClients,
    params.include,
]);
export const TrainingGroupModelSelectorSort = [
    { field: "name", direction: 1 },
];
export const TrainingGroupModelToSelectorData = (data) => ({
    value: data.id,
    label: data.name,
});
export const useTrainingGroupModelTranslations = () => useTranslation(["common", "team"]);
export const useTrainingGroupModel = (params) => {
    const tenantId = useTenantId();
    const { t } = useTrainingGroupModelTranslations();
    const addStaff = useAddEmployeeDialog();
    const [perms] = usePermissionContext();
    const addStaffPerms = hasPermission(perms, "staff.writer");
    return useMemo(() => TrainingGroupModel({
        tenantId,
        t,
        dialogParams: { addStaffDialog: addStaffPerms ? addStaff : undefined },
        ...params,
    }), [tenantId, t, addStaffPerms, addStaff, params]);
};
export const useAddTrainingGroupDialog = () => {
    const [pushDialog, popDialog] = useDialogContext();
    const { t } = useTranslation("inventory");
    return useCallback(async () => {
        try {
            const data = await new Promise((resolve, reject) => pushDialog(_jsx(FormDialog, { dialogTitle: t("groups.add-new"), useCustomClasses: true, onClose: reject, children: _jsx(TrainingGroupCRUD, { disableRouting: true, initialView: "new", formProps: {
                        onSubmit: (data) => {
                            popDialog();
                            resolve(data);
                        },
                    } }) })));
            return {
                value: data.id,
                label: data.name,
            };
        }
        catch (_e) {
            return null;
        }
    }, [popDialog, pushDialog, t]);
};
export const StaffTeamImage = "/img/placeholders/person.png";
export const DeviceTeamImage = "/img/placeholders/device.png";
export const MixedTeamImage = "/img/placeholders/device-and-person.svg";
export default TrainingGroupModel;
