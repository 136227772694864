import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormHelperText } from "@mui/material";
import TypeStringArray from "components-care/dist/backend-integration/Model/Types/TypeStringArray";
import MaintenanceTypesSelector from "../../../components/MaintenanceTypesSelector";
/**
 * Renders a maintenance types multi select
 */
class RendererMaintenanceTypes extends TypeStringArray {
    params;
    constructor(params) {
        super();
        this.params = params;
    }
    render(params) {
        const { visibility, field, value, label, handleChange, errorMsg } = params;
        if (visibility.disabled)
            return _jsx(_Fragment, {});
        if (visibility.hidden) {
            return (_jsx("input", { type: "hidden", name: field, value: this.stringify(value), readOnly: true, "aria-hidden": "true" }));
        }
        if (visibility.editable) {
            if (visibility.grid)
                throw new Error("Not supported");
            return (_jsxs(_Fragment, { children: [_jsx(MaintenanceTypesSelector, { ...this.params, label: label, selected: value, onSelect: (newValues) => {
                            handleChange(field, newValues);
                        }, disabled: visibility.readOnly, required: visibility.required, error: !!params.errorMsg, warning: !!params.warningMsg }), _jsx(FormHelperText, { error: !!errorMsg, children: errorMsg })] }));
        }
        throw new Error("RendererMaintenanceTypes: Pure view not supported");
    }
}
export default RendererMaintenanceTypes;
