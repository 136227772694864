import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { ArrowForwardIos as ArrowRightIcon, Menu as MenuIcon, } from "@mui/icons-material";
import { DefaultFormPage, FormField, } from "components-care/dist";
import { useTranslation } from "react-i18next";
import BasicPageLayout from "../../../../../components/BasicPageLayout";
import BoxGrid from "../../../../../components/BoxGrid";
import { ActionButton, throwError, useFormContext } from "components-care";
import ContactActionsButton from "./ContactActionsButton";
import { useContactsCrudContext } from "./Contacts";
import { ContactHasAccountStatusFromForm } from "./ContactHasAccountStatus";
import CompanyContactList from "./CompanyContactList";
import SamedisMobileFormButtonsPortal, { SamedisMobileFormButtonsPortalBoxGridRenderer, SamedisMobileFormButtonsPortalNullRenderer, } from "../../../../../components-care/SamedisMobileFormButtonsPortal";
import usePageContainerSpacing from "../../../../../utils/usePageContainerSpacing";
import { IsInFormDialogContext } from "components-care/dist/backend-components/Form/FormDialog";
import SamedisCrudFileUpload from "../../../../../components-care/SamedisCrudFileUpload";
import { useTenantId } from "../../../../components/TenantContext";
const ContactFormContent = () => {
    const { t } = useTranslation("contact");
    const { id, values, setCustomReadOnly, removeCustomReadOnly, customProps, readOnly, } = useFormContext();
    const { open } = customProps;
    const tenantId = useTenantId();
    const contactType = values.contact_type;
    const status = values.status;
    const companyId = values.company_id;
    const context = useContactsCrudContext();
    const isMdm = context.mode !== "tenant";
    const pageSpacing = usePageContainerSpacing();
    const inDialog = useContext(IsInFormDialogContext);
    useEffect(() => {
        if (isMdm)
            return;
        if (!id)
            return;
        if (status === "tenant")
            return;
        setCustomReadOnly("public-contact");
        return () => {
            removeCustomReadOnly("public-contact");
        };
    }, [id, isMdm, removeCustomReadOnly, setCustomReadOnly, status]);
    const openCompany = useCallback(() => {
        if (!companyId)
            return;
        open(companyId);
    }, [companyId, open]);
    const showGoToCompany = !inDialog && id;
    const showContactList = !inDialog && id;
    return useMemo(() => (_jsxs(_Fragment, { children: [_jsxs(Grid, { item: true, xs: 12, sm: 6, md: 8, container: true, spacing: pageSpacing, children: [_jsxs(BoxGrid, { item: true, xs: 12, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "contact_type" }) }), contactType === "person" && (_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "salutation" }) })), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "last_name", overrides: (prev) => {
                                        const props = prev.type
                                            .props;
                                        const suffix = contactType === "company" ? "-alt" : "";
                                        if (props) {
                                            props.placeholder = t("last_name.placeholder" + suffix);
                                        }
                                        prev.getLabel = () => t("last_name.field" + suffix);
                                        return prev;
                                    } }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "first_name", overrides: (prev) => {
                                        const props = prev.type
                                            .props;
                                        const suffix = contactType === "company" ? "-alt" : "";
                                        if (props) {
                                            props.placeholder = t("first_name.placeholder" + suffix);
                                        }
                                        prev.getLabel = () => t("first_name.field" + suffix);
                                        return prev;
                                    } }) }), contactType === "person" && (_jsxs(Grid, { item: true, xs: 12, container: true, spacing: 2, alignItems: "flex-end", wrap: "nowrap", children: [_jsx(Grid, { item: true, xs: true, children: _jsx(FormField, { name: "company_id" }) }), showGoToCompany && (_jsx(Grid, { item: true, children: _jsx(IconButton, { disabled: !companyId, color: "primary", size: "large", onClick: openCompany, children: _jsx(ArrowRightIcon, {}) }) }))] }))] }), _jsxs(BoxGrid, { item: true, xs: 12, md: 6, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "h2", children: t("communication") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "phone" }) }), contactType !== "company" && (_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "mobile" }) })), contactType === "company" && (_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "fax" }) })), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "email" }) }), contactType === "company" && (_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "url" }) }))] }), _jsxs(BoxGrid, { item: true, xs: 12, md: 6, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "h2", children: t("address") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "street" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "zip" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "town" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "country" }) })] })] }), _jsxs(BoxGrid, { item: true, xs: 12, sm: 6, md: 4, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "avatar", overrides: {
                                getLabel: () => contactType === "company"
                                    ? t("avatar.logo")
                                    : t("avatar.field"),
                            } }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "categories" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(SamedisCrudFileUpload, { field: "uploads", getEndpoint: (id) => context.mode === "tenant"
                                ? `v4/tenants/${tenantId}/contacts/${id}/uploads`
                                : context.mode === "admin"
                                    ? `v4/contacts/${id}/uploads`
                                    : context.mode === "user"
                                        ? `v4/tenants/${tenantId}/mdm/contacts/${id}/uploads`
                                        : context.mode === "enterprise"
                                            ? `v4/enterprise/tenants/${tenantId}/contacts/${id}/uploads`
                                            : throwError("invalid context"), extraParams: context.mode === "admin" ? { tenant_id: tenantId } : undefined, label: t("documents"), initialId: id }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "notes" }) })] }), contactType === "company" && showContactList && (_jsx(CompanyContactList, { id: id })), _jsx(SamedisMobileFormButtonsPortal, { render: !readOnly
                    ? SamedisMobileFormButtonsPortalBoxGridRenderer
                    : SamedisMobileFormButtonsPortalNullRenderer })] })), [
        pageSpacing,
        contactType,
        showGoToCompany,
        companyId,
        openCompany,
        t,
        context.mode,
        tenantId,
        id,
        showContactList,
        readOnly,
    ]);
};
const ContactFormPublishButton = () => {
    const { t } = useTranslation("contact");
    const { values, setFieldValue, submit, submitting } = useFormContext();
    const isPublic = values.status !== "tenant";
    const isCompany = values.contact_type === "company";
    const makePublic = useCallback(async () => {
        setFieldValue("publish_contact", true);
        try {
            await submit();
        }
        catch {
            // shown to user via ErrorComponent
        }
    }, [setFieldValue, submit]);
    const isDisabled = isPublic || !isCompany || submitting;
    const reasonList = useMemo(() => [
        isPublic && t("publish_contact.disabled_reasons.already_public"),
        !isCompany && t("publish_contact.disabled_reasons.not_a_company"), // not supported by backend
    ]
        .filter(Boolean)
        .join("; "), [isCompany, isPublic, t]);
    return useMemo(() => {
        const btn = (_jsx(ActionButton, { color: "secondary", disabled: isDisabled, onClick: makePublic, children: t("buttons.make_public") }));
        return isDisabled ? (_jsx(Tooltip, { title: reasonList, children: _jsx("span", { children: btn }) })) : (btn);
    }, [isDisabled, reasonList, makePublic, t]);
};
const ContactForm = (formProps) => {
    const { t } = useTranslation("contact");
    const context = useContactsCrudContext();
    const isMdmAdmin = context.mode === "admin";
    const inDialog = useContext(IsInFormDialogContext);
    const extraButtons = useMemo(() => isMdmAdmin && _jsx(ContactFormPublishButton, {}, "publish"), [isMdmAdmin]);
    return (_jsx(DefaultFormPage, { ...formProps, extraButtons: extraButtons, children: _jsx(BasicPageLayout, { title: formProps.id ? t("pagetitle-edit") : t("pagetitle-new"), statusBlock: _jsx(ContactHasAccountStatusFromForm, {}), actions: formProps.id &&
                !inDialog && [
                {
                    id: "contact-actions",
                    name: t("buttons.actions"),
                    icon: MenuIcon,
                    handler: ContactActionsButton,
                },
            ], children: _jsx(ContactFormContent, {}) }) }));
};
export default ContactForm;
