import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ActionButton, BackActionButton, FormButtons, useDialogContext, } from "components-care";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeviceModelPublishRequestedConfirmDialog from "./DeviceModelPublishRequestedConfirmDialog";
const DeviceModelPublishRequestedFormButtons = (props) => {
    const { showBackButtonOnly, readOnly, readOnlyReasons, isSubmitting, dirty, customProps, } = props;
    const goBack = customProps?.goBack;
    const { t } = useTranslation("device-models");
    const [pushDialog] = useDialogContext();
    const handleBack = useCallback(() => goBack && goBack(), [goBack]);
    const [goBackTrigger, setGoBackTrigger] = useState(null);
    const handleDone = useCallback(() => {
        setGoBackTrigger(Date.now());
    }, []);
    useEffect(() => {
        if (dirty || isSubmitting || !goBackTrigger)
            return;
        if (goBack)
            goBack(true);
    }, [dirty, goBack, goBackTrigger, isSubmitting]);
    const handlePublish = useCallback(() => {
        pushDialog(_jsx(DeviceModelPublishRequestedConfirmDialog, { action: "accept", onDone: handleDone }));
    }, [handleDone, pushDialog]);
    const handleMerge = useCallback(() => {
        pushDialog(_jsx(DeviceModelPublishRequestedConfirmDialog, { action: "merge", onDone: handleDone }));
    }, [handleDone, pushDialog]);
    const handleDeny = useCallback(() => {
        pushDialog(_jsx(DeviceModelPublishRequestedConfirmDialog, { action: "deny", onDone: handleDone }));
    }, [handleDone, pushDialog]);
    const publishBtn = (_jsx(ActionButton, { disabled: isSubmitting || readOnly, onClick: handlePublish, children: t("publish-requested.buttons.publish") }));
    const mergeBtn = (_jsx(ActionButton, { disabled: isSubmitting || readOnly, onClick: handleMerge, color: "secondary", children: t("publish-requested.buttons.merge") }));
    const denyBtn = (_jsx(ActionButton, { disabled: isSubmitting || readOnly, onClick: handleDeny, color: "error", children: t("publish-requested.buttons.deny") }));
    const humanReadOnlyReasons = useMemo(() => Object.values(readOnlyReasons).filter((e) => !!e), [readOnlyReasons]);
    return (_jsxs(FormButtons, { children: [!showBackButtonOnly &&
                (readOnly && humanReadOnlyReasons.length > 0 ? (_jsx(Tooltip, { title: humanReadOnlyReasons.join(", "), children: _jsx("span", { children: publishBtn }) })) : (publishBtn)), !showBackButtonOnly &&
                (readOnly && humanReadOnlyReasons.length > 0 ? (_jsx(Tooltip, { title: humanReadOnlyReasons.join(", "), children: _jsx("span", { children: mergeBtn }) })) : (mergeBtn)), !showBackButtonOnly &&
                (readOnly && humanReadOnlyReasons.length > 0 ? (_jsx(Tooltip, { title: humanReadOnlyReasons.join(", "), children: _jsx("span", { children: denyBtn }) })) : (denyBtn)), goBack && (_jsx(BackActionButton, { disabled: isSubmitting, onClick: handleBack, children: t("publish-requested.buttons.cancel") }))] }));
};
export default React.memo(DeviceModelPublishRequestedFormButtons);
