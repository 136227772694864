import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ActionButton, BackActionButton, FormButtons, isObjectEmpty, useDialogContext, useFormContext, } from "components-care";
import { showErrorDialog } from "components-care/dist/non-standalone/Dialog/Utils";
const FacilityCreateFormPageButtons = (props) => {
    const { t } = useTranslation("common");
    const { submit, isSubmitting, step, setStep, customProps } = props;
    const { validateForm } = useFormContext();
    const [pushDialog] = useDialogContext();
    const handleBack = useCallback(() => {
        setStep((step) => (step > 0 ? step - 1 : step));
    }, [setStep]);
    const handleNext = useCallback(async () => {
        if (step == 2) {
            try {
                await submit();
            }
            catch (_e) {
                // ignore, error is shown regardless
            }
        }
        else {
            const validations = await validateForm();
            if (isObjectEmpty(validations)) {
                setStep(step + 1);
            }
            else {
                showErrorDialog(pushDialog, validations);
            }
        }
    }, [step, submit, validateForm, setStep, pushDialog]);
    const handleCancel = useCallback(() => {
        customProps.goBack();
    }, [customProps]);
    return (_jsxs(FormButtons, { children: [_jsx(ActionButton, { onClick: handleNext, disabled: isSubmitting, children: step == 2 ? t("buttons.create") : t("buttons.next") }), _jsx(ActionButton, { onClick: handleBack, disabled: isSubmitting || step === 0, children: t("buttons.back") }), step !== 0 && (_jsx(BackActionButton, { onClick: handleCancel, disabled: isSubmitting, children: t("buttons.cancel") }))] }));
};
export default React.memo(FacilityCreateFormPageButtons);
