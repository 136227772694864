import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useImperativeHandle, } from "react";
import { Grid, Popover } from "@mui/material";
import { ChangeHistory } from "@mui/icons-material";
import { SubActionButton, useDialogContext, useFormContextLite, } from "components-care";
import { useTranslation } from "react-i18next";
import { useActionButtonStyles } from "../../../../../../components/ActionButton";
import DeviceModelChangelogDialog from "../DeviceModelChangelogDialog";
import { MdmContext } from "../../../components/MdmTypes";
import DeviceModelActionPublishLanguage from "./DeviceModelActionPublishLanguage";
import DeviceModelActionPublishToCommunity from "./DeviceModelActionPublishToCommunity";
const DeviceModelActionButton = React.forwardRef((_props, ref) => {
    const { t } = useTranslation("device-models");
    const [pushDialog] = useDialogContext();
    const { classes } = useActionButtonStyles();
    const { id: recordId, getFieldValue } = useFormContextLite();
    const target = useContext(MdmContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleAction = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);
    useImperativeHandle(ref, () => ({
        handleAction,
    }), [handleAction]);
    const openChangelog = useCallback(() => {
        setAnchorEl(null);
        if (!recordId)
            throw new Error("record does not exist on server");
        const titleForDialog = [
            getFieldValue("title"),
            getFieldValue("version"),
        ]
            .filter(Boolean)
            .join(" • ");
        pushDialog(_jsx(DeviceModelChangelogDialog, { id: recordId, title: titleForDialog, target: target }));
    }, [recordId, getFieldValue, pushDialog, target]);
    return (_jsx(Popover, { open: !!anchorEl, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
        }, transformOrigin: {
            vertical: "top",
            horizontal: "right",
        }, className: classes.popover, children: _jsx("div", { className: classes.content, children: _jsxs(Grid, { container: true, spacing: 2, children: [target !== "tenant" && (_jsx(DeviceModelActionPublishLanguage, { close: handleClose })), target === "tenant" && (_jsx(DeviceModelActionPublishToCommunity, { close: handleClose })), _jsx(Grid, { item: true, xs: 12, children: _jsx(SubActionButton, { onClick: openChangelog, disableDivider: true, icon: _jsx(ChangeHistory, {}), children: t("actions.show-changelog") }) })] }) }) }));
});
export default React.memo(DeviceModelActionButton);
