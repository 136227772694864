import { jsx as _jsx } from "react/jsx-runtime";
import SamedisMobileDataRendererBase, { SAMEDIS_MOBILE_DATA_RENDERER_BASE_HEIGHT, } from "./SamedisMobileDataRendererBase";
import getLocalizedString from "../../utils/getLocalizedString";
import { useDeviceTypeModelTranslations } from "../models/DeviceTypeModel";
const SamedisMobileDataRendererDeviceType = (props) => {
    const record = props.record;
    const { t, i18n } = useDeviceTypeModelTranslations();
    return (_jsx(SamedisMobileDataRendererBase, { line1: getLocalizedString(i18n.language, record.title_labels), line2: t("device-type:trust_level.enum." + record.trust_level) }));
};
export const SamedisMobileDataRendererDeviceTypeConfig = {
    rendererHeight: SAMEDIS_MOBILE_DATA_RENDERER_BASE_HEIGHT,
    renderer: SamedisMobileDataRendererDeviceType,
};
