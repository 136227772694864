import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import TagRenderer from "./TagRenderer";
import { useTranslation } from "react-i18next";
import { TrustLevelIcon } from "../../../../../components/icons";
import { getDeviceModelImage, getDeviceModelManufacturerLabel, OPERATOR_ORDINANCE_OPTIONS, } from "../../../../../components-care/models/DeviceModelsModel";
import { useRegulatoryDomain } from "../../../../../utils/useRegulatoryDomain";
import DenseLayout from "./DenseLayout";
import getLocalizedString from "../../../../../utils/getLocalizedString";
const DeviceModelRenderer = (props) => {
    const { data, ...otherProps } = props;
    const { t, i18n } = useTranslation(["global-device-catalog"]);
    const regDomain = useRegulatoryDomain();
    const manufacturer = getDeviceModelManufacturerLabel(data);
    const annex = regDomain !== "DE" || data.operator_ordinance === "none"
        ? null
        : OPERATOR_ORDINANCE_OPTIONS(t)
            .find((op) => op.value === data.operator_ordinance)
            ?.getLabel();
    const serviceTag = data.end_of_life ? (_jsx(TagRenderer, { color: "red", dense: true, denseLabel: true, children: t("global-device-catalog:tags.service.end_of_life") }, "service")) : data.end_of_service ? (_jsx(TagRenderer, { color: "yellow", dense: true, denseLabel: true, children: t("global-device-catalog:tags.service.end_of_service") }, "service")) : null;
    return (_jsx(DenseLayout, { id: data.id, icon: _jsx("img", { src: getDeviceModelImage(data), alt: data.title }), title: [
            data.title,
            data.associated_version_ids.length > 0 && data.version,
        ]
            .filter(Boolean)
            .join(" • "), info: [
            [
                getLocalizedString(i18n.language, data.device_type_title_labels),
                annex,
            ]
                .filter((e) => !!e)
                .join(" • "),
            manufacturer,
        ].filter((e) => !!e), tags: [
            serviceTag,
            data.inventory_count > 0 && (_jsx(TagRenderer, { color: "yellow", info: t("global-device-catalog:tags.inventory_info"), icon: _jsx("img", { src: "/img/placeholders/device.png", alt: "" }), dense: true, children: t("global-device-catalog:tags.inventory") }, "inventory")),
            _jsx(TagRenderer, { color: "blue", info: t("global-device-catalog:tags.trust-level." +
                    data.trust_level +
                    "_info"), icon: _jsx(TrustLevelIcon, { level: data.trust_level }), dense: true, children: t("global-device-catalog:tags.trust-level." + data.trust_level) }, "trust_level"),
        ], ...otherProps }));
};
export default React.memo(DeviceModelRenderer);
