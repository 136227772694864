import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useChangelogModel } from "../../../../../components-care/models/ChangelogModel";
import { FullFormDialog } from "components-care";
import { useTranslation } from "react-i18next";
import SamedisCrud from "../../../../../components-care/SamedisCrud";
import { useSubscriptionModel } from "../../../../../components-care/models/SubscriptionModel";
import { useTenantId } from "../../../../components/TenantContext";
const FacilitySubscriptionChangelogDialog = (props) => {
    const { tenantId: targetTenantId } = props;
    const tenantId = useTenantId();
    const { t } = useTranslation("tenant");
    // tried using useInventoryModel here, somehow react just loops forever due to the tenant data fetch in there
    // so we're using the model manually here
    const subscriptionModel = useSubscriptionModel();
    const changelogModel = useChangelogModel({
        baseModel: subscriptionModel,
        id: targetTenantId,
        customEndpoint: `v4/admin/tenant_subscription_plans/${targetTenantId}/changelogs`,
        customQueryParams: { tenant_id: tenantId },
    });
    const dialogTitle = t("subscription-changelog.title");
    return (_jsx(FullFormDialog, { dialogTitle: dialogTitle, useCustomClasses: true, children: _jsx(SamedisCrud, { model: changelogModel, gridTitle: dialogTitle, deletePermission: false, readPermission: false, editPermission: false, newPermission: false, exportPermission: false, disableRouting: true, gridProps: {
                disableSelection: true,
            }, children: undefined }) }));
};
export default React.memo(FacilitySubscriptionChangelogDialog);
