import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SamedisMobileDataRendererBase, { SAMEDIS_MOBILE_DATA_RENDERER_BASE_HEIGHT, } from "./SamedisMobileDataRendererBase";
import { Link as MuiLink, Typography } from "@mui/material";
import { getContactImage } from "../models/ContactModel";
import { preventPropagation } from "../../constants";
const SamedisMobileDataRendererCompanyContacts = (props) => {
    const record = props.record;
    return (_jsx(SamedisMobileDataRendererBase, { icon: getContactImage(record), line1: [record.last_name, record.first_name].filter(Boolean).join(", "), line2: [record.street, record.zip, record.town]
            .filter(Boolean)
            .join(", "), line3: _jsxs(Typography, { variant: "body2", component: "span", children: [record.phone && (_jsx(MuiLink, { href: "tel:" + record.phone, onClick: preventPropagation, children: record.phone })), record.phone && record.email && " •\u00A0", record.email && (_jsx(MuiLink, { href: "mailto:" + record.email, onClick: preventPropagation, children: record.email }))] }) }));
};
export const SamedisMobileDataRendererCompanyContactsConfig = {
    rendererHeight: SAMEDIS_MOBILE_DATA_RENDERER_BASE_HEIGHT,
    renderer: SamedisMobileDataRendererCompanyContacts,
};
