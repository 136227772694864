import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import DenseLayout from "./DenseLayout";
const AccessProfileRenderer = (props) => {
    const { data, ...otherProps } = props;
    return (_jsx(DenseLayout, { id: data.id, title: [data.title, data.description]
            .map((e) => e.trim())
            .filter((e) => !!e)
            .join(": "), ...otherProps }));
};
export default React.memo(AccessProfileRenderer);
