import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { FullFormDialog } from "components-care";
import BasicPageLayout from "../../../../../components/BasicPageLayout";
const SearchAndSingleSelect = (props) => {
    const { instance: Instance, props: instanceProps, onSelected, title } = props;
    const handleSelect = useCallback((selected) => {
        if (selected.length === 0)
            return;
        onSelected(selected[0]);
    }, [onSelected]);
    const handleClose = useCallback(() => {
        onSelected(null);
    }, [onSelected]);
    return (_jsx(FullFormDialog, { onClose: handleClose, useCustomClasses: true, maxWidth: "md", dialogTitle: title, children: _jsx(BasicPageLayout, { title: title, column: true, noPadding: true, spacing: 2, children: React.createElement(Instance, {
                ...instanceProps,
                onSelected: handleSelect,
                multiSelect: false,
            }) }) }));
};
export default React.memo(SearchAndSingleSelect);
