import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import getLocalizedString from "../../../../../utils/getLocalizedString";
import { useTranslation } from "react-i18next";
import DenseLayout from "./DenseLayout";
const DeviceLocationRenderer = (props) => {
    const { data, ...otherProps } = props;
    const { i18n, t } = useTranslation("device-tag");
    return (_jsx(DenseLayout, { id: data.id, title: t("type.enum." + data.type) +
            ": " +
            getLocalizedString(i18n.language, data.labels), ...otherProps }));
};
export default React.memo(DeviceLocationRenderer);
