import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { ActionButton, BackActionButton, FormField, FullFormDialog, isObjectEmpty, showErrorDialog, showInfoDialog, useDialogContext, useFormContext, useModelMutation, } from "components-care";
import { useTranslation } from "react-i18next";
import BasicPageLayout from "../../../../../components/BasicPageLayout";
import BoxGrid from "../../../../../components/BoxGrid";
import { Grid } from "@mui/material";
const DeviceModelPublishRequestedConfirmDialog = (props) => {
    const { action, onCancel, onDone } = props;
    const { t } = useTranslation("device-models");
    const { id: recordId, submit, model, values, setFieldValue, getFieldValue, validateForm, } = useFormContext();
    const [pushDialog, popDialog] = useDialogContext();
    const { mutateAsync } = useModelMutation(model);
    const handleConfirm = useCallback(async () => {
        setFieldValue("publish_to_community_decision", action);
        if (action === "accept") {
            try {
                await submit();
                popDialog();
                await showInfoDialog(pushDialog, {
                    title: t("publish-requested.dialog-success.accept.title"),
                    message: t("publish-requested.dialog-success.accept.message"),
                });
                onDone();
            }
            catch (_e) {
                // validation errors, shown to user regardless via Form Error Component
            }
        }
        else if (action === "merge") {
            try {
                const validation = await validateForm();
                if (!isObjectEmpty(validation))
                    throw validation;
                await mutateAsync({
                    id: recordId,
                    publish_to_community_decision: "merge",
                    publish_to_community_merge_device_model_id: getFieldValue("publish_to_community_merge_device_model_id"),
                    publish_to_community_reply: getFieldValue("publish_to_community_reply"),
                });
                popDialog();
                await showInfoDialog(pushDialog, {
                    title: t("publish-requested.dialog-success.merge.title"),
                    message: t("publish-requested.dialog-success.merge.message"),
                });
                onDone();
            }
            catch (e) {
                await showErrorDialog(pushDialog, e);
            }
        }
        else if (action === "deny") {
            try {
                await mutateAsync({
                    id: recordId,
                    publish_to_community_decision: "deny",
                    publish_to_community_reply: getFieldValue("publish_to_community_reply"),
                });
                popDialog();
                await showInfoDialog(pushDialog, {
                    title: t("publish-requested.dialog-success.deny.title"),
                    message: t("publish-requested.dialog-success.deny.message"),
                });
                onDone();
            }
            catch (e) {
                await showErrorDialog(pushDialog, e);
            }
        }
    }, [
        action,
        getFieldValue,
        mutateAsync,
        onDone,
        popDialog,
        pushDialog,
        recordId,
        setFieldValue,
        submit,
        t,
        validateForm,
    ]);
    const handleCancel = useCallback(() => {
        setFieldValue("publish_to_community_merge_device_model_id", null);
        setFieldValue("publish_to_community_decision", "");
        if (onCancel)
            onCancel();
    }, [onCancel, setFieldValue]);
    const handleCancelAndPopDialog = useCallback(() => {
        popDialog();
        handleCancel();
    }, [handleCancel, popDialog]);
    return (_jsx(FullFormDialog, { dialogTitle: t("publish-requested.dialog-title." + action), onClose: handleCancel, children: _jsx(BasicPageLayout, { title: null, children: _jsxs(BoxGrid, { item: true, xs: 12, container: true, spacing: 2, children: [action === "merge" && (_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "publish_to_community_merge_device_model_id" }) })), action === "merge" && (_jsx(Grid, { item: true, xs: 12, children: t("publish-requested.affected-inventories", {
                            count: values.affected_inventories ?? -1,
                        }) })), action === "merge" && (_jsx(Grid, { item: true, xs: 12, children: t("publish-requested.affected-trainings", {
                            count: values.affected_trainings ?? -1,
                        }) })), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "publish_to_community_reply" }) }), _jsx(Grid, { item: true, children: _jsx(ActionButton, { onClick: handleConfirm, children: t("publish-requested.buttons.confirm") }) }), _jsx(Grid, { item: true, children: _jsx(BackActionButton, { onClick: handleCancelAndPopDialog, children: t("publish-requested.buttons.cancel") }) })] }) }) }));
};
export default React.memo(DeviceModelPublishRequestedConfirmDialog);
