import { Model, ModelDataTypeBooleanCheckboxRendererCC, ModelVisibilityDisabledReadOnly, ModelVisibilityEdit, ModelVisibilityEditReadOnly, throwError, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import { useMemo } from "react";
import { useTenantIdOpt } from "../../pages/components/TenantContext";
import { IncidentMessageContentRenderer } from "../../pages/TenantSpecific/Devices/Requests/components/Chat/ChatBox";
import { useTranslation } from "react-i18next";
import { useIncidentsContext } from "../../pages/TenantSpecific/Devices/Requests/components/DeviceRequestsCRUD";
import { AUDIT_FIELDS_DEF, ID_FIELD_DEF } from "../../constants";
import RendererObjectId from "../types/renderers/RendererObjectId";
const IncidentMessageModel = (params) => new Model("incident-message-model", {
    tenant_id: {
        // frontend only
        type: new RendererObjectId(),
        getLabel: () => "",
        getDefaultValue: () => params.enterpriseClientId,
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityEditReadOnly,
            create: ModelVisibilityEditReadOnly,
        },
        customData: null,
    },
    content: {
        type: new IncidentMessageContentRenderer({ noMarkdown: true }),
        getLabel: () => params.t("incident:chat.box.title"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEdit,
        },
        customData: null,
    },
    system: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEdit,
        },
        customData: null,
    },
    has_uploads: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEdit,
        },
        customData: null,
    },
    ...AUDIT_FIELDS_DEF(params.t, null),
    id: {
        ...ID_FIELD_DEF(params.t),
        customData: null,
    },
}, new BackendConnector(params.enterpriseClientId
    ? params.role === "dispatcher"
        ? `v4/enterprise/tenants/${params.tenantId}/clients/${params.enterpriseClientId}/incidents/${params.incidentId}/messages`
        : params.role === "supporter"
            ? `v4/enterprise/tenants/${params.tenantId}/clients/${params.enterpriseClientId}/incidents/${params.incidentId}/supporter_messages`
            : throwError("invalid")
    : params.role === "dispatcher"
        ? `v4/tenants/${params.tenantId}/incidents/${params.incidentId}/messages`
        : params.role === "supporter"
            ? `v4/tenants/${params.tenantId}/incidents/${params.incidentId}/supporter_messages`
            : params.role === "reporter"
                ? `v4/my/incidents/${params.incidentId}/messages`
                : throwError("invalid"), "data"), [
    params.tenantId,
    params.incidentId,
    params.enterpriseClientId,
    params.role,
]);
export const useIncidentMessageModelTranslations = () => useTranslation("incident");
export const useIncidentMessageModel = (params) => {
    const { t } = useIncidentMessageModelTranslations();
    const tenantId = useTenantIdOpt();
    const { type: role } = useIncidentsContext();
    if (!tenantId && role !== "reporter")
        throw new Error("Tenant id not set, but role != reporter");
    return useMemo(() => IncidentMessageModel({ t, tenantId, role, ...params }), [t, tenantId, role, params]);
};
export default IncidentMessageModel;
