import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { DefaultErrorComponent, Form, FormField, FormHelperTextCC, GroupBox, useDialogContext, useFormContext, } from "components-care";
import { useTranslation } from "react-i18next";
import { useIncidentMessageModel, } from "../../../../../../components-care/models/IncidentMessageModel";
import TypeString from "components-care/dist/backend-integration/Model/Types/TypeString";
import { Box, Button, Divider, FormHelperText, Grid, IconButton, Link as MuiLink, } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { AttachFile as AttachFileIcon, CameraAlt as CameraIcon, Mic as MicIcon, Send as SendIcon, Videocam as VideoIcon, } from "@mui/icons-material";
import DOMPurify from "dompurify";
import { marked } from "marked";
import { JournalRenderer } from "../../../../../../components/MarkedRenderer";
import { MARKDOWN_PURIFY_CONFIG } from "../../../../../../constants";
import { useQuery } from "react-query";
import { supportsInputCapture } from "../../../../../../utils/featureTest";
import AudioCapture, { useIsAudioCaptureSupported } from "./AudioCapture";
import ChatBoxUploads from "./ChatBoxUploads";
import { useFormEnterpriseClientIdOpt } from "../../../../Enterprise/utils/useFormEnterpriseClientId";
import useAsyncMemo from "components-care/dist/utils/useAsyncMemo";
const useStyles = makeStyles()({
    input: {
        resize: "vertical",
        width: "100%",
        border: "none",
        outline: "none",
        font: "inherit",
        minHeight: 128,
    },
    mdHint: {
        textAlign: "right",
    },
});
const ChatBoxControl = (props) => {
    const { visibility, field, value, label, handleChange, handleBlur, errorMsg, warningMsg, noMarkdown, } = props;
    const { id: formId, submitting, submit, customProps } = useFormContext();
    const [pushDialog] = useDialogContext();
    const customPropsTyped = customProps;
    const incidentId = customPropsTyped?.incidentId;
    const { classes } = useStyles();
    const { t } = useTranslation("incident");
    const [preview, setPreview] = useState(false);
    const handleTogglePreview = useCallback(() => {
        setPreview((prev) => !prev);
    }, []);
    const uploadRef = useRef(null);
    const handleAttachFile = useCallback(() => {
        const dispatch = uploadRef.current;
        if (!dispatch)
            return;
        dispatch.openUploadDialog();
    }, []);
    const handleCaptureImage = useCallback(() => {
        const dispatch = uploadRef.current;
        if (!dispatch)
            return;
        dispatch.openUploadDialog({ type: "image", source: "environment" });
    }, []);
    const handleCaptureVideo = useCallback(() => {
        const dispatch = uploadRef.current;
        if (!dispatch)
            return;
        dispatch.openUploadDialog({ type: "video", source: "environment" });
    }, []);
    const supportsCaptureVoice = useIsAudioCaptureSupported();
    const handleCaptureVoice = useCallback(() => {
        const dispatch = uploadRef.current;
        if (!dispatch)
            return;
        pushDialog(_jsx(AudioCapture, { onDone: (data) => {
                if (!data)
                    return;
                return dispatch.addFile(data);
            } }));
    }, [pushDialog]);
    const [hasFiles, setHasFiles] = useState(false);
    const handleFilesChange = useCallback((files) => setHasFiles(files.length > 0), []);
    const groupBoxLabel = useMemo(() => noMarkdown ? (label) : (_jsxs("span", { children: [label, " -", " ", _jsx(MuiLink, { href: "#", onClick: handleTogglePreview, children: t("chat.box.toggle-preview") })] })), [handleTogglePreview, label, noMarkdown, t]);
    const buttonsAndUploads = useMemo(() => (_jsxs(Grid, { item: true, xs: 12, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, style: { display: hasFiles ? undefined : "none" }, children: _jsx(Box, { px: 1, pt: hasFiles ? 1 : 0, children: _jsx(ChatBoxUploads, { ref: uploadRef, incidentId: incidentId, formId: formId, onChange: handleFilesChange }) }) }), hasFiles && (_jsx(Grid, { item: true, xs: 12, children: _jsx(Divider, {}) })), _jsxs(Grid, { item: true, xs: 12, container: true, children: [_jsx(Grid, { item: true, children: _jsx(IconButton, { onClick: handleAttachFile, color: "primary", size: "large", children: _jsx(AttachFileIcon, {}) }) }), supportsInputCapture && (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, children: _jsx(IconButton, { onClick: handleCaptureImage, color: "primary", size: "large", children: _jsx(CameraIcon, {}) }) }), _jsx(Grid, { item: true, children: _jsx(IconButton, { onClick: handleCaptureVideo, color: "primary", size: "large", children: _jsx(VideoIcon, {}) }) })] })), supportsCaptureVoice && (_jsx(Grid, { item: true, children: _jsx(IconButton, { onClick: handleCaptureVoice, color: "primary", size: "large", children: _jsx(MicIcon, {}) }) })), !visibility.required && ( // yes required, used to hide send on create
                    _jsxs(_Fragment, { children: [_jsx(Grid, { item: true, xs: true }), _jsx(Grid, { item: true, children: _jsx(Button, { onClick: submit, disabled: submitting, color: submitting ? undefined : "primary", size: "large", endIcon: _jsx(SendIcon, {}), children: t("chat.box.send") }) })] }))] })] })), [
        formId,
        handleAttachFile,
        handleCaptureImage,
        handleCaptureVideo,
        handleCaptureVoice,
        handleFilesChange,
        hasFiles,
        incidentId,
        submit,
        submitting,
        supportsCaptureVoice,
        t,
        visibility.required,
    ]);
    const mdInfoText = useMemo(() => (_jsx(Grid, { item: true, xs: 12, children: _jsx(FormHelperText, { className: classes.mdHint, children: t("chat.box.support-md") }) })), [classes.mdHint, t]);
    const mdContent = useAsyncMemo(async () => DOMPurify.isSupported // if not supported the library falls back to returning exactly what you pass into it, so we block this
        ? DOMPurify.sanitize(await marked(value || t("chat.no-content"), {
            renderer: new JournalRenderer(),
        }), MARKDOWN_PURIFY_CONFIG)
        : "Your browser is out-of-date", [value]);
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(GroupBox, { label: groupBoxLabel, children: _jsx(Grid, { container: true, spacing: 2, children: preview ? (_jsx(Grid, { item: true, xs: 12, dangerouslySetInnerHTML: {
                                __html: mdContent ?? "",
                            } })) : (_jsx(Grid, { item: true, xs: 12, children: _jsx("textarea", { name: field, className: classes.input, value: value ?? "", disabled: visibility.readOnly, onChange: (evt) => handleChange(evt.target.name, evt.target.value), onBlur: handleBlur }) })) }) }) }), !noMarkdown && mdInfoText, (errorMsg || warningMsg) && (_jsx(Grid, { item: true, xs: 12, children: _jsx(FormHelperTextCC, { error: !!errorMsg, warning: !!warningMsg, children: errorMsg || warningMsg }) })), buttonsAndUploads] }));
};
export class IncidentMessageContentRenderer extends TypeString {
    params;
    constructor(params) {
        super();
        this.params = params;
    }
    render(params) {
        const { visibility, field, value } = params;
        if (visibility.disabled)
            return _jsx(_Fragment, {});
        if (visibility.hidden) {
            return (_jsx("input", { type: "hidden", name: field, value: value ?? "", readOnly: true, "aria-hidden": "true" }));
        }
        if (visibility.editable) {
            if (visibility.grid)
                throw new Error("Not supported");
            return _jsx(ChatBoxControl, { ...this.params, ...params });
        }
        throw new Error("Not supported");
    }
}
export const ChatBoxForm = (_props) => {
    return _jsx(FormField, { name: "content" });
};
const ChatBox = (props) => {
    const enterpriseClientId = useFormEnterpriseClientIdOpt();
    const model = useIncidentMessageModel(useMemo(() => ({ enterpriseClientId, incidentId: props.incidentId }), [enterpriseClientId, props.incidentId]));
    const [resetKey, setResetKey] = useState("first");
    const { refetch } = useQuery(model.getReactQueryKey("index", false));
    const handleSubmit = useCallback((data) => {
        void refetch();
        setResetKey(data.id);
    }, [refetch]);
    return (_jsx(Form, { model: model, errorComponent: DefaultErrorComponent, id: null, customProps: { incidentId: props.incidentId }, onSubmit: handleSubmit, children: ChatBoxForm }, resetKey));
};
export default React.memo(ChatBox);
