import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import SamedisMobileDataRendererBase, { SAMEDIS_MOBILE_DATA_RENDERER_BASE_HEIGHT, } from "./SamedisMobileDataRendererBase";
import getLocalizedString from "../../utils/getLocalizedString";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { getDeviceTaskImage } from "../models/DeviceTaskModel";
import { ToDateLocaleStringOptions } from "../../constants";
const SamedisMobileDataRendererDeviceRequests = (props) => {
    const { t, i18n } = useTranslation("incident");
    const record = props.record;
    return (_jsx(SamedisMobileDataRendererBase, { icon: getDeviceTaskImage(record), line1: [record.device_model_title, record.device_model_version]
            .filter(Boolean)
            .join(" • "), line2: getLocalizedString(i18n.language, record.device_type_title_labels), line3: record.device_model_manufacturer_according_to_type_plate, rightInfo: _jsxs(_Fragment, { children: [_jsxs(Typography, { component: "p", variant: "body2", color: "textSecondary", noWrap: true, children: [t("incident_number.field"), " ", _jsx(Typography, { component: "span", variant: "body2", color: "textPrimary", noWrap: true, children: record.incident_number })] }), _jsxs(Typography, { component: "p", variant: "body2", color: "textSecondary", noWrap: true, children: [t("last_activity_at.field"), " ", _jsx(Typography, { component: "span", variant: "body2", color: "textPrimary", noWrap: true, children: record.updated_at.toLocaleString(i18n.language, ToDateLocaleStringOptions) })] }), _jsxs(Typography, { component: "p", variant: "body2", color: "textSecondary", noWrap: true, children: [t("status.field"), " ", _jsx(Typography, { component: "span", variant: "body2", color: "textPrimary", noWrap: true, children: t("status.enum." + record.status) })] })] }) }));
};
export const SamedisMobileDataRendererDeviceRequestsConfig = {
    rendererHeight: SAMEDIS_MOBILE_DATA_RENDERER_BASE_HEIGHT,
    renderer: SamedisMobileDataRendererDeviceRequests,
};
