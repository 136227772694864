import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from "react";
import { ActionButton, FullFormDialog, showInfoDialog, useDialogContext, } from "components-care";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import BasicPageLayout from "../../../../../components/BasicPageLayout";
const DEFAULT_PROHIBIT_UNSELECT = [[], ""];
const SearchAndMultiSelect = (props) => {
    const { instance: Instance, props: instanceProps, onSelected, initialSelection, title, prohibitUnselect, } = props;
    const [prohibitIds, prohibitMsg] = prohibitUnselect ?? DEFAULT_PROHIBIT_UNSELECT;
    const [selected, setSelected] = useState(initialSelection);
    const [pushDialog, popDialog] = useDialogContext();
    const { t } = useTranslation("global-device-catalog");
    const handleSelect = useCallback((newSelected) => {
        const unselected = selected.filter((id) => !newSelected.includes(id));
        if (unselected.find((id) => prohibitIds.includes(id))) {
            void showInfoDialog(pushDialog, {
                title: t("unselect-prevention-title"),
                message: prohibitMsg,
            });
        }
        else {
            setSelected(newSelected);
        }
    }, [prohibitIds, prohibitMsg, pushDialog, selected, t]);
    const handleConfirm = useCallback(() => {
        popDialog();
        onSelected(selected);
    }, [onSelected, popDialog, selected]);
    return (_jsx(FullFormDialog, { useCustomClasses: true, maxWidth: "md", dialogTitle: title, children: _jsxs(BasicPageLayout, { title: title, column: true, noPadding: true, spacing: 2, children: [React.createElement(Instance, {
                    ...instanceProps,
                    selected,
                    onSelected: handleSelect,
                    multiSelect: true,
                }), _jsx(Grid, { item: true, container: true, spacing: 2, justifyContent: "flex-end", children: _jsx(Grid, { item: true, mr: 2, mb: 2, children: _jsx(ActionButton, { onClick: handleConfirm, fullWidth: false, children: t("actions.confirm") }) }) })] }) }));
};
export default React.memo(SearchAndMultiSelect);
