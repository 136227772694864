import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo } from "react";
import { BaseSelectorContext, useDialogContext, useFormContextLite, useFormFieldContext, } from "components-care";
import { IconButton } from "@mui/material";
import SearchAndSingleSelect from "../SearchAndBrowse/Instances/SearchAndSingleSelect";
import { useButtonEndAdornmentStyles } from "./PickDeviceModelButton";
import InventorySearch from "../SearchAndBrowse/Instances/InventorySearch";
import { useFormEnterpriseClientIdOpt } from "../../Enterprise/utils/useFormEnterpriseClientId";
import { Search as SearchIcon } from "@mui/icons-material";
import reactNodeToElement from "../../../../utils/reactNodeToElement";
const PickInventoryButton = (props) => {
    const { title, ...otherProps } = props;
    const { id: recordId } = useFormContextLite();
    const { visibility, field, handleChange } = useFormFieldContext();
    const { classes } = useButtonEndAdornmentStyles();
    const selectorContext = useContext(BaseSelectorContext);
    const [pushDialog, popDialog] = useDialogContext();
    const enterpriseClientId = useFormEnterpriseClientIdOpt();
    const handleSelect = useCallback((selected) => {
        if (selected) {
            handleChange(field, selected);
            if (selectorContext)
                selectorContext.addToLru(selected);
            popDialog();
        }
    }, [handleChange, field, selectorContext, popDialog]);
    const openDialog = useCallback((evt) => {
        evt.stopPropagation();
        pushDialog(_jsx(SearchAndSingleSelect, { instance: InventorySearch, onSelected: handleSelect, props: {
                ...otherProps,
                enterpriseClientId,
                searchForMainInventoryRecordId: recordId,
            }, title: title }));
    }, [pushDialog, title, handleSelect, otherProps, enterpriseClientId, recordId]);
    return useMemo(() => reactNodeToElement(!visibility.readOnly && (_jsx(IconButton, { onClick: openDialog, className: classes.button, disabled: visibility.readOnly, size: "large", color: "primary", children: _jsx(SearchIcon, {}) }))), [classes.button, openDialog, visibility.readOnly]);
};
export default React.memo(PickInventoryButton);
