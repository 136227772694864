import i18n from "../../i18n";
/**
 * Type to handle Device Model Regulatories
 */
class TypeRegulatories {
    params;
    constructor(params) {
        this.params = params;
    }
    validate(value, field) {
        if (value.find((v) => !v.label?.trim() || !v.value?.trim()))
            return i18n.t("common:errors.regulatory-input.blank-records", {
                FIELD: field.getLabel(),
            });
        return null;
    }
    getFilterType() {
        return null;
    }
    getDefaultValue() {
        return [];
    }
    stringify(value) {
        return value.map((entry) => `${entry.label}: ${entry.value}`).join("; ");
    }
}
export default TypeRegulatories;
