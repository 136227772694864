import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext } from "react";
import { BaseSelectorContext, useDialogContext, useFormFieldContext, } from "components-care";
import { IconButton } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { useButtonEndAdornmentStyles } from "./PickDeviceModelButton";
import SearchAndMultiSelect from "../SearchAndBrowse/Instances/SearchAndMultiSelect";
import reactNodeToElement from "../../../../utils/reactNodeToElement";
import PositionSearch from "../SearchAndBrowse/Instances/PositionSearch";
const EMPTY_OBJECT = {};
const PickPositionsButton = (props) => {
    const { title } = props;
    const { visibility, value, field, handleChange } = useFormFieldContext();
    const { classes } = useButtonEndAdornmentStyles();
    const selectorContext = useContext(BaseSelectorContext);
    const [pushDialog] = useDialogContext();
    const handleSelect = useCallback((selected) => {
        handleChange(field, selected);
        if (selectorContext)
            selectorContext.addToLru(...selected);
    }, [handleChange, field, selectorContext]);
    const openDialog = useCallback((evt) => {
        evt.stopPropagation();
        pushDialog(_jsx(SearchAndMultiSelect, { instance: PositionSearch, initialSelection: value, onSelected: handleSelect, props: EMPTY_OBJECT, title: title }));
    }, [pushDialog, value, handleSelect, title]);
    return reactNodeToElement(!visibility.readOnly && (_jsx(IconButton, { onClick: openDialog, className: classes.button, disabled: visibility.readOnly, size: "large", color: "primary", children: _jsx(SearchIcon, {}) })));
};
export default React.memo(PickPositionsButton);
