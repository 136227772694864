import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import { throwError } from "components-care";
import { DeviceTeamImage, MixedTeamImage, StaffTeamImage, } from "../../../../../components-care/models/TrainingGroupModel";
import DenseLayout from "./DenseLayout";
const TrainingGroupRenderer = (props) => {
    const { data, ...otherProps } = props;
    const { t } = useTranslation("team");
    const image = data.type === "staff"
        ? StaffTeamImage
        : data.type === "device"
            ? DeviceTeamImage
            : data.type === "both"
                ? MixedTeamImage
                : throwError("unsupported type");
    return (_jsx(DenseLayout, { id: data.id, icon: _jsx("img", { src: image, alt: t(`grid.filter.types.${data.type}`) }), title: data.name, info: [data.manager, data.description].filter((e) => !!e), ...otherProps }));
};
export default React.memo(TrainingGroupRenderer);
