import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import SearchAndBrowse from "../index";
import { useTenantId } from "../../../../components/TenantContext";
import InventoryModel, { InventoryModelSelectorSort, useInventoryModelTranslations, } from "../../../../../components-care/models/InventoryModel";
import InventoryRenderer from "../Renderers/InventoryRenderer";
import { useDeviceModelFilters } from "./DeviceModelSearch";
const InventorySearch = (props) => {
    const { searchForMainInventory, searchForMainInventoryRecordId, searchForDeviceSystem, noRetired, enterpriseClientId, ...other } = props;
    const tenantId = useTenantId();
    const { t: inventoryT } = useInventoryModelTranslations();
    const loadRecords = useCallback(async (search, filters, offset) => {
        const deviceTagIds = [
            ...filters.area_of_application,
            ...filters.purpose,
            ...filters.device_type,
            ...filters.technical_property,
        ];
        const inventoryModel = InventoryModel({
            t: inventoryT,
            tenantId,
            extraParams: {
                "filter[tagged]": deviceTagIds.join(","),
                "filter[manufacturer_id]": filters.manufacturers.join(","),
                "filter[status]": noRetired ? "active" : "",
            },
            filterMainInventoryId: searchForMainInventory ? "empty" : undefined,
            filterMainInventoryIdRecordId: searchForMainInventory
                ? searchForMainInventoryRecordId
                : undefined,
            filterDeviceSystem: searchForDeviceSystem,
            enterpriseClientId,
        });
        const [data, meta] = await inventoryModel.index2({
            offset: offset,
            rows: 25,
            sort: InventoryModelSelectorSort(null),
            quickFilter: search,
        });
        return {
            data: data,
            total: meta.filteredRows ?? meta.totalRows,
        };
    }, [
        inventoryT,
        tenantId,
        noRetired,
        searchForMainInventory,
        searchForMainInventoryRecordId,
        searchForDeviceSystem,
        enterpriseClientId,
    ]);
    const filters = useDeviceModelFilters("tenant", enterpriseClientId, "public_and_tenant");
    return (_jsx(SearchAndBrowse, { ...other, loadRecords: loadRecords, filters: filters, renderer: InventoryRenderer, rendererContext: undefined, controlName: "InventorySearch" }));
};
export default React.memo(InventorySearch);
