import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo } from "react";
import { GroupBox, Loader, useFormContext, useModelFetchAll, } from "components-care";
import { MdmContext } from "../../components/MdmTypes";
import { useDeviceModelsModelOptionalTenant } from "../../../../../components-care/models/DeviceModelsModel";
import { useTranslation } from "react-i18next";
import { Link as MuiLink, Typography } from "@mui/material";
import { INVALID_ID } from "../../../../../constants";
export const useDeviceModelVersions = (device) => {
    const target = useContext(MdmContext);
    const deviceModelsModel = useDeviceModelsModelOptionalTenant(useMemo(() => ({
        target,
        extraParams: {
            "filter[scope]": "public_and_tenant",
        },
    }), [target]));
    return useModelFetchAll(deviceModelsModel, useMemo(() => ({
        fieldFilter: {
            id: {
                type: "inSet",
                value1: [
                    ...device.associated_version_ids,
                    device.id,
                ]
                    .sort()
                    .join(",") || INVALID_ID,
                value2: "",
            },
        },
        sort: [
            {
                field: "version_number",
                direction: 1,
            },
        ],
    }), [device.id, device.associated_version_ids]));
};
const DeviceModelVersionListEntry = (props) => {
    const { onClick, id, version, version_number } = props;
    const handleClick = useCallback(() => {
        if (!onClick)
            return;
        onClick(id);
    }, [onClick, id]);
    return (_jsx("li", { children: onClick ? (_jsx(MuiLink, { onClick: handleClick, href: "#", children: version || version_number })) : (_jsx(Typography, { variant: "inherit", component: "span", children: version || version_number })) }));
};
const DeviceModelVersionList = () => {
    const { t } = useTranslation("device-models");
    const { id: recordId, values, customProps } = useFormContext();
    const { isLoading, data, error } = useDeviceModelVersions(values);
    const openView = customProps.open;
    const handleClick = useCallback((id) => {
        openView(id);
    }, [openView]);
    return (_jsxs(GroupBox, { label: t("versions"), children: [isLoading && _jsx(Loader, {}), error && _jsx(Typography, { color: "error", children: error.message }), data && (_jsx("ul", { style: { paddingLeft: 16 }, children: data[0].map((entry) => (_jsx(DeviceModelVersionListEntry, { onClick: entry.id !== recordId ? handleClick : null, ...(entry.id === recordId ? values : entry) }, entry.id))) }))] }));
};
export default React.memo(DeviceModelVersionList);
