import { useQuery } from "react-query";
import SamedisApiClient from "../../components-care/connectors/SamedisApiClient";
import { useTenantId } from "../../pages/components/TenantContext";
import { ModelDataStore } from "components-care";
import { hasPermission, usePermissionContext } from "components-care/dist";
import IncidentModel, { useIncidentModelTranslations, } from "../../components-care/models/IncidentModel";
import { ReactQueryRetryHandler } from "../../constants";
export const invalidateDashboardData = (tenantId) => {
    ModelDataStore.removeQueries({
        queryKey: ["tenants/dashboard", { tenantId }],
    });
};
export const dashboardDataForceRefresh = { current: false };
export const useDashboardData = () => {
    const tenantId = useTenantId();
    return useQuery(["tenants/dashboard", { tenantId }], async () => {
        const forceRefresh = dashboardDataForceRefresh.current;
        const res = (await SamedisApiClient.get(`/api/v4/tenants/${tenantId}/dashboard`, forceRefresh ? { force_update: true } : null)).data.attributes;
        if (forceRefresh)
            dashboardDataForceRefresh.current = false;
        return res;
    }, { retry: ReactQueryRetryHandler, staleTime: 1000 });
};
export const useRequestsDashboardData = () => {
    const tenantId = useTenantId();
    const [perms] = usePermissionContext();
    const isDispatcher = hasPermission(perms, "incidents.dispatcher");
    const isSupporter = hasPermission(perms, "incidents.supporter");
    const { t } = useIncidentModelTranslations();
    return useQuery([
        "tenants/dashboard/requests",
        tenantId,
        isDispatcher ? "dispatcher" : isSupporter ? "supporter" : "reporter",
    ], async () => {
        if (!isDispatcher && !isSupporter)
            return { new: 0, pending: 0, inProgress: 0, updatedAt: Date.now() };
        const model = IncidentModel({
            t,
            tenantId,
            role: isDispatcher ? "dispatcher" : "supporter",
        });
        const newStats = model.index({
            rows: 0,
            fieldFilter: { status: { type: "equals", value1: "new", value2: "" } },
        });
        const pendingStats = model.index({
            rows: 0,
            fieldFilter: {
                status: { type: "equals", value1: "pending", value2: "" },
            },
        });
        const inProgressStats = model.index({
            rows: 0,
            fieldFilter: {
                status: { type: "equals", value1: "in_progress", value2: "" },
            },
        });
        const [, newMeta] = await newStats;
        const [, pendingMeta] = await pendingStats;
        const [, inProgressMeta] = await inProgressStats;
        return {
            new: newMeta.filteredRows ?? newMeta.totalRows,
            pending: pendingMeta.filteredRows ?? pendingMeta.totalRows,
            inProgress: inProgressMeta.filteredRows ?? inProgressMeta.totalRows,
            updatedAt: Date.now(),
        };
    }, {
        retry: ReactQueryRetryHandler,
        staleTime: 1000,
    });
};
