import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { FormHelperText, Grid, IconButton, styled, Table, TableBody, TableCell, TableHead, TableRow, } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { AddCircleOutlined as AddIcon, Edit as EditIcon, RemoveCircleOutlined as RemoveIcon, } from "@mui/icons-material";
import { ActionButton, FullFormDialog, GroupBox, IntegerInputField, selectorLocalLoadHandler, TextFieldWithHelp, throwError, useDialogContext, BackActionButton, } from "components-care";
import { useTranslation } from "react-i18next";
import Interval from "./Interval";
import { SingleSelect } from "components-care/dist";
import ServiceMaintenanceIcon from "./icons/ServiceMaintenanceIcon";
import ServiceInspectionIcon from "./icons/ServiceInspectionIcon";
import MultiLanguageInput from "components-care/dist/standalone/UIKit/InputControls/MultiLanguageInput";
import getLocalizedString from "../utils/getLocalizedString";
import ActiveLanguageContext, { AvailableLanguageContext, } from "./ActiveLanguageContext";
import i18n from "../i18n";
import MaintenanceTypeCategory from "./MaintenanceTypeCategory";
const useStyles = makeStyles()({
    addButton: { color: "rgba(0, 0, 0, 0.26)" },
    removeButton: { color: "rgba(0, 0, 0, 0.18)" },
    actionBtnColumn: { width: 64 },
    wordBreak: { wordBreak: "break-all" },
});
const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
    [theme.breakpoints.only("xs")]: {
        padding: 4,
    },
}));
const StyledTableCell = styled(StyledTableHeaderCell)({
    borderBottom: "none",
});
export const SERVICE_INTERVAL_CATEGORIES = (t, clazz) => [
    {
        icon: _jsx(ServiceMaintenanceIcon, { className: clazz }),
        value: "maintenance",
        label: t("common:service_intervals.category.enum.maintenance"),
    },
    {
        icon: _jsx(ServiceInspectionIcon, { className: clazz }),
        value: "inspection",
        label: t("common:service_intervals.category.enum.inspection"),
    },
];
const SERVICE_INTERVAL_INTERVAL_UNITS = (t) => ["day", "week", "month", "year"].map((value) => ({
    value,
    label: t("common:service_intervals.interval.enum." + value),
}));
const MaintenanceTypeEditDialog = (props) => {
    const { onChange, viewLanguage, languages } = props;
    const { t } = useTranslation("common");
    const [, popDialog] = useDialogContext();
    const [data, setData] = useState(props.data);
    const categoryOptions = useMemo(() => SERVICE_INTERVAL_CATEGORIES(t), [t]);
    const intervalUnitOptions = useMemo(() => SERVICE_INTERVAL_INTERVAL_UNITS(t), [t]);
    const selectedCategory = categoryOptions.find((entry) => entry.value === data.category);
    const selectedIntervalUnit = intervalUnitOptions.find((entry) => entry.value === data.unit);
    if (!selectedCategory)
        throw new Error("Invalid data: category = " + data.category);
    if (!selectedIntervalUnit)
        throw new Error("Invalid data: unit = " + data.unit);
    const handleCategoryChange = useCallback((data) => {
        if (!data)
            return;
        setData((prev) => ({
            ...prev,
            category: data.value,
        }));
    }, []);
    const handleLabelChange = useCallback((evt) => {
        setData((prev) => ({ ...prev, label: evt.target.value }));
    }, []);
    const handleLabelsChange = useCallback((newValues) => {
        setData((prev) => ({
            ...prev,
            label: getLocalizedString(viewLanguage || i18n.language, newValues),
            labels: newValues,
        }));
    }, [viewLanguage]);
    const handleUnitChange = useCallback((data) => {
        if (!data)
            return;
        setData((prev) => ({ ...prev, unit: data.value }));
    }, []);
    const handleValueChange = useCallback((_evt, value) => {
        setData((prev) => ({
            ...prev,
            value: value && Math.abs(value),
        }));
    }, []);
    const handleDone = useCallback(() => {
        onChange(data);
        popDialog();
    }, [data, popDialog, onChange]);
    const handleCancel = useCallback(() => {
        popDialog();
    }, [popDialog]);
    return (_jsx(FullFormDialog, { dialogTitle: t("service_intervals.edit-dialog.title"), maxWidth: "sm", children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(SingleSelect, { label: t("service_intervals.category.label"), placeholder: t("service_intervals.category.placeholder"), onLoad: selectorLocalLoadHandler(categoryOptions), onSelect: handleCategoryChange, selected: selectedCategory, disableClearable: true, disableSearch: true, enableIcons: true }) }), _jsx(Grid, { item: true, xs: 12, children: languages ? (_jsx(MultiLanguageInput, { enabledLanguages: languages, label: t("service_intervals.type.label"), placeholder: t("service_intervals.type.placeholder"), values: data.labels ?? {}, onChange: handleLabelsChange })) : (_jsx(TextFieldWithHelp, { label: t("service_intervals.type.label"), placeholder: t("service_intervals.type.placeholder"), value: data.label ?? "", onChange: handleLabelChange, fullWidth: true })) }), _jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsx(IntegerInputField, { label: t("service_intervals.interval.label"), placeholder: t("service_intervals.interval.placeholder"), value: data.value, onChange: handleValueChange, fullWidth: true }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsx(SingleSelect, { label: t("service_intervals.interval-unit"), placeholder: t("service_intervals.interval-unit-placeholder"), onLoad: selectorLocalLoadHandler(intervalUnitOptions), onSelect: handleUnitChange, selected: selectedIntervalUnit, disableClearable: true, disableSearch: true }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(ActionButton, { onClick: handleDone, children: t("service_intervals.edit-dialog.done") }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(BackActionButton, { onClick: handleCancel, children: t("service_intervals.edit-dialog.cancel") }) })] }) }));
};
const defaultValues = [];
const FALLBACK_LANGUAGES = ["en"];
const MaintenanceTypesInput = (props) => {
    const { label, onBlur, readOnly, name, errorMsg } = props;
    const values = props.values ?? defaultValues;
    const activeLanguage = useContext(ActiveLanguageContext);
    const viewLanguage = props.viewLanguage === "context"
        ? activeLanguage
            ? activeLanguage[0]
            : throwError("active language context not set")
        : props.viewLanguage;
    const availLanguages = useContext(AvailableLanguageContext);
    const languages = props.languages === "context"
        ? availLanguages
            ? availLanguages.length === 0
                ? FALLBACK_LANGUAGES
                : availLanguages
            : throwError("AvailableLanguageContext missing")
        : props.languages;
    const [pushDialog] = useDialogContext();
    const { t, i18n } = useTranslation("common");
    const { classes } = useStyles();
    const stubOnChange = useCallback(() => null, []);
    const onChange = props.onChange ?? stubOnChange;
    const deleteRow = useCallback((idx) => {
        onChange(values.filter((_, index) => index !== idx));
    }, [onChange, values]);
    const editRow = useCallback((idx) => {
        pushDialog(_jsx(MaintenanceTypeEditDialog, { data: values[idx], onChange: (newData) => {
                onChange(values.map((data, index) => (index === idx ? newData : data)));
            }, languages: languages, viewLanguage: viewLanguage }));
    }, [pushDialog, values, languages, viewLanguage, onChange]);
    const addRow = useCallback(() => {
        const newRow = {
            label: "",
            labels: languages ? {} : undefined,
            value: null,
            unit: "month",
            category: "maintenance",
        };
        onChange([...values, newRow]);
    }, [onChange, values, languages]);
    return (_jsxs(GroupBox, { label: label, children: [_jsxs(Table, { onBlur: onBlur, "data-name": name, children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(StyledTableHeaderCell, { children: t("service_intervals.key") }), _jsx(StyledTableHeaderCell, { children: t("service_intervals.interval.label") }), !readOnly && (_jsx(StyledTableHeaderCell, { className: classes.actionBtnColumn }))] }) }), _jsxs(TableBody, { children: [values.map((entry, index) => {
                                return (_jsxs(TableRow, { children: [_jsx(StyledTableCell, { children: _jsxs(Grid, { container: true, spacing: 1, alignItems: "center", wrap: "nowrap", children: [_jsx(Grid, { item: true, children: _jsx(MaintenanceTypeCategory, { category: entry.category }) }), _jsx(Grid, { item: true, className: classes.wordBreak, children: entry.labels && languages
                                                            ? getLocalizedString(viewLanguage || i18n.language, entry.labels)
                                                            : entry.label })] }) }), _jsx(StyledTableCell, { children: entry.value != null && (_jsx(Interval, { value: entry.value, unit: entry.unit })) }), !readOnly && (_jsx(StyledTableCell, { children: _jsxs(Grid, { container: true, wrap: "nowrap", children: [_jsx(Grid, { item: true, children: _jsx(IconButton, { onClick: () => editRow(index), className: classes.removeButton, size: "large", children: _jsx(EditIcon, {}) }) }), _jsx(Grid, { item: true, children: _jsx(IconButton, { onClick: () => deleteRow(index), className: classes.removeButton, size: "large", children: _jsx(RemoveIcon, {}) }) })] }) }))] }, index));
                            }), !readOnly && (_jsxs(TableRow, { children: [_jsx(StyledTableCell, {}), _jsx(StyledTableCell, {}), _jsx(StyledTableCell, { children: _jsx(IconButton, { onClick: addRow, className: classes.addButton, size: "large", children: _jsx(AddIcon, {}) }) })] }))] })] }), _jsx(FormHelperText, { error: !!errorMsg, children: errorMsg })] }));
};
export default React.memo(MaintenanceTypesInput);
