import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from "react";
import BoxGrid from "../../../../../components/BoxGrid";
import { useContactModel } from "../../../../../components-care/models/ContactModel";
import ContactsCRUD, { useContactsCrudContext } from "./Contacts";
import SamedisCrud from "../../../../../components-care/SamedisCrud";
import { OpenDataGridRecordCrud } from "../../../components/OpenDataGridRecord";
import { makeStyles } from "tss-react/mui";
import { FullFormDialog, useDialogContext } from "components-care";
import { SamedisMobileDataRendererCompanyContactsConfig } from "../../../../../components-care/SamedisMobileDataRenderers/SamedisMobileDataRendererCompanyContacts";
const useStyles = makeStyles()({
    root: {
        minHeight: "80vh",
    },
});
const CompanyContactList = (props) => {
    const context = useContactsCrudContext();
    const { classes } = useStyles();
    const [pushDialog, popDialog] = useDialogContext();
    const [refreshToken, setRefreshToken] = useState(Date.now());
    const model = useContactModel(useMemo(() => ({ target: context.mode, ofCompany: props.id }), [context.mode, props.id]));
    const handleNew = useCallback(() => {
        pushDialog(_jsx(FullFormDialog, { children: _jsx(ContactsCRUD, { mode: context.mode, forceFields: { contact_type: "person", company_id: props.id }, disableRouting: true, disableBackgroundGrid: true, disableStore: true, initialView: "new", formProps: {
                    onSubmit: () => {
                        popDialog();
                        setRefreshToken(Date.now());
                    },
                } }) }));
    }, [context.mode, popDialog, props.id, pushDialog]);
    return (_jsx(BoxGrid, { item: true, xs: 12, classNamePaper: classes.root, children: _jsx(SamedisCrud, { model: model, gridTitle: "", gridProps: {
                customSelectionControl: OpenDataGridRecordCrud,
                forceAddNew: handleNew,
            }, gridMobileRenderer: SamedisMobileDataRendererCompanyContactsConfig, disableRouting: true, disableStore: true, disableGridWrapper: true, deletePermission: "contacts.deleter", readPermission: "contacts.reader", editPermission: "contacts.writer", newPermission: "contacts.writer", exportPermission: "contacts.xlsx", children: undefined }, refreshToken) }));
};
export default React.memo(CompanyContactList);
