import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useDeviceModelsModel } from "../../../../../components-care/models/DeviceModelsModel";
import SamedisCrud from "../../../../../components-care/SamedisCrud";
import DeviceModelsForm from "./DeviceModelsForm";
import { MdmContext } from "../../components/MdmTypes";
import DeviceModelPublishRequestedForm from "./DeviceModelPublishRequestedForm";
import { useTranslation } from "react-i18next";
import { SamedisMobileDataRendererDeviceModelsConfig } from "../../../../../components-care/SamedisMobileDataRenderers/SamedisMobileDataRendererDeviceModels";
const DeviceModelsCRUD = (props) => {
    const { t } = useTranslation("device-models");
    const model = useDeviceModelsModel({
        target: props.mode,
        publishRequested: props.publishRequested,
    });
    return (_jsx(MdmContext.Provider, { value: props.mode, children: _jsx(SamedisCrud, { model: model, gridMobileRenderer: SamedisMobileDataRendererDeviceModelsConfig, gridTitle: t("grid-title"), gridProps: {
                defaultSort: [
                    {
                        field: "title",
                        direction: 1,
                    },
                ],
            }, deletePermission: !props.publishRequested && [
                "catalogs.deleter",
                "catalogs.tenant-deleter",
            ], readPermission: ["catalogs.reader", "catalogs.tenant-reader"], editPermission: ["catalogs.writer", "catalogs.tenant-writer"], newPermission: !props.publishRequested && [
                "catalogs.writer",
                "catalogs.tenant-writer",
            ], exportPermission: ["catalogs.xlsx", "catalogs.tenant-xlsx"], ...props, children: props.publishRequested
                ? DeviceModelPublishRequestedForm
                : DeviceModelsForm }) }));
};
export default React.memo(DeviceModelsCRUD);
