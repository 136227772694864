import { fileToData, ModelDataTypeFilesRenderer, } from "components-care";
class FileRenderer extends ModelDataTypeFilesRenderer {
    settings;
    fileNameField;
    constructor(params, fileNameField) {
        super(params);
        this.fileNameField = fileNameField;
        this.settings = {
            updateHooks: this.fileNameField ? [fileNameField] : undefined,
        };
    }
    render(params) {
        const nameField = this.fileNameField;
        if (!nameField)
            return super.render(params);
        const handleChange = (field, value) => {
            params.handleChange(field, value);
            params.setFieldValue(nameField, value.length > 0 ? value[0].file.name : "");
        };
        return super.render({
            ...params,
            handleChange,
            value: params.value.map((entry) => entry.canBeUploaded
                ? entry
                : {
                    ...entry,
                    file: {
                        ...entry.file,
                        name: params.values[nameField] || entry.file.name,
                    },
                }),
        });
    }
    // @ts-expect-error serializer output type changed
    serialize = async (fileData) => {
        if (fileData.length === 0)
            return null;
        const file = fileData.find((file) => file.canBeUploaded);
        return file
            ? (file.preview ?? (await fileToData(file.file, true)))
            : undefined;
    };
    deserialize = (value) => {
        if (!value)
            return [];
        if (Array.isArray(value))
            return value; // already deserialized => backend connector sets default value if field is undefined (completeAttributes)
        const url = new URL(value);
        return [
            {
                file: {
                    name: url.pathname.substring(url.pathname.lastIndexOf("/") + 1),
                    downloadLink: value,
                },
            },
        ];
    };
}
export default FileRenderer;
