import { createElement as _createElement } from "react";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from "react";
import { Loader, MultiSelect, useFormContext, } from "components-care";
import InventoryModel from "../components-care/models/InventoryModel";
import { useTenantId } from "../pages/components/TenantContext";
import { SERVICE_INTERVAL_CATEGORIES, } from "./MaintenanceTypesInput";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import { intervalToString } from "./Interval";
import { validateMaintenanceTypeEntry } from "../components-care/types/TypeMaintenanceTypes";
const entryToData = (t, value) => {
    const category = SERVICE_INTERVAL_CATEGORIES(t).find((entry) => entry.value === value.category);
    if (value.label == null)
        throw new Error("Label not found");
    return {
        icon: category?.icon,
        label: t("event-issue:maintenance-types.entry", {
            LABEL: value.label,
            CATEGORY: category?.label ?? value.category,
            INTERVAL: intervalToString(value, t),
        }),
        value: value.label,
    };
};
const MaintenanceTypesSelector = (props) => {
    const { inventoryId, selected, onSelect, ...otherProps } = props;
    const { t } = useTranslation("event-issue");
    const { parentFormContext } = useFormContext();
    const [refreshToken, setRefreshToken] = useState(() => new Date().toISOString());
    const [maintenanceTypes, setMaintenanceTypes] = useState([]);
    const tenantId = useTenantId();
    useEffect(() => {
        (async () => {
            let mTypes;
            if (parentFormContext &&
                parentFormContext.model.modelId === "inventory-model") {
                mTypes = parentFormContext.values
                    .with_service_intervals.filter((entry) => validateMaintenanceTypeEntry(entry) == null);
            }
            else {
                mTypes = (await InventoryModel({ t: i18n.t, tenantId }).getCached(inventoryId))[0].with_service_intervals;
            }
            setMaintenanceTypes(mTypes);
            setRefreshToken(new Date().toISOString());
        })();
    }, [inventoryId, parentFormContext, tenantId]);
    if (!inventoryId)
        throw new Error("Should not be rendered without Inventory ID");
    const handleLoad = useCallback((query) => {
        if (!maintenanceTypes)
            return [];
        return maintenanceTypes
            .filter((entry) => entry.label.toLowerCase().includes(query.toLowerCase()))
            .map((entry) => entryToData(t, entry));
    }, [maintenanceTypes, t]);
    const handleSelect = useCallback((newSelected) => {
        if (!onSelect)
            return;
        onSelect(newSelected.map((entry) => entry.value));
    }, [onSelect]);
    if (!maintenanceTypes)
        return _jsx(Loader, {});
    return (_createElement(MultiSelect, { ...otherProps, key: refreshToken, selected: selected.map((value) => entryToData(t, maintenanceTypes.find((entry) => entry.label === value) ?? {
            label: value,
            value: null,
            unit: "month",
            category: "maintenance",
        })), onLoad: handleLoad, onSelect: handleSelect, enableIcons: true }));
};
export default React.memo(MaintenanceTypesSelector);
