import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo } from "react";
import SamedisCrudFileUpload, { FILE_UPLOAD_IMAGE_RESIZE, } from "../../../../../../components-care/SamedisCrudFileUpload";
import { useTenantIdOpt } from "../../../../../components/TenantContext";
import { useFormEnterpriseClientIdOpt } from "../../../../Enterprise/utils/useFormEnterpriseClientId";
import { useIncidentsContext } from "../DeviceRequestsCRUD";
import { getMessageUploadsUrl } from "./ChatUploads";
import ChatAttachmentUploadRenderer from "./ChatAttachmentUploadRenderer";
import { useFormContext } from "components-care";
const ChatBoxUploads = React.forwardRef((props, ref) => {
    const { incidentId, formId, onChange } = props;
    const tenantId = useTenantIdOpt();
    const clientId = useFormEnterpriseClientIdOpt();
    const { type } = useIncidentsContext();
    const getEndpoint = useMemo(() => getMessageUploadsUrl(clientId ? tenantId : null, clientId ?? tenantId, incidentId, false, type), [clientId, incidentId, tenantId, type]);
    const { setPostSubmitHandler, removePostSubmitHandler, model, getFieldValue, } = useFormContext();
    // trigger cache invalidation after create submit so has_uploads field is refreshed
    useEffect(() => {
        if (incidentId)
            return; // only on create new
        const field = "incident_has_upload_refresh";
        setPostSubmitHandler(field, () => {
            const id = getFieldValue("id");
            if (!id)
                throw new Error("ID is null");
            model.invalidateCacheForId(id);
        });
        return () => removePostSubmitHandler(field);
    }, [
        getFieldValue,
        incidentId,
        model,
        removePostSubmitHandler,
        setPostSubmitHandler,
    ]);
    return (_jsx(SamedisCrudFileUpload, { ref: ref, field: "uploads", accept: ".pdf,audio/*,video/*,image/*", imageDownscaleOptions: FILE_UPLOAD_IMAGE_RESIZE, convertImagesTo: "image/png", previewImages: true, onChange: onChange, getEndpoint: getEndpoint, initialId: formId, variant: ChatAttachmentUploadRenderer, previewSize: 24 }));
});
export default React.memo(ChatBoxUploads);
