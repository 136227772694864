import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import DenseLayout from "./DenseLayout";
import { getContactImage } from "../../../../../components-care/models/ContactModel";
const ContactRenderer = (props) => {
    const { data, context, ...otherProps } = props;
    const { t } = useTranslation("contact");
    const responsibleSelection = !!(context.issue || context.incident);
    return (_jsx(DenseLayout, { id: data.id, icon: _jsx("img", { src: getContactImage(data), alt: t("contact_type.enum." + data.contact_type) }), title: data.name, info: [
            data.email,
            data.url,
            (data.categories ?? [])
                .map((cat) => t("categories.enum." + cat))
                .join(", "),
        ].filter((e) => !!e), tags: responsibleSelection ? (_jsx(Typography, { color: "textSecondary", align: "right", children: [t("contact_type.enum." + data.contact_type), data.company_name]
                .filter((e) => !!e)
                .join(" • ") })) : undefined, ...otherProps }));
};
export default React.memo(ContactRenderer);
