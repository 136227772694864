import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useState } from "react";
import { FullFormDialog, semaphoreExec, showInfoDialog, useDialogContext, validateEmailRaw, } from "components-care";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { CircularProgress, Grid, Tooltip, Typography } from "@mui/material";
import DialogButtons from "../../../components/Dialogs/Buttons/DialogButtons";
import DialogButton from "../../../components/Dialogs/Buttons/DialogButton";
import { showErrorDialog } from "components-care/dist/non-standalone/Dialog/Utils";
import { useInvitesModel } from "../../../../../components-care/models/InvitesModel";
import { useModelMutation } from "components-care/dist";
import { useTenantId } from "../../../../components/TenantContext";
import { ContactModelSelectorSort, ContactModelToSelectorData, useContactModel, } from "../../../../../components-care/models/ContactModel";
import AccessProfileSelector from "../../../components/AccessProfileSelector";
import { useLruConfig } from "../../../../../utils";
import { INVALID_ID, ReactQueryRetryHandler } from "../../../../../constants";
const contactModelParams = {
    target: "tenant",
};
const useContactInfo = (contactIds) => {
    const model = useContactModel(contactModelParams);
    return useQuery(["contacts", contactIds], async () => {
        const records = [];
        // const targetLength = contactIds.length;
        const perRequest = 100;
        while (contactIds.length > 0) {
            records.push(...(await model.index({
                fieldFilter: {
                    id: {
                        type: "inSet",
                        value1: contactIds.join(",") || INVALID_ID,
                        value2: "",
                    },
                },
                sort: ContactModelSelectorSort,
                rows: perRequest,
            }))[0]);
            contactIds = contactIds.slice(perRequest);
        }
        // i guess we can ignore this because the user gets preview
        // if (records.length !== targetLength) throw new Error("Failed to find records");
        return records;
    }, {
        retry: ReactQueryRetryHandler,
    });
};
const getContactInviteError = (t, contact) => {
    if (contact.ident_user_id)
        return t("contact:invite-dialog.errors.has_account");
    if (!validateEmailRaw(contact.email))
        return t("contact:invite-dialog.errors.no_email");
    return null;
};
const ContactInviteDialogUserList = React.memo(function ContactInviteDialogUserList(props) {
    const { t } = useTranslation("contact");
    const { isLoading, data, error } = useContactInfo(props.contactIds);
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { children: t("invite-dialog.invited") }), error && _jsx(Typography, { color: "error", children: error.message }), isLoading && _jsx(Typography, { children: t("invite-dialog.loading") }), data && (_jsx("ul", { children: data.map((record) => {
                            const contactErr = getContactInviteError(t, record);
                            const label = (_jsx(Typography, { color: contactErr ? "error" : undefined, children: ContactModelToSelectorData(record).label }));
                            return (_jsx("li", { children: contactErr ? (_jsx(Tooltip, { title: contactErr, children: label })) : (label) }, record.id));
                        }) }))] }), data &&
                data.find((record) => getContactInviteError(t, record)) && (_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { children: t("invite-dialog.explainer-error") }) }))] }));
});
const ContactInviteDialog = (props) => {
    const { contactIds } = props;
    const { t } = useTranslation("contact");
    const { data } = useContactInfo(contactIds);
    const [pushDialog, popDialog] = useDialogContext();
    const inviteModel = useInvitesModel();
    const { mutateAsync: createInvite } = useModelMutation(inviteModel);
    const tenantId = useTenantId();
    const [accessProfile, setAccessProfile] = useState(null);
    const [progress, setProgress] = useState(null);
    const handleInvite = useCallback(async () => {
        if (!data)
            return;
        try {
            const [results] = await semaphoreExec(data.filter((contact) => !getContactInviteError(t, contact)), 10, (contact) => createInvite({
                invitable_id: tenantId,
                invitable_type: "access_control",
                name: contact.name,
                email: contact.email,
                profile_id: accessProfile,
                actions: {
                    contact_id: contact.id,
                },
                my_samedis: false,
            }), (done, total) => setProgress([done, total]));
            await showInfoDialog(pushDialog, {
                title: t("invite-dialog.success.title"),
                message: t("invite-dialog.success.message", { N: results.length }),
            });
        }
        catch (e) {
            await showErrorDialog(pushDialog, e);
        }
        finally {
            popDialog();
        }
    }, [accessProfile, createInvite, data, popDialog, pushDialog, t, tenantId]);
    const accessProfileLru = useLruConfig("access-profile");
    return (_jsx(FullFormDialog, { dialogTitle: t("invite-dialog.title"), maxWidth: "sm", children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { children: t("invite-dialog.explainer") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(AccessProfileSelector, { selected: accessProfile, onSelect: setAccessProfile, lru: accessProfileLru }) }), _jsx(ContactInviteDialogUserList, { contactIds: contactIds }), _jsx(Grid, { item: true, xs: 12, children: _jsx(DialogButtons, { children: _jsxs(DialogButton, { styleType: "action", onClick: handleInvite, disabled: !accessProfile ||
                                !data ||
                                !data.find((contact) => !getContactInviteError(t, contact)) ||
                                progress != null, startIcon: progress && (_jsx(CircularProgress, { variant: "determinate", value: Math.floor((progress[0] / progress[1]) * 100) })), children: [" ", progress
                                    ? t("invite-dialog.inviting", {
                                        DONE: progress[0],
                                        TOTAL: progress[1],
                                    })
                                    : t("invite-dialog.invite")] }) }) })] }) }));
};
export default React.memo(ContactInviteDialog);
