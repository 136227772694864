import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState, } from "react";
import { CrudMultiImage, MultiImage, showConfirmDialogBool, useFormContext, DefaultErrorComponent as ErrorComponent, } from "components-care";
import { useDialogContext } from "components-care/dist";
import BackendConnector from "./connectors/BackendConnector";
import { useTranslation } from "react-i18next";
import uploadImage from "../assets/img/upload-image.png";
import useAsyncMemo from "components-care/dist/utils/useAsyncMemo";
import * as Sentry from "@sentry/react";
import useLazyCrudConnector, { extractLazyCrudConnectorParams, } from "components-care/dist/backend-components/Form/useLazyCrudConnector";
import DefaultConnector from "components-care/dist/backend-integration/Connector/DefaultConnector";
export const serializeImageData = async (data, id) => ({
    ...data,
    image: data.image.startsWith("data:") ? data.image : undefined, // don't give backend url for upload
    image_url: data.image.startsWith("data:") ? undefined : data.image, // don't remove the attribute in lazy connector
    id: id ?? undefined,
});
export const deserializeImageData = (data) => ({
    id: data.id,
    name: data.name,
    image: (data.image_url ?? data.image),
});
const SamedisCrudMultiImage = (props) => {
    const [connectorProps, crudMultiImageProps] = extractLazyCrudConnectorParams(props);
    const { primaryId: primaryImageId, onPrimaryChange, onChange: _, additionalImageSource, additionalImages, additionalImagesLoading, ...otherProps } = crudMultiImageProps;
    const [pushDialog] = useDialogContext();
    const { t } = useTranslation("common");
    const [primaryId, setPrimaryId] = useState(primaryImageId);
    const primaryIdRef = useRef(primaryImageId);
    const onPrimaryChangeRef = useRef(onPrimaryChange);
    const parentRecordId = useRef(connectorProps.initialId);
    let formReadOnly;
    let setCustomFieldDirty;
    let connector = null;
    let onlySubmitMounted;
    try {
        // this is technically violating the rules of react hooks, but it doesn't matter
        // this would only cause issues if the form context would appear/disappear without this component remounting
        // which will never happen, it will always remount
        const formCtx = useFormContext();
        formReadOnly = formCtx.readOnly;
        setCustomFieldDirty = formCtx.setCustomFieldDirty;
        onlySubmitMounted = formCtx.onlySubmitMounted;
        // eslint-disable-next-line react-hooks/rules-of-hooks
        connector = useLazyCrudConnector({
            ...connectorProps,
            onParentIdChange: (id) => {
                if (id === "null")
                    return;
                parentRecordId.current = id;
            },
        }).connector;
    }
    catch (_e) {
        formReadOnly = false;
        setCustomFieldDirty = null;
        onlySubmitMounted = false;
        // using without form context, connect directly with backend
        parentRecordId.current = connectorProps.initialId;
        if (connectorProps.initialId) {
            if (!DefaultConnector.getApi)
                throw new Error("Please configure setDefaultConnectorAPI");
            const backendConnector = DefaultConnector.getApi(connectorProps.getEndpoint(connectorProps.initialId), connectorProps.extraParams);
            if (connectorProps.configureConnector) {
                connectorProps.configureConnector(backendConnector);
            }
            connector = backendConnector;
        }
    }
    const readOnly = formReadOnly || props.readOnly;
    const handlePrimaryChange = useCallback((_name, id) => {
        id = id ?? "";
        primaryIdRef.current = id;
        setPrimaryId(id);
    }, []);
    useEffect(() => {
        handlePrimaryChange(undefined, primaryImageId);
    }, [handlePrimaryChange, primaryImageId]);
    useEffect(() => {
        onPrimaryChangeRef.current = onPrimaryChange;
    }, [onPrimaryChange]);
    const additionalImages2 = useAsyncMemo(async () => {
        if (!additionalImageSource)
            return [];
        try {
            const connector = new BackendConnector(additionalImageSource);
            if (connectorProps.configureConnector)
                connectorProps.configureConnector(connector);
            return (await connector.index({ rows: 100 }))[0]
                .map(deserializeImageData)
                .map((img) => ({ ...img, readOnly: true }));
        }
        catch (e) {
            console.error("Failed loading additional images.", e);
            if (!(e instanceof Error &&
                e.name === "BackendError" &&
                e.code === "record_not_found_error")) {
                Sentry.captureException(e);
            }
            return [];
        }
    }, [additionalImageSource]);
    useEffect(() => {
        if (!connector)
            return;
        const lazyConnector = connector;
        // type check
        if (typeof lazyConnector.workQueue === "undefined")
            return;
        // this may not be an anonymous function, we rely on it's name to check if we already hooked it
        async function hook() {
            let primaryId = primaryIdRef.current;
            await origWorkQueue();
            if (primaryId && lazyConnector.mapId(primaryId) !== primaryId) {
                primaryId = lazyConnector.mapId(primaryId);
            }
            if (!parentRecordId.current)
                throw new Error("called workQueue, but parent id is null?");
            if (onPrimaryChangeRef.current)
                onPrimaryChangeRef.current(parentRecordId.current, primaryId ?? "");
        }
        if (lazyConnector.workQueue.name === hook.name) {
            // @ts-expect-error hooking
            lazyConnector.workQueue = lazyConnector.origWorkQueue;
        }
        const origWorkQueue = lazyConnector.workQueue.bind(connector);
        // @ts-expect-error hooking
        lazyConnector.origWorkQueue = lazyConnector.workQueue;
        lazyConnector.workQueue = hook;
    }, [connector]);
    // manage dirty state
    const connectorIsLazy = connector &&
        typeof connector
            .workQueue !== "undefined";
    const primaryIdMapped = primaryId && connectorIsLazy
        ? connector.mapId(primaryId)
        : primaryId;
    useEffect(() => {
        if (!setCustomFieldDirty || readOnly)
            return;
        const dirtyKey = connectorProps.field + "-primary";
        const dirty = primaryIdMapped !== primaryImageId;
        setCustomFieldDirty(dirtyKey, dirty);
        if (!onlySubmitMounted)
            return;
        return () => {
            if (!setCustomFieldDirty)
                return;
            setCustomFieldDirty(dirtyKey, false);
        };
    }, [
        connectorProps.field,
        primaryIdMapped,
        primaryImageId,
        setCustomFieldDirty,
        onlySubmitMounted,
        readOnly,
    ]);
    const handleDelete = useCallback(() => showConfirmDialogBool(pushDialog, {
        title: t("multi-image.confirm.title"),
        message: t("multi-image.confirm.message"),
        textButtonYes: t("multi-image.confirm.yes"),
        textButtonNo: t("multi-image.confirm.no"),
    }), [pushDialog, t]);
    const allAdditionalImages = useMemo(() => [...(additionalImages ?? []), ...(additionalImages2 ?? [])], [additionalImages, additionalImages2]);
    if (!connector) {
        return (_jsx(MultiImage, { previewSize: 256, primary: null, images: allAdditionalImages ?? [], uploadImage: uploadImage, ...otherProps, readOnly: true }));
    }
    return (_jsx(CrudMultiImage, { connector: connector, errorComponent: ErrorComponent, serialize: serializeImageData, deserialize: deserializeImageData, primary: (primaryId && "unmapId" in connector
            ? connector.unmapId(primaryId)
            : primaryId) || null // convert empty string to null to prevent dirty state issues if no image is set
        , onDelete: handleDelete, previewSize: 256, readOnly: readOnly, uploadImage: uploadImage, onPrimaryChange: handlePrimaryChange, additionalImages: allAdditionalImages, additionalImagesLoading: additionalImages2 === null || additionalImagesLoading, ...otherProps }));
};
export default React.memo(SamedisCrudMultiImage);
