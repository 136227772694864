import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import { Grid } from "@mui/material";
import { FormField, useFormContextLite } from "components-care";
import DeviceModelUploads from "./DeviceModelUploads";
import DeviceModelVideosEdit from "./DeviceModelVideosEdit";
import DeviceModelFirmwareVersionList from "./DeviceModelFirmwareVersionList";
import { MdmContext } from "../../components/MdmTypes";
const TabTechnical = () => {
    const target = useContext(MdmContext);
    const { id: recordId } = useFormContextLite();
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(FormField, { name: "network_connections" }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "stores_patient_data" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "software_operating_system" }) }), target !== "tenant" /* tenant can't create new versions */ &&
                            recordId /* can only create versions if we have a record to clone */ && (_jsx(Grid, { item: true, xs: 12, children: _jsx(DeviceModelFirmwareVersionList, {}) }))] }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(DeviceModelUploads, { category: "technical" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(DeviceModelVideosEdit, { category: "technical" }) })] }));
};
export default React.memo(TabTechnical);
