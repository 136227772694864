import RendererBackendObjectIdArray from "./RendererBackendObjectIdArray";
import RendererEnumSelect from "components-care/dist/backend-integration/Model/Types/Renderers/RendererEnumSelect";
import i18n from "../../../i18n";
import { throwError } from "components-care";
class RendererEnterpriseAccess extends RendererBackendObjectIdArray {
    subRenderer;
    enterpriseId;
    constructor(enterpriseId) {
        super();
        this.enterpriseId = enterpriseId;
        this.subRenderer = new RendererEnumSelect(["full_access", "inventory_ref"].map((value) => ({
            value,
            getLabel: () => i18n.t("common:enterprise_access." + value),
        })));
    }
    render(params) {
        const { visibility, value } = params;
        if (!visibility.grid || visibility.editable)
            throw new Error("Unsupported");
        const val = value.includes(this.enterpriseId)
            ? "full_access"
            : "inventory_ref";
        return this.subRenderer.render({
            ...params,
            value: val,
            initialValue: val,
            handleChange: () => throwError("Unsupported"),
        });
    }
}
export default RendererEnterpriseAccess;
