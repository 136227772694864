import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid, Typography } from "@mui/material";
import { DeviceModelUploadsNonForm } from "../../../MasterDataManagement/CatalogModels/components/DeviceModelUploads";
import { GroupBox } from "components-care";
import { useTranslation } from "react-i18next";
import { NETWORK_CONNECTION_OPTIONS } from "../../../../../components-care/models/DeviceModelsModel";
import DeviceModelViewerCommunityUploads from "./DeviceModelViewerCommunityUploads";
import VideoList from "./Videos/VideoList";
import { useActiveLanguageContext } from "../../../../../components/ActiveLanguageContext";
const DeviceModelViewerTabTechnical = (props) => {
    const { device, enterpriseClientId } = props;
    const { t } = useTranslation("device-models");
    const [activeLanguage] = useActiveLanguageContext();
    const connectivityOptions = NETWORK_CONNECTION_OPTIONS(t);
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(GroupBox, { label: t("viewer.technical.info.title"), children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { variant: "caption2", component: "p", color: "textSecondary", children: t("viewer.technical.info.os") }), _jsx(Typography, { variant: "body1", children: device.software_operating_system ||
                                            t("viewer.technical.info.os-unknown") })] }), _jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { variant: "caption2", component: "p", color: "textSecondary", children: t("viewer.technical.info.connectivity") }), _jsx(Typography, { variant: "body1", children: device.network_connections
                                            .map((con) => connectivityOptions
                                            .find((opt) => opt.value === con)
                                            ?.getLabel() ?? "unknown")
                                            .join(", ") ||
                                            t("viewer.technical.info.connectivity-unknown") })] })] }) }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(DeviceModelUploadsNonForm, { recordId: device.id, category: "technical", enterpriseClientId: enterpriseClientId, readOnly: true }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(DeviceModelViewerCommunityUploads, { category: "technical", recordId: device.id, enterpriseClientId: enterpriseClientId }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(GroupBox, { label: t("videos.technical"), children: _jsx(VideoList, { recordId: device.id, category: "technical", language: activeLanguage, scope: "public" }) }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(GroupBox, { label: t("facility-videos.technical"), children: _jsx(VideoList, { recordId: device.id, category: "technical", language: activeLanguage, scope: "tenant" }) }) })] }));
};
export default React.memo(DeviceModelViewerTabTechnical);
