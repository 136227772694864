import { ModelDataTypeDateNullableRendererCC, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityDisabledReadOnly, ModelVisibilityGridViewHidden, ModelVisibilityHidden, } from "components-care";
import RendererObjectId from "./components-care/types/renderers/RendererObjectId";
import supportedLanguagesRaw from "./assets/data/supported-languages.json";
import { getStringLabel } from "components-care/dist";
export const supportedLanguages = supportedLanguagesRaw;
export const ReactQueryRetryHandler = (count, err) => err.name === "NetworkError" && count < 3;
export const ToDateLocaleStringOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
};
export const SIG_CELL_WIDTH = 240;
export const SIG_CELL_HEIGHT = 140;
export const IS_DEV = process.env.NODE_ENV === "development";
export const ENABLE_OFFLINE_MODE = false;
export const StripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const PlaceholderDeviceModelId = "63e399b904f218000e738670";
// ID to supply in filters if filter can be empty (empty value1) and thus ignored
export const INVALID_ID = "0123456789abcdef01234567";
export const GRID_FILTER_BREAKPOINTS_SINGLE_ENTRY = {
    xs: 12,
    sm: 12,
    md: 9,
    lg: 9,
    xl: 6,
};
export const GRID_FILTER_BREAKPOINTS_TWO_ENTRIES = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 6,
};
export const GRID_FILTER_DIALOG_MEDIA_QUERY_SINGLE_ENTRY = "(max-width:799.95px)";
export const GRID_FILTER_DIALOG_MEDIA_QUERY_TWO_ENTRIES = "(max-width:1200px)";
export const GRID_FILTER_DIALOG_MEDIA_QUERY_THREE_ENTRIES = "(max-width:1250px)";
export const FORM_DIALOG_PADDING = 1;
export const VAT_REGEX = /^((AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(XI)([0-9]{9}([0-9]{3})|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|(IT)[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})$/i;
export const VAT_REGEX_COUNTRIES = [
    "AT",
    "BE",
    "BG",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "EL",
    "ES",
    "FI",
    "FR",
    "HU",
    "IE",
    "IT",
    "LT",
    "LU",
    "LV",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SE",
    "SI",
    "SK",
];
export const CURRENCY_STORAGE_KEY = "currency";
export const MARKDOWN_PURIFY_CONFIG = {
    USE_PROFILES: { html: true, svg: true },
    FORCE_BODY: true,
    FORBID_TAGS: ["style", "form", "button", "input", "textarea"],
    FORBID_ATTR: ["style"],
};
// add all subdomains to VALID_SUBDOMAIN_PREFIXES
export const MY_APP_DOMAIN_PREFIX = "my";
export const SYNC_APP_DOMAIN_PREFIX = "sync";
export const GLOBAL_MODE_APPS = ["my"];
export const VALID_SUBDOMAIN_PREFIXES = [
    MY_APP_DOMAIN_PREFIX,
    SYNC_APP_DOMAIN_PREFIX,
];
export const MultiSelectorDefaultSelectedSort = (a, b) => {
    return getStringLabel(a).localeCompare(getStringLabel(b));
};
export const AUDIT_FIELDS_DEF = (t, customData, gridVisibilityOverrides) => ({
    created_by: {
        type: new RendererObjectId(),
        getLabel: () => t("common:audit.created-by"),
        visibility: {
            overview: gridVisibilityOverrides?.created_by ?? ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    created_by_user: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => t("common:audit.created-by"),
        visibility: {
            overview: gridVisibilityOverrides?.created_by_user ??
                ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    created_at: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => t("common:audit.created-at"),
        visibility: {
            overview: gridVisibilityOverrides?.created_at ?? ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData,
        filterable: true,
        sortable: true,
        columnWidth: [160, 160, 160],
    },
    updated_by: {
        type: new RendererObjectId(),
        getLabel: () => t("common:audit.created-by"),
        visibility: {
            overview: gridVisibilityOverrides?.updated_by ?? ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    updated_by_user: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => t("common:audit.updated-by"),
        visibility: {
            overview: gridVisibilityOverrides?.updated_by_user ??
                ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    updated_at: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => t("common:audit.updated-at"),
        visibility: {
            overview: gridVisibilityOverrides?.updated_at ?? ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData,
        filterable: true,
        sortable: true,
        columnWidth: [160, 160, 160],
    },
});
export const ID_FIELD_DEF = (t) => ({
    type: new RendererObjectId(),
    getLabel: () => t("common:id"),
    visibility: {
        overview: ModelVisibilityGridViewHidden,
        create: ModelVisibilityHidden,
        edit: ModelVisibilityHidden,
    },
    filterable: true,
    sortable: true,
    columnWidth: [220, 220, 220],
});
export const preventPropagation = (evt) => {
    evt.stopPropagation();
};
