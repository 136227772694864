import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { ActionButton, BackActionButton, BackendSingleSelect, FullFormDialog, } from "components-care";
import { DeviceTypeModelToSelectorData, useDeviceTypeModel, } from "../../../../../components-care/models/DeviceTypeModel";
import DeviceTypeCatalogTreeView from "./DeviceTypeCatalogTreeView";
import { useTenantId } from "../../../../components/TenantContext";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()({
    fullHeight: {
        height: "100%",
    },
});
const DeviceTypePickerDialog = (props) => {
    const { mode, onConfirm, onCancel, initialSelectedId } = props;
    const tenantId = useTenantId();
    const { t } = useTranslation("device-type");
    const { classes } = useStyles();
    const [catalogId, setCatalogId] = useState(props.initialCatalogId);
    const [selectedId, setSelectedId] = useState(initialSelectedId);
    const catalogModel = useDeviceTypeModel(useMemo(() => ({
        tenantId,
        target: mode,
        onlyParent: true,
        extraParams: {
            "filter[scope]": "public_and_tenant",
        },
    }), [tenantId, mode]));
    const handleTreeClick = useCallback((evt) => {
        const clickId = evt.currentTarget.getAttribute("data-id");
        if (!clickId) {
            console.error("handleTreeClick click ID null", evt, evt.currentTarget);
            throw new Error("handleTreeClick click ID null");
        }
        setSelectedId(clickId);
    }, []);
    const handleConfirm = useCallback(() => {
        onConfirm(selectedId);
    }, [selectedId, onConfirm]);
    const handleCancel = useCallback(() => {
        onCancel();
    }, [onCancel]);
    return (_jsx(FullFormDialog, { dialogTitle: t("tree-select.title"), useCustomClasses: true, maxWidth: "sm", children: _jsxs(Grid, { container: true, direction: "column", spacing: 2, className: classes.fullHeight, wrap: "nowrap", children: [_jsx(Grid, { item: true, children: _jsx(BackendSingleSelect, { model: catalogModel, modelToSelectorData: DeviceTypeModelToSelectorData, selected: catalogId, onSelect: setCatalogId }) }), _jsx(Grid, { item: true, xs: true, children: catalogId && (_jsx(DeviceTypeCatalogTreeView, { mode: mode, catalogId: catalogId, activeId: selectedId, onClick: handleTreeClick })) }), _jsx(Grid, { item: true, children: _jsx(ActionButton, { onClick: handleConfirm, children: t("tree-select.confirm") }) }), _jsx(Grid, { item: true, children: _jsx(BackActionButton, { onClick: handleCancel, children: t("tree-select.cancel") }) })] }) }));
};
export default React.memo(DeviceTypePickerDialog);
