import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { advancedEnumValueToBaseSelectorData, GridSingleSelectFilter, } from "components-care";
import { useTranslation } from "react-i18next";
import { GRID_FILTER_BREAKPOINTS_SINGLE_ENTRY } from "../../../../../constants";
import { STATUS_OPTIONS } from "../../../../../components-care/models/IncidentModel";
const DeviceRequestsFilterBar = (props) => {
    const { customData, setCustomData, inDialog } = props;
    const { t } = useTranslation("incident");
    return (_jsx(_Fragment, { children: _jsx(GridSingleSelectFilter, { label: t("status.field"), options: [
                {
                    value: "",
                    label: t("grid.filter.show-all"),
                },
                ...STATUS_OPTIONS(t).map(advancedEnumValueToBaseSelectorData),
            ], selected: customData["filter[status]"] ?? "", defaultSelection: "", onSelect: (selected) => setCustomData((old) => ({
                ...old,
                "filter[status]": selected || null,
            })), dialog: inDialog, barBreakpoints: GRID_FILTER_BREAKPOINTS_SINGLE_ENTRY }) }));
};
export default React.memo(DeviceRequestsFilterBar);
