import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { makeStyles } from "tss-react/mui";
import OperationStatus from "../../../Devices/Data/components/InventoryOperationStatus";
const useStyles = makeStyles()((theme) => ({
    root: {
        position: "absolute",
        right: theme.spacing(2),
        top: theme.spacing(2),
    },
}));
const DeviceModelViewerTabModelOperationStatus = (props) => {
    const { inventory } = props;
    const { classes } = useStyles();
    return (_jsx("div", { className: classes.root, children: _jsx(OperationStatus, { status: inventory.operation_status, small: true }) }));
};
export default React.memo(DeviceModelViewerTabModelOperationStatus);
