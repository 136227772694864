import { Model, ModelDataTypeBackendSingleSelectRenderer, ModelDataTypeStringRendererCC, ModelVisibilityDisabledReadOnly, ModelVisibilityEdit, ModelVisibilityEditReadOnly, ModelVisibilityEditRequired, ModelVisibilityGridView, validatePresence, } from "components-care";
import BackendConnector from "../../connectors/BackendConnector";
import { AUDIT_FIELDS_DEF, ID_FIELD_DEF } from "../../../constants";
import { useTranslation } from "react-i18next";
import { useTenantId } from "../../../pages/components/TenantContext";
import { useMemo } from "react";
import DeviceLocationPropertyModel from "./DeviceLocationPropertyModel";
const DeviceLocationBuildingModel = (params) => new Model("device-locations-buildings-model", {
    title: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("device-locations:title.placeholder"),
        }),
        getLabel: () => params.t("device-locations:title.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 420, 260],
        validate: validatePresence,
    },
    path: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("device-locations:path.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 420, 260],
    },
    notes: {
        type: new ModelDataTypeStringRendererCC({
            multiline: true,
            rows: 5,
            placeholder: params.t("device-locations:notes.placeholder"),
        }),
        getLabel: () => params.t("device-locations:notes.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 640, 320],
    },
    property_id: {
        type: new ModelDataTypeBackendSingleSelectRenderer({
            modelToSelectorData: (data) => ({
                value: data.id,
                label: data.path,
            }),
        }),
        getLabel: () => params.t("device-locations:property_id.field"),
        getRelationModel: () => DeviceLocationPropertyModel(params),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditReadOnly,
        },
        customData: null,
    },
    ...AUDIT_FIELDS_DEF(params.t, null),
    id: {
        ...ID_FIELD_DEF(params.t),
        customData: null,
    },
}, new BackendConnector(`v4/tenants/${params.tenantId}/buildings`, "data", {
    additionalQueryParameters: params.filters,
}), [params.tenantId], {
    cacheKeysIndex: [params.filters],
    enableRequestBatching: true,
});
export const useDeviceLocationBuildingModelTranslations = () => useTranslation("device-locations");
export const useDeviceLocationBuildingModel = (params) => {
    const tenantId = useTenantId();
    const { t } = useDeviceLocationBuildingModelTranslations();
    return useMemo(() => DeviceLocationBuildingModel({ t, tenantId, ...params }), [tenantId, t, params]);
};
export default DeviceLocationBuildingModel;
