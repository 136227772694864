import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { DefaultErrorComponent as ErrorComponent, Form, throwError, useNavigate, } from "components-care";
import { useTenantModel } from "../../../components-care/models/TenantModel";
import FacilityDataForm from "../../TenantSpecific/Facility/Facility/components/FacilityDataForm";
import { useAuthProviderReset } from "../../components/AuthProvider";
const FacilityCreate = () => {
    const navigate = useNavigate();
    const resetAuthContext = useAuthProviderReset();
    const model = useTenantModel();
    const goToSelection = useCallback(() => {
        navigate("/");
    }, [navigate]);
    const goToTenant = useCallback((id) => {
        resetAuthContext();
        navigate(`/tenant/${id}`);
    }, [navigate, resetAuthContext]);
    return (_jsx(Form, { model: model, id: null, errorComponent: ErrorComponent, onSubmit: (data) => goToTenant(data.id), customProps: {
            goBack: goToSelection,
            open: () => throwError("open not implemented"),
            hasCustomSubmitHandler: true,
        }, onlyValidateMounted: true, children: FacilityDataForm }));
};
export default React.memo(FacilityCreate);
