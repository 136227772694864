import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Button, Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { combineClassNames, combineColors, throwError } from "components-care";
const useStyles = makeStyles()((theme) => ({
    action: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        "&:hover": {
            backgroundColor: `rgba(${combineColors(theme.palette.primary.main, theme.palette.action.hover).join()})`,
        },
    },
    navigation: {
        backgroundColor: theme.palette.action.disabled,
        color: theme.palette.getContrastText(theme.palette.action.disabled),
        "&:hover": {
            backgroundColor: `rgba(${combineColors(theme.palette.action.disabled, theme.palette.action.hover).join()})`,
        },
    },
}));
const DialogButton = (props) => {
    const { styleType, ...muiProps } = props;
    const { classes } = useStyles();
    const styleClass = styleType === "action"
        ? classes.action
        : styleType === "navigation"
            ? classes.navigation
            : throwError("invalid");
    return (_jsx(Grid, { item: true, children: _jsx(Button, { ...muiProps, variant: "contained", className: combineClassNames([styleClass, muiProps.className]) }) }));
};
export default React.memo(DialogButton);
