import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { DefaultFormPage, EditOnlyFormPage, FormField, RoutedTabPanelWrapper, RoutedTabs, useRoutedTabPanel, } from "components-care";
import { Box, Grid, Tab } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import FacilityBasicInformationTab from "./FacilityBasicInformationTab";
import BasicPageLayout from "../../../../../components/BasicPageLayout";
import TenantOverviewContext from "../../../MasterDataManagement/TenantManagement/components/TenantOverviewContext";
import FacilityActionsButton from "./FacilityActionsButton";
import BoxGrid from "../../../../../components/BoxGrid";
import FacilityAdminStatistics from "./FacilityAdminStatistics";
import { StripePublishableKey } from "../../../../../constants";
import TenantAdminSubscriptionManagement from "./FacilityAdminSubscriptionManagement";
import TenantInventorySettings from "./FacilitySettingsTab";
import TenantSyncSettings from "./FacilitySyncSettings";
import FacilityContactInformationTab from "./FacilityContactInformationTab";
import FacilityDataTitle from "./FacilityDataTitle";
import FacilityCreateFormPage from "./FacilityCreateFormPage";
import FacilityCreateStep1 from "./FacilityCreateStep1";
import FacilityCreateStep2 from "./FacilityCreateStep2";
import FacilityCreateStep3 from "./FacilityCreateStep3";
import { makeStyles } from "tss-react/mui";
import SamedisMobileFormButtonsPortal, { SamedisMobileFormButtonsPortalBoxGridRenderer, } from "../../../../../components-care/SamedisMobileFormButtonsPortal";
const useStyles = makeStyles()({
    mainContentWrapper: {
        padding: 0,
    },
});
const FacilityDataForm = (props) => {
    const { t } = useTranslation("tenant");
    const isAdmin = useContext(TenantOverviewContext);
    const { id } = props;
    const isCreate = !id;
    const [createStep, setCreateStep] = useState(0);
    const { classes } = useStyles();
    const tab = useRoutedTabPanel();
    const content = (_jsxs(BasicPageLayout, { title: _jsx(FacilityDataTitle, {}), actions: !isCreate &&
            [
                {
                    id: "facility-actions",
                    name: t("actions.button"),
                    icon: MenuIcon,
                    handler: FacilityActionsButton,
                    handlerProps: {
                        tenantId: id,
                        goBack: props?.customProps?.goBack,
                        isAdmin,
                    },
                },
            ], children: [isCreate ? (_jsxs(_Fragment, { children: [createStep === 0 && _jsx(FacilityCreateStep1, {}), createStep === 1 && _jsx(FacilityCreateStep2, {}), createStep === 2 && _jsx(FacilityCreateStep3, {})] })) : (_jsxs(_Fragment, { children: [_jsx(BoxGrid, { item: true, xs: 12, sm: 8, lg: 9, classNamePaper: classes.mainContentWrapper, children: _jsxs(Grid, { container: true, children: [_jsx(Grid, { item: true, xs: 12, children: _jsxs(RoutedTabs, { variant: "fullWidth", children: [_jsx(Tab, { value: "", label: t("tabs.facility") }), _jsx(Tab, { value: "contacts", label: t("tabs.contacts") }), _jsx(Tab, { value: "settings", label: t("tabs.settings") }), isAdmin && _jsx(Tab, { value: "sync", label: t("tabs.sync") }), isAdmin && StripePublishableKey && (_jsx(Tab, { value: "subscription", label: t("tabs.subscription") }))] }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Box, { p: 2, children: _jsxs(RoutedTabPanelWrapper, { children: [tab("contacts", _jsx(FacilityContactInformationTab, {})), tab("settings", _jsx(TenantInventorySettings, {})), tab("sync", _jsx(TenantSyncSettings, {})), tab("subscription", _jsx(TenantAdminSubscriptionManagement, {})), tab("", _jsx(FacilityBasicInformationTab, {}))] }) }) })] }) }), _jsxs(BoxGrid, { item: true, xs: 12, sm: 4, lg: 3, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "image" }) }), isAdmin && (_jsx(Grid, { item: true, xs: 12, children: _jsx(FacilityAdminStatistics, {}) }))] })] })), _jsx(SamedisMobileFormButtonsPortal, { render: SamedisMobileFormButtonsPortalBoxGridRenderer })] }));
    const customProps = props.customProps;
    if (isCreate)
        return (_jsx(FacilityCreateFormPage, { ...props, customProps: customProps, btnProps: { step: createStep, setStep: setCreateStep }, children: content }));
    else if (customProps)
        return (_jsx(DefaultFormPage, { ...props, customProps: customProps, children: content }));
    else
        return (_jsx(EditOnlyFormPage, { ...props, customProps: customProps, children: content }));
};
export default FacilityDataForm;
