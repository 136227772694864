import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { combineClassNames } from "components-care";
import { useAuthProviderContext } from "../../../../../components/AuthProvider";
import DOMPurify from "dompurify";
import { marked } from "marked";
import { JournalRenderer } from "../../../../../../components/MarkedRenderer";
import { MARKDOWN_PURIFY_CONFIG } from "../../../../../../constants";
import { useTranslation } from "react-i18next";
import ChatUploads from "./ChatUploads";
import useAsyncMemo from "components-care/dist/utils/useAsyncMemo";
const useStyles = makeStyles()((theme) => ({
    layout: {
        maxWidth: "80%",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
    layoutSelf: {
        float: "right",
    },
    header: {
        padding: theme.spacing(1),
        borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    },
    headerSelf: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    headerUser: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    headerSystem: {
        backgroundColor: theme.palette.action.disabled,
        color: theme.palette.getContrastText(theme.palette.action.disabled),
    },
    deleteButton: {
        padding: `calc(${theme.spacing(1)} / 2)`,
    },
    content: {
        padding: `0 ${theme.spacing(1)}`,
    },
}));
const Message = (props) => {
    const { incidentId, id: messageId, content, system, created_by, created_by_user, created_at, has_uploads, onDelete, } = props;
    const { t, i18n } = useTranslation("incident");
    const { classes } = useStyles();
    const authCtx = useAuthProviderContext();
    const self = authCtx.current_user.id === created_by;
    const handleDelete = useCallback(() => {
        if (!messageId)
            return;
        onDelete(messageId);
    }, [onDelete, messageId]);
    const mdContent = useAsyncMemo(async () => DOMPurify.isSupported // if not supported the library falls back to returning exactly what you pass into it, so we block this
        ? DOMPurify.sanitize(await marked(content ||
            (has_uploads ? t("chat.attachments") : t("chat.no-content")), {
            renderer: new JournalRenderer(),
        }), MARKDOWN_PURIFY_CONFIG)
        : "Your browser is out-of-date", [content, has_uploads]);
    return (_jsx(Grid, { item: true, xs: 12, children: _jsxs(Grid, { container: true, className: combineClassNames([
                classes.layout,
                self && classes.layoutSelf,
            ]), children: [_jsx(Grid, { item: true, xs: 12, className: combineClassNames([
                        classes.header,
                        system && classes.headerSystem,
                        !system && self && classes.headerSelf,
                        !system && !self && classes.headerUser,
                    ]), children: _jsxs(Typography, { align: "right", children: [created_by_user, ",", " ", created_at.toLocaleString(i18n.language), !system && self && messageId && (_jsx(IconButton, { className: classes.deleteButton, onClick: handleDelete, size: "large", children: _jsx(DeleteIcon, {}) }))] }) }), _jsx(Grid, { item: true, xs: 12, className: classes.content, dangerouslySetInnerHTML: {
                        __html: mdContent ?? "",
                    } }), has_uploads && (_jsx(Grid, { item: true, xs: 12, children: _jsx(Box, { px: 1, children: _jsx(ChatUploads, { incidentId: incidentId, messageId: messageId, system: system }) }) }))] }) }));
};
export default React.memo(Message);
