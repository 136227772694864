import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import { useAuthProviderContext } from "../../../../components/AuthProvider";
import { ActionButton, hasPermission } from "components-care";
import { getSubdomain, normalizeCanDos } from "../../../../../utils";
import { Grid } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
export const useDeviceModelEditLink = (device) => {
    const authCtx = useAuthProviderContext();
    return useMemo(() => {
        const deviceId = device.id;
        const isPublic = device.is_public;
        const deviceModelTenantId = device.tenant_id;
        const catalogTenantPermissions = normalizeCanDos(authCtx.current_user.tenants.find((tenant) => deviceModelTenantId === tenant.id)?.candos ?? []);
        const getMdmAdminTenant = () => {
            return authCtx.current_user.tenants.find((tenant) => normalizeCanDos(tenant.candos).includes("catalogs.mdm-admin"))?.id;
        };
        return !isPublic &&
            hasPermission(catalogTenantPermissions, "catalogs.tenant-writer")
            ? `//${getSubdomain(null)}/tenant/${deviceModelTenantId}/facility/device-models/${deviceId}`
            : isPublic && hasPermission(catalogTenantPermissions, "catalogs.mdm-user")
                ? `//${getSubdomain(null)}/tenant/${deviceModelTenantId}/mdm/catalog-models/${deviceId}`
                : isPublic && getMdmAdminTenant()
                    ? `//${getSubdomain(null)}/tenant/${getMdmAdminTenant()}/mdm/catalog-models/${deviceId}`
                    : null;
    }, [authCtx, device]);
};
const DeviceModelViewerActionEdit = (props) => {
    const { deviceEditLink, onAction } = props;
    const { t } = useTranslation("device-models");
    const jumpToDeviceModel = useCallback(() => {
        onAction();
        if (!deviceEditLink)
            return;
        window.open(deviceEditLink, "_blank");
    }, [onAction, deviceEditLink]);
    return (_jsx(Grid, { item: true, xs: 12, children: _jsx(ActionButton, { icon: _jsx(EditIcon, {}), onClick: jumpToDeviceModel, children: t("actions.edit") }) }));
};
export default React.memo(DeviceModelViewerActionEdit);
