import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Grid, IconButton, styled, Tooltip, Typography } from "@mui/material";
import { Loader, useDialogContext, useModelGet } from "components-care";
import { useTenantIdWithContext } from "../../../../components/TenantContext";
import { useInventoryModelLite } from "../../../../../components-care/models/InventoryModel";
import SamedisCrudMultiImage from "../../../../../components-care/SamedisCrudMultiImage";
import OperationStatus from "../../Data/components/InventoryOperationStatus";
import DeviceModelViewer from "../../../components/Dialogs/DeviceModelViewer/DeviceModelViewer";
import getLocalizedString from "../../../../../utils/getLocalizedString";
import { ReadMore as MoreInfoIcon } from "@mui/icons-material";
const MoreInfoButtonGrid = styled(Grid)({ position: "relative" });
const MoreInfoButton = styled(IconButton)({
    padding: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
});
const InventoryDetails = (props) => {
    const { inventoryId, noImage, enterpriseClientId } = props;
    const tenantId = useTenantIdWithContext(props.tenantId);
    const { t, i18n } = useTranslation([
        "common",
        "inventory",
        "device-models",
        "event-issue",
    ]);
    const inventoryModel = useInventoryModelLite({
        tenantId: props.tenantId,
        enterpriseClientId,
    });
    const { isLoading, error, data } = useModelGet(inventoryModel, inventoryId);
    const [fields] = data ?? [];
    const deviceId = fields?.catalog_id;
    const device_number = fields?.device_number;
    const serial_number = fields?.serial_number;
    const device_types = fields
        ? getLocalizedString(i18n.language, fields.device_type_title_labels)
        : "";
    const deviceModelTitle = fields?.device_model_title;
    const deviceModelVersion = fields?.device_model_version;
    const deviceManufacturerTypePlate = fields?.device_model_manufacturer_according_to_type_plate;
    const inventory_device_location = fields?.device_location_path;
    const has_warranty = fields?.has_warranty;
    const do_maintenance = fields?.do_maintenance;
    const linked_image_id = fields?.linked_image_id;
    const operationStatus = fields?.operation_status;
    const [pushDialog] = useDialogContext();
    const openInfo = useCallback(() => pushDialog(_jsx(DeviceModelViewer, { deviceId: fields.catalog_id, inventoryId: inventoryId, enterpriseClientId: enterpriseClientId, target: "tenant" })), [fields, inventoryId, pushDialog, enterpriseClientId]);
    const displayField = useCallback((field, value) => {
        return (value && (_jsx(Grid, { item: true, xs: 12, children: _jsxs(Typography, { variant: "caption2", children: [_jsx(Typography, { variant: "caption2", fontWeight: "500", component: "span", children: field }), ": ", value] }) })));
    }, []);
    return (_jsx(Grid, { children: error ? (error.message) : isLoading ? (_jsx(Loader, {})) : (_jsxs(Grid, { container: true, spacing: 3, children: [!noImage && (_jsx(Grid, { item: true, xs: 12, children: _jsx(SamedisCrudMultiImage, { field: "images", getEndpoint: () => enterpriseClientId
                            ? `v4/enterprise/tenants/${tenantId}/clients/${enterpriseClientId}/inventories/${inventoryId}/images`
                            : `v4/tenants/${tenantId}/inventories/${inventoryId}/images`, initialId: inventoryId, label: t("inventory:inventory-image"), placeholderImage: "/img/placeholders/device.png", additionalImageSource: deviceId
                            ? enterpriseClientId
                                ? `v4/enterprise/tenants/${tenantId}/clients/${enterpriseClientId}/device_models/${deviceId}/images`
                                : `v4/tenants/${tenantId}/device_models/${deviceId}/images`
                            : undefined, primaryId: linked_image_id, readOnly: true }) })), _jsxs(Grid, { item: true, xs: 12, container: true, spacing: 1, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(OperationStatus, { status: operationStatus, fullWidth: true }) }), _jsxs(Grid, { item: true, xs: 12, container: true, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "caption2", color: "textSecondary", children: t("inventory:device-info") }) }), displayField(t("device-models:device-type.field"), device_types), displayField(t("device-models:device-form-title.field"), [deviceModelTitle, deviceModelVersion]
                                    .filter(Boolean)
                                    .join(" • ")), displayField(t("device-models:manufacturer-according-to-type-plate.field"), deviceManufacturerTypePlate), displayField(t("inventory:device-number.field"), device_number), displayField(t("inventory:serial-number.field"), serial_number), displayField(t("inventory:device-location-id.field"), inventory_device_location), displayField(t("inventory:has_warranty.info"), has_warranty ? t("common:boolean.yes") : t("common:boolean.no")), displayField(t("inventory:do_maintenance.info"), do_maintenance
                                    ? t("common:boolean.yes")
                                    : t("common:boolean.no")), _jsx(MoreInfoButtonGrid, { item: true, children: _jsx(Tooltip, { title: t("common:links.more-info"), children: _jsx(MoreInfoButton, { onClick: openInfo, color: "primary", children: _jsx(MoreInfoIcon, {}) }) }) })] })] })] })) }));
};
export default React.memo(InventoryDetails);
