import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import TrustLevelManufacturerIcon from "./TrustLevelManufacturerIcon";
import TrustLevelCommunityIcon from "./TrustLevelCommunityIcon";
import TrustLevelTenantIcon from "./TrustLevelTenantIcon";
import TrustLevelSupplierIcon from "./TrustLevelSupplierIcon";
const TrustLevelIcon = React.forwardRef((props, ref) => {
    const { level, ...other } = props;
    switch (level) {
        case "tenant":
            return _jsx(TrustLevelTenantIcon, { ref: ref, ...other });
        case "supplier":
            return _jsx(TrustLevelSupplierIcon, { ref: ref, ...other });
        case "community":
            return _jsx(TrustLevelCommunityIcon, { ref: ref, ...other });
        case "manufacturer":
            return _jsx(TrustLevelManufacturerIcon, { ref: ref, ...other });
    }
    throw new Error("Unsupported Trust Level: " + level);
});
export default React.memo(TrustLevelIcon);
